import { DeleteForever, Person } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { FC, useState } from 'react'
import { TeamUser as TeamUserType } from '../../../../../../../../API'

interface TeamUsersProps {
  teamUser: TeamUserType
  onDelete: (userId: string) => void
  showEdit: boolean
}

const TeamUser: FC<TeamUsersProps> = (props: TeamUsersProps) => {
  const { teamUser, onDelete, showEdit } = props
  const [openDialog, setOpenDialog] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState('')

  const cancelDelete = () => {
    setOpenDialog(false)
  }

  const confirmDeleteUser = (id: string) => {
    setDeleteUserId(id)
    setOpenDialog(true)
  }

  const deleteUser = () => {
    onDelete(deleteUserId)
  }

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary={teamUser.email} />
        </ListItemButton>
        {
          showEdit && <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" color="error" onClick={() => { confirmDeleteUser(teamUser.userId) }}>
              <DeleteForever />
            </IconButton>
          </ListItemSecondaryAction>
        }
      </ListItem>

      <Dialog
        open={openDialog}
        onClose={cancelDelete}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm removing user from team"
        fullWidth={true}
      >
        <DialogTitle id="confirm-dialog-title">{'Remove user from Team?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="info" variant="contained">Cancel</Button>
          <Button onClick={deleteUser} color="error" variant="contained">Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TeamUser
