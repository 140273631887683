import React from 'react'
import { RadioInput } from 'src/components/atoms/radio-input/RadioInput'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import { OptionBluePrint, UseCase } from 'src/API'
import { Tooltip } from 'src/components/atoms/tooltip'

export const BlueprintCard: React.FC<{ disabled: boolean, chatGPT: boolean, selectedBlueprint: string, blueprint: any, handleChatGPTChange: (value: boolean) => void, handleBluePrintChange: (blueprint: OptionBluePrint) => void }> = props => {
  const { disabled, chatGPT, blueprint, selectedBlueprint, handleBluePrintChange, handleChatGPTChange } = props
  const handleRadioInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleBluePrintChange(e.target.value as OptionBluePrint)
  }
  const handleCheckedChatGPT = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChatGPTChange(e.target.checked)
  }
  const blueprintOnchange = (blueprint: OptionBluePrint) => {
    handleBluePrintChange(blueprint)
  }
  return (
    <div onClick={() => { !disabled && blueprintOnchange(blueprint.value) }}
         className={'min-w-[344px]  bg-[#171B21] py-[14px] hover:ring-2 ring-[#826AED] rounded-lg border-2 border-[#2B2D31] flex pl-[17px] pr-[17px] gap-[16px] items-center'}>
      <RadioInput disabled={disabled}
                  isActiveDisabled={selectedBlueprint === blueprint.value}
                  onChange={(e) => { handleRadioInput(e) }}
                  value={blueprint.value}
                  checked={selectedBlueprint === blueprint.value}/>
      <div className={'flex flex-col gap-[2px]'}>
        <div className={'font-medium text-sm'}>
          {blueprint.title}
          {blueprint.longDescription && (
          <Tooltip text={blueprint.longDescription} />
          )}
        </div>
        {blueprint.value !== OptionBluePrint.NONE && <>
        <div className={'text-gray-light font-normal text-xs'}>
          {blueprint.description}
        </div>
        {blueprint.useCase === UseCase.APIGW &&
        <div className='mt-1 flex items-center'>
          <CheckboxInput
            isActiveDisabled={selectedBlueprint === blueprint.value && chatGPT}
            labelText={'Build with ChatGPT'}
            onChange={handleCheckedChatGPT}
            checked={selectedBlueprint === blueprint.value ? chatGPT : false}
          />
          <Tooltip text='It builds the Lambda Function Code with ChatGPT based on description fields instead of using CloudGTO template' />
        </div>}
        </>}
      </div>
    </div>)
}
