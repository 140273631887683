import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg'
import { classNames } from 'src/utils/css'
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up.svg'
export interface Props {
  iconLeft?: React.ReactNode
  title?: string
  children: React.ReactNode
  className?: string
  itemsClassName?: string
}

export const Dropdown: React.FC<Props> = ({
  children,
  title,
  iconLeft,
  className = '',
  itemsClassName = ''
}) => (
  <Menu
    as="div"
    className="relative inline-block text-left "
  >
    {({ open }) => (
      <>
        <div>
          <Menu.Button

            className={classNames('text-[#7E858F]  inline-flex w-full justify-evenly rounded-lg items-center  text-xs font-normal focus:outline-none ', className)}
          >
            {title}
            <div className="px-3" >{iconLeft}</div>
            { open
              ? <ArrowUpIcon />
              : <ArrowDownIcon />
            }
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames('overflow-hidden  bg-[#171B21] absolute rounded-lg right-0 z-10 mt-1 m-66 origin-top-right focus:outline-none divide-y divide-gray-100', itemsClassName)}
          >
            {children}
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
)
