import React, { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg'
import { classNames } from 'src/utils/css'
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up.svg'

export interface SelectInputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string
  error?: boolean
  classname?: string
  handleValueChange: (data: string | number) => void
  selected?: string | number
  classNameOptions?: string
  bgOpenDropdown?: string
  labelText?: string
  children?: React.ReactNode
  showRequired?: boolean
  iconLeft?: React.ReactNode
}

export const SelectOutlinedInputChildren: FC<SelectInputProps> = ({
  children,
  classname = '',
  showRequired = false,
  error,
  classNameOptions = '',
  labelText = '',
  handleValueChange,
  selected,
  placeholder,
  bgOpenDropdown = 'text-[#7E858F]',
  iconLeft,
  ...props
}) => (
  <div className={classname}>
    <label
        className={`text-xs ${error ? 'text-red-default' : 'text-white'}`}
    >
      {labelText}{showRequired && <span className={'ml-[4px] text-red-default'}>*</span>}</label>
    <Listbox disabled={props.disabled} value={selected} onChange={handleValueChange}>
      {({ open }) => (
        <div className="relative  mt-[6px] ">
          <Listbox.Button
            className={classNames(
              `bg-[#2B2D31] ${iconLeft ? 'pl-[50px]' : 'pl-4'} min-h-[42px] ${props.disabled ? 'text-[#7E858F]' : 'text-white'}  cursor-pointer text-xs  relative w-full rounded-lg  py-3  pr-10 text-left  focus:outline-none `,
                `${error && 'ring-2 ring-red-default'}`
            )}
          >
            {iconLeft && <span
              className="
              bg-black-darker border-2 border-[#2B2D31] rounded-l-lg box-border  absolute inset-y-0 left-0 flex items-center px-2"
            >
           {iconLeft}
            </span>}
            {selected}
            <span
              className="
              bg-black-darker border-2 border-[#2B2D31] rounded-r-lg box-border pointer-events-none absolute inset-y-0 right-0 flex items-center px-2"
            >
            { open
              ? <ArrowUpIcon />
              : <ArrowDownIcon />
             }
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={classNames('absolute mt-0.5 py-0 pb-[10px] z-50  w-full  overflow-auto rounded-lg bg-[#2B2D31] text-white  text-xs focus:outline-none', classNameOptions)}
            >
              {children}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  </div>
)
