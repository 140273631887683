import { TextInput } from 'src/components/atoms/text-input'
import { Button } from 'src/components/atoms/button'
import React, { useState } from 'react'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'

export const ModelDataSourceRow: React.FC<{
  model: any
  models: any[]
  dataSources: any[]
  dataSourceHandler: (dataSource: any, model: any) => void
  modelIsObjectTypeHandler: (e: any, model: any) => void
  confirmRowDelete: (model: any) => void

}> = props => {
  const { model, confirmRowDelete, dataSources, dataSourceHandler, modelIsObjectTypeHandler } = props

  const [dataSource, setDataSource] = useState('')

  const handleDataSourceChange = (newDataSource: any) => {
    setDataSource(newDataSource)
    dataSourceHandler(newDataSource, model)
  }

  return (<div className={'flex items-center pt-[4px] justify-between border-t-[1px] border-[#2B2D31]'}>
    <TextInput
      error={model.name === ''}
      required
      disabled={ model.name}
      value={model.name}
      labelText={'Model:'}
      showRequired
      id="resourceName"
    />
    <CheckboxInput labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   labelText={'Object Type'}
                   tooltipText='Object or Custom Types have no Data Source.'
                   checked={model.isObjectType}
                   onChange={(e) => { modelIsObjectTypeHandler(e, model) }}
    />
    <SelectOutlinedInput
      disabled={model.isObjectType}
      error={!model?.dataSource && !model.isObjectType ? 'field is required' : ''}
      classname={'w-1/5'}
      handleValueChange={handleDataSourceChange}
      labelText={'Data Source:'}
      selected={!model.isObjectType ? dataSource : ''}
      options={
      dataSources?.map((m: any) => ({ name: m.name, value: m }))
    }
    />
    <Button classname={'mt-[28px]'} iconLeft={<TrashBinIcon/>} onClick={ () => { confirmRowDelete(model) }} variant={'darker'} title={'Delete Row'} type={'button'}/>
  </div>)
}
