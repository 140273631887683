/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCognitoInput = {
  serviceId: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  cognitoType?: string | null,
  cognitoExisting?: boolean | null,
  cognitoTestEmail?: string | null,
};

export type Cognito = {
  __typename: "Cognito",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  resourceType?: string | null,
  tags?: Array< string | null > | null,
  cognitoType?: string | null,
  cognitoTestEmail?: string | null,
  cognitoExisting?: boolean | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type CreateDynamoInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  billingMode?: string | null,
  readCapacity?: number | null,
  writeCapacity?: number | null,
  keyType?: string | null,
  primaryKeyName?: string | null,
  primaryKeyType?: string | null,
  secondaryKeyName?: string | null,
  secondaryKeyType?: string | null,
  hasIndex?: boolean | null,
  gsiName?: string | null,
  gsiKeyType?: string | null,
  primaryAttribute?: string | null,
  primaryAttributeType?: string | null,
  secondaryAttribute?: string | null,
  secondaryAttributeType?: string | null,
  projectionType?: string | null,
};

export type Dynamo = {
  __typename: "Dynamo",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  billingMode?: string | null,
  readCapacity?: number | null,
  writeCapacity?: number | null,
  keyType?: string | null,
  primaryKeyName?: string | null,
  primaryKeyType?: string | null,
  secondaryKeyName?: string | null,
  secondaryKeyType?: string | null,
  hasIndex?: boolean | null,
  GSI?:  Array<Gsi | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type Gsi = {
  __typename: "Gsi",
  gsiId?: string | null,
  gsiName?: string | null,
  gsiKeyType?: string | null,
  primaryAttribute?: string | null,
  primaryAttributeType?: string | null,
  secondaryAttribute?: string | null,
  secondaryAttributeType?: string | null,
  projectionType?: string | null,
};

export type CreateLambdaInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  runtime?: Runtime | null,
  memory?: number | null,
  timeout?: number | null,
  prune?: boolean | null,
  tracing?: boolean | null,
  vpc?: boolean | null,
  webpack?: boolean | null,
};

export enum Runtime {
  NODEJS18 = "NODEJS18",
  NODEJS16 = "NODEJS16",
  NODEJS14 = "NODEJS14",
  NODEJS12 = "NODEJS12",
}


export type Lambda = {
  __typename: "Lambda",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  runtime?: Runtime | null,
  memory?: number | null,
  timeout?: number | null,
  prune?: boolean | null,
  tracing?: boolean | null,
  vpc?: boolean | null,
  webpack?: boolean | null,
  connection?: ResourceConnection | null,
  trigger?: Trigger | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type ResourceConnection = {
  __typename: "ResourceConnection",
  id: string,
  resourceId: string,
  permissions?: Permissions | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type Permissions = {
  __typename: "Permissions",
  create?: boolean | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  scan?: boolean | null,
};

export type Trigger = {
  __typename: "Trigger",
  id: string,
  serviceId?: string | null,
  resourceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  private?: boolean | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  eventTypes?: triggerEventType | null,
  typename?: string | null,
};

export type triggerEventType = {
  __typename: "triggerEventType",
  create?: boolean | null,
  delete?: boolean | null,
};

export type CreateModelInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  dataSource?: string | null,
  fields?: string | null,
  tags?: Array< string | null > | null,
};

export type Model = {
  __typename: "Model",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  dataSource?: string | null,
  fields?: string | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type CreateOrganizationInput = {
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type CreateOrganizationUserInput = {
  email?: string | null,
};

export type OrganizationUser = {
  __typename: "OrganizationUser",
  id: string,
  email?: string | null,
  organization?: Organization | null,
  userProfile?: UserProfile | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type UserProfile = {
  __typename: "UserProfile",
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  bio?: string | null,
  avatar?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type CreateRdsInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  vpcName?: string | null,
  vpcExisting?: boolean | null,
  tags?: Array< string | null > | null,
  rdsEngine?: string | null,
};

export type Rds = {
  __typename: "Rds",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  vpcName?: string | null,
  vpcExisting?: boolean | null,
  tags?: Array< string | null > | null,
  rdsEngine?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type CreateResolverInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  model?: string | null,
  dataSource?: string | null,
  type?: string | null,
  isPipeline?: boolean | null,
  resolverType?: string | null,
  modelFieldId?: string | null,
  tags?: Array< string | null > | null,
};

export type Resolver = {
  __typename: "Resolver",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  model?: string | null,
  dataSource?: string | null,
  type?: string | null,
  resolverType?: string | null,
  isPipeline?: boolean | null,
  resolveWith_PK?: string | null,
  resolveWith_SK?: string | null,
  resolveFrom?: string | null,
  queryFrom?: string | null,
  modelFieldId?: string | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type CreateResourceConnectionInput = {
  id: string,
  resourceId: string,
  create?: boolean | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  scan?: boolean | null,
};

export type CreateRouteInput = {
  serviceId: string,
  authId?: string | null,
  resourceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  cors?: boolean | null,
  method?: Method | null,
  path?: string | null,
  type?: string | null,
  private?: boolean | null,
  tags?: Array< string | null > | null,
};

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  ANY = "ANY",
}


export type Route = {
  __typename: "Route",
  id: string,
  serviceId?: string | null,
  authId?: string | null,
  auth?: Cognito | null,
  resourceId?: string | null,
  resource?: Resource | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  cors?: boolean | null,
  method?: Method | null,
  path?: string | null,
  type?: string | null,
  private?: boolean | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type Resource = Cognito | Dynamo | Lambda | Rds | S3 | Sns | Sqs | Trigger | Vpc | Resolver | Model


export type S3 = {
  __typename: "S3",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type Sns = {
  __typename: "Sns",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  topicType?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type Sqs = {
  __typename: "Sqs",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  hasDlq?: boolean | null,
  topicType?: string | null,
  visibilityTimeout?: number | null,
  deliveryDelay?: number | null,
  msgRetentionPeriod?: number | null,
  receiveMsgWaitTimeSecs?: number | null,
  maxMsgSize?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type Vpc = {
  __typename: "Vpc",
  id: string,
  serviceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  vpcExisting?: boolean | null,
  vpcExistingId?: string | null,
  vpcExistingSubnetA?: string | null,
  vpcExistingSubnetB?: string | null,
  vpcExistingSubnetC?: string | null,
  vpcExistingSecurityGroupId?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type CreateS3Input = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
};

export type CreateServiceInput = {
  teamId?: string | null,
  orgId?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  cloudProvider?: CloudProvider | null,
  cloudProviderRegion?: CloudProviderRegion | null,
  iacFramework?: IACFramework | null,
  useCase?: UseCase | null,
  stage?: string | null,
  optionBluePrint?: OptionBluePrint | null,
  appsyncName?: string | null,
  appsyncDescription?: string | null,
  authType?: AuthType | null,
  logging?: Logging | null,
  caching?: boolean | null,
  xRay?: boolean | null,
  webpack?: boolean | null,
  prune?: boolean | null,
  chatgpt?: boolean | null,
  operatingSystem?: string | null,
  allowedTeamsIds?: Array< string > | null,
};

export enum CloudProvider {
  AWS = "AWS",
}


export enum CloudProviderRegion {
  us_east_1 = "us_east_1",
  us_east_2 = "us_east_2",
  us_west_1 = "us_west_1",
  us_west_2 = "us_west_2",
  eu_west_1 = "eu_west_1",
  eu_west_2 = "eu_west_2",
  eu_west_3 = "eu_west_3",
  eu_central_1 = "eu_central_1",
  ca_central_1 = "ca_central_1",
  ap_southeast_1 = "ap_southeast_1",
  ap_southeast_2 = "ap_southeast_2",
  ap_northeast_1 = "ap_northeast_1",
  ap_northeast_2 = "ap_northeast_2",
  ap_south_1 = "ap_south_1",
  eu_north_1 = "eu_north_1",
  sa_east_1 = "sa_east_1",
  cn_north_1 = "cn_north_1",
  us_gov_west_1 = "us_gov_west_1",
  us_gov_east_1 = "us_gov_east_1",
}


export enum IACFramework {
  SLS = "SLS",
}


export enum UseCase {
  APIGW = "APIGW",
  APPSYNC = "APPSYNC",
}


export enum OptionBluePrint {
  MONOLITHIC_LAMBDA = "MONOLITHIC_LAMBDA",
  LAMBDA_MANY_ROUTES = "LAMBDA_MANY_ROUTES",
  LAMBDA_PER_ROUTE = "LAMBDA_PER_ROUTE",
  SINGLE_DATA_SOURCE = "SINGLE_DATA_SOURCE",
  MULTI_DATA_SOURCE = "MULTI_DATA_SOURCE",
  SINGLE_TABLE_DESIGN = "SINGLE_TABLE_DESIGN",
  BYOSCHEMA = "BYOSCHEMA",
  NONE = "NONE",
}


export enum AuthType {
  Cognito = "Cognito",
  Api_Keys = "Api_Keys",
}


export enum Logging {
  NONE = "NONE",
  ALL = "ALL",
  ERROR = "ERROR",
}


export type Service = {
  __typename: "Service",
  id: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  cloudProvider?: CloudProvider | null,
  cloudProviderRegion?: CloudProviderRegion | null,
  stage?: string | null,
  iacFramework?: IACFramework | null,
  useCase?: UseCase | null,
  status?: ServiceStatus | null,
  optionBluePrint?: OptionBluePrint | null,
  appsyncName?: string | null,
  appsyncDescription?: string | null,
  authType?: AuthType | null,
  logging?: Logging | null,
  caching?: boolean | null,
  xRay?: boolean | null,
  webpack?: boolean | null,
  prune?: boolean | null,
  chatgpt?: boolean | null,
  operatingSystem?: string | null,
  organization?: Organization | null,
  allowedTeams?:  Array<Team | null > | null,
  resources?:  Array<Resource | null > | null,
  routes?:  Array<Route | null > | null,
  stars?: ListServiceStarsResult | null,
  isServiceOwner?: boolean | null,
  enums?:  Array<Enum | null > | null,
  subscriptions?:  Array<Subs | null > | null,
  customObjects?:  Array<CustomObject | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export enum ServiceStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}


export type Team = {
  __typename: "Team",
  id: string,
  name?: string | null,
  description?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ListServiceStarsResult = {
  __typename: "ListServiceStarsResult",
  ServiceStars?:  Array<ServiceStar | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ServiceStar = {
  __typename: "ServiceStar",
  id: string,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type Enum = {
  __typename: "Enum",
  id?: string | null,
  name?: string | null,
  serviceId?: string | null,
  fields?: Array< string | null > | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type Subs = {
  __typename: "Subs",
  id?: string | null,
  name?: string | null,
  serviceId?: string | null,
  description?: string | null,
  args?:  Array<Arg | null > | null,
  type?: string | null,
  array?: boolean | null,
  required?: boolean | null,
  derivative?: Derivative | null,
  tags?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  typename?: string | null,
};

export type Arg = {
  __typename: "Arg",
  name?: string | null,
  type?: string | null,
  required?: boolean | null,
};

export type Derivative = {
  __typename: "Derivative",
  name?: string | null,
  mutations?: Array< string | null > | null,
};

export type CustomObject = {
  __typename: "CustomObject",
  id?: string | null,
  serviceId?: string | null,
  name?: string | null,
  description?: string | null,
  fields?:  Array<CustomObjectField | null > | null,
  tags?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  typename?: string | null,
};

export type CustomObjectField = {
  __typename: "CustomObjectField",
  name?: string | null,
  fieldType?: string | null,
  type?: string | null,
  required?: boolean | null,
  array?: boolean | null,
};

export type CreateServiceStarInput = {
  serviceId: string,
};

export type CreateSnsInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  topicType?: string | null,
};

export type CreateSqsInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  hasDlq?: boolean | null,
  visibilityTimeout?: number | null,
  deliveryDelay?: number | null,
  msgRetentionPeriod?: number | null,
  receiveMsgWaitTimeSecs?: number | null,
  maxMsgSize?: number | null,
  topicType?: string | null,
};

export type CreateTeamInput = {
  orgId: string,
  name?: string | null,
  description?: string | null,
};

export type CreateTeamServiceInput = {
  teamId: string,
  serviceId: string,
};

export type TeamService = {
  __typename: "TeamService",
  id: string,
  teamId: string,
  serviceId: string,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type CreateTeamUserInput = {
  teamId: string,
  userId: string,
  email?: string | null,
};

export type TeamUser = {
  __typename: "TeamUser",
  id: string,
  userId: string,
  team: Team,
  email?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type CreateTriggerInput = {
  serviceId: string,
  resourceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  private?: boolean | null,
  tags?: Array< string | null > | null,
  eventTypes?: triggerEventInput | null,
};

export type triggerEventInput = {
  create?: boolean | null,
  delete?: boolean | null,
};

export type CreateUserProfileInput = {
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  avatar?: string | null,
  bio?: string | null,
};

export type CreateVpcInput = {
  serviceId: string,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  vpcExisting?: boolean | null,
  vpcExistingId?: string | null,
  vpcExistingSubnetA?: string | null,
  vpcExistingSubnetB?: string | null,
  vpcExistingSubnetC?: string | null,
  vpcExistingSecurityGroupId?: string | null,
};

export type DeleteCognitoInput = {
  id: string,
  serviceId: string,
};

export type DeleteDynamoInput = {
  id: string,
  serviceId: string,
};

export type DeleteLambdaInput = {
  id: string,
  serviceId: string,
};

export type DeleteModelInput = {
  id: string,
  serviceId: string,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type DeleteOrganizationUserInput = {
  id: string,
  orgId: string,
};

export type DeleteRdsInput = {
  id: string,
  serviceId: string,
};

export type DeleteResolverInput = {
  id: string,
  serviceId: string,
};

export type DeleteResourceConnectionInput = {
  id: string,
  resourceId: string,
};

export type DeleteRouteInput = {
  id: string,
  serviceId: string,
};

export type DeleteS3Input = {
  id: string,
  serviceId: string,
};

export type DeleteServiceInput = {
  id: string,
  teamId?: string | null,
};

export type DeleteServiceStarInput = {
  serviceId: string,
};

export type DeleteSnsInput = {
  id: string,
  serviceId: string,
};

export type DeleteSqsInput = {
  id: string,
  serviceId: string,
};

export type DeleteTeamInput = {
  id: string,
  orgId: string,
};

export type DeleteTeamServiceInput = {
  teamId: string,
  serviceId: string,
};

export type DeleteTeamUserInput = {
  teamId: string,
  userId: string,
};

export type DeleteTriggerInput = {
  id: string,
  serviceId: string,
};

export type DeleteUserProfileInput = {
  id: string,
};

export type DeleteVpcInput = {
  id: string,
  serviceId: string,
};

export type ParseSchemaInput = {
  schema: string,
};

export type ParseSchemaResult = {
  __typename: "ParseSchemaResult",
  status?: string | null,
  result?: string | null,
};

export type UpdateCognitoInput = {
  id: string,
  serviceId: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  cognitoType?: string | null,
  cognitoExisting?: boolean | null,
  cognitoTestEmail?: string | null,
};

export type UpdateCreateServiceStatusInput = {
  id: string,
  status: string,
  done?: boolean | null,
  error?: boolean | null,
};

export type OnUpdateCreateServiceStatusResult = {
  __typename: "OnUpdateCreateServiceStatusResult",
  serviceId: string,
  status?: string | null,
  done?: boolean | null,
  error?: boolean | null,
};

export type UpdateDynamoInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  billingMode?: string | null,
  readCapacity?: number | null,
  writeCapacity?: number | null,
  keyType?: string | null,
  primaryKeyName?: string | null,
  primaryKeyType?: string | null,
  secondaryKeyName?: string | null,
  secondaryKeyType?: string | null,
  hasIndex?: boolean | null,
  gsiName?: string | null,
  gsiKeyType?: string | null,
  primaryAttribute?: string | null,
  primaryAttributeType?: string | null,
  secondaryAttribute?: string | null,
  secondaryAttributeType?: string | null,
  projectionType?: string | null,
};

export type UpdateLambdaInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  runtime?: Runtime | null,
  memory?: number | null,
  timeout?: number | null,
  prune?: boolean | null,
  tracing?: boolean | null,
  vpc?: boolean | null,
  webpack?: boolean | null,
};

export type UpdateModelInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  dataSource?: string | null,
  fields?: string | null,
  tags?: Array< string | null > | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
};

export type UpdateOrganizationUserInput = {
  id: string,
  orgId: string,
  email?: string | null,
};

export type UpdateRdsInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  vpcName?: string | null,
  vpcExisting?: boolean | null,
  tags?: Array< string | null > | null,
  rdsEngine?: string | null,
};

export type UpdateResolverInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  model?: string | null,
  dataSource?: string | null,
  type?: string | null,
  isPipeline?: boolean | null,
  resolverType?: string | null,
  tags?: Array< string | null > | null,
};

export type UpdateResourceConnectionInput = {
  id: string,
  resourceId: string,
  create?: boolean | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  scan?: boolean | null,
};

export type UpdateRouteInput = {
  id: string,
  serviceId: string,
  authId?: string | null,
  resourceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  cors?: boolean | null,
  method?: Method | null,
  path?: string | null,
  type?: string | null,
  private?: boolean | null,
  tags?: Array< string | null > | null,
};

export type UpdateS3Input = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
};

export type UpdateServiceInput = {
  id: string,
  teamId?: string | null,
  orgId?: string | null,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  cloudProvider?: CloudProvider | null,
  cloudProviderRegion?: CloudProviderRegion | null,
  iacFramework?: IACFramework | null,
  useCase?: UseCase | null,
  stage?: string | null,
  optionBluePrint?: OptionBluePrint | null,
  appsyncName?: string | null,
  appsyncDescription?: string | null,
  authType?: AuthType | null,
  logging?: Logging | null,
  caching?: boolean | null,
  xRay?: boolean | null,
  webpack?: boolean | null,
  prune?: boolean | null,
  chatgpt?: boolean | null,
  operatingSystem?: string | null,
  allowedTeamsIds?: Array< string > | null,
};

export type UpdateSnsInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  topicType?: string | null,
};

export type UpdateSqsInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  hasDlq?: boolean | null,
  visibilityTimeout?: number | null,
  deliveryDelay?: number | null,
  msgRetentionPeriod?: number | null,
  receiveMsgWaitTimeSecs?: number | null,
  maxMsgSize?: number | null,
  topicType?: string | null,
};

export type UpdateTeamInput = {
  id: string,
  orgId: string,
  name?: string | null,
  description?: string | null,
};

export type UpdateTeamServiceInput = {
  teamId: string,
  serviceId: string,
};

export type UpdateTeamUserInput = {
  teamId: string,
  userId: string,
  email?: string | null,
};

export type UpdateTriggerInput = {
  id: string,
  serviceId: string,
  resourceId?: string | null,
  user?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  private?: boolean | null,
  tags?: Array< string | null > | null,
  eventTypes?: triggerEventInput | null,
};

export type UpdateUserProfileInput = {
  firstName?: string | null,
  lastName?: string | null,
  avatar?: string | null,
  bio?: string | null,
};

export type UpdateVpcInput = {
  id: string,
  serviceId: string,
  user: string,
  name?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  vpcExisting?: boolean | null,
  vpcExistingId?: string | null,
  vpcExistingSubnetA?: string | null,
  vpcExistingSubnetB?: string | null,
  vpcExistingSubnetC?: string | null,
  vpcExistingSecurityGroupId?: string | null,
};

export type ListServicesInput = {
  teamId?: string | null,
  name?: string | null,
  nextToken?: string | null,
};

export type ListServicesResult = {
  __typename: "ListServicesResult",
  Services?:  Array<Service | null > | null,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type GetCognitoInput = {
  serviceId: string,
  id: string,
};

export type GetDynamoInput = {
  serviceId: string,
  id: string,
};

export type GetLambdaInput = {
  serviceId: string,
  id: string,
};

export type GetModelInput = {
  serviceId: string,
  id: string,
};

export type GetOrganizationUserInput = {
  id: string,
  orgId: string,
};

export type GetRdsInput = {
  serviceId: string,
  id: string,
};

export type GetResolverInput = {
  serviceId: string,
  id: string,
};

export type GetResourceConnectionInput = {
  id: string,
  resourceId: string,
};

export type GetRouteInput = {
  serviceId: string,
  id: string,
};

export type GetS3Input = {
  serviceId: string,
  id: string,
};

export type GetServiceInput = {
  id: string,
  teamId?: string | null,
};

export type GetServiceStarInput = {
  serviceId: string,
};

export type GetSnsInput = {
  serviceId: string,
  id: string,
};

export type GetSqsInput = {
  serviceId: string,
  id: string,
};

export type GetTeamInput = {
  id: string,
  orgId: string,
};

export type GetTeamServiceInput = {
  teamId: string,
  serviceId: string,
};

export type GetTeamUserInput = {
  teamId: string,
  userId: string,
};

export type GetTriggerInput = {
  serviceId: string,
  id: string,
};

export type GetUserProfileInput = {
  id?: string | null,
};

export type GetVpcInput = {
  serviceId: string,
  id: string,
};

export type ListCognitosInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCognitosResult = {
  __typename: "ListCognitosResult",
  Cognitos?:  Array<Cognito | null > | null,
  nextToken?: string | null,
};

export type ListDynamosInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDynamosResult = {
  __typename: "ListDynamosResult",
  Dynamos?:  Array<Dynamo | null > | null,
  nextToken?: string | null,
};

export type ListLambdasInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLambdasResult = {
  __typename: "ListLambdasResult",
  Lambdas?:  Array<Lambda | null > | null,
  nextToken?: string | null,
};

export type ListModelsInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModelsResult = {
  __typename: "ListModelsResult",
  Models?:  Array<Model | null > | null,
  nextToken?: string | null,
};

export type ListOrganizationUsersInput = {
  orgId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationUsersResult = {
  __typename: "ListOrganizationUsersResult",
  OrganizationUsers?:  Array<OrganizationUser | null > | null,
  nextToken?: string | null,
};

export type ListOrganizationsInput = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsResult = {
  __typename: "ListOrganizationsResult",
  Organizations?:  Array<Organization | null > | null,
  nextToken?: string | null,
};

export type ListRdssInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRdssResult = {
  __typename: "ListRdssResult",
  Rdss?:  Array<Rds | null > | null,
  nextToken?: string | null,
};

export type ListResolversInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResolversResult = {
  __typename: "ListResolversResult",
  Resolvers?:  Array<Resolver | null > | null,
  nextToken?: string | null,
};

export type ListResourceConnectionsInput = {
  resourceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResourceConnectionsResult = {
  __typename: "ListResourceConnectionsResult",
  ResourceConnections?:  Array<ResourceConnection | null > | null,
  nextToken?: string | null,
};

export type ListRoutesInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRoutesResult = {
  __typename: "ListRoutesResult",
  Routes?:  Array<Route | null > | null,
  nextToken?: string | null,
};

export type ListS3sInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListS3sResult = {
  __typename: "ListS3sResult",
  S3s?:  Array<S3 | null > | null,
  nextToken?: string | null,
};

export type ListServiceStarsInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSnssInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSnssResult = {
  __typename: "ListSnssResult",
  Snss?:  Array<Sns | null > | null,
  nextToken?: string | null,
};

export type ListSqssInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSqssResult = {
  __typename: "ListSqssResult",
  Sqss?:  Array<Sqs | null > | null,
  nextToken?: string | null,
};

export type ListTeamServicesInput = {
  teamId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamServicesResult = {
  __typename: "ListTeamServicesResult",
  TeamServices?:  Array<TeamService | null > | null,
  nextToken?: string | null,
};

export type ListTeamUsersInput = {
  teamId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamUsersResult = {
  __typename: "ListTeamUsersResult",
  TeamUsers?:  Array<TeamUser | null > | null,
  nextToken?: string | null,
};

export type ListTeamsInput = {
  orgId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsResult = {
  __typename: "ListTeamsResult",
  Teams?:  Array<Team | null > | null,
  nextToken?: string | null,
};

export type ListTriggersInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriggersResult = {
  __typename: "ListTriggersResult",
  Triggers?:  Array<Trigger | null > | null,
  nextToken?: string | null,
};

export type ListUserProfilesInput = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserProfilesResult = {
  __typename: "ListUserProfilesResult",
  UserProfiles?:  Array<UserProfile | null > | null,
  nextToken?: string | null,
};

export type ListVpcsInput = {
  serviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVpcsResult = {
  __typename: "ListVpcsResult",
  Vpcs?:  Array<Vpc | null > | null,
  nextToken?: string | null,
};

export type CreateCognitoMutationVariables = {
  input: CreateCognitoInput,
};

export type CreateCognitoMutation = {
  createCognito:  {
    __typename: "Cognito",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    resourceType?: string | null,
    tags?: Array< string | null > | null,
    cognitoType?: string | null,
    cognitoTestEmail?: string | null,
    cognitoExisting?: boolean | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateDynamoMutationVariables = {
  input: CreateDynamoInput,
};

export type CreateDynamoMutation = {
  createDynamo:  {
    __typename: "Dynamo",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    billingMode?: string | null,
    readCapacity?: number | null,
    writeCapacity?: number | null,
    keyType?: string | null,
    primaryKeyName?: string | null,
    primaryKeyType?: string | null,
    secondaryKeyName?: string | null,
    secondaryKeyType?: string | null,
    hasIndex?: boolean | null,
    GSI?:  Array< {
      __typename: "Gsi",
      gsiId?: string | null,
      gsiName?: string | null,
      gsiKeyType?: string | null,
      primaryAttribute?: string | null,
      primaryAttributeType?: string | null,
      secondaryAttribute?: string | null,
      secondaryAttributeType?: string | null,
      projectionType?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateLambdaMutationVariables = {
  input: CreateLambdaInput,
};

export type CreateLambdaMutation = {
  createLambda:  {
    __typename: "Lambda",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    runtime?: Runtime | null,
    memory?: number | null,
    timeout?: number | null,
    prune?: boolean | null,
    tracing?: boolean | null,
    vpc?: boolean | null,
    webpack?: boolean | null,
    connection?:  {
      __typename: "ResourceConnection",
      id: string,
      resourceId: string,
      permissions?:  {
        __typename: "Permissions",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        scan?: boolean | null,
      } | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      serviceId?: string | null,
      resourceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      eventTypes?:  {
        __typename: "triggerEventType",
        create?: boolean | null,
        delete?: boolean | null,
      } | null,
      typename?: string | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateModelMutationVariables = {
  input: CreateModelInput,
};

export type CreateModelMutation = {
  createModel:  {
    __typename: "Model",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    dataSource?: string | null,
    fields?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
};

export type CreateOrganizationMutation = {
  createOrganization:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateOrganizationUserMutationVariables = {
  input: CreateOrganizationUserInput,
};

export type CreateOrganizationUserMutation = {
  createOrganizationUser:  {
    __typename: "OrganizationUser",
    id: string,
    email?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    userProfile?:  {
      __typename: "UserProfile",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: string | null,
      avatar?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateRdsMutationVariables = {
  input: CreateRdsInput,
};

export type CreateRdsMutation = {
  createRds:  {
    __typename: "Rds",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    vpcName?: string | null,
    vpcExisting?: boolean | null,
    tags?: Array< string | null > | null,
    rdsEngine?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateResolverMutationVariables = {
  input: CreateResolverInput,
};

export type CreateResolverMutation = {
  createResolver:  {
    __typename: "Resolver",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    model?: string | null,
    dataSource?: string | null,
    type?: string | null,
    resolverType?: string | null,
    isPipeline?: boolean | null,
    resolveWith_PK?: string | null,
    resolveWith_SK?: string | null,
    resolveFrom?: string | null,
    queryFrom?: string | null,
    modelFieldId?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateResourceConnectionMutationVariables = {
  input: CreateResourceConnectionInput,
};

export type CreateResourceConnectionMutation = {
  createResourceConnection:  {
    __typename: "ResourceConnection",
    id: string,
    resourceId: string,
    permissions?:  {
      __typename: "Permissions",
      create?: boolean | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      scan?: boolean | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateRouteMutationVariables = {
  input: CreateRouteInput,
};

export type CreateRouteMutation = {
  createRoute:  {
    __typename: "Route",
    id: string,
    serviceId?: string | null,
    authId?: string | null,
    auth?:  {
      __typename: "Cognito",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      resourceType?: string | null,
      tags?: Array< string | null > | null,
      cognitoType?: string | null,
      cognitoTestEmail?: string | null,
      cognitoExisting?: boolean | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null,
    resourceId?: string | null,
    resource: ( {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Dynamo",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        billingMode?: string | null,
        readCapacity?: number | null,
        writeCapacity?: number | null,
        keyType?: string | null,
        primaryKeyName?: string | null,
        primaryKeyType?: string | null,
        secondaryKeyName?: string | null,
        secondaryKeyType?: string | null,
        hasIndex?: boolean | null,
        GSI?:  Array< {
          __typename: string,
          gsiId?: string | null,
          gsiName?: string | null,
          gsiKeyType?: string | null,
          primaryAttribute?: string | null,
          primaryAttributeType?: string | null,
          secondaryAttribute?: string | null,
          secondaryAttributeType?: string | null,
          projectionType?: string | null,
        } | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Lambda",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        runtime?: Runtime | null,
        memory?: number | null,
        timeout?: number | null,
        prune?: boolean | null,
        tracing?: boolean | null,
        vpc?: boolean | null,
        webpack?: boolean | null,
        connection?:  {
          __typename: string,
          id: string,
          resourceId: string,
          permissions?:  {
            __typename: string,
            create?: boolean | null,
            read?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
            scan?: boolean | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null,
        trigger?:  {
          __typename: string,
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Rds",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        vpcName?: string | null,
        vpcExisting?: boolean | null,
        tags?: Array< string | null > | null,
        rdsEngine?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "S3",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sns",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        topicType?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sqs",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        hasDlq?: boolean | null,
        topicType?: string | null,
        visibilityTimeout?: number | null,
        deliveryDelay?: number | null,
        msgRetentionPeriod?: number | null,
        receiveMsgWaitTimeSecs?: number | null,
        maxMsgSize?: number | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Trigger",
        id: string,
        serviceId?: string | null,
        resourceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        eventTypes?:  {
          __typename: string,
          create?: boolean | null,
          delete?: boolean | null,
        } | null,
        typename?: string | null,
      } | {
        __typename: "Vpc",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        vpcExisting?: boolean | null,
        vpcExistingId?: string | null,
        vpcExistingSubnetA?: string | null,
        vpcExistingSubnetB?: string | null,
        vpcExistingSubnetC?: string | null,
        vpcExistingSecurityGroupId?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Resolver",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        model?: string | null,
        dataSource?: string | null,
        type?: string | null,
        resolverType?: string | null,
        isPipeline?: boolean | null,
        resolveWith_PK?: string | null,
        resolveWith_SK?: string | null,
        resolveFrom?: string | null,
        queryFrom?: string | null,
        modelFieldId?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Model",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        dataSource?: string | null,
        fields?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      }
    ) | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    cors?: boolean | null,
    method?: Method | null,
    path?: string | null,
    type?: string | null,
    private?: boolean | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateS3MutationVariables = {
  input: CreateS3Input,
};

export type CreateS3Mutation = {
  createS3:  {
    __typename: "S3",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateServiceMutationVariables = {
  input: CreateServiceInput,
};

export type CreateServiceMutation = {
  createService:  {
    __typename: "Service",
    id: string,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    cloudProvider?: CloudProvider | null,
    cloudProviderRegion?: CloudProviderRegion | null,
    stage?: string | null,
    iacFramework?: IACFramework | null,
    useCase?: UseCase | null,
    status?: ServiceStatus | null,
    optionBluePrint?: OptionBluePrint | null,
    appsyncName?: string | null,
    appsyncDescription?: string | null,
    authType?: AuthType | null,
    logging?: Logging | null,
    caching?: boolean | null,
    xRay?: boolean | null,
    webpack?: boolean | null,
    prune?: boolean | null,
    chatgpt?: boolean | null,
    operatingSystem?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    allowedTeams?:  Array< {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    resources:  Array<( {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Dynamo",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        billingMode?: string | null,
        readCapacity?: number | null,
        writeCapacity?: number | null,
        keyType?: string | null,
        primaryKeyName?: string | null,
        primaryKeyType?: string | null,
        secondaryKeyName?: string | null,
        secondaryKeyType?: string | null,
        hasIndex?: boolean | null,
        GSI?:  Array< {
          __typename: string,
          gsiId?: string | null,
          gsiName?: string | null,
          gsiKeyType?: string | null,
          primaryAttribute?: string | null,
          primaryAttributeType?: string | null,
          secondaryAttribute?: string | null,
          secondaryAttributeType?: string | null,
          projectionType?: string | null,
        } | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Lambda",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        runtime?: Runtime | null,
        memory?: number | null,
        timeout?: number | null,
        prune?: boolean | null,
        tracing?: boolean | null,
        vpc?: boolean | null,
        webpack?: boolean | null,
        connection?:  {
          __typename: string,
          id: string,
          resourceId: string,
          permissions?:  {
            __typename: string,
            create?: boolean | null,
            read?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
            scan?: boolean | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null,
        trigger?:  {
          __typename: string,
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Rds",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        vpcName?: string | null,
        vpcExisting?: boolean | null,
        tags?: Array< string | null > | null,
        rdsEngine?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "S3",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sns",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        topicType?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sqs",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        hasDlq?: boolean | null,
        topicType?: string | null,
        visibilityTimeout?: number | null,
        deliveryDelay?: number | null,
        msgRetentionPeriod?: number | null,
        receiveMsgWaitTimeSecs?: number | null,
        maxMsgSize?: number | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Trigger",
        id: string,
        serviceId?: string | null,
        resourceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        eventTypes?:  {
          __typename: string,
          create?: boolean | null,
          delete?: boolean | null,
        } | null,
        typename?: string | null,
      } | {
        __typename: "Vpc",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        vpcExisting?: boolean | null,
        vpcExistingId?: string | null,
        vpcExistingSubnetA?: string | null,
        vpcExistingSubnetB?: string | null,
        vpcExistingSubnetC?: string | null,
        vpcExistingSecurityGroupId?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Resolver",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        model?: string | null,
        dataSource?: string | null,
        type?: string | null,
        resolverType?: string | null,
        isPipeline?: boolean | null,
        resolveWith_PK?: string | null,
        resolveWith_SK?: string | null,
        resolveFrom?: string | null,
        queryFrom?: string | null,
        modelFieldId?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Model",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        dataSource?: string | null,
        fields?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      }
    ) | null > | null,
    routes?:  Array< {
      __typename: "Route",
      id: string,
      serviceId?: string | null,
      authId?: string | null,
      auth?:  {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null,
      resourceId?: string | null,
      resource: ( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      cors?: boolean | null,
      method?: Method | null,
      path?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    stars?:  {
      __typename: "ListServiceStarsResult",
      ServiceStars?:  Array< {
        __typename: "ServiceStar",
        id: string,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
      total?: number | null,
    } | null,
    isServiceOwner?: boolean | null,
    enums?:  Array< {
      __typename: "Enum",
      id?: string | null,
      name?: string | null,
      serviceId?: string | null,
      fields?: Array< string | null > | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    subscriptions?:  Array< {
      __typename: "Subs",
      id?: string | null,
      name?: string | null,
      serviceId?: string | null,
      description?: string | null,
      args?:  Array< {
        __typename: "Arg",
        name?: string | null,
        type?: string | null,
        required?: boolean | null,
      } | null > | null,
      type?: string | null,
      array?: boolean | null,
      required?: boolean | null,
      derivative?:  {
        __typename: "Derivative",
        name?: string | null,
        mutations?: Array< string | null > | null,
      } | null,
      tags?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      typename?: string | null,
    } | null > | null,
    customObjects?:  Array< {
      __typename: "CustomObject",
      id?: string | null,
      serviceId?: string | null,
      name?: string | null,
      description?: string | null,
      fields?:  Array< {
        __typename: "CustomObjectField",
        name?: string | null,
        fieldType?: string | null,
        type?: string | null,
        required?: boolean | null,
        array?: boolean | null,
      } | null > | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateServiceStarMutationVariables = {
  input: CreateServiceStarInput,
};

export type CreateServiceStarMutation = {
  createServiceStar:  {
    __typename: "ServiceStar",
    id: string,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateSnsMutationVariables = {
  input: CreateSnsInput,
};

export type CreateSnsMutation = {
  createSns:  {
    __typename: "Sns",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    topicType?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateSqsMutationVariables = {
  input: CreateSqsInput,
};

export type CreateSqsMutation = {
  createSqs:  {
    __typename: "Sqs",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    hasDlq?: boolean | null,
    topicType?: string | null,
    visibilityTimeout?: number | null,
    deliveryDelay?: number | null,
    msgRetentionPeriod?: number | null,
    receiveMsgWaitTimeSecs?: number | null,
    maxMsgSize?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
};

export type CreateTeamMutation = {
  createTeam:  {
    __typename: "Team",
    id: string,
    name?: string | null,
    description?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateTeamServiceMutationVariables = {
  input: CreateTeamServiceInput,
};

export type CreateTeamServiceMutation = {
  createTeamService:  {
    __typename: "TeamService",
    id: string,
    teamId: string,
    serviceId: string,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateTeamUserMutationVariables = {
  input: CreateTeamUserInput,
};

export type CreateTeamUserMutation = {
  createTeamUser:  {
    __typename: "TeamUser",
    id: string,
    userId: string,
    team:  {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    },
    email?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateTriggerMutationVariables = {
  input: CreateTriggerInput,
};

export type CreateTriggerMutation = {
  createTrigger:  {
    __typename: "Trigger",
    id: string,
    serviceId?: string | null,
    resourceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    private?: boolean | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    eventTypes?:  {
      __typename: "triggerEventType",
      create?: boolean | null,
      delete?: boolean | null,
    } | null,
    typename?: string | null,
  },
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput,
};

export type CreateUserProfileMutation = {
  createUserProfile:  {
    __typename: "UserProfile",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: string | null,
    avatar?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type CreateVpcMutationVariables = {
  input: CreateVpcInput,
};

export type CreateVpcMutation = {
  createVpc:  {
    __typename: "Vpc",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    vpcExisting?: boolean | null,
    vpcExistingId?: string | null,
    vpcExistingSubnetA?: string | null,
    vpcExistingSubnetB?: string | null,
    vpcExistingSubnetC?: string | null,
    vpcExistingSecurityGroupId?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type DeleteCognitoMutationVariables = {
  input: DeleteCognitoInput,
};

export type DeleteCognitoMutation = {
  deleteCognito: boolean,
};

export type DeleteDynamoMutationVariables = {
  input: DeleteDynamoInput,
};

export type DeleteDynamoMutation = {
  deleteDynamo: boolean,
};

export type DeleteLambdaMutationVariables = {
  input: DeleteLambdaInput,
};

export type DeleteLambdaMutation = {
  deleteLambda: boolean,
};

export type DeleteModelMutationVariables = {
  input: DeleteModelInput,
};

export type DeleteModelMutation = {
  deleteModel: boolean,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
};

export type DeleteOrganizationMutation = {
  deleteOrganization: boolean,
};

export type DeleteOrganizationUserMutationVariables = {
  input: DeleteOrganizationUserInput,
};

export type DeleteOrganizationUserMutation = {
  deleteOrganizationUser: boolean,
};

export type DeleteRdsMutationVariables = {
  input: DeleteRdsInput,
};

export type DeleteRdsMutation = {
  deleteRds: boolean,
};

export type DeleteResolverMutationVariables = {
  input: DeleteResolverInput,
};

export type DeleteResolverMutation = {
  deleteResolver: boolean,
};

export type DeleteResourceConnectionMutationVariables = {
  input: DeleteResourceConnectionInput,
};

export type DeleteResourceConnectionMutation = {
  deleteResourceConnection: boolean,
};

export type DeleteRouteMutationVariables = {
  input: DeleteRouteInput,
};

export type DeleteRouteMutation = {
  deleteRoute: boolean,
};

export type DeleteS3MutationVariables = {
  input: DeleteS3Input,
};

export type DeleteS3Mutation = {
  deleteS3: boolean,
};

export type DeleteServiceMutationVariables = {
  input: DeleteServiceInput,
};

export type DeleteServiceMutation = {
  deleteService: boolean,
};

export type DeleteServiceStarMutationVariables = {
  input: DeleteServiceStarInput,
};

export type DeleteServiceStarMutation = {
  deleteServiceStar: boolean,
};

export type DeleteSnsMutationVariables = {
  input: DeleteSnsInput,
};

export type DeleteSnsMutation = {
  deleteSns: boolean,
};

export type DeleteSqsMutationVariables = {
  input: DeleteSqsInput,
};

export type DeleteSqsMutation = {
  deleteSqs: boolean,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
};

export type DeleteTeamMutation = {
  deleteTeam: boolean,
};

export type DeleteTeamServiceMutationVariables = {
  input: DeleteTeamServiceInput,
};

export type DeleteTeamServiceMutation = {
  deleteTeamService: boolean,
};

export type DeleteTeamUserMutationVariables = {
  input: DeleteTeamUserInput,
};

export type DeleteTeamUserMutation = {
  deleteTeamUser: boolean,
};

export type DeleteTriggerMutationVariables = {
  input: DeleteTriggerInput,
};

export type DeleteTriggerMutation = {
  deleteTrigger: boolean,
};

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput,
};

export type DeleteUserProfileMutation = {
  deleteUserProfile: boolean,
};

export type DeleteVpcMutationVariables = {
  input: DeleteVpcInput,
};

export type DeleteVpcMutation = {
  deleteVpc: boolean,
};

export type ParseSchemaMutationVariables = {
  input: ParseSchemaInput,
};

export type ParseSchemaMutation = {
  parseSchema:  {
    __typename: "ParseSchemaResult",
    status?: string | null,
    result?: string | null,
  },
};

export type UpdateCognitoMutationVariables = {
  input: UpdateCognitoInput,
};

export type UpdateCognitoMutation = {
  updateCognito:  {
    __typename: "Cognito",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    resourceType?: string | null,
    tags?: Array< string | null > | null,
    cognitoType?: string | null,
    cognitoTestEmail?: string | null,
    cognitoExisting?: boolean | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateCreateServiceStatusMutationVariables = {
  input: UpdateCreateServiceStatusInput,
};

export type UpdateCreateServiceStatusMutation = {
  updateCreateServiceStatus:  {
    __typename: "OnUpdateCreateServiceStatusResult",
    serviceId: string,
    status?: string | null,
    done?: boolean | null,
    error?: boolean | null,
  },
};

export type UpdateDynamoMutationVariables = {
  input: UpdateDynamoInput,
};

export type UpdateDynamoMutation = {
  updateDynamo:  {
    __typename: "Dynamo",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    billingMode?: string | null,
    readCapacity?: number | null,
    writeCapacity?: number | null,
    keyType?: string | null,
    primaryKeyName?: string | null,
    primaryKeyType?: string | null,
    secondaryKeyName?: string | null,
    secondaryKeyType?: string | null,
    hasIndex?: boolean | null,
    GSI?:  Array< {
      __typename: "Gsi",
      gsiId?: string | null,
      gsiName?: string | null,
      gsiKeyType?: string | null,
      primaryAttribute?: string | null,
      primaryAttributeType?: string | null,
      secondaryAttribute?: string | null,
      secondaryAttributeType?: string | null,
      projectionType?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateLambdaMutationVariables = {
  input: UpdateLambdaInput,
};

export type UpdateLambdaMutation = {
  updateLambda:  {
    __typename: "Lambda",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    runtime?: Runtime | null,
    memory?: number | null,
    timeout?: number | null,
    prune?: boolean | null,
    tracing?: boolean | null,
    vpc?: boolean | null,
    webpack?: boolean | null,
    connection?:  {
      __typename: "ResourceConnection",
      id: string,
      resourceId: string,
      permissions?:  {
        __typename: "Permissions",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        scan?: boolean | null,
      } | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      serviceId?: string | null,
      resourceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      eventTypes?:  {
        __typename: "triggerEventType",
        create?: boolean | null,
        delete?: boolean | null,
      } | null,
      typename?: string | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateModelMutationVariables = {
  input: UpdateModelInput,
};

export type UpdateModelMutation = {
  updateModel:  {
    __typename: "Model",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    dataSource?: string | null,
    fields?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
};

export type UpdateOrganizationMutation = {
  updateOrganization:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateOrganizationUserMutationVariables = {
  input: UpdateOrganizationUserInput,
};

export type UpdateOrganizationUserMutation = {
  updateOrganizationUser:  {
    __typename: "OrganizationUser",
    id: string,
    email?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    userProfile?:  {
      __typename: "UserProfile",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: string | null,
      avatar?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateRdsMutationVariables = {
  input: UpdateRdsInput,
};

export type UpdateRdsMutation = {
  updateRds:  {
    __typename: "Rds",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    vpcName?: string | null,
    vpcExisting?: boolean | null,
    tags?: Array< string | null > | null,
    rdsEngine?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateResolverMutationVariables = {
  input: UpdateResolverInput,
};

export type UpdateResolverMutation = {
  updateResolver:  {
    __typename: "Resolver",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    model?: string | null,
    dataSource?: string | null,
    type?: string | null,
    resolverType?: string | null,
    isPipeline?: boolean | null,
    resolveWith_PK?: string | null,
    resolveWith_SK?: string | null,
    resolveFrom?: string | null,
    queryFrom?: string | null,
    modelFieldId?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateResourceConnectionMutationVariables = {
  input: UpdateResourceConnectionInput,
};

export type UpdateResourceConnectionMutation = {
  updateResourceConnection:  {
    __typename: "ResourceConnection",
    id: string,
    resourceId: string,
    permissions?:  {
      __typename: "Permissions",
      create?: boolean | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      scan?: boolean | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateRouteMutationVariables = {
  input: UpdateRouteInput,
};

export type UpdateRouteMutation = {
  updateRoute:  {
    __typename: "Route",
    id: string,
    serviceId?: string | null,
    authId?: string | null,
    auth?:  {
      __typename: "Cognito",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      resourceType?: string | null,
      tags?: Array< string | null > | null,
      cognitoType?: string | null,
      cognitoTestEmail?: string | null,
      cognitoExisting?: boolean | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null,
    resourceId?: string | null,
    resource: ( {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Dynamo",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        billingMode?: string | null,
        readCapacity?: number | null,
        writeCapacity?: number | null,
        keyType?: string | null,
        primaryKeyName?: string | null,
        primaryKeyType?: string | null,
        secondaryKeyName?: string | null,
        secondaryKeyType?: string | null,
        hasIndex?: boolean | null,
        GSI?:  Array< {
          __typename: string,
          gsiId?: string | null,
          gsiName?: string | null,
          gsiKeyType?: string | null,
          primaryAttribute?: string | null,
          primaryAttributeType?: string | null,
          secondaryAttribute?: string | null,
          secondaryAttributeType?: string | null,
          projectionType?: string | null,
        } | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Lambda",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        runtime?: Runtime | null,
        memory?: number | null,
        timeout?: number | null,
        prune?: boolean | null,
        tracing?: boolean | null,
        vpc?: boolean | null,
        webpack?: boolean | null,
        connection?:  {
          __typename: string,
          id: string,
          resourceId: string,
          permissions?:  {
            __typename: string,
            create?: boolean | null,
            read?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
            scan?: boolean | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null,
        trigger?:  {
          __typename: string,
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Rds",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        vpcName?: string | null,
        vpcExisting?: boolean | null,
        tags?: Array< string | null > | null,
        rdsEngine?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "S3",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sns",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        topicType?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sqs",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        hasDlq?: boolean | null,
        topicType?: string | null,
        visibilityTimeout?: number | null,
        deliveryDelay?: number | null,
        msgRetentionPeriod?: number | null,
        receiveMsgWaitTimeSecs?: number | null,
        maxMsgSize?: number | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Trigger",
        id: string,
        serviceId?: string | null,
        resourceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        eventTypes?:  {
          __typename: string,
          create?: boolean | null,
          delete?: boolean | null,
        } | null,
        typename?: string | null,
      } | {
        __typename: "Vpc",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        vpcExisting?: boolean | null,
        vpcExistingId?: string | null,
        vpcExistingSubnetA?: string | null,
        vpcExistingSubnetB?: string | null,
        vpcExistingSubnetC?: string | null,
        vpcExistingSecurityGroupId?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Resolver",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        model?: string | null,
        dataSource?: string | null,
        type?: string | null,
        resolverType?: string | null,
        isPipeline?: boolean | null,
        resolveWith_PK?: string | null,
        resolveWith_SK?: string | null,
        resolveFrom?: string | null,
        queryFrom?: string | null,
        modelFieldId?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Model",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        dataSource?: string | null,
        fields?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      }
    ) | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    cors?: boolean | null,
    method?: Method | null,
    path?: string | null,
    type?: string | null,
    private?: boolean | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateS3MutationVariables = {
  input: UpdateS3Input,
};

export type UpdateS3Mutation = {
  updateS3:  {
    __typename: "S3",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput,
};

export type UpdateServiceMutation = {
  updateService:  {
    __typename: "Service",
    id: string,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    cloudProvider?: CloudProvider | null,
    cloudProviderRegion?: CloudProviderRegion | null,
    stage?: string | null,
    iacFramework?: IACFramework | null,
    useCase?: UseCase | null,
    status?: ServiceStatus | null,
    optionBluePrint?: OptionBluePrint | null,
    appsyncName?: string | null,
    appsyncDescription?: string | null,
    authType?: AuthType | null,
    logging?: Logging | null,
    caching?: boolean | null,
    xRay?: boolean | null,
    webpack?: boolean | null,
    prune?: boolean | null,
    chatgpt?: boolean | null,
    operatingSystem?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    allowedTeams?:  Array< {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    resources:  Array<( {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Dynamo",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        billingMode?: string | null,
        readCapacity?: number | null,
        writeCapacity?: number | null,
        keyType?: string | null,
        primaryKeyName?: string | null,
        primaryKeyType?: string | null,
        secondaryKeyName?: string | null,
        secondaryKeyType?: string | null,
        hasIndex?: boolean | null,
        GSI?:  Array< {
          __typename: string,
          gsiId?: string | null,
          gsiName?: string | null,
          gsiKeyType?: string | null,
          primaryAttribute?: string | null,
          primaryAttributeType?: string | null,
          secondaryAttribute?: string | null,
          secondaryAttributeType?: string | null,
          projectionType?: string | null,
        } | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Lambda",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        runtime?: Runtime | null,
        memory?: number | null,
        timeout?: number | null,
        prune?: boolean | null,
        tracing?: boolean | null,
        vpc?: boolean | null,
        webpack?: boolean | null,
        connection?:  {
          __typename: string,
          id: string,
          resourceId: string,
          permissions?:  {
            __typename: string,
            create?: boolean | null,
            read?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
            scan?: boolean | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null,
        trigger?:  {
          __typename: string,
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Rds",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        vpcName?: string | null,
        vpcExisting?: boolean | null,
        tags?: Array< string | null > | null,
        rdsEngine?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "S3",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sns",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        topicType?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sqs",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        hasDlq?: boolean | null,
        topicType?: string | null,
        visibilityTimeout?: number | null,
        deliveryDelay?: number | null,
        msgRetentionPeriod?: number | null,
        receiveMsgWaitTimeSecs?: number | null,
        maxMsgSize?: number | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Trigger",
        id: string,
        serviceId?: string | null,
        resourceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        eventTypes?:  {
          __typename: string,
          create?: boolean | null,
          delete?: boolean | null,
        } | null,
        typename?: string | null,
      } | {
        __typename: "Vpc",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        vpcExisting?: boolean | null,
        vpcExistingId?: string | null,
        vpcExistingSubnetA?: string | null,
        vpcExistingSubnetB?: string | null,
        vpcExistingSubnetC?: string | null,
        vpcExistingSecurityGroupId?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Resolver",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        model?: string | null,
        dataSource?: string | null,
        type?: string | null,
        resolverType?: string | null,
        isPipeline?: boolean | null,
        resolveWith_PK?: string | null,
        resolveWith_SK?: string | null,
        resolveFrom?: string | null,
        queryFrom?: string | null,
        modelFieldId?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Model",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        dataSource?: string | null,
        fields?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      }
    ) | null > | null,
    routes?:  Array< {
      __typename: "Route",
      id: string,
      serviceId?: string | null,
      authId?: string | null,
      auth?:  {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null,
      resourceId?: string | null,
      resource: ( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      cors?: boolean | null,
      method?: Method | null,
      path?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    stars?:  {
      __typename: "ListServiceStarsResult",
      ServiceStars?:  Array< {
        __typename: "ServiceStar",
        id: string,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
      total?: number | null,
    } | null,
    isServiceOwner?: boolean | null,
    enums?:  Array< {
      __typename: "Enum",
      id?: string | null,
      name?: string | null,
      serviceId?: string | null,
      fields?: Array< string | null > | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    subscriptions?:  Array< {
      __typename: "Subs",
      id?: string | null,
      name?: string | null,
      serviceId?: string | null,
      description?: string | null,
      args?:  Array< {
        __typename: "Arg",
        name?: string | null,
        type?: string | null,
        required?: boolean | null,
      } | null > | null,
      type?: string | null,
      array?: boolean | null,
      required?: boolean | null,
      derivative?:  {
        __typename: "Derivative",
        name?: string | null,
        mutations?: Array< string | null > | null,
      } | null,
      tags?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      typename?: string | null,
    } | null > | null,
    customObjects?:  Array< {
      __typename: "CustomObject",
      id?: string | null,
      serviceId?: string | null,
      name?: string | null,
      description?: string | null,
      fields?:  Array< {
        __typename: "CustomObjectField",
        name?: string | null,
        fieldType?: string | null,
        type?: string | null,
        required?: boolean | null,
        array?: boolean | null,
      } | null > | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateSnsMutationVariables = {
  input: UpdateSnsInput,
};

export type UpdateSnsMutation = {
  updateSns:  {
    __typename: "Sns",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    topicType?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateSqsMutationVariables = {
  input: UpdateSqsInput,
};

export type UpdateSqsMutation = {
  updateSqs:  {
    __typename: "Sqs",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    hasDlq?: boolean | null,
    topicType?: string | null,
    visibilityTimeout?: number | null,
    deliveryDelay?: number | null,
    msgRetentionPeriod?: number | null,
    receiveMsgWaitTimeSecs?: number | null,
    maxMsgSize?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    name?: string | null,
    description?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type UpdateTeamServiceMutationVariables = {
  input: UpdateTeamServiceInput,
};

export type UpdateTeamServiceMutation = {
  updateTeamService:  {
    __typename: "TeamService",
    id: string,
    teamId: string,
    serviceId: string,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateTeamUserMutationVariables = {
  input: UpdateTeamUserInput,
};

export type UpdateTeamUserMutation = {
  updateTeamUser:  {
    __typename: "TeamUser",
    id: string,
    userId: string,
    team:  {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    },
    email?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateTriggerMutationVariables = {
  input: UpdateTriggerInput,
};

export type UpdateTriggerMutation = {
  updateTrigger:  {
    __typename: "Trigger",
    id: string,
    serviceId?: string | null,
    resourceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    private?: boolean | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    eventTypes?:  {
      __typename: "triggerEventType",
      create?: boolean | null,
      delete?: boolean | null,
    } | null,
    typename?: string | null,
  },
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput,
};

export type UpdateUserProfileMutation = {
  updateUserProfile:  {
    __typename: "UserProfile",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: string | null,
    avatar?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type UpdateVpcMutationVariables = {
  input: UpdateVpcInput,
};

export type UpdateVpcMutation = {
  updateVpc:  {
    __typename: "Vpc",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    vpcExisting?: boolean | null,
    vpcExistingId?: string | null,
    vpcExistingSubnetA?: string | null,
    vpcExistingSubnetB?: string | null,
    vpcExistingSubnetC?: string | null,
    vpcExistingSecurityGroupId?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type FindServicesByNameQueryVariables = {
  input: ListServicesInput,
};

export type FindServicesByNameQuery = {
  findServicesByName:  {
    __typename: "ListServicesResult",
    Services?:  Array< {
      __typename: "Service",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      cloudProvider?: CloudProvider | null,
      cloudProviderRegion?: CloudProviderRegion | null,
      stage?: string | null,
      iacFramework?: IACFramework | null,
      useCase?: UseCase | null,
      status?: ServiceStatus | null,
      optionBluePrint?: OptionBluePrint | null,
      appsyncName?: string | null,
      appsyncDescription?: string | null,
      authType?: AuthType | null,
      logging?: Logging | null,
      caching?: boolean | null,
      xRay?: boolean | null,
      webpack?: boolean | null,
      prune?: boolean | null,
      chatgpt?: boolean | null,
      operatingSystem?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      allowedTeams?:  Array< {
        __typename: "Team",
        id: string,
        name?: string | null,
        description?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      resources:  Array<( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null > | null,
      routes?:  Array< {
        __typename: "Route",
        id: string,
        serviceId?: string | null,
        authId?: string | null,
        auth?:  {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | null,
        resourceId?: string | null,
        resource: ( {
            __typename: "Cognito",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            resourceType?: string | null,
            tags?: Array< string | null > | null,
            cognitoType?: string | null,
            cognitoTestEmail?: string | null,
            cognitoExisting?: boolean | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Dynamo",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            billingMode?: string | null,
            readCapacity?: number | null,
            writeCapacity?: number | null,
            keyType?: string | null,
            primaryKeyName?: string | null,
            primaryKeyType?: string | null,
            secondaryKeyName?: string | null,
            secondaryKeyType?: string | null,
            hasIndex?: boolean | null,
            GSI?:  Array< {
              __typename: string,
              gsiId?: string | null,
              gsiName?: string | null,
              gsiKeyType?: string | null,
              primaryAttribute?: string | null,
              primaryAttributeType?: string | null,
              secondaryAttribute?: string | null,
              secondaryAttributeType?: string | null,
              projectionType?: string | null,
            } | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Lambda",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            runtime?: Runtime | null,
            memory?: number | null,
            timeout?: number | null,
            prune?: boolean | null,
            tracing?: boolean | null,
            vpc?: boolean | null,
            webpack?: boolean | null,
            connection?:  {
              __typename: string,
              id: string,
              resourceId: string,
              permissions?:  {
                __typename: string,
                create?: boolean | null,
                read?: boolean | null,
                update?: boolean | null,
                delete?: boolean | null,
                scan?: boolean | null,
              } | null,
              updatedAt?: string | null,
              createdAt?: string | null,
            } | null,
            trigger?:  {
              __typename: string,
              id: string,
              serviceId?: string | null,
              resourceId?: string | null,
              user?: string | null,
              name?: string | null,
              description?: string | null,
              type?: string | null,
              private?: boolean | null,
              tags?: Array< string | null > | null,
              updatedAt?: string | null,
              createdAt?: string | null,
              eventTypes?:  {
                __typename: string,
                create?: boolean | null,
                delete?: boolean | null,
              } | null,
              typename?: string | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Rds",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            vpcName?: string | null,
            vpcExisting?: boolean | null,
            tags?: Array< string | null > | null,
            rdsEngine?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "S3",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sns",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            topicType?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sqs",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            hasDlq?: boolean | null,
            topicType?: string | null,
            visibilityTimeout?: number | null,
            deliveryDelay?: number | null,
            msgRetentionPeriod?: number | null,
            receiveMsgWaitTimeSecs?: number | null,
            maxMsgSize?: number | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Trigger",
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | {
            __typename: "Vpc",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            vpcExisting?: boolean | null,
            vpcExistingId?: string | null,
            vpcExistingSubnetA?: string | null,
            vpcExistingSubnetB?: string | null,
            vpcExistingSubnetC?: string | null,
            vpcExistingSecurityGroupId?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Resolver",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            model?: string | null,
            dataSource?: string | null,
            type?: string | null,
            resolverType?: string | null,
            isPipeline?: boolean | null,
            resolveWith_PK?: string | null,
            resolveWith_SK?: string | null,
            resolveFrom?: string | null,
            queryFrom?: string | null,
            modelFieldId?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Model",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            dataSource?: string | null,
            fields?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          }
        ) | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        cors?: boolean | null,
        method?: Method | null,
        path?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      stars?:  {
        __typename: "ListServiceStarsResult",
        ServiceStars?:  Array< {
          __typename: "ServiceStar",
          id: string,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null > | null,
        nextToken?: string | null,
        total?: number | null,
      } | null,
      isServiceOwner?: boolean | null,
      enums?:  Array< {
        __typename: "Enum",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        fields?: Array< string | null > | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      subscriptions?:  Array< {
        __typename: "Subs",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        description?: string | null,
        args?:  Array< {
          __typename: "Arg",
          name?: string | null,
          type?: string | null,
          required?: boolean | null,
        } | null > | null,
        type?: string | null,
        array?: boolean | null,
        required?: boolean | null,
        derivative?:  {
          __typename: "Derivative",
          name?: string | null,
          mutations?: Array< string | null > | null,
        } | null,
        tags?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        typename?: string | null,
      } | null > | null,
      customObjects?:  Array< {
        __typename: "CustomObject",
        id?: string | null,
        serviceId?: string | null,
        name?: string | null,
        description?: string | null,
        fields?:  Array< {
          __typename: "CustomObjectField",
          name?: string | null,
          fieldType?: string | null,
          type?: string | null,
          required?: boolean | null,
          array?: boolean | null,
        } | null > | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
  },
};

export type GetCognitoQueryVariables = {
  input: GetCognitoInput,
};

export type GetCognitoQuery = {
  getCognito:  {
    __typename: "Cognito",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    resourceType?: string | null,
    tags?: Array< string | null > | null,
    cognitoType?: string | null,
    cognitoTestEmail?: string | null,
    cognitoExisting?: boolean | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetDynamoQueryVariables = {
  input: GetDynamoInput,
};

export type GetDynamoQuery = {
  getDynamo:  {
    __typename: "Dynamo",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    billingMode?: string | null,
    readCapacity?: number | null,
    writeCapacity?: number | null,
    keyType?: string | null,
    primaryKeyName?: string | null,
    primaryKeyType?: string | null,
    secondaryKeyName?: string | null,
    secondaryKeyType?: string | null,
    hasIndex?: boolean | null,
    GSI?:  Array< {
      __typename: "Gsi",
      gsiId?: string | null,
      gsiName?: string | null,
      gsiKeyType?: string | null,
      primaryAttribute?: string | null,
      primaryAttributeType?: string | null,
      secondaryAttribute?: string | null,
      secondaryAttributeType?: string | null,
      projectionType?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetLambdaQueryVariables = {
  input: GetLambdaInput,
};

export type GetLambdaQuery = {
  getLambda:  {
    __typename: "Lambda",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    runtime?: Runtime | null,
    memory?: number | null,
    timeout?: number | null,
    prune?: boolean | null,
    tracing?: boolean | null,
    vpc?: boolean | null,
    webpack?: boolean | null,
    connection?:  {
      __typename: "ResourceConnection",
      id: string,
      resourceId: string,
      permissions?:  {
        __typename: "Permissions",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        scan?: boolean | null,
      } | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      serviceId?: string | null,
      resourceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      eventTypes?:  {
        __typename: "triggerEventType",
        create?: boolean | null,
        delete?: boolean | null,
      } | null,
      typename?: string | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetModelQueryVariables = {
  input: GetModelInput,
};

export type GetModelQuery = {
  getModel:  {
    __typename: "Model",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    dataSource?: string | null,
    fields?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type GetOrganizationUserQueryVariables = {
  input: GetOrganizationUserInput,
};

export type GetOrganizationUserQuery = {
  getOrganizationUser:  {
    __typename: "OrganizationUser",
    id: string,
    email?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    userProfile?:  {
      __typename: "UserProfile",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: string | null,
      avatar?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetRdsQueryVariables = {
  input: GetRdsInput,
};

export type GetRdsQuery = {
  getRds:  {
    __typename: "Rds",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    vpcName?: string | null,
    vpcExisting?: boolean | null,
    tags?: Array< string | null > | null,
    rdsEngine?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetResolverQueryVariables = {
  input: GetResolverInput,
};

export type GetResolverQuery = {
  getResolver:  {
    __typename: "Resolver",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    model?: string | null,
    dataSource?: string | null,
    type?: string | null,
    resolverType?: string | null,
    isPipeline?: boolean | null,
    resolveWith_PK?: string | null,
    resolveWith_SK?: string | null,
    resolveFrom?: string | null,
    queryFrom?: string | null,
    modelFieldId?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetResourceConnectionQueryVariables = {
  input: GetResourceConnectionInput,
};

export type GetResourceConnectionQuery = {
  getResourceConnection:  {
    __typename: "ResourceConnection",
    id: string,
    resourceId: string,
    permissions?:  {
      __typename: "Permissions",
      create?: boolean | null,
      read?: boolean | null,
      update?: boolean | null,
      delete?: boolean | null,
      scan?: boolean | null,
    } | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetRouteQueryVariables = {
  input: GetRouteInput,
};

export type GetRouteQuery = {
  getRoute:  {
    __typename: "Route",
    id: string,
    serviceId?: string | null,
    authId?: string | null,
    auth?:  {
      __typename: "Cognito",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      resourceType?: string | null,
      tags?: Array< string | null > | null,
      cognitoType?: string | null,
      cognitoTestEmail?: string | null,
      cognitoExisting?: boolean | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null,
    resourceId?: string | null,
    resource: ( {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Dynamo",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        billingMode?: string | null,
        readCapacity?: number | null,
        writeCapacity?: number | null,
        keyType?: string | null,
        primaryKeyName?: string | null,
        primaryKeyType?: string | null,
        secondaryKeyName?: string | null,
        secondaryKeyType?: string | null,
        hasIndex?: boolean | null,
        GSI?:  Array< {
          __typename: string,
          gsiId?: string | null,
          gsiName?: string | null,
          gsiKeyType?: string | null,
          primaryAttribute?: string | null,
          primaryAttributeType?: string | null,
          secondaryAttribute?: string | null,
          secondaryAttributeType?: string | null,
          projectionType?: string | null,
        } | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Lambda",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        runtime?: Runtime | null,
        memory?: number | null,
        timeout?: number | null,
        prune?: boolean | null,
        tracing?: boolean | null,
        vpc?: boolean | null,
        webpack?: boolean | null,
        connection?:  {
          __typename: string,
          id: string,
          resourceId: string,
          permissions?:  {
            __typename: string,
            create?: boolean | null,
            read?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
            scan?: boolean | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null,
        trigger?:  {
          __typename: string,
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Rds",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        vpcName?: string | null,
        vpcExisting?: boolean | null,
        tags?: Array< string | null > | null,
        rdsEngine?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "S3",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sns",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        topicType?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sqs",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        hasDlq?: boolean | null,
        topicType?: string | null,
        visibilityTimeout?: number | null,
        deliveryDelay?: number | null,
        msgRetentionPeriod?: number | null,
        receiveMsgWaitTimeSecs?: number | null,
        maxMsgSize?: number | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Trigger",
        id: string,
        serviceId?: string | null,
        resourceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        eventTypes?:  {
          __typename: string,
          create?: boolean | null,
          delete?: boolean | null,
        } | null,
        typename?: string | null,
      } | {
        __typename: "Vpc",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        vpcExisting?: boolean | null,
        vpcExistingId?: string | null,
        vpcExistingSubnetA?: string | null,
        vpcExistingSubnetB?: string | null,
        vpcExistingSubnetC?: string | null,
        vpcExistingSecurityGroupId?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Resolver",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        model?: string | null,
        dataSource?: string | null,
        type?: string | null,
        resolverType?: string | null,
        isPipeline?: boolean | null,
        resolveWith_PK?: string | null,
        resolveWith_SK?: string | null,
        resolveFrom?: string | null,
        queryFrom?: string | null,
        modelFieldId?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Model",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        dataSource?: string | null,
        fields?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      }
    ) | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    cors?: boolean | null,
    method?: Method | null,
    path?: string | null,
    type?: string | null,
    private?: boolean | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetS3QueryVariables = {
  input: GetS3Input,
};

export type GetS3Query = {
  getS3:  {
    __typename: "S3",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetServiceQueryVariables = {
  input: GetServiceInput,
};

export type GetServiceQuery = {
  getService:  {
    __typename: "Service",
    id: string,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    cloudProvider?: CloudProvider | null,
    cloudProviderRegion?: CloudProviderRegion | null,
    stage?: string | null,
    iacFramework?: IACFramework | null,
    useCase?: UseCase | null,
    status?: ServiceStatus | null,
    optionBluePrint?: OptionBluePrint | null,
    appsyncName?: string | null,
    appsyncDescription?: string | null,
    authType?: AuthType | null,
    logging?: Logging | null,
    caching?: boolean | null,
    xRay?: boolean | null,
    webpack?: boolean | null,
    prune?: boolean | null,
    chatgpt?: boolean | null,
    operatingSystem?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    allowedTeams?:  Array< {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    resources:  Array<( {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Dynamo",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        billingMode?: string | null,
        readCapacity?: number | null,
        writeCapacity?: number | null,
        keyType?: string | null,
        primaryKeyName?: string | null,
        primaryKeyType?: string | null,
        secondaryKeyName?: string | null,
        secondaryKeyType?: string | null,
        hasIndex?: boolean | null,
        GSI?:  Array< {
          __typename: string,
          gsiId?: string | null,
          gsiName?: string | null,
          gsiKeyType?: string | null,
          primaryAttribute?: string | null,
          primaryAttributeType?: string | null,
          secondaryAttribute?: string | null,
          secondaryAttributeType?: string | null,
          projectionType?: string | null,
        } | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Lambda",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        runtime?: Runtime | null,
        memory?: number | null,
        timeout?: number | null,
        prune?: boolean | null,
        tracing?: boolean | null,
        vpc?: boolean | null,
        webpack?: boolean | null,
        connection?:  {
          __typename: string,
          id: string,
          resourceId: string,
          permissions?:  {
            __typename: string,
            create?: boolean | null,
            read?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
            scan?: boolean | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null,
        trigger?:  {
          __typename: string,
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Rds",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        vpcName?: string | null,
        vpcExisting?: boolean | null,
        tags?: Array< string | null > | null,
        rdsEngine?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "S3",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sns",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        topicType?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Sqs",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        hasDlq?: boolean | null,
        topicType?: string | null,
        visibilityTimeout?: number | null,
        deliveryDelay?: number | null,
        msgRetentionPeriod?: number | null,
        receiveMsgWaitTimeSecs?: number | null,
        maxMsgSize?: number | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Trigger",
        id: string,
        serviceId?: string | null,
        resourceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        eventTypes?:  {
          __typename: string,
          create?: boolean | null,
          delete?: boolean | null,
        } | null,
        typename?: string | null,
      } | {
        __typename: "Vpc",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        vpcExisting?: boolean | null,
        vpcExistingId?: string | null,
        vpcExistingSubnetA?: string | null,
        vpcExistingSubnetB?: string | null,
        vpcExistingSubnetC?: string | null,
        vpcExistingSecurityGroupId?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Resolver",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        model?: string | null,
        dataSource?: string | null,
        type?: string | null,
        resolverType?: string | null,
        isPipeline?: boolean | null,
        resolveWith_PK?: string | null,
        resolveWith_SK?: string | null,
        resolveFrom?: string | null,
        queryFrom?: string | null,
        modelFieldId?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | {
        __typename: "Model",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        dataSource?: string | null,
        fields?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      }
    ) | null > | null,
    routes?:  Array< {
      __typename: "Route",
      id: string,
      serviceId?: string | null,
      authId?: string | null,
      auth?:  {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null,
      resourceId?: string | null,
      resource: ( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      cors?: boolean | null,
      method?: Method | null,
      path?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    stars?:  {
      __typename: "ListServiceStarsResult",
      ServiceStars?:  Array< {
        __typename: "ServiceStar",
        id: string,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
      total?: number | null,
    } | null,
    isServiceOwner?: boolean | null,
    enums?:  Array< {
      __typename: "Enum",
      id?: string | null,
      name?: string | null,
      serviceId?: string | null,
      fields?: Array< string | null > | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    subscriptions?:  Array< {
      __typename: "Subs",
      id?: string | null,
      name?: string | null,
      serviceId?: string | null,
      description?: string | null,
      args?:  Array< {
        __typename: "Arg",
        name?: string | null,
        type?: string | null,
        required?: boolean | null,
      } | null > | null,
      type?: string | null,
      array?: boolean | null,
      required?: boolean | null,
      derivative?:  {
        __typename: "Derivative",
        name?: string | null,
        mutations?: Array< string | null > | null,
      } | null,
      tags?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      typename?: string | null,
    } | null > | null,
    customObjects?:  Array< {
      __typename: "CustomObject",
      id?: string | null,
      serviceId?: string | null,
      name?: string | null,
      description?: string | null,
      fields?:  Array< {
        __typename: "CustomObjectField",
        name?: string | null,
        fieldType?: string | null,
        type?: string | null,
        required?: boolean | null,
        array?: boolean | null,
      } | null > | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetServiceStarQueryVariables = {
  input: GetServiceStarInput,
};

export type GetServiceStarQuery = {
  getServiceStar:  {
    __typename: "ServiceStar",
    id: string,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetSnsQueryVariables = {
  input: GetSnsInput,
};

export type GetSnsQuery = {
  getSns:  {
    __typename: "Sns",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    topicType?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetSqsQueryVariables = {
  input: GetSqsInput,
};

export type GetSqsQuery = {
  getSqs:  {
    __typename: "Sqs",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    hasDlq?: boolean | null,
    topicType?: string | null,
    visibilityTimeout?: number | null,
    deliveryDelay?: number | null,
    msgRetentionPeriod?: number | null,
    receiveMsgWaitTimeSecs?: number | null,
    maxMsgSize?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type GetTeamQueryVariables = {
  input: GetTeamInput,
};

export type GetTeamQuery = {
  getTeam:  {
    __typename: "Team",
    id: string,
    name?: string | null,
    description?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetTeamServiceQueryVariables = {
  input: GetTeamServiceInput,
};

export type GetTeamServiceQuery = {
  getTeamService:  {
    __typename: "TeamService",
    id: string,
    teamId: string,
    serviceId: string,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetTeamUserQueryVariables = {
  input: GetTeamUserInput,
};

export type GetTeamUserQuery = {
  getTeamUser:  {
    __typename: "TeamUser",
    id: string,
    userId: string,
    team:  {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    },
    email?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetTriggerQueryVariables = {
  input: GetTriggerInput,
};

export type GetTriggerQuery = {
  getTrigger:  {
    __typename: "Trigger",
    id: string,
    serviceId?: string | null,
    resourceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    private?: boolean | null,
    tags?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    eventTypes?:  {
      __typename: "triggerEventType",
      create?: boolean | null,
      delete?: boolean | null,
    } | null,
    typename?: string | null,
  },
};

export type GetUserProfileQueryVariables = {
  input?: GetUserProfileInput | null,
};

export type GetUserProfileQuery = {
  getUserProfile:  {
    __typename: "UserProfile",
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: string | null,
    avatar?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  },
};

export type GetVpcQueryVariables = {
  input: GetVpcInput,
};

export type GetVpcQuery = {
  getVpc:  {
    __typename: "Vpc",
    id: string,
    serviceId?: string | null,
    user?: string | null,
    name?: string | null,
    description?: string | null,
    tags?: Array< string | null > | null,
    vpcExisting?: boolean | null,
    vpcExistingId?: string | null,
    vpcExistingSubnetA?: string | null,
    vpcExistingSubnetB?: string | null,
    vpcExistingSubnetC?: string | null,
    vpcExistingSecurityGroupId?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    typename?: string | null,
  },
};

export type ListCognitosQueryVariables = {
  input: ListCognitosInput,
};

export type ListCognitosQuery = {
  listCognitos:  {
    __typename: "ListCognitosResult",
    Cognitos?:  Array< {
      __typename: "Cognito",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      resourceType?: string | null,
      tags?: Array< string | null > | null,
      cognitoType?: string | null,
      cognitoTestEmail?: string | null,
      cognitoExisting?: boolean | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListDynamosQueryVariables = {
  input: ListDynamosInput,
};

export type ListDynamosQuery = {
  listDynamos:  {
    __typename: "ListDynamosResult",
    Dynamos?:  Array< {
      __typename: "Dynamo",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      billingMode?: string | null,
      readCapacity?: number | null,
      writeCapacity?: number | null,
      keyType?: string | null,
      primaryKeyName?: string | null,
      primaryKeyType?: string | null,
      secondaryKeyName?: string | null,
      secondaryKeyType?: string | null,
      hasIndex?: boolean | null,
      GSI?:  Array< {
        __typename: "Gsi",
        gsiId?: string | null,
        gsiName?: string | null,
        gsiKeyType?: string | null,
        primaryAttribute?: string | null,
        primaryAttributeType?: string | null,
        secondaryAttribute?: string | null,
        secondaryAttributeType?: string | null,
        projectionType?: string | null,
      } | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListLambdasQueryVariables = {
  input: ListLambdasInput,
};

export type ListLambdasQuery = {
  listLambdas:  {
    __typename: "ListLambdasResult",
    Lambdas?:  Array< {
      __typename: "Lambda",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      runtime?: Runtime | null,
      memory?: number | null,
      timeout?: number | null,
      prune?: boolean | null,
      tracing?: boolean | null,
      vpc?: boolean | null,
      webpack?: boolean | null,
      connection?:  {
        __typename: "ResourceConnection",
        id: string,
        resourceId: string,
        permissions?:  {
          __typename: "Permissions",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          scan?: boolean | null,
        } | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      trigger?:  {
        __typename: "Trigger",
        id: string,
        serviceId?: string | null,
        resourceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        eventTypes?:  {
          __typename: "triggerEventType",
          create?: boolean | null,
          delete?: boolean | null,
        } | null,
        typename?: string | null,
      } | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListModelsQueryVariables = {
  input: ListModelsInput,
};

export type ListModelsQuery = {
  listModels:  {
    __typename: "ListModelsResult",
    Models?:  Array< {
      __typename: "Model",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      dataSource?: string | null,
      fields?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListOrganizationUsersQueryVariables = {
  input: ListOrganizationUsersInput,
};

export type ListOrganizationUsersQuery = {
  listOrganizationUsers:  {
    __typename: "ListOrganizationUsersResult",
    OrganizationUsers?:  Array< {
      __typename: "OrganizationUser",
      id: string,
      email?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      userProfile?:  {
        __typename: "UserProfile",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: string | null,
        avatar?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListOrganizationsQueryVariables = {
  input: ListOrganizationsInput,
};

export type ListOrganizationsQuery = {
  listOrganizations:  {
    __typename: "ListOrganizationsResult",
    Organizations?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListOrganizationsByUserQueryVariables = {
  input?: ListOrganizationUsersInput | null,
};

export type ListOrganizationsByUserQuery = {
  listOrganizationsByUser:  {
    __typename: "ListOrganizationUsersResult",
    OrganizationUsers?:  Array< {
      __typename: "OrganizationUser",
      id: string,
      email?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      userProfile?:  {
        __typename: "UserProfile",
        id: string,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: string | null,
        avatar?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListRdssQueryVariables = {
  input: ListRdssInput,
};

export type ListRdssQuery = {
  listRdss:  {
    __typename: "ListRdssResult",
    Rdss?:  Array< {
      __typename: "Rds",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      vpcName?: string | null,
      vpcExisting?: boolean | null,
      tags?: Array< string | null > | null,
      rdsEngine?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListResolversQueryVariables = {
  input: ListResolversInput,
};

export type ListResolversQuery = {
  listResolvers:  {
    __typename: "ListResolversResult",
    Resolvers?:  Array< {
      __typename: "Resolver",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      model?: string | null,
      dataSource?: string | null,
      type?: string | null,
      resolverType?: string | null,
      isPipeline?: boolean | null,
      resolveWith_PK?: string | null,
      resolveWith_SK?: string | null,
      resolveFrom?: string | null,
      queryFrom?: string | null,
      modelFieldId?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListResourceConnectionsQueryVariables = {
  input: ListResourceConnectionsInput,
};

export type ListResourceConnectionsQuery = {
  listResourceConnections:  {
    __typename: "ListResourceConnectionsResult",
    ResourceConnections?:  Array< {
      __typename: "ResourceConnection",
      id: string,
      resourceId: string,
      permissions?:  {
        __typename: "Permissions",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        scan?: boolean | null,
      } | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListRoutesQueryVariables = {
  input: ListRoutesInput,
};

export type ListRoutesQuery = {
  listRoutes:  {
    __typename: "ListRoutesResult",
    Routes?:  Array< {
      __typename: "Route",
      id: string,
      serviceId?: string | null,
      authId?: string | null,
      auth?:  {
        __typename: "Cognito",
        id: string,
        serviceId?: string | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        resourceType?: string | null,
        tags?: Array< string | null > | null,
        cognitoType?: string | null,
        cognitoTestEmail?: string | null,
        cognitoExisting?: boolean | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null,
      resourceId?: string | null,
      resource: ( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      cors?: boolean | null,
      method?: Method | null,
      path?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListS3sQueryVariables = {
  input: ListS3sInput,
};

export type ListS3sQuery = {
  listS3s:  {
    __typename: "ListS3sResult",
    S3s?:  Array< {
      __typename: "S3",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListServiceStarsQueryVariables = {
  input: ListServiceStarsInput,
};

export type ListServiceStarsQuery = {
  listServiceStars:  {
    __typename: "ListServiceStarsResult",
    ServiceStars?:  Array< {
      __typename: "ServiceStar",
      id: string,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  },
};

export type ListServicesQueryVariables = {
  input: ListServicesInput,
};

export type ListServicesQuery = {
  listServices:  {
    __typename: "ListServicesResult",
    Services?:  Array< {
      __typename: "Service",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      cloudProvider?: CloudProvider | null,
      cloudProviderRegion?: CloudProviderRegion | null,
      stage?: string | null,
      iacFramework?: IACFramework | null,
      useCase?: UseCase | null,
      status?: ServiceStatus | null,
      optionBluePrint?: OptionBluePrint | null,
      appsyncName?: string | null,
      appsyncDescription?: string | null,
      authType?: AuthType | null,
      logging?: Logging | null,
      caching?: boolean | null,
      xRay?: boolean | null,
      webpack?: boolean | null,
      prune?: boolean | null,
      chatgpt?: boolean | null,
      operatingSystem?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      allowedTeams?:  Array< {
        __typename: "Team",
        id: string,
        name?: string | null,
        description?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      resources:  Array<( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null > | null,
      routes?:  Array< {
        __typename: "Route",
        id: string,
        serviceId?: string | null,
        authId?: string | null,
        auth?:  {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | null,
        resourceId?: string | null,
        resource: ( {
            __typename: "Cognito",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            resourceType?: string | null,
            tags?: Array< string | null > | null,
            cognitoType?: string | null,
            cognitoTestEmail?: string | null,
            cognitoExisting?: boolean | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Dynamo",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            billingMode?: string | null,
            readCapacity?: number | null,
            writeCapacity?: number | null,
            keyType?: string | null,
            primaryKeyName?: string | null,
            primaryKeyType?: string | null,
            secondaryKeyName?: string | null,
            secondaryKeyType?: string | null,
            hasIndex?: boolean | null,
            GSI?:  Array< {
              __typename: string,
              gsiId?: string | null,
              gsiName?: string | null,
              gsiKeyType?: string | null,
              primaryAttribute?: string | null,
              primaryAttributeType?: string | null,
              secondaryAttribute?: string | null,
              secondaryAttributeType?: string | null,
              projectionType?: string | null,
            } | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Lambda",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            runtime?: Runtime | null,
            memory?: number | null,
            timeout?: number | null,
            prune?: boolean | null,
            tracing?: boolean | null,
            vpc?: boolean | null,
            webpack?: boolean | null,
            connection?:  {
              __typename: string,
              id: string,
              resourceId: string,
              permissions?:  {
                __typename: string,
                create?: boolean | null,
                read?: boolean | null,
                update?: boolean | null,
                delete?: boolean | null,
                scan?: boolean | null,
              } | null,
              updatedAt?: string | null,
              createdAt?: string | null,
            } | null,
            trigger?:  {
              __typename: string,
              id: string,
              serviceId?: string | null,
              resourceId?: string | null,
              user?: string | null,
              name?: string | null,
              description?: string | null,
              type?: string | null,
              private?: boolean | null,
              tags?: Array< string | null > | null,
              updatedAt?: string | null,
              createdAt?: string | null,
              eventTypes?:  {
                __typename: string,
                create?: boolean | null,
                delete?: boolean | null,
              } | null,
              typename?: string | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Rds",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            vpcName?: string | null,
            vpcExisting?: boolean | null,
            tags?: Array< string | null > | null,
            rdsEngine?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "S3",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sns",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            topicType?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sqs",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            hasDlq?: boolean | null,
            topicType?: string | null,
            visibilityTimeout?: number | null,
            deliveryDelay?: number | null,
            msgRetentionPeriod?: number | null,
            receiveMsgWaitTimeSecs?: number | null,
            maxMsgSize?: number | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Trigger",
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | {
            __typename: "Vpc",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            vpcExisting?: boolean | null,
            vpcExistingId?: string | null,
            vpcExistingSubnetA?: string | null,
            vpcExistingSubnetB?: string | null,
            vpcExistingSubnetC?: string | null,
            vpcExistingSecurityGroupId?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Resolver",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            model?: string | null,
            dataSource?: string | null,
            type?: string | null,
            resolverType?: string | null,
            isPipeline?: boolean | null,
            resolveWith_PK?: string | null,
            resolveWith_SK?: string | null,
            resolveFrom?: string | null,
            queryFrom?: string | null,
            modelFieldId?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Model",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            dataSource?: string | null,
            fields?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          }
        ) | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        cors?: boolean | null,
        method?: Method | null,
        path?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      stars?:  {
        __typename: "ListServiceStarsResult",
        ServiceStars?:  Array< {
          __typename: "ServiceStar",
          id: string,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null > | null,
        nextToken?: string | null,
        total?: number | null,
      } | null,
      isServiceOwner?: boolean | null,
      enums?:  Array< {
        __typename: "Enum",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        fields?: Array< string | null > | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      subscriptions?:  Array< {
        __typename: "Subs",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        description?: string | null,
        args?:  Array< {
          __typename: "Arg",
          name?: string | null,
          type?: string | null,
          required?: boolean | null,
        } | null > | null,
        type?: string | null,
        array?: boolean | null,
        required?: boolean | null,
        derivative?:  {
          __typename: "Derivative",
          name?: string | null,
          mutations?: Array< string | null > | null,
        } | null,
        tags?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        typename?: string | null,
      } | null > | null,
      customObjects?:  Array< {
        __typename: "CustomObject",
        id?: string | null,
        serviceId?: string | null,
        name?: string | null,
        description?: string | null,
        fields?:  Array< {
          __typename: "CustomObjectField",
          name?: string | null,
          fieldType?: string | null,
          type?: string | null,
          required?: boolean | null,
          array?: boolean | null,
        } | null > | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
  },
};

export type ListServicesByOrgQueryVariables = {
  input: ListServicesInput,
};

export type ListServicesByOrgQuery = {
  listServicesByOrg:  {
    __typename: "ListServicesResult",
    Services?:  Array< {
      __typename: "Service",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      cloudProvider?: CloudProvider | null,
      cloudProviderRegion?: CloudProviderRegion | null,
      stage?: string | null,
      iacFramework?: IACFramework | null,
      useCase?: UseCase | null,
      status?: ServiceStatus | null,
      optionBluePrint?: OptionBluePrint | null,
      appsyncName?: string | null,
      appsyncDescription?: string | null,
      authType?: AuthType | null,
      logging?: Logging | null,
      caching?: boolean | null,
      xRay?: boolean | null,
      webpack?: boolean | null,
      prune?: boolean | null,
      chatgpt?: boolean | null,
      operatingSystem?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      allowedTeams?:  Array< {
        __typename: "Team",
        id: string,
        name?: string | null,
        description?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      resources:  Array<( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null > | null,
      routes?:  Array< {
        __typename: "Route",
        id: string,
        serviceId?: string | null,
        authId?: string | null,
        auth?:  {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | null,
        resourceId?: string | null,
        resource: ( {
            __typename: "Cognito",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            resourceType?: string | null,
            tags?: Array< string | null > | null,
            cognitoType?: string | null,
            cognitoTestEmail?: string | null,
            cognitoExisting?: boolean | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Dynamo",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            billingMode?: string | null,
            readCapacity?: number | null,
            writeCapacity?: number | null,
            keyType?: string | null,
            primaryKeyName?: string | null,
            primaryKeyType?: string | null,
            secondaryKeyName?: string | null,
            secondaryKeyType?: string | null,
            hasIndex?: boolean | null,
            GSI?:  Array< {
              __typename: string,
              gsiId?: string | null,
              gsiName?: string | null,
              gsiKeyType?: string | null,
              primaryAttribute?: string | null,
              primaryAttributeType?: string | null,
              secondaryAttribute?: string | null,
              secondaryAttributeType?: string | null,
              projectionType?: string | null,
            } | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Lambda",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            runtime?: Runtime | null,
            memory?: number | null,
            timeout?: number | null,
            prune?: boolean | null,
            tracing?: boolean | null,
            vpc?: boolean | null,
            webpack?: boolean | null,
            connection?:  {
              __typename: string,
              id: string,
              resourceId: string,
              permissions?:  {
                __typename: string,
                create?: boolean | null,
                read?: boolean | null,
                update?: boolean | null,
                delete?: boolean | null,
                scan?: boolean | null,
              } | null,
              updatedAt?: string | null,
              createdAt?: string | null,
            } | null,
            trigger?:  {
              __typename: string,
              id: string,
              serviceId?: string | null,
              resourceId?: string | null,
              user?: string | null,
              name?: string | null,
              description?: string | null,
              type?: string | null,
              private?: boolean | null,
              tags?: Array< string | null > | null,
              updatedAt?: string | null,
              createdAt?: string | null,
              eventTypes?:  {
                __typename: string,
                create?: boolean | null,
                delete?: boolean | null,
              } | null,
              typename?: string | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Rds",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            vpcName?: string | null,
            vpcExisting?: boolean | null,
            tags?: Array< string | null > | null,
            rdsEngine?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "S3",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sns",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            topicType?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sqs",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            hasDlq?: boolean | null,
            topicType?: string | null,
            visibilityTimeout?: number | null,
            deliveryDelay?: number | null,
            msgRetentionPeriod?: number | null,
            receiveMsgWaitTimeSecs?: number | null,
            maxMsgSize?: number | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Trigger",
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | {
            __typename: "Vpc",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            vpcExisting?: boolean | null,
            vpcExistingId?: string | null,
            vpcExistingSubnetA?: string | null,
            vpcExistingSubnetB?: string | null,
            vpcExistingSubnetC?: string | null,
            vpcExistingSecurityGroupId?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Resolver",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            model?: string | null,
            dataSource?: string | null,
            type?: string | null,
            resolverType?: string | null,
            isPipeline?: boolean | null,
            resolveWith_PK?: string | null,
            resolveWith_SK?: string | null,
            resolveFrom?: string | null,
            queryFrom?: string | null,
            modelFieldId?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Model",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            dataSource?: string | null,
            fields?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          }
        ) | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        cors?: boolean | null,
        method?: Method | null,
        path?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      stars?:  {
        __typename: "ListServiceStarsResult",
        ServiceStars?:  Array< {
          __typename: "ServiceStar",
          id: string,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null > | null,
        nextToken?: string | null,
        total?: number | null,
      } | null,
      isServiceOwner?: boolean | null,
      enums?:  Array< {
        __typename: "Enum",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        fields?: Array< string | null > | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      subscriptions?:  Array< {
        __typename: "Subs",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        description?: string | null,
        args?:  Array< {
          __typename: "Arg",
          name?: string | null,
          type?: string | null,
          required?: boolean | null,
        } | null > | null,
        type?: string | null,
        array?: boolean | null,
        required?: boolean | null,
        derivative?:  {
          __typename: "Derivative",
          name?: string | null,
          mutations?: Array< string | null > | null,
        } | null,
        tags?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        typename?: string | null,
      } | null > | null,
      customObjects?:  Array< {
        __typename: "CustomObject",
        id?: string | null,
        serviceId?: string | null,
        name?: string | null,
        description?: string | null,
        fields?:  Array< {
          __typename: "CustomObjectField",
          name?: string | null,
          fieldType?: string | null,
          type?: string | null,
          required?: boolean | null,
          array?: boolean | null,
        } | null > | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
  },
};

export type ListServicesByTeamQueryVariables = {
  input: ListServicesInput,
};

export type ListServicesByTeamQuery = {
  listServicesByTeam:  {
    __typename: "ListServicesResult",
    Services?:  Array< {
      __typename: "Service",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      cloudProvider?: CloudProvider | null,
      cloudProviderRegion?: CloudProviderRegion | null,
      stage?: string | null,
      iacFramework?: IACFramework | null,
      useCase?: UseCase | null,
      status?: ServiceStatus | null,
      optionBluePrint?: OptionBluePrint | null,
      appsyncName?: string | null,
      appsyncDescription?: string | null,
      authType?: AuthType | null,
      logging?: Logging | null,
      caching?: boolean | null,
      xRay?: boolean | null,
      webpack?: boolean | null,
      prune?: boolean | null,
      chatgpt?: boolean | null,
      operatingSystem?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      allowedTeams?:  Array< {
        __typename: "Team",
        id: string,
        name?: string | null,
        description?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      resources:  Array<( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null > | null,
      routes?:  Array< {
        __typename: "Route",
        id: string,
        serviceId?: string | null,
        authId?: string | null,
        auth?:  {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | null,
        resourceId?: string | null,
        resource: ( {
            __typename: "Cognito",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            resourceType?: string | null,
            tags?: Array< string | null > | null,
            cognitoType?: string | null,
            cognitoTestEmail?: string | null,
            cognitoExisting?: boolean | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Dynamo",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            billingMode?: string | null,
            readCapacity?: number | null,
            writeCapacity?: number | null,
            keyType?: string | null,
            primaryKeyName?: string | null,
            primaryKeyType?: string | null,
            secondaryKeyName?: string | null,
            secondaryKeyType?: string | null,
            hasIndex?: boolean | null,
            GSI?:  Array< {
              __typename: string,
              gsiId?: string | null,
              gsiName?: string | null,
              gsiKeyType?: string | null,
              primaryAttribute?: string | null,
              primaryAttributeType?: string | null,
              secondaryAttribute?: string | null,
              secondaryAttributeType?: string | null,
              projectionType?: string | null,
            } | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Lambda",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            runtime?: Runtime | null,
            memory?: number | null,
            timeout?: number | null,
            prune?: boolean | null,
            tracing?: boolean | null,
            vpc?: boolean | null,
            webpack?: boolean | null,
            connection?:  {
              __typename: string,
              id: string,
              resourceId: string,
              permissions?:  {
                __typename: string,
                create?: boolean | null,
                read?: boolean | null,
                update?: boolean | null,
                delete?: boolean | null,
                scan?: boolean | null,
              } | null,
              updatedAt?: string | null,
              createdAt?: string | null,
            } | null,
            trigger?:  {
              __typename: string,
              id: string,
              serviceId?: string | null,
              resourceId?: string | null,
              user?: string | null,
              name?: string | null,
              description?: string | null,
              type?: string | null,
              private?: boolean | null,
              tags?: Array< string | null > | null,
              updatedAt?: string | null,
              createdAt?: string | null,
              eventTypes?:  {
                __typename: string,
                create?: boolean | null,
                delete?: boolean | null,
              } | null,
              typename?: string | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Rds",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            vpcName?: string | null,
            vpcExisting?: boolean | null,
            tags?: Array< string | null > | null,
            rdsEngine?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "S3",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sns",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            topicType?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sqs",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            hasDlq?: boolean | null,
            topicType?: string | null,
            visibilityTimeout?: number | null,
            deliveryDelay?: number | null,
            msgRetentionPeriod?: number | null,
            receiveMsgWaitTimeSecs?: number | null,
            maxMsgSize?: number | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Trigger",
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | {
            __typename: "Vpc",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            vpcExisting?: boolean | null,
            vpcExistingId?: string | null,
            vpcExistingSubnetA?: string | null,
            vpcExistingSubnetB?: string | null,
            vpcExistingSubnetC?: string | null,
            vpcExistingSecurityGroupId?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Resolver",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            model?: string | null,
            dataSource?: string | null,
            type?: string | null,
            resolverType?: string | null,
            isPipeline?: boolean | null,
            resolveWith_PK?: string | null,
            resolveWith_SK?: string | null,
            resolveFrom?: string | null,
            queryFrom?: string | null,
            modelFieldId?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Model",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            dataSource?: string | null,
            fields?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          }
        ) | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        cors?: boolean | null,
        method?: Method | null,
        path?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      stars?:  {
        __typename: "ListServiceStarsResult",
        ServiceStars?:  Array< {
          __typename: "ServiceStar",
          id: string,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null > | null,
        nextToken?: string | null,
        total?: number | null,
      } | null,
      isServiceOwner?: boolean | null,
      enums?:  Array< {
        __typename: "Enum",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        fields?: Array< string | null > | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      subscriptions?:  Array< {
        __typename: "Subs",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        description?: string | null,
        args?:  Array< {
          __typename: "Arg",
          name?: string | null,
          type?: string | null,
          required?: boolean | null,
        } | null > | null,
        type?: string | null,
        array?: boolean | null,
        required?: boolean | null,
        derivative?:  {
          __typename: "Derivative",
          name?: string | null,
          mutations?: Array< string | null > | null,
        } | null,
        tags?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        typename?: string | null,
      } | null > | null,
      customObjects?:  Array< {
        __typename: "CustomObject",
        id?: string | null,
        serviceId?: string | null,
        name?: string | null,
        description?: string | null,
        fields?:  Array< {
          __typename: "CustomObjectField",
          name?: string | null,
          fieldType?: string | null,
          type?: string | null,
          required?: boolean | null,
          array?: boolean | null,
        } | null > | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
  },
};

export type ListSnssQueryVariables = {
  input: ListSnssInput,
};

export type ListSnssQuery = {
  listSnss:  {
    __typename: "ListSnssResult",
    Snss?:  Array< {
      __typename: "Sns",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      topicType?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListSqssQueryVariables = {
  input: ListSqssInput,
};

export type ListSqssQuery = {
  listSqss:  {
    __typename: "ListSqssResult",
    Sqss?:  Array< {
      __typename: "Sqs",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      hasDlq?: boolean | null,
      topicType?: string | null,
      visibilityTimeout?: number | null,
      deliveryDelay?: number | null,
      msgRetentionPeriod?: number | null,
      receiveMsgWaitTimeSecs?: number | null,
      maxMsgSize?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListTeamServicesQueryVariables = {
  input: ListTeamServicesInput,
};

export type ListTeamServicesQuery = {
  listTeamServices:  {
    __typename: "ListTeamServicesResult",
    TeamServices?:  Array< {
      __typename: "TeamService",
      id: string,
      teamId: string,
      serviceId: string,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListTeamUsersQueryVariables = {
  input: ListTeamUsersInput,
};

export type ListTeamUsersQuery = {
  listTeamUsers:  {
    __typename: "ListTeamUsersResult",
    TeamUsers?:  Array< {
      __typename: "TeamUser",
      id: string,
      userId: string,
      team:  {
        __typename: "Team",
        id: string,
        name?: string | null,
        description?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      },
      email?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListTeamUsersByUserQueryVariables = {
  input: ListTeamUsersInput,
};

export type ListTeamUsersByUserQuery = {
  listTeamUsersByUser:  {
    __typename: "ListTeamUsersResult",
    TeamUsers?:  Array< {
      __typename: "TeamUser",
      id: string,
      userId: string,
      team:  {
        __typename: "Team",
        id: string,
        name?: string | null,
        description?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      },
      email?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListTeamsQueryVariables = {
  input: ListTeamsInput,
};

export type ListTeamsQuery = {
  listTeams:  {
    __typename: "ListTeamsResult",
    Teams?:  Array< {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListTriggersQueryVariables = {
  input: ListTriggersInput,
};

export type ListTriggersQuery = {
  listTriggers:  {
    __typename: "ListTriggersResult",
    Triggers?:  Array< {
      __typename: "Trigger",
      id: string,
      serviceId?: string | null,
      resourceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      private?: boolean | null,
      tags?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      eventTypes?:  {
        __typename: "triggerEventType",
        create?: boolean | null,
        delete?: boolean | null,
      } | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListUserProfilesQueryVariables = {
  input: ListUserProfilesInput,
};

export type ListUserProfilesQuery = {
  listUserProfiles:  {
    __typename: "ListUserProfilesResult",
    UserProfiles?:  Array< {
      __typename: "UserProfile",
      id: string,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: string | null,
      avatar?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ListVpcsQueryVariables = {
  input: ListVpcsInput,
};

export type ListVpcsQuery = {
  listVpcs:  {
    __typename: "ListVpcsResult",
    Vpcs?:  Array< {
      __typename: "Vpc",
      id: string,
      serviceId?: string | null,
      user?: string | null,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      vpcExisting?: boolean | null,
      vpcExistingId?: string | null,
      vpcExistingSubnetA?: string | null,
      vpcExistingSubnetB?: string | null,
      vpcExistingSubnetC?: string | null,
      vpcExistingSecurityGroupId?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      typename?: string | null,
    } | null > | null,
    nextToken?: string | null,
  },
};

export type ScanServicesByNameQueryVariables = {
  input: ListServicesInput,
};

export type ScanServicesByNameQuery = {
  scanServicesByName:  {
    __typename: "ListServicesResult",
    Services?:  Array< {
      __typename: "Service",
      id: string,
      name?: string | null,
      description?: string | null,
      tags?: Array< string | null > | null,
      cloudProvider?: CloudProvider | null,
      cloudProviderRegion?: CloudProviderRegion | null,
      stage?: string | null,
      iacFramework?: IACFramework | null,
      useCase?: UseCase | null,
      status?: ServiceStatus | null,
      optionBluePrint?: OptionBluePrint | null,
      appsyncName?: string | null,
      appsyncDescription?: string | null,
      authType?: AuthType | null,
      logging?: Logging | null,
      caching?: boolean | null,
      xRay?: boolean | null,
      webpack?: boolean | null,
      prune?: boolean | null,
      chatgpt?: boolean | null,
      operatingSystem?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      allowedTeams?:  Array< {
        __typename: "Team",
        id: string,
        name?: string | null,
        description?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      resources:  Array<( {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Dynamo",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          billingMode?: string | null,
          readCapacity?: number | null,
          writeCapacity?: number | null,
          keyType?: string | null,
          primaryKeyName?: string | null,
          primaryKeyType?: string | null,
          secondaryKeyName?: string | null,
          secondaryKeyType?: string | null,
          hasIndex?: boolean | null,
          GSI?:  Array< {
            __typename: string,
            gsiId?: string | null,
            gsiName?: string | null,
            gsiKeyType?: string | null,
            primaryAttribute?: string | null,
            primaryAttributeType?: string | null,
            secondaryAttribute?: string | null,
            secondaryAttributeType?: string | null,
            projectionType?: string | null,
          } | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Lambda",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          runtime?: Runtime | null,
          memory?: number | null,
          timeout?: number | null,
          prune?: boolean | null,
          tracing?: boolean | null,
          vpc?: boolean | null,
          webpack?: boolean | null,
          connection?:  {
            __typename: string,
            id: string,
            resourceId: string,
            permissions?:  {
              __typename: string,
              create?: boolean | null,
              read?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
              scan?: boolean | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
          } | null,
          trigger?:  {
            __typename: string,
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Rds",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          vpcName?: string | null,
          vpcExisting?: boolean | null,
          tags?: Array< string | null > | null,
          rdsEngine?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "S3",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sns",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          topicType?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Sqs",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          hasDlq?: boolean | null,
          topicType?: string | null,
          visibilityTimeout?: number | null,
          deliveryDelay?: number | null,
          msgRetentionPeriod?: number | null,
          receiveMsgWaitTimeSecs?: number | null,
          maxMsgSize?: number | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Trigger",
          id: string,
          serviceId?: string | null,
          resourceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          type?: string | null,
          private?: boolean | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          eventTypes?:  {
            __typename: string,
            create?: boolean | null,
            delete?: boolean | null,
          } | null,
          typename?: string | null,
        } | {
          __typename: "Vpc",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          vpcExisting?: boolean | null,
          vpcExistingId?: string | null,
          vpcExistingSubnetA?: string | null,
          vpcExistingSubnetB?: string | null,
          vpcExistingSubnetC?: string | null,
          vpcExistingSecurityGroupId?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Resolver",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          model?: string | null,
          dataSource?: string | null,
          type?: string | null,
          resolverType?: string | null,
          isPipeline?: boolean | null,
          resolveWith_PK?: string | null,
          resolveWith_SK?: string | null,
          resolveFrom?: string | null,
          queryFrom?: string | null,
          modelFieldId?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | {
          __typename: "Model",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          dataSource?: string | null,
          fields?: string | null,
          tags?: Array< string | null > | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        }
      ) | null > | null,
      routes?:  Array< {
        __typename: "Route",
        id: string,
        serviceId?: string | null,
        authId?: string | null,
        auth?:  {
          __typename: "Cognito",
          id: string,
          serviceId?: string | null,
          user?: string | null,
          name?: string | null,
          description?: string | null,
          resourceType?: string | null,
          tags?: Array< string | null > | null,
          cognitoType?: string | null,
          cognitoTestEmail?: string | null,
          cognitoExisting?: boolean | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          typename?: string | null,
        } | null,
        resourceId?: string | null,
        resource: ( {
            __typename: "Cognito",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            resourceType?: string | null,
            tags?: Array< string | null > | null,
            cognitoType?: string | null,
            cognitoTestEmail?: string | null,
            cognitoExisting?: boolean | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Dynamo",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            billingMode?: string | null,
            readCapacity?: number | null,
            writeCapacity?: number | null,
            keyType?: string | null,
            primaryKeyName?: string | null,
            primaryKeyType?: string | null,
            secondaryKeyName?: string | null,
            secondaryKeyType?: string | null,
            hasIndex?: boolean | null,
            GSI?:  Array< {
              __typename: string,
              gsiId?: string | null,
              gsiName?: string | null,
              gsiKeyType?: string | null,
              primaryAttribute?: string | null,
              primaryAttributeType?: string | null,
              secondaryAttribute?: string | null,
              secondaryAttributeType?: string | null,
              projectionType?: string | null,
            } | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Lambda",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            runtime?: Runtime | null,
            memory?: number | null,
            timeout?: number | null,
            prune?: boolean | null,
            tracing?: boolean | null,
            vpc?: boolean | null,
            webpack?: boolean | null,
            connection?:  {
              __typename: string,
              id: string,
              resourceId: string,
              permissions?:  {
                __typename: string,
                create?: boolean | null,
                read?: boolean | null,
                update?: boolean | null,
                delete?: boolean | null,
                scan?: boolean | null,
              } | null,
              updatedAt?: string | null,
              createdAt?: string | null,
            } | null,
            trigger?:  {
              __typename: string,
              id: string,
              serviceId?: string | null,
              resourceId?: string | null,
              user?: string | null,
              name?: string | null,
              description?: string | null,
              type?: string | null,
              private?: boolean | null,
              tags?: Array< string | null > | null,
              updatedAt?: string | null,
              createdAt?: string | null,
              eventTypes?:  {
                __typename: string,
                create?: boolean | null,
                delete?: boolean | null,
              } | null,
              typename?: string | null,
            } | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Rds",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            vpcName?: string | null,
            vpcExisting?: boolean | null,
            tags?: Array< string | null > | null,
            rdsEngine?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "S3",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sns",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            topicType?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Sqs",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            hasDlq?: boolean | null,
            topicType?: string | null,
            visibilityTimeout?: number | null,
            deliveryDelay?: number | null,
            msgRetentionPeriod?: number | null,
            receiveMsgWaitTimeSecs?: number | null,
            maxMsgSize?: number | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Trigger",
            id: string,
            serviceId?: string | null,
            resourceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            type?: string | null,
            private?: boolean | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            eventTypes?:  {
              __typename: string,
              create?: boolean | null,
              delete?: boolean | null,
            } | null,
            typename?: string | null,
          } | {
            __typename: "Vpc",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            vpcExisting?: boolean | null,
            vpcExistingId?: string | null,
            vpcExistingSubnetA?: string | null,
            vpcExistingSubnetB?: string | null,
            vpcExistingSubnetC?: string | null,
            vpcExistingSecurityGroupId?: string | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Resolver",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            model?: string | null,
            dataSource?: string | null,
            type?: string | null,
            resolverType?: string | null,
            isPipeline?: boolean | null,
            resolveWith_PK?: string | null,
            resolveWith_SK?: string | null,
            resolveFrom?: string | null,
            queryFrom?: string | null,
            modelFieldId?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          } | {
            __typename: "Model",
            id: string,
            serviceId?: string | null,
            user?: string | null,
            name?: string | null,
            description?: string | null,
            dataSource?: string | null,
            fields?: string | null,
            tags?: Array< string | null > | null,
            updatedAt?: string | null,
            createdAt?: string | null,
            typename?: string | null,
          }
        ) | null,
        user?: string | null,
        name?: string | null,
        description?: string | null,
        cors?: boolean | null,
        method?: Method | null,
        path?: string | null,
        type?: string | null,
        private?: boolean | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null > | null,
      stars?:  {
        __typename: "ListServiceStarsResult",
        ServiceStars?:  Array< {
          __typename: "ServiceStar",
          id: string,
          updatedAt?: string | null,
          createdAt?: string | null,
        } | null > | null,
        nextToken?: string | null,
        total?: number | null,
      } | null,
      isServiceOwner?: boolean | null,
      enums?:  Array< {
        __typename: "Enum",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        fields?: Array< string | null > | null,
        description?: string | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      subscriptions?:  Array< {
        __typename: "Subs",
        id?: string | null,
        name?: string | null,
        serviceId?: string | null,
        description?: string | null,
        args?:  Array< {
          __typename: "Arg",
          name?: string | null,
          type?: string | null,
          required?: boolean | null,
        } | null > | null,
        type?: string | null,
        array?: boolean | null,
        required?: boolean | null,
        derivative?:  {
          __typename: "Derivative",
          name?: string | null,
          mutations?: Array< string | null > | null,
        } | null,
        tags?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        typename?: string | null,
      } | null > | null,
      customObjects?:  Array< {
        __typename: "CustomObject",
        id?: string | null,
        serviceId?: string | null,
        name?: string | null,
        description?: string | null,
        fields?:  Array< {
          __typename: "CustomObjectField",
          name?: string | null,
          fieldType?: string | null,
          type?: string | null,
          required?: boolean | null,
          array?: boolean | null,
        } | null > | null,
        tags?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        typename?: string | null,
      } | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
  },
};

export type OnUpdateCreateServiceStatusSubscriptionVariables = {
  serviceId: string,
};

export type OnUpdateCreateServiceStatusSubscription = {
  onUpdateCreateServiceStatus?:  {
    __typename: "OnUpdateCreateServiceStatusResult",
    serviceId: string,
    status?: string | null,
    done?: boolean | null,
    error?: boolean | null,
  } | null,
};
