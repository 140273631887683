import React from 'react'
import { ReactComponent as AlertInfoIcon } from 'src/assets/icons/alertInfoIcon.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import { ReactComponent as ErrorCloseIcon } from 'src/assets/icons/errorCloseIcon.svg'
import { ReactComponent as SuccessIcon } from 'src/assets/icons/successIcon.svg'
import { ReactComponent as AttentionIcon } from 'src/assets/icons/attentionIcon.svg'
import { classNames } from 'src/utils/css'

export interface Props {
  children: React.ReactNode
  classname?: string
  variant?: 'info' | 'error' | 'darker' | 'success'
  isCloseIcon?: boolean
  onClose?: () => void
}

export const Alert: React.FC<Props> = ({
  children,
  classname = '',
  variant = 'info',
  isCloseIcon = false, onClose = () => {}
}) => {
  const classnameByVariant = {
    info: 'text-white ring-2 ring-[#826AED]',
    error: 'text-white ring-2 ring-[#FF3F3F]',
    darker: 'text-white ring-2 ring-[#2B2D31]',
    success: 'text-white ring-2 ring-[#72DE94]'
  }
  return (<div
      className={classNames(`${classnameByVariant[variant]}`, 'box-border flex justify-between bg-[#171B21] rounded-md  block font-normal text-xs  focus:outline-none', classname)}>
      <div className={'flex gap-[24px] items-center py-[16px]  px-[24px]'}>
        {variant === 'info' && <AlertInfoIcon/>}
        {variant === 'error' && <ErrorCloseIcon/>}
        {variant === 'success' && <SuccessIcon/>}
        {variant === 'darker' && <AttentionIcon/>}
        <div className={'flex flex-col'}>
          <div className={'text-lg font-bold'}>
            {variant === 'info' && 'Info'}
            {variant === 'error' && 'Error'}
            {variant === 'success' && 'Success!'}
            {variant === 'darker' && 'Success!'}
          </div>
          <div className={'text-xs  text-[#7E858F] font-normal break-all'}>{children}</div>
        </div>
      </div>
      {isCloseIcon && <CloseIcon onClick={() => { onClose() }} className={'m-[8px] cursor-pointer'}/>}
    </div>

  )
}
