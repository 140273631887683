import { TextInput } from 'src/components/atoms/text-input'
import React, { useState } from 'react'
import { RowResolver } from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/ModelForm'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { RESOLVER_TYPE } from 'src/enums'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'

export const ResolverModelRow: React.FC<{
  models: any[]
  resolver: RowResolver
  resolverNameHandler: (e: Event | any, row: RowResolver) => void
  resolverTypeHandler: (e: Event | any, row: RowResolver) => void
  resolverPipelineHandler: (e: Event | any, row: RowResolver) => void
  resolverModelHandler: (model: any, resolver: RowResolver) => void
  confirmRowDelete?: (row: RowResolver) => void

}> = props => {
  const { resolver, models, resolverNameHandler, resolverModelHandler, resolverTypeHandler, resolverPipelineHandler } = props

  const [model, setModel] = useState('')

  const handleModelChange = (newModel: any) => {
    setModel(newModel)
    resolverModelHandler(newModel, resolver)
  }

  return (<div className={'flex items-center pt-[4px] justify-between border-t-[1px] border-[#2B2D31]'}>
    <TextInput
      error={resolver.name === ''}
      required
      disabled={true}
      value={resolver.name}
      onChange={(e) => {
        resolverNameHandler(e, resolver)
      }}
      labelText={'Resolver:'}
      showRequired
      id="resourceName"
    />
    <SelectOutlinedInput
      error={model ? '' : 'field is required'}
      classname={'w-1/5'}
      handleValueChange={handleModelChange}
      showRequired={true}
      labelText={'Model:'}
      selected={model}
      options={
        models?.map((m: any) => ({ name: m.name, value: m }))
      }
    />
    <CheckboxInput labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   labelText={'Pipeline'}
                   tooltipText='Checking Pipeline makes the function reusable'
                   checked={resolver.isPipeline}
                   onChange={(e) => { resolverPipelineHandler(e, resolver) }}
    />

     <SelectOutlinedInput
      error={resolver.model && resolver.type === '' ? 'field is required' : ''}
      classname={'w-1/5'}
      handleValueChange={(e) => {
        resolverTypeHandler(e, resolver)
      }}
      showRequired={true}
      labelText={'Type:'}
      selected={resolver.type}
      disabled={!resolver.model || resolver.isDisabled}
      options={[
        { name: 'Get', value: RESOLVER_TYPE.GET },
        { name: 'Create', value: RESOLVER_TYPE.CREATE },
        { name: 'Delete', value: RESOLVER_TYPE.DELETE },
        { name: 'Update', value: RESOLVER_TYPE.UPDATE },
        { name: 'Query', value: RESOLVER_TYPE.QUERY },
        { name: 'Scan', value: RESOLVER_TYPE.SCAN }
      ]
      }
    />
    {/* <Button classname={'mt-[28px]'} disabled={true} iconLeft={<TrashBinIcon/>} onClick={ () => { confirmRowDelete(resolver) }} variant={'darker'} title={'Delete Row'} type={'button'}/> */}
  </div>)
}
