/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const findServicesByName = /* GraphQL */ `query FindServicesByName($input: ListServicesInput!) {
  findServicesByName(input: $input) {
    Services {
      id
      name
      description
      tags
      cloudProvider
      cloudProviderRegion
      stage
      iacFramework
      useCase
      status
      optionBluePrint
      appsyncName
      appsyncDescription
      authType
      logging
      caching
      xRay
      webpack
      prune
      chatgpt
      operatingSystem
      organization {
        id
        name
        description
        tags
        updatedAt
        createdAt
        __typename
      }
      allowedTeams {
        id
        name
        description
        updatedAt
        createdAt
        __typename
      }
      resources {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      routes {
        id
        serviceId
        authId
        auth {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
          __typename
        }
        resourceId
        resource {
          ... on Cognito {
            id
            serviceId
            user
            name
            description
            resourceType
            tags
            cognitoType
            cognitoTestEmail
            cognitoExisting
            updatedAt
            createdAt
            typename
          }
          ... on Dynamo {
            id
            serviceId
            user
            name
            description
            tags
            billingMode
            readCapacity
            writeCapacity
            keyType
            primaryKeyName
            primaryKeyType
            secondaryKeyName
            secondaryKeyType
            hasIndex
            GSI {
              gsiId
              gsiName
              gsiKeyType
              primaryAttribute
              primaryAttributeType
              secondaryAttribute
              secondaryAttributeType
              projectionType
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Lambda {
            id
            serviceId
            user
            name
            description
            tags
            runtime
            memory
            timeout
            prune
            tracing
            vpc
            webpack
            connection {
              id
              resourceId
              permissions {
                create
                read
                update
                delete
                scan
                __typename
              }
              updatedAt
              createdAt
              __typename
            }
            trigger {
              id
              serviceId
              resourceId
              user
              name
              description
              type
              private
              tags
              updatedAt
              createdAt
              eventTypes {
                create
                delete
                __typename
              }
              typename
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Rds {
            id
            serviceId
            user
            name
            description
            vpcName
            vpcExisting
            tags
            rdsEngine
            updatedAt
            createdAt
            typename
          }
          ... on S3 {
            id
            serviceId
            user
            name
            description
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Sns {
            id
            serviceId
            user
            name
            description
            tags
            topicType
            updatedAt
            createdAt
            typename
          }
          ... on Sqs {
            id
            serviceId
            user
            name
            description
            tags
            hasDlq
            topicType
            visibilityTimeout
            deliveryDelay
            msgRetentionPeriod
            receiveMsgWaitTimeSecs
            maxMsgSize
            updatedAt
            createdAt
            typename
          }
          ... on Trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
          }
          ... on Vpc {
            id
            serviceId
            user
            name
            description
            tags
            vpcExisting
            vpcExistingId
            vpcExistingSubnetA
            vpcExistingSubnetB
            vpcExistingSubnetC
            vpcExistingSecurityGroupId
            updatedAt
            createdAt
            typename
          }
          ... on Resolver {
            id
            serviceId
            user
            name
            description
            model
            dataSource
            type
            resolverType
            isPipeline
            resolveWith_PK
            resolveWith_SK
            resolveFrom
            queryFrom
            modelFieldId
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Model {
            id
            serviceId
            user
            name
            description
            dataSource
            fields
            tags
            updatedAt
            createdAt
            typename
          }
        }
        user
        name
        description
        cors
        method
        path
        type
        private
        tags
        updatedAt
        createdAt
        __typename
      }
      stars {
        ServiceStars {
          id
          updatedAt
          createdAt
          __typename
        }
        nextToken
        total
        __typename
      }
      isServiceOwner
      enums {
        id
        name
        serviceId
        fields
        description
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      subscriptions {
        id
        name
        serviceId
        description
        args {
          name
          type
          required
          __typename
        }
        type
        array
        required
        derivative {
          name
          mutations
          __typename
        }
        tags
        createdAt
        updatedAt
        typename
        __typename
      }
      customObjects {
        id
        serviceId
        name
        description
        fields {
          name
          fieldType
          type
          required
          array
          __typename
        }
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FindServicesByNameQueryVariables,
  APITypes.FindServicesByNameQuery
>;
export const getCognito = /* GraphQL */ `query GetCognito($input: GetCognitoInput!) {
  getCognito(input: $input) {
    id
    serviceId
    user
    name
    description
    resourceType
    tags
    cognitoType
    cognitoTestEmail
    cognitoExisting
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCognitoQueryVariables,
  APITypes.GetCognitoQuery
>;
export const getDynamo = /* GraphQL */ `query GetDynamo($input: GetDynamoInput!) {
  getDynamo(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    billingMode
    readCapacity
    writeCapacity
    keyType
    primaryKeyName
    primaryKeyType
    secondaryKeyName
    secondaryKeyType
    hasIndex
    GSI {
      gsiId
      gsiName
      gsiKeyType
      primaryAttribute
      primaryAttributeType
      secondaryAttribute
      secondaryAttributeType
      projectionType
      __typename
    }
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDynamoQueryVariables, APITypes.GetDynamoQuery>;
export const getLambda = /* GraphQL */ `query GetLambda($input: GetLambdaInput!) {
  getLambda(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    runtime
    memory
    timeout
    prune
    tracing
    vpc
    webpack
    connection {
      id
      resourceId
      permissions {
        create
        read
        update
        delete
        scan
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    trigger {
      id
      serviceId
      resourceId
      user
      name
      description
      type
      private
      tags
      updatedAt
      createdAt
      eventTypes {
        create
        delete
        __typename
      }
      typename
      __typename
    }
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLambdaQueryVariables, APITypes.GetLambdaQuery>;
export const getModel = /* GraphQL */ `query GetModel($input: GetModelInput!) {
  getModel(input: $input) {
    id
    serviceId
    user
    name
    description
    dataSource
    fields
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetModelQueryVariables, APITypes.GetModelQuery>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    description
    tags
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const getOrganizationUser = /* GraphQL */ `query GetOrganizationUser($input: GetOrganizationUserInput!) {
  getOrganizationUser(input: $input) {
    id
    email
    organization {
      id
      name
      description
      tags
      updatedAt
      createdAt
      __typename
    }
    userProfile {
      id
      email
      firstName
      lastName
      bio
      avatar
      updatedAt
      createdAt
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationUserQueryVariables,
  APITypes.GetOrganizationUserQuery
>;
export const getRds = /* GraphQL */ `query GetRds($input: GetRdsInput!) {
  getRds(input: $input) {
    id
    serviceId
    user
    name
    description
    vpcName
    vpcExisting
    tags
    rdsEngine
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRdsQueryVariables, APITypes.GetRdsQuery>;
export const getResolver = /* GraphQL */ `query GetResolver($input: GetResolverInput!) {
  getResolver(input: $input) {
    id
    serviceId
    user
    name
    description
    model
    dataSource
    type
    resolverType
    isPipeline
    resolveWith_PK
    resolveWith_SK
    resolveFrom
    queryFrom
    modelFieldId
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetResolverQueryVariables,
  APITypes.GetResolverQuery
>;
export const getResourceConnection = /* GraphQL */ `query GetResourceConnection($input: GetResourceConnectionInput!) {
  getResourceConnection(input: $input) {
    id
    resourceId
    permissions {
      create
      read
      update
      delete
      scan
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetResourceConnectionQueryVariables,
  APITypes.GetResourceConnectionQuery
>;
export const getRoute = /* GraphQL */ `query GetRoute($input: GetRouteInput!) {
  getRoute(input: $input) {
    id
    serviceId
    authId
    auth {
      id
      serviceId
      user
      name
      description
      resourceType
      tags
      cognitoType
      cognitoTestEmail
      cognitoExisting
      updatedAt
      createdAt
      typename
      __typename
    }
    resourceId
    resource {
      ... on Cognito {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
      }
      ... on Dynamo {
        id
        serviceId
        user
        name
        description
        tags
        billingMode
        readCapacity
        writeCapacity
        keyType
        primaryKeyName
        primaryKeyType
        secondaryKeyName
        secondaryKeyType
        hasIndex
        GSI {
          gsiId
          gsiName
          gsiKeyType
          primaryAttribute
          primaryAttributeType
          secondaryAttribute
          secondaryAttributeType
          projectionType
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Lambda {
        id
        serviceId
        user
        name
        description
        tags
        runtime
        memory
        timeout
        prune
        tracing
        vpc
        webpack
        connection {
          id
          resourceId
          permissions {
            create
            read
            update
            delete
            scan
            __typename
          }
          updatedAt
          createdAt
          __typename
        }
        trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Rds {
        id
        serviceId
        user
        name
        description
        vpcName
        vpcExisting
        tags
        rdsEngine
        updatedAt
        createdAt
        typename
      }
      ... on S3 {
        id
        serviceId
        user
        name
        description
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Sns {
        id
        serviceId
        user
        name
        description
        tags
        topicType
        updatedAt
        createdAt
        typename
      }
      ... on Sqs {
        id
        serviceId
        user
        name
        description
        tags
        hasDlq
        topicType
        visibilityTimeout
        deliveryDelay
        msgRetentionPeriod
        receiveMsgWaitTimeSecs
        maxMsgSize
        updatedAt
        createdAt
        typename
      }
      ... on Trigger {
        id
        serviceId
        resourceId
        user
        name
        description
        type
        private
        tags
        updatedAt
        createdAt
        eventTypes {
          create
          delete
          __typename
        }
        typename
      }
      ... on Vpc {
        id
        serviceId
        user
        name
        description
        tags
        vpcExisting
        vpcExistingId
        vpcExistingSubnetA
        vpcExistingSubnetB
        vpcExistingSubnetC
        vpcExistingSecurityGroupId
        updatedAt
        createdAt
        typename
      }
      ... on Resolver {
        id
        serviceId
        user
        name
        description
        model
        dataSource
        type
        resolverType
        isPipeline
        resolveWith_PK
        resolveWith_SK
        resolveFrom
        queryFrom
        modelFieldId
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Model {
        id
        serviceId
        user
        name
        description
        dataSource
        fields
        tags
        updatedAt
        createdAt
        typename
      }
    }
    user
    name
    description
    cors
    method
    path
    type
    private
    tags
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRouteQueryVariables, APITypes.GetRouteQuery>;
export const getS3 = /* GraphQL */ `query GetS3($input: GetS3Input!) {
  getS3(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetS3QueryVariables, APITypes.GetS3Query>;
export const getService = /* GraphQL */ `query GetService($input: GetServiceInput!) {
  getService(input: $input) {
    id
    name
    description
    tags
    cloudProvider
    cloudProviderRegion
    stage
    iacFramework
    useCase
    status
    optionBluePrint
    appsyncName
    appsyncDescription
    authType
    logging
    caching
    xRay
    webpack
    prune
    chatgpt
    operatingSystem
    organization {
      id
      name
      description
      tags
      updatedAt
      createdAt
      __typename
    }
    allowedTeams {
      id
      name
      description
      updatedAt
      createdAt
      __typename
    }
    resources {
      ... on Cognito {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
      }
      ... on Dynamo {
        id
        serviceId
        user
        name
        description
        tags
        billingMode
        readCapacity
        writeCapacity
        keyType
        primaryKeyName
        primaryKeyType
        secondaryKeyName
        secondaryKeyType
        hasIndex
        GSI {
          gsiId
          gsiName
          gsiKeyType
          primaryAttribute
          primaryAttributeType
          secondaryAttribute
          secondaryAttributeType
          projectionType
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Lambda {
        id
        serviceId
        user
        name
        description
        tags
        runtime
        memory
        timeout
        prune
        tracing
        vpc
        webpack
        connection {
          id
          resourceId
          permissions {
            create
            read
            update
            delete
            scan
            __typename
          }
          updatedAt
          createdAt
          __typename
        }
        trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Rds {
        id
        serviceId
        user
        name
        description
        vpcName
        vpcExisting
        tags
        rdsEngine
        updatedAt
        createdAt
        typename
      }
      ... on S3 {
        id
        serviceId
        user
        name
        description
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Sns {
        id
        serviceId
        user
        name
        description
        tags
        topicType
        updatedAt
        createdAt
        typename
      }
      ... on Sqs {
        id
        serviceId
        user
        name
        description
        tags
        hasDlq
        topicType
        visibilityTimeout
        deliveryDelay
        msgRetentionPeriod
        receiveMsgWaitTimeSecs
        maxMsgSize
        updatedAt
        createdAt
        typename
      }
      ... on Trigger {
        id
        serviceId
        resourceId
        user
        name
        description
        type
        private
        tags
        updatedAt
        createdAt
        eventTypes {
          create
          delete
          __typename
        }
        typename
      }
      ... on Vpc {
        id
        serviceId
        user
        name
        description
        tags
        vpcExisting
        vpcExistingId
        vpcExistingSubnetA
        vpcExistingSubnetB
        vpcExistingSubnetC
        vpcExistingSecurityGroupId
        updatedAt
        createdAt
        typename
      }
      ... on Resolver {
        id
        serviceId
        user
        name
        description
        model
        dataSource
        type
        resolverType
        isPipeline
        resolveWith_PK
        resolveWith_SK
        resolveFrom
        queryFrom
        modelFieldId
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Model {
        id
        serviceId
        user
        name
        description
        dataSource
        fields
        tags
        updatedAt
        createdAt
        typename
      }
    }
    routes {
      id
      serviceId
      authId
      auth {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
        __typename
      }
      resourceId
      resource {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      user
      name
      description
      cors
      method
      path
      type
      private
      tags
      updatedAt
      createdAt
      __typename
    }
    stars {
      ServiceStars {
        id
        updatedAt
        createdAt
        __typename
      }
      nextToken
      total
      __typename
    }
    isServiceOwner
    enums {
      id
      name
      serviceId
      fields
      description
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    subscriptions {
      id
      name
      serviceId
      description
      args {
        name
        type
        required
        __typename
      }
      type
      array
      required
      derivative {
        name
        mutations
        __typename
      }
      tags
      createdAt
      updatedAt
      typename
      __typename
    }
    customObjects {
      id
      serviceId
      name
      description
      fields {
        name
        fieldType
        type
        required
        array
        __typename
      }
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceQueryVariables,
  APITypes.GetServiceQuery
>;
export const getServiceStar = /* GraphQL */ `query GetServiceStar($input: GetServiceStarInput!) {
  getServiceStar(input: $input) {
    id
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceStarQueryVariables,
  APITypes.GetServiceStarQuery
>;
export const getSns = /* GraphQL */ `query GetSns($input: GetSnsInput!) {
  getSns(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    topicType
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSnsQueryVariables, APITypes.GetSnsQuery>;
export const getSqs = /* GraphQL */ `query GetSqs($input: GetSqsInput!) {
  getSqs(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    hasDlq
    topicType
    visibilityTimeout
    deliveryDelay
    msgRetentionPeriod
    receiveMsgWaitTimeSecs
    maxMsgSize
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSqsQueryVariables, APITypes.GetSqsQuery>;
export const getTeam = /* GraphQL */ `query GetTeam($input: GetTeamInput!) {
  getTeam(input: $input) {
    id
    name
    description
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const getTeamService = /* GraphQL */ `query GetTeamService($input: GetTeamServiceInput!) {
  getTeamService(input: $input) {
    id
    teamId
    serviceId
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamServiceQueryVariables,
  APITypes.GetTeamServiceQuery
>;
export const getTeamUser = /* GraphQL */ `query GetTeamUser($input: GetTeamUserInput!) {
  getTeamUser(input: $input) {
    id
    userId
    team {
      id
      name
      description
      updatedAt
      createdAt
      __typename
    }
    email
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamUserQueryVariables,
  APITypes.GetTeamUserQuery
>;
export const getTrigger = /* GraphQL */ `query GetTrigger($input: GetTriggerInput!) {
  getTrigger(input: $input) {
    id
    serviceId
    resourceId
    user
    name
    description
    type
    private
    tags
    updatedAt
    createdAt
    eventTypes {
      create
      delete
      __typename
    }
    typename
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTriggerQueryVariables,
  APITypes.GetTriggerQuery
>;
export const getUserProfile = /* GraphQL */ `query GetUserProfile($input: GetUserProfileInput) {
  getUserProfile(input: $input) {
    id
    email
    firstName
    lastName
    bio
    avatar
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileQueryVariables,
  APITypes.GetUserProfileQuery
>;
export const getVpc = /* GraphQL */ `query GetVpc($input: GetVpcInput!) {
  getVpc(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    vpcExisting
    vpcExistingId
    vpcExistingSubnetA
    vpcExistingSubnetB
    vpcExistingSubnetC
    vpcExistingSecurityGroupId
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVpcQueryVariables, APITypes.GetVpcQuery>;
export const listCognitos = /* GraphQL */ `query ListCognitos($input: ListCognitosInput!) {
  listCognitos(input: $input) {
    Cognitos {
      id
      serviceId
      user
      name
      description
      resourceType
      tags
      cognitoType
      cognitoTestEmail
      cognitoExisting
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCognitosQueryVariables,
  APITypes.ListCognitosQuery
>;
export const listDynamos = /* GraphQL */ `query ListDynamos($input: ListDynamosInput!) {
  listDynamos(input: $input) {
    Dynamos {
      id
      serviceId
      user
      name
      description
      tags
      billingMode
      readCapacity
      writeCapacity
      keyType
      primaryKeyName
      primaryKeyType
      secondaryKeyName
      secondaryKeyType
      hasIndex
      GSI {
        gsiId
        gsiName
        gsiKeyType
        primaryAttribute
        primaryAttributeType
        secondaryAttribute
        secondaryAttributeType
        projectionType
        __typename
      }
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDynamosQueryVariables,
  APITypes.ListDynamosQuery
>;
export const listLambdas = /* GraphQL */ `query ListLambdas($input: ListLambdasInput!) {
  listLambdas(input: $input) {
    Lambdas {
      id
      serviceId
      user
      name
      description
      tags
      runtime
      memory
      timeout
      prune
      tracing
      vpc
      webpack
      connection {
        id
        resourceId
        permissions {
          create
          read
          update
          delete
          scan
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      trigger {
        id
        serviceId
        resourceId
        user
        name
        description
        type
        private
        tags
        updatedAt
        createdAt
        eventTypes {
          create
          delete
          __typename
        }
        typename
        __typename
      }
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLambdasQueryVariables,
  APITypes.ListLambdasQuery
>;
export const listModels = /* GraphQL */ `query ListModels($input: ListModelsInput!) {
  listModels(input: $input) {
    Models {
      id
      serviceId
      user
      name
      description
      dataSource
      fields
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListModelsQueryVariables,
  APITypes.ListModelsQuery
>;
export const listOrganizationUsers = /* GraphQL */ `query ListOrganizationUsers($input: ListOrganizationUsersInput!) {
  listOrganizationUsers(input: $input) {
    OrganizationUsers {
      id
      email
      organization {
        id
        name
        description
        tags
        updatedAt
        createdAt
        __typename
      }
      userProfile {
        id
        email
        firstName
        lastName
        bio
        avatar
        updatedAt
        createdAt
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationUsersQueryVariables,
  APITypes.ListOrganizationUsersQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations($input: ListOrganizationsInput!) {
  listOrganizations(input: $input) {
    Organizations {
      id
      name
      description
      tags
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const listOrganizationsByUser = /* GraphQL */ `query ListOrganizationsByUser($input: ListOrganizationUsersInput) {
  listOrganizationsByUser(input: $input) {
    OrganizationUsers {
      id
      email
      organization {
        id
        name
        description
        tags
        updatedAt
        createdAt
        __typename
      }
      userProfile {
        id
        email
        firstName
        lastName
        bio
        avatar
        updatedAt
        createdAt
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsByUserQueryVariables,
  APITypes.ListOrganizationsByUserQuery
>;
export const listRdss = /* GraphQL */ `query ListRdss($input: ListRdssInput!) {
  listRdss(input: $input) {
    Rdss {
      id
      serviceId
      user
      name
      description
      vpcName
      vpcExisting
      tags
      rdsEngine
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRdssQueryVariables, APITypes.ListRdssQuery>;
export const listResolvers = /* GraphQL */ `query ListResolvers($input: ListResolversInput!) {
  listResolvers(input: $input) {
    Resolvers {
      id
      serviceId
      user
      name
      description
      model
      dataSource
      type
      resolverType
      isPipeline
      resolveWith_PK
      resolveWith_SK
      resolveFrom
      queryFrom
      modelFieldId
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListResolversQueryVariables,
  APITypes.ListResolversQuery
>;
export const listResourceConnections = /* GraphQL */ `query ListResourceConnections($input: ListResourceConnectionsInput!) {
  listResourceConnections(input: $input) {
    ResourceConnections {
      id
      resourceId
      permissions {
        create
        read
        update
        delete
        scan
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListResourceConnectionsQueryVariables,
  APITypes.ListResourceConnectionsQuery
>;
export const listRoutes = /* GraphQL */ `query ListRoutes($input: ListRoutesInput!) {
  listRoutes(input: $input) {
    Routes {
      id
      serviceId
      authId
      auth {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
        __typename
      }
      resourceId
      resource {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      user
      name
      description
      cors
      method
      path
      type
      private
      tags
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRoutesQueryVariables,
  APITypes.ListRoutesQuery
>;
export const listS3s = /* GraphQL */ `query ListS3s($input: ListS3sInput!) {
  listS3s(input: $input) {
    S3s {
      id
      serviceId
      user
      name
      description
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListS3sQueryVariables, APITypes.ListS3sQuery>;
export const listServiceStars = /* GraphQL */ `query ListServiceStars($input: ListServiceStarsInput!) {
  listServiceStars(input: $input) {
    ServiceStars {
      id
      updatedAt
      createdAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceStarsQueryVariables,
  APITypes.ListServiceStarsQuery
>;
export const listServices = /* GraphQL */ `query ListServices($input: ListServicesInput!) {
  listServices(input: $input) {
    Services {
      id
      name
      description
      tags
      cloudProvider
      cloudProviderRegion
      stage
      iacFramework
      useCase
      status
      optionBluePrint
      appsyncName
      appsyncDescription
      authType
      logging
      caching
      xRay
      webpack
      prune
      chatgpt
      operatingSystem
      organization {
        id
        name
        description
        tags
        updatedAt
        createdAt
        __typename
      }
      allowedTeams {
        id
        name
        description
        updatedAt
        createdAt
        __typename
      }
      resources {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      routes {
        id
        serviceId
        authId
        auth {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
          __typename
        }
        resourceId
        resource {
          ... on Cognito {
            id
            serviceId
            user
            name
            description
            resourceType
            tags
            cognitoType
            cognitoTestEmail
            cognitoExisting
            updatedAt
            createdAt
            typename
          }
          ... on Dynamo {
            id
            serviceId
            user
            name
            description
            tags
            billingMode
            readCapacity
            writeCapacity
            keyType
            primaryKeyName
            primaryKeyType
            secondaryKeyName
            secondaryKeyType
            hasIndex
            GSI {
              gsiId
              gsiName
              gsiKeyType
              primaryAttribute
              primaryAttributeType
              secondaryAttribute
              secondaryAttributeType
              projectionType
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Lambda {
            id
            serviceId
            user
            name
            description
            tags
            runtime
            memory
            timeout
            prune
            tracing
            vpc
            webpack
            connection {
              id
              resourceId
              permissions {
                create
                read
                update
                delete
                scan
                __typename
              }
              updatedAt
              createdAt
              __typename
            }
            trigger {
              id
              serviceId
              resourceId
              user
              name
              description
              type
              private
              tags
              updatedAt
              createdAt
              eventTypes {
                create
                delete
                __typename
              }
              typename
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Rds {
            id
            serviceId
            user
            name
            description
            vpcName
            vpcExisting
            tags
            rdsEngine
            updatedAt
            createdAt
            typename
          }
          ... on S3 {
            id
            serviceId
            user
            name
            description
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Sns {
            id
            serviceId
            user
            name
            description
            tags
            topicType
            updatedAt
            createdAt
            typename
          }
          ... on Sqs {
            id
            serviceId
            user
            name
            description
            tags
            hasDlq
            topicType
            visibilityTimeout
            deliveryDelay
            msgRetentionPeriod
            receiveMsgWaitTimeSecs
            maxMsgSize
            updatedAt
            createdAt
            typename
          }
          ... on Trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
          }
          ... on Vpc {
            id
            serviceId
            user
            name
            description
            tags
            vpcExisting
            vpcExistingId
            vpcExistingSubnetA
            vpcExistingSubnetB
            vpcExistingSubnetC
            vpcExistingSecurityGroupId
            updatedAt
            createdAt
            typename
          }
          ... on Resolver {
            id
            serviceId
            user
            name
            description
            model
            dataSource
            type
            resolverType
            isPipeline
            resolveWith_PK
            resolveWith_SK
            resolveFrom
            queryFrom
            modelFieldId
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Model {
            id
            serviceId
            user
            name
            description
            dataSource
            fields
            tags
            updatedAt
            createdAt
            typename
          }
        }
        user
        name
        description
        cors
        method
        path
        type
        private
        tags
        updatedAt
        createdAt
        __typename
      }
      stars {
        ServiceStars {
          id
          updatedAt
          createdAt
          __typename
        }
        nextToken
        total
        __typename
      }
      isServiceOwner
      enums {
        id
        name
        serviceId
        fields
        description
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      subscriptions {
        id
        name
        serviceId
        description
        args {
          name
          type
          required
          __typename
        }
        type
        array
        required
        derivative {
          name
          mutations
          __typename
        }
        tags
        createdAt
        updatedAt
        typename
        __typename
      }
      customObjects {
        id
        serviceId
        name
        description
        fields {
          name
          fieldType
          type
          required
          array
          __typename
        }
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesQueryVariables,
  APITypes.ListServicesQuery
>;
export const listServicesByOrg = /* GraphQL */ `query ListServicesByOrg($input: ListServicesInput!) {
  listServicesByOrg(input: $input) {
    Services {
      id
      name
      description
      tags
      cloudProvider
      cloudProviderRegion
      stage
      iacFramework
      useCase
      status
      optionBluePrint
      appsyncName
      appsyncDescription
      authType
      logging
      caching
      xRay
      webpack
      prune
      chatgpt
      operatingSystem
      organization {
        id
        name
        description
        tags
        updatedAt
        createdAt
        __typename
      }
      allowedTeams {
        id
        name
        description
        updatedAt
        createdAt
        __typename
      }
      resources {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      routes {
        id
        serviceId
        authId
        auth {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
          __typename
        }
        resourceId
        resource {
          ... on Cognito {
            id
            serviceId
            user
            name
            description
            resourceType
            tags
            cognitoType
            cognitoTestEmail
            cognitoExisting
            updatedAt
            createdAt
            typename
          }
          ... on Dynamo {
            id
            serviceId
            user
            name
            description
            tags
            billingMode
            readCapacity
            writeCapacity
            keyType
            primaryKeyName
            primaryKeyType
            secondaryKeyName
            secondaryKeyType
            hasIndex
            GSI {
              gsiId
              gsiName
              gsiKeyType
              primaryAttribute
              primaryAttributeType
              secondaryAttribute
              secondaryAttributeType
              projectionType
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Lambda {
            id
            serviceId
            user
            name
            description
            tags
            runtime
            memory
            timeout
            prune
            tracing
            vpc
            webpack
            connection {
              id
              resourceId
              permissions {
                create
                read
                update
                delete
                scan
                __typename
              }
              updatedAt
              createdAt
              __typename
            }
            trigger {
              id
              serviceId
              resourceId
              user
              name
              description
              type
              private
              tags
              updatedAt
              createdAt
              eventTypes {
                create
                delete
                __typename
              }
              typename
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Rds {
            id
            serviceId
            user
            name
            description
            vpcName
            vpcExisting
            tags
            rdsEngine
            updatedAt
            createdAt
            typename
          }
          ... on S3 {
            id
            serviceId
            user
            name
            description
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Sns {
            id
            serviceId
            user
            name
            description
            tags
            topicType
            updatedAt
            createdAt
            typename
          }
          ... on Sqs {
            id
            serviceId
            user
            name
            description
            tags
            hasDlq
            topicType
            visibilityTimeout
            deliveryDelay
            msgRetentionPeriod
            receiveMsgWaitTimeSecs
            maxMsgSize
            updatedAt
            createdAt
            typename
          }
          ... on Trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
          }
          ... on Vpc {
            id
            serviceId
            user
            name
            description
            tags
            vpcExisting
            vpcExistingId
            vpcExistingSubnetA
            vpcExistingSubnetB
            vpcExistingSubnetC
            vpcExistingSecurityGroupId
            updatedAt
            createdAt
            typename
          }
          ... on Resolver {
            id
            serviceId
            user
            name
            description
            model
            dataSource
            type
            resolverType
            isPipeline
            resolveWith_PK
            resolveWith_SK
            resolveFrom
            queryFrom
            modelFieldId
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Model {
            id
            serviceId
            user
            name
            description
            dataSource
            fields
            tags
            updatedAt
            createdAt
            typename
          }
        }
        user
        name
        description
        cors
        method
        path
        type
        private
        tags
        updatedAt
        createdAt
        __typename
      }
      stars {
        ServiceStars {
          id
          updatedAt
          createdAt
          __typename
        }
        nextToken
        total
        __typename
      }
      isServiceOwner
      enums {
        id
        name
        serviceId
        fields
        description
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      subscriptions {
        id
        name
        serviceId
        description
        args {
          name
          type
          required
          __typename
        }
        type
        array
        required
        derivative {
          name
          mutations
          __typename
        }
        tags
        createdAt
        updatedAt
        typename
        __typename
      }
      customObjects {
        id
        serviceId
        name
        description
        fields {
          name
          fieldType
          type
          required
          array
          __typename
        }
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesByOrgQueryVariables,
  APITypes.ListServicesByOrgQuery
>;
export const listServicesByTeam = /* GraphQL */ `query ListServicesByTeam($input: ListServicesInput!) {
  listServicesByTeam(input: $input) {
    Services {
      id
      name
      description
      tags
      cloudProvider
      cloudProviderRegion
      stage
      iacFramework
      useCase
      status
      optionBluePrint
      appsyncName
      appsyncDescription
      authType
      logging
      caching
      xRay
      webpack
      prune
      chatgpt
      operatingSystem
      organization {
        id
        name
        description
        tags
        updatedAt
        createdAt
        __typename
      }
      allowedTeams {
        id
        name
        description
        updatedAt
        createdAt
        __typename
      }
      resources {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      routes {
        id
        serviceId
        authId
        auth {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
          __typename
        }
        resourceId
        resource {
          ... on Cognito {
            id
            serviceId
            user
            name
            description
            resourceType
            tags
            cognitoType
            cognitoTestEmail
            cognitoExisting
            updatedAt
            createdAt
            typename
          }
          ... on Dynamo {
            id
            serviceId
            user
            name
            description
            tags
            billingMode
            readCapacity
            writeCapacity
            keyType
            primaryKeyName
            primaryKeyType
            secondaryKeyName
            secondaryKeyType
            hasIndex
            GSI {
              gsiId
              gsiName
              gsiKeyType
              primaryAttribute
              primaryAttributeType
              secondaryAttribute
              secondaryAttributeType
              projectionType
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Lambda {
            id
            serviceId
            user
            name
            description
            tags
            runtime
            memory
            timeout
            prune
            tracing
            vpc
            webpack
            connection {
              id
              resourceId
              permissions {
                create
                read
                update
                delete
                scan
                __typename
              }
              updatedAt
              createdAt
              __typename
            }
            trigger {
              id
              serviceId
              resourceId
              user
              name
              description
              type
              private
              tags
              updatedAt
              createdAt
              eventTypes {
                create
                delete
                __typename
              }
              typename
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Rds {
            id
            serviceId
            user
            name
            description
            vpcName
            vpcExisting
            tags
            rdsEngine
            updatedAt
            createdAt
            typename
          }
          ... on S3 {
            id
            serviceId
            user
            name
            description
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Sns {
            id
            serviceId
            user
            name
            description
            tags
            topicType
            updatedAt
            createdAt
            typename
          }
          ... on Sqs {
            id
            serviceId
            user
            name
            description
            tags
            hasDlq
            topicType
            visibilityTimeout
            deliveryDelay
            msgRetentionPeriod
            receiveMsgWaitTimeSecs
            maxMsgSize
            updatedAt
            createdAt
            typename
          }
          ... on Trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
          }
          ... on Vpc {
            id
            serviceId
            user
            name
            description
            tags
            vpcExisting
            vpcExistingId
            vpcExistingSubnetA
            vpcExistingSubnetB
            vpcExistingSubnetC
            vpcExistingSecurityGroupId
            updatedAt
            createdAt
            typename
          }
          ... on Resolver {
            id
            serviceId
            user
            name
            description
            model
            dataSource
            type
            resolverType
            isPipeline
            resolveWith_PK
            resolveWith_SK
            resolveFrom
            queryFrom
            modelFieldId
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Model {
            id
            serviceId
            user
            name
            description
            dataSource
            fields
            tags
            updatedAt
            createdAt
            typename
          }
        }
        user
        name
        description
        cors
        method
        path
        type
        private
        tags
        updatedAt
        createdAt
        __typename
      }
      stars {
        ServiceStars {
          id
          updatedAt
          createdAt
          __typename
        }
        nextToken
        total
        __typename
      }
      isServiceOwner
      enums {
        id
        name
        serviceId
        fields
        description
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      subscriptions {
        id
        name
        serviceId
        description
        args {
          name
          type
          required
          __typename
        }
        type
        array
        required
        derivative {
          name
          mutations
          __typename
        }
        tags
        createdAt
        updatedAt
        typename
        __typename
      }
      customObjects {
        id
        serviceId
        name
        description
        fields {
          name
          fieldType
          type
          required
          array
          __typename
        }
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesByTeamQueryVariables,
  APITypes.ListServicesByTeamQuery
>;
export const listSnss = /* GraphQL */ `query ListSnss($input: ListSnssInput!) {
  listSnss(input: $input) {
    Snss {
      id
      serviceId
      user
      name
      description
      tags
      topicType
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSnssQueryVariables, APITypes.ListSnssQuery>;
export const listSqss = /* GraphQL */ `query ListSqss($input: ListSqssInput!) {
  listSqss(input: $input) {
    Sqss {
      id
      serviceId
      user
      name
      description
      tags
      hasDlq
      topicType
      visibilityTimeout
      deliveryDelay
      msgRetentionPeriod
      receiveMsgWaitTimeSecs
      maxMsgSize
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSqssQueryVariables, APITypes.ListSqssQuery>;
export const listTeamServices = /* GraphQL */ `query ListTeamServices($input: ListTeamServicesInput!) {
  listTeamServices(input: $input) {
    TeamServices {
      id
      teamId
      serviceId
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamServicesQueryVariables,
  APITypes.ListTeamServicesQuery
>;
export const listTeamUsers = /* GraphQL */ `query ListTeamUsers($input: ListTeamUsersInput!) {
  listTeamUsers(input: $input) {
    TeamUsers {
      id
      userId
      team {
        id
        name
        description
        updatedAt
        createdAt
        __typename
      }
      email
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamUsersQueryVariables,
  APITypes.ListTeamUsersQuery
>;
export const listTeamUsersByUser = /* GraphQL */ `query ListTeamUsersByUser($input: ListTeamUsersInput!) {
  listTeamUsersByUser(input: $input) {
    TeamUsers {
      id
      userId
      team {
        id
        name
        description
        updatedAt
        createdAt
        __typename
      }
      email
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamUsersByUserQueryVariables,
  APITypes.ListTeamUsersByUserQuery
>;
export const listTeams = /* GraphQL */ `query ListTeams($input: ListTeamsInput!) {
  listTeams(input: $input) {
    Teams {
      id
      name
      description
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamsQueryVariables, APITypes.ListTeamsQuery>;
export const listTriggers = /* GraphQL */ `query ListTriggers($input: ListTriggersInput!) {
  listTriggers(input: $input) {
    Triggers {
      id
      serviceId
      resourceId
      user
      name
      description
      type
      private
      tags
      updatedAt
      createdAt
      eventTypes {
        create
        delete
        __typename
      }
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTriggersQueryVariables,
  APITypes.ListTriggersQuery
>;
export const listUserProfiles = /* GraphQL */ `query ListUserProfiles($input: ListUserProfilesInput!) {
  listUserProfiles(input: $input) {
    UserProfiles {
      id
      email
      firstName
      lastName
      bio
      avatar
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProfilesQueryVariables,
  APITypes.ListUserProfilesQuery
>;
export const listVpcs = /* GraphQL */ `query ListVpcs($input: ListVpcsInput!) {
  listVpcs(input: $input) {
    Vpcs {
      id
      serviceId
      user
      name
      description
      tags
      vpcExisting
      vpcExistingId
      vpcExistingSubnetA
      vpcExistingSubnetB
      vpcExistingSubnetC
      vpcExistingSecurityGroupId
      updatedAt
      createdAt
      typename
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListVpcsQueryVariables, APITypes.ListVpcsQuery>;
export const scanServicesByName = /* GraphQL */ `query ScanServicesByName($input: ListServicesInput!) {
  scanServicesByName(input: $input) {
    Services {
      id
      name
      description
      tags
      cloudProvider
      cloudProviderRegion
      stage
      iacFramework
      useCase
      status
      optionBluePrint
      appsyncName
      appsyncDescription
      authType
      logging
      caching
      xRay
      webpack
      prune
      chatgpt
      operatingSystem
      organization {
        id
        name
        description
        tags
        updatedAt
        createdAt
        __typename
      }
      allowedTeams {
        id
        name
        description
        updatedAt
        createdAt
        __typename
      }
      resources {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      routes {
        id
        serviceId
        authId
        auth {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
          __typename
        }
        resourceId
        resource {
          ... on Cognito {
            id
            serviceId
            user
            name
            description
            resourceType
            tags
            cognitoType
            cognitoTestEmail
            cognitoExisting
            updatedAt
            createdAt
            typename
          }
          ... on Dynamo {
            id
            serviceId
            user
            name
            description
            tags
            billingMode
            readCapacity
            writeCapacity
            keyType
            primaryKeyName
            primaryKeyType
            secondaryKeyName
            secondaryKeyType
            hasIndex
            GSI {
              gsiId
              gsiName
              gsiKeyType
              primaryAttribute
              primaryAttributeType
              secondaryAttribute
              secondaryAttributeType
              projectionType
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Lambda {
            id
            serviceId
            user
            name
            description
            tags
            runtime
            memory
            timeout
            prune
            tracing
            vpc
            webpack
            connection {
              id
              resourceId
              permissions {
                create
                read
                update
                delete
                scan
                __typename
              }
              updatedAt
              createdAt
              __typename
            }
            trigger {
              id
              serviceId
              resourceId
              user
              name
              description
              type
              private
              tags
              updatedAt
              createdAt
              eventTypes {
                create
                delete
                __typename
              }
              typename
              __typename
            }
            updatedAt
            createdAt
            typename
          }
          ... on Rds {
            id
            serviceId
            user
            name
            description
            vpcName
            vpcExisting
            tags
            rdsEngine
            updatedAt
            createdAt
            typename
          }
          ... on S3 {
            id
            serviceId
            user
            name
            description
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Sns {
            id
            serviceId
            user
            name
            description
            tags
            topicType
            updatedAt
            createdAt
            typename
          }
          ... on Sqs {
            id
            serviceId
            user
            name
            description
            tags
            hasDlq
            topicType
            visibilityTimeout
            deliveryDelay
            msgRetentionPeriod
            receiveMsgWaitTimeSecs
            maxMsgSize
            updatedAt
            createdAt
            typename
          }
          ... on Trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
          }
          ... on Vpc {
            id
            serviceId
            user
            name
            description
            tags
            vpcExisting
            vpcExistingId
            vpcExistingSubnetA
            vpcExistingSubnetB
            vpcExistingSubnetC
            vpcExistingSecurityGroupId
            updatedAt
            createdAt
            typename
          }
          ... on Resolver {
            id
            serviceId
            user
            name
            description
            model
            dataSource
            type
            resolverType
            isPipeline
            resolveWith_PK
            resolveWith_SK
            resolveFrom
            queryFrom
            modelFieldId
            tags
            updatedAt
            createdAt
            typename
          }
          ... on Model {
            id
            serviceId
            user
            name
            description
            dataSource
            fields
            tags
            updatedAt
            createdAt
            typename
          }
        }
        user
        name
        description
        cors
        method
        path
        type
        private
        tags
        updatedAt
        createdAt
        __typename
      }
      stars {
        ServiceStars {
          id
          updatedAt
          createdAt
          __typename
        }
        nextToken
        total
        __typename
      }
      isServiceOwner
      enums {
        id
        name
        serviceId
        fields
        description
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      subscriptions {
        id
        name
        serviceId
        description
        args {
          name
          type
          required
          __typename
        }
        type
        array
        required
        derivative {
          name
          mutations
          __typename
        }
        tags
        createdAt
        updatedAt
        typename
        __typename
      }
      customObjects {
        id
        serviceId
        name
        description
        fields {
          name
          fieldType
          type
          required
          array
          __typename
        }
        tags
        updatedAt
        createdAt
        typename
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ScanServicesByNameQueryVariables,
  APITypes.ScanServicesByNameQuery
>;
