import React from 'react'
import { Service } from 'src/API'
import { ServiceBuilderCard } from 'src/components/atoms/service-builder-card'

export const ServiceBuilderCards: React.FC<{ confirmDelete: (id: string) => void, download: (id: string) => void, previewCode: (id: string) => void, editService: (id: string) => void, services: Service [] }> = props => {
  const { confirmDelete, editService, previewCode, download, services } = props
  return (
      <div className={'mt-11 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-y-6 justify-left'}>
          {services?.map((service) => <ServiceBuilderCard
              confirmDelete={confirmDelete}
              download={download} previewCode={previewCode}
              editService={editService}
              service={service} key={service.id}/>)}
      </div>
  )
}
export default ServiceBuilderCards
