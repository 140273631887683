import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { isValidEmail } from 'src/utils/validation'
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo-cloudgto.svg'
import { TextInput } from 'src/components/atoms/text-input'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as NextIcon } from 'src/assets/icons/arrow-forward-circle.svg'
import Preloader from 'src/components/UI/preloader/Preloader'

interface FormSignUp {
  name: string
  email: string
  firstName: string
  lastName: string
}

const SignUp: React.FC = () => {
  const [isLoading, setIsloading] = useState(false)
  const navigate = useNavigate()
  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required'),
    firstName: yup
      .string()
      .required('This field is required'),
    lastName: yup
      .string()
      .required('This field is required'),
    email: yup
      .string()
      .required('This field is required')
      .test(
        'Email',
        'Please enter a valid email address',
        (value) => {
          if (value) return isValidEmail(value)
          return true
        }
      )
  })

  const {
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormSignUp>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      email: '',
      lastName: '',
      firstName: ''
    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      name: '',
      email: '',
      lastName: '',
      firstName: ''
    })
  }, [reset])

  const onSubmit = (data: FormSignUp) => {
    setIsloading(true)
    localStorage.setItem('name', data.name)
    localStorage.setItem('email', data.email)
    localStorage.setItem('lastName', data.lastName)
    localStorage.setItem('firstName', data.firstName)
    navigate('/questionsForm')
    console.log('data FormSignUp', data)
    setIsloading(false)
  }
  const goToLogin = () => {
    navigate('/login')
  }
  return (<>
    <div className={'fixed inset-0 z-50 text-white w-screen flex flex-col items-center justify-center gap-[50px] h-screen bg-[#020409]'}
    >
      <LogoIcon/>
      <div className={'bg-[#171B21] rounded-2xl md:w-[478px] sm:w-full'}>
        <div className={'p-8'}>
          <div className={'flex flex-col gap-[22px]'}>
            <div className={'text-2xl font-normal'}><div>Create account</div>
              <div className={'mt-[8px] text-[#7E858F] text-xs'}>It&apos;s Free! No credit card required</div>
            </div>
            <div className={'text-[#7E858F] text-xs'}>Create account?<span onClick={goToLogin} className={'cursor-pointer pl-4 text-[#826AED]'}>Back to login</span></div>
            <div className={'border-b-[1px] border-[#2B2D31]'}></div>
          </div>
          <form className={'flex flex-col mt-[16px] gap-[22px]'}>
                <Controller
                  name={'firstName'}
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: {
                      onChange,
                      onBlur,
                      value
                    }
                  }) => (<TextInput
                    {...register('firstName', { maxLength: 25 })}
                    labelText={'First name:'}
                    error={errors?.firstName?.message}
                    maxLength={25}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}/>)}
                />
            <Controller
              name={'lastName'}
              rules={{ required: true }}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                {...register('lastName', { maxLength: 25 })}
                labelText={'Last name:'}
                error={errors?.lastName?.message}
                maxLength={25}
                onChange={onChange}
                onBlur={onBlur}
                value={value}/>)}
            />
            <Controller
              name={'name'}
              rules={{ required: true }}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                {...register('name', { maxLength: 25 })}
                labelText={'Organization name:'}
                error={errors?.name?.message}
                maxLength={25}
                onChange={onChange}
                onBlur={onBlur}
                value={value}/>)}
            />
            <Controller
              name={'email'}
              rules={{ required: true }}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                {...register('email')}
                labelText={'Email:'}
                error={errors?.email?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}/>)}
            />
            <div className={'flex item-start'}>
                  <Button title={'Sign Up'} iconRight={<NextIcon/>} variant={'primary'} disabled={!isDirty || !isValid} onClick={handleSubmit(onSubmit)} type={'submit'}/>
                </div>
              </form>
        </div>
      </div>
    </div>
      <Preloader open={isLoading} modalText={'Loading...'}/>
    </>
  )
}

export default SignUp
