import { classNames } from 'src/utils/css'

export interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>,
  Required<Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>> {
  title?: string
  className?: string
  disabled?: boolean
  error?: string
  isActive?: boolean
  variant?: 'primary' | 'darker'
}
export const ButtonTab: React.FC<Props> = ({
  title,
  className = '',
  isActive,
  variant = 'primary',
  error = '',
  ...props
}) => {
  const classnameByVariant = {
    primary: `${isActive ? ' border-b-[3px] box-border border-[#826AED] text-white ' : 'border-b-[3px] box-border border-[#171B21]'}`,
    darker: `${isActive ? ' border-b-[3px] box-border border-[#826AED] text-white ' : 'border-b-[3px] box-border border-[#020409]'}`
  }
  return (
    <button
      disabled={props.disabled}
      className={classNames(
        'box-border h-10 text-center px-5 py-2 font-bold text-xs leading-none text-[#7E858F] focus:ring-b-[3px]  focus:ring-[#826AED] focus:text-white  disabled:text-[#2B2D31] ', `${classnameByVariant[variant]}`, className
      )}
      {...props}
    >
      {title}
    </button>
  )
}
