import React, { useContext, useEffect, useState } from 'react'
import Organizations from 'src/components/organisms/settings/Organizations'
import { IAuthenticatedUser } from 'src/auth/cognito'
import ApplicationContext from 'src/store/application-context-provider'

const SelectOrganization: React.FC = (props) => {
  const [user, setUser] = useState<IAuthenticatedUser | null>()
  const ctx = useContext(ApplicationContext)
  async function getUser () {
    try {
      const user = await ctx?.getCurrentUser()
      console.log('user', user)
      setUser(user?.attributes)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getUser()
  }, [ctx])
  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'fixed', top: '0', left: '0' }} className={'bg-[#020409] z-50'}></div>
      <div className={'absolute inset-0 z-50 flex gap-[92px] flex-col items-center'}>
        <img src="assets/images/logo-cloudgto.svg" alt="logo" className={'mt-[170px]'} height={24}/>
        <div className={'bg-[#171B21] md:w-[578px] sm:w-full  flex flex-col gap-[22px] rounded-2xl p-[32px]'}>
          <div className={'text-white sm:text-sm md:text-2xl'}>Welcome, {user?.email}</div>
          {/* <div className={'text-[#7E858F] text-xs'}>You belong to multiple organizations. Please choose one to proceed.</div> */}
          <div className={'border-b-[1px] border-[#2B2D31]'}></div>
            <Organizations />
          </div>
      </div>
    </>
  )
}

export default SelectOrganization
