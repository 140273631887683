import { Delete, Edit, Group } from '@mui/icons-material'
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { FC, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Team as TeamType } from '../../../../../../API'
import ApplicationContext from '../../../../../../store/application-context-provider'

interface TeamProps {
  team: TeamType
  showEdit: boolean
  onRemoveTeam: (team: TeamType, orgId: string) => void
}

const Team: FC<TeamProps> = (props: TeamProps) => {
  const { team, showEdit, onRemoveTeam } = props
  const teamId = team.id

  const { organizationId } = useParams()
  const ctx = useContext(ApplicationContext)

  const navigate = useNavigate()

  const goTeamUsers = () => {
    navigate(`/settings/organizations/${organizationId}/teams/${teamId}/users`)
  }

  const editTeam = (team: TeamType) => {
    ctx?.setSelectedTeam(team)
    navigate(`/settings/organizations/${organizationId}/teams/upsert`)
  }

  const deleteTeam = (team: TeamType) => {
    ctx?.setSelectedTeam(team)
    if (!organizationId) {
      console.log('No organizationId')
      return
    }
    onRemoveTeam(team, organizationId)
  }

  return (
  <>
    <ListItem disablePadding>
      <ListItemButton onClick={goTeamUsers}>
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText primary={team.name} />
      </ListItemButton>
      {
        showEdit && <ListItemSecondaryAction>
          <IconButton aria-label="edit" color="secondary" onClick={() => { editTeam(team) }}>
            <Edit/>
          </IconButton>
          <IconButton edge="end" aria-label="delete" color="error" onClick={() => { deleteTeam(team) }}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      }
    </ListItem>
</>
  )
}

export default Team
