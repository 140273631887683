import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import ApplicationContext from 'src/store/application-context-provider'
import { FIELDTYPE_TYPE } from 'src/enums'
import { v4 as uuidv4 } from 'uuid'
import { isValidResolvers } from 'src/utils/validation'
import { TextInput } from 'src/components/atoms/text-input'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp-gray.svg'
import { Button } from 'src/components/atoms/button'
import { CustomFieldsRow } from 'src/components/molecules/custom-fields-row/CustomFieldsRow'
import { Alert } from 'src/components/atoms/alert'

export interface CustomRowField {
  id: string
  name: string
  type: string
  required: boolean
  array: boolean
  isDisabled: boolean
}

interface CustomForm {
  name: string
  description: string
}

const CustomTypeForm: React.FC<{ close: Function, submit: (customType: any) => void }> = props => {
  const { close, submit } = props

  const ctx = useContext(ApplicationContext)
  const [isTypesAlert, setTypesAlert] = useState(false)
  const [isFieldAlert, setIsFieldAlert] = useState(false)
  const [customFields, setCustomFields] = useState<CustomRowField []>([])

  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
      .max(25),
    description: yup
      .string()
      .required('This field is required')
  })

  const {
    getValues,
    control,
    handleSubmit,
    register,
    trigger,
    formState: {
      errors,
      isValid
    },
    reset

  } = useForm<CustomForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      description: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: ctx?.customTypesInput?.name,
      description: ctx?.customTypesInput?.description
    })
  }, [reset])

  useEffect(() => {
    const typesNameList = ctx?.customTypesList?.map((s: any) => s.name)
    if (typesNameList?.includes(getValues().name) && ctx?.customTypesInput?.name !== getValues().name) {
      setTypesAlert(true)
    } else {
      setTypesAlert(false)
    }
  }, [getValues().name, ctx?.customTypesList])

  useEffect(() => {
    const newFields = (ctx?.customTypesInput.fields ??
      [{
        id: uuidv4(),
        name: '',
        type: '',
        required: false,
        array: false,
        fieldType: FIELDTYPE_TYPE.SCALAR,
        isDisabled: false
      }])

    setCustomFields(newFields)
  }, [])

  const fieldNameHandler = (e: Event | any, row: CustomRowField) => {
    const fieldNameList = customFields?.map((s: any) => s.name)
    if (fieldNameList?.includes(e.target.value)) {
      setIsFieldAlert(true)
      const editData = customFields.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setCustomFields(editData)
    } else {
      const editData = customFields.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setCustomFields(editData)
      setIsFieldAlert(false)
    }
  }

  const fieldTypeHandler = (type: string, row: CustomRowField) => {
    const editData = customFields.map((f) =>
      f.id === row.id ? { ...f, type, fieldType: FIELDTYPE_TYPE.SCALAR } : f
    )
    setCustomFields(editData)
  }
  const fieldRequiredHandler = (e: Event | any, row: CustomRowField) => {
    const editData = customFields.map((f) =>
      f.id === row.id ? { ...f, required: !row.required } : f
    )
    setCustomFields(editData)
  }
  const fieldArrayHandler = (e: Event | any, row: CustomRowField) => {
    const editData = customFields.map((f) =>
      f.id === row.id ? { ...f, array: !row.array } : f
    )
    setCustomFields(editData)
  }
  const onSubmit = (data: CustomForm) => {
    const input = {
      name: data.name,
      description: data.description,
      fields: customFields,
      id: ctx?.customTypesInput.id ?? null
    }
    submit(input)
    console.log('CustomTypes input', input)
  }

  const handleClose = (event: Event | any) => {
    close()
  }

  const confirmFieldDelete = (model: any) => {
    const filtered = customFields.filter(f => f.id !== model.id)
    setCustomFields(filtered)
  }

  return (
    <form >

      <div className={'flex px-8 flex-col gap-[16px] mt-[16px]'}>
        {isTypesAlert && <Alert variant="error">This custom type is already in use, please choose a unique name.</Alert>}
        <Controller
          name={'name'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('name', { maxLength: 25 })}
            required
            labelText={'Name:'}
            showRequired
            maxLength={25}
            error={errors?.name?.message}
            id="resourceName"
            onChange={(e) => {
              trigger('name')
              onChange(e)
            }}
            onBlur={onBlur}
            value={value?.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
        />
        <Controller
          name={'description'}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            showRequired
            {...register('description', { maxLength: 25 })}
            labelText={'Description:'}
            maxLength={25}
            error={errors?.description?.message}
            id="resourceName"
            onChange={onChange}
            onBlur={onBlur}
            value={value}/>)}
        />

        <div className={'flex justify-between mt-8'}>
        <div className={'text-2xl text-white'}>Custom Type Fields</div>
        <Button iconLeft={<AddIcon/>} variant={'darker'} title={'Add Field'} type={'button'}
                onClick={() => {
                  setCustomFields([...customFields, {
                    id: uuidv4(),
                    name: '',
                    type: '',
                    required: false,
                    array: false,
                    isDisabled: false
                  }])
                }}

        />
      </div>

      {isFieldAlert && <Alert variant="error">This field name is already in use, please choose a unique name.</Alert>}

      {customFields?.map((row: CustomRowField, index) => <CustomFieldsRow
        key={row.id}
        row={row}
        fieldNameHandler={fieldNameHandler}
        fieldTypeHandler={fieldTypeHandler}
        fieldRequiredHandler={fieldRequiredHandler}
        fieldArrayHandler={fieldArrayHandler}
        confirmFieldDelete={confirmFieldDelete}
      />)}
      </div>
      <ResourceFormControls isDisabledSaveButton={!isValid || isFieldAlert || !isValidResolvers(customFields) || isTypesAlert} onSubmit={handleSubmit(onSubmit)} onClose={handleClose}/>
    </form>
  )
}

export default CustomTypeForm
