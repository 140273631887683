export const regionList = {
  us_east_1: 'US East (N. Virginia)',
  us_east_2: 'US East (Ohio)',
  us_west_1: 'US West (N. California)',
  us_west_2: 'US West (Oregon)',
  ca_central_1: 'Canada (Central)',
  eu_west_1: 'EU (Ireland)',
  eu_central_1: 'EU (Frankfurt)',
  eu_west_2: 'EU (London)',
  eu_west_3: 'EU (Paris)',
  eu_north_1: 'EU (Stockholm)',
  ap_northeast_1: 'Asia Pacific (Tokyo)',
  ap_northeast_2: 'Asia Pacific (Seoul)',
  ap_southeast_1: 'Asia Pacific (Singapore)',
  ap_southeast_2: 'Asia Pacific (Sydney)',
  ap_south_1: 'Asia Pacific (Mumbai)',
  sa_east_1: 'South America (São Paulo)',
  us_gov_west_1: 'US Gov West 1',
  us_gov_east_1: 'US Gov East 1'
}
