import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import ConfirmDialog from 'src/components/molecules/confirm-dialog/ConfirmDialog'
import ApplicationContext from 'src/store/application-context-provider'
import {
  ServiceCardEnumObject
} from 'src/components/organisms/service-builder/wizard/define-enums/serviceCard-enum-object/ServiceCardEnumObject'
import EnumModal from 'src/components/organisms/service-builder/wizard/define-enums/enum-modal/EnumModal'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp.svg'
import { v4 as uuidv4 } from 'uuid'
import { Alert } from 'src/components/atoms/alert'
import { checkEmptyString } from 'src/utils/validation'

const DefineEnums: FC = () => {
  const ctx = useContext(ApplicationContext)

  const [openModal, setOpenModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [onConfirm, setOnConfirm] = useState(() => () => { })
  const [enums, setEnums] = useState<any[]>([])

  const isShowEnumError = useMemo(() => {
    const allFields = ctx?.serviceBuilder?.enums?.map((item: any) => item.fields).flat()
    return !enums.every((m: any) => m.name && !checkEmptyString(allFields))
  }, [enums, ctx?.serviceBuilder?.enums])

  useEffect(() => {
    setEnums(ctx?.serviceBuilder?.enums ?? [])
  }, [])

  const cancelDelete = () => {
    setIsOpen(false)
  }

  const toggleOpenModal = () => {
    setOpenModal(!openModal)
  }

  const confirmDeleteEnum = (currentEnum: any) => {
    setIsOpen(true)
    setOnConfirm(() => async () => {
      const serviceBuilder = ctx?.serviceBuilder
      serviceBuilder.enums = serviceBuilder?.enums?.filter((e: any) => e.id !== currentEnum.id)
      ctx?.setServiceBuilder(serviceBuilder)
      setEnums(serviceBuilder.enums)
      setIsOpen(false)
    })
  }

  const handleEnumSubmit = () => {
    const serviceBuilder = ctx?.serviceBuilder
    const input = ctx?.enumInput

    if (!input.id) {
      input.id = uuidv4()
      serviceBuilder.enums = serviceBuilder.enums ?? []
      serviceBuilder.enums.push(input)
    } else {
      serviceBuilder.enums = serviceBuilder?.enums?.map((r: any) => {
        if (r.id === input.id) {
          return input
        }
        return r
      })
    }
    ctx?.setServiceBuilder(serviceBuilder)
    setEnums([...serviceBuilder.enums].flat())
    console.log('submit DefineEnums', serviceBuilder)
  }

  const editEnum = (enumInput: any) => {
    const res = ctx?.serviceBuilder?.enums?.find((r: any) => r.id === enumInput.id)
    ctx?.setEnumInput(res)
    toggleOpenModal()
  }
  return (
    <>

      <div className={'flex justify-center'}>
        {isShowEnumError && <Alert classname={'w-2/3'}
                        variant={'info'}>
          The enum marked in red have an error, please fix them to proceed
        </Alert>}
      </div>
      <div className={'rounded-2xl border-[1px] bg-[#171B21] border-[#2B2D31]'}>
        <div className={'px-4 flex gap-[16px] items-center py-[4px]'}>
          <div className={'text-lg'}>Enums</div>
          <Button iconLeft={<AddIcon/>} classname={'bg-[#171B21] font-normal'} title={'Add enum'}
                  onClick={() => {
                    ctx?.setEnumInput({})
                    toggleOpenModal()
                  }} variant={'darker'} type="button"/>
        </div>
        <div
          className={'gap-[28px] min-h-[162px]  grid grid-cols-[repeat(auto-fill,minmax(248px,3fr))]  px-[30px] py-[33px] border-t-[1px] border-[#2B2D31]'}>
          {enums?.map((m) => <ServiceCardEnumObject
            key={m.id}
            error={!m.name || checkEmptyString(m.fields)}
            handleRemoveCard={confirmDeleteEnum}
            handleEditCard={editEnum}
            card={m}/>)}
        </div>
      </div>
      <ConfirmDialog isOpen={isOpen} onConfirm={onConfirm} onCancel={cancelDelete} />
      <EnumModal onClose={toggleOpenModal} onSubmit={handleEnumSubmit} open={openModal}/>
    </>
  )
}

export default DefineEnums
