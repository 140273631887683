import React, { FC, ReactNode } from 'react'
import { ReactComponent as CheckboxIcon } from 'src/assets/icons/checkbox.svg'
import { ReactComponent as CheckboxDisabledIcon } from 'src/assets/icons/checkbox-input-disabled.svg'
import { classNames } from 'src/utils/css'
import { Tooltip } from '../tooltip'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string
  placeholder?: string
  showRequired?: boolean
  type?: 'password' | 'text' | 'email'
  iconRight?: ReactNode
  iconLeft?: ReactNode
  error?: string
  ref?: React.ForwardedRef<HTMLInputElement>
  inputClassName?: string
  className?: string
  labelText?: string
  maxLength?: number
  isActiveDisabled?: boolean
  labelClassName?: string
  variant?: 'primary' | 'darker'
  tooltipText?: string
}

export const CheckboxInput: FC<InputProps> = React.forwardRef<
HTMLInputElement,
InputProps
>(
  (
    {
      placeholder = '',
      showRequired = false,
      type = 'text',
      iconRight,
      iconLeft,
      className = '',
      error = '',
      variant = 'primary',
      inputClassName = '',
      labelClassName = '',
      labelText = '',
      isActiveDisabled = false,
      tooltipText = '',
      ...props
    },
    ref
  ) => {
    const classnameByVariant = {
      primary: `${props.disabled ? 'text-[#7E858F]' : 'text-white'}`,
      darker: 'text-[#7E858F]'
    }

    return (
        <label className={`flex items-center ${className}`}>
            <input disabled={props.disabled} ref={ref} type="checkbox" {...props} className="hidden" />
            <div className={classNames(`w-[16px] h-[16px] cursor-pointer rounded    ${props.checked ? 'bg-[#826AED]' : 'bg-[#7E858F] transition-all ease-in-out duration-300 hover:border-[3px]  hover:border-[#2B2D31] hover:ring-[1px] hover:ring-[#826AED]  hover:ring-offset-0'}`, inputClassName)}>
                {props.disabled ? isActiveDisabled && <CheckboxDisabledIcon/> : props.checked && <CheckboxIcon />}
            </div>
            <div className={classNames(` ${classnameByVariant[variant]}`, 'ml-[4px] font-normal text-xs', labelClassName)}>
              {labelText}
              {tooltipText &&
                <Tooltip text={tooltipText} />
              }
            </div>
        </label>
    )
  }
)
