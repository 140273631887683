import { GetUserProfileInput } from '../../API'
import { API, graphqlOperation } from 'aws-amplify'
import { getUserProfile } from '../../graphql/queries'
import { GraphQLResult } from '@aws-amplify/api'

export const fetchUserProfile = async (input: GetUserProfileInput) => {
  const fetchUserProfileResult = (await API.graphql(
    graphqlOperation(getUserProfile, { input })
  )) as GraphQLResult<{ getUserProfile: any }>

  return fetchUserProfileResult?.data?.getUserProfile
}
