export const BlankStates = () => {
  return (
    <div className={'flex flex-col'}>
      <div className={'flex items-center justify-center mt-[153px]'}>
        <div className={'flex flex-col rounded-2xl gap-[22px] items-center px-[32px] py-[50px] w-2/3   bg-[#171B21] '}>
          <div className={'text-2xl text-white'}>No results found</div>
          <div className={'text-xs text-[#7E858F] text-center'}>Please check if the words are spelled correctly or use more general keywords. <br />If you prefer, register a new service.</div>
        </div>
      </div>
    </div>)
}
