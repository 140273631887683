import React, { useContext, useEffect, useMemo, useState } from 'react'
import ApplicationContext from 'src/store/application-context-provider'
import { ModelDataSourceRow } from 'src/components/molecules/model-dataSources-row/ModelDataSourceRow'
import { RESOURCE_TYPE } from 'src/enums'
import { Alert } from 'src/components/atoms/alert'
import { setFieldTypeToField } from 'src/utils/modelForm'
import { scalarTypesList } from 'src/constants/modelForm'
import { v4 as uuidv4 } from 'uuid'

export interface RowModel {
  id: string
  name: string
  description: string
  type: string
  dataSource: { type: string, name: string, id: string } | null
}

export const MapModelsToDataSources = () => {
  const ctx = useContext(ApplicationContext)
  const [isModelAlert, setIsModelAlert] = useState('')
  const [isCustomObjectAlert, setIsCustomObjectAlert] = useState(false)
  const [models, setModels] = useState<RowModel[]>([])

  const listDynamos = useMemo(() => {
    return ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)
  }, [ctx?.serviceBuilder])

  useEffect(() => {
    const serviceBuilder = ctx?.serviceBuilder
    serviceBuilder.models = ctx?.schemaFileResponse?.possibleModels?.map((m: any) => ({ ...m, isObjectType: false, fields: m.fields.map((f: any) => f.fieldType === '' ? ({ ...f, array: f.array ?? false, required: f.required ?? false, type: f.type ?? '', fieldType: setFieldTypeToField(f.type, scalarTypesList, ctx?.schemaFileResponse?.possibleModels) }) : f) })) ?? []
    serviceBuilder.enums = ctx?.schemaFileResponse?.enums?.map((e: any) => ({ ...e, id: uuidv4() })) ?? []
    serviceBuilder.subscriptions = ctx?.schemaFileResponse?.subscriptions?.map((e: any) => ({ ...e, id: uuidv4() })) ?? []
    setModels(ctx?.serviceBuilder?.models)
    ctx?.setServiceBuilder(serviceBuilder)
  }, [])

  useEffect(() => {
    setModels(ctx?.serviceBuilder?.models)
  }, [])

  useEffect(() => {
    const checkUniqueDataSourceIds = (models: any) => {
      const hasNoObjectTypeFalse = !models.some((model: any) => model.isObjectType === false)
      if (hasNoObjectTypeFalse) {
        setIsCustomObjectAlert(true)
        return true
      } else {
        setIsCustomObjectAlert(false)
      }
      const dataSourceIds = new Set()
      for (const model of models) {
        if (!model.isObjectType) {
          const dataSource = model.dataSource
          if (!dataSource) {
            return true
          }
          const dataSourceId = dataSource?.id
          if (dataSourceId && dataSourceIds.has(dataSourceId)) {
            setIsModelAlert('You can’t add the same data source to more than one model. Please select another data source or create new data sources.')
            return true
          }
          dataSourceIds.add(dataSourceId)
        }
      }
      setIsModelAlert('')
      return false
    }
    ctx?.setMapModelsToDataSourcesButtonDisabled(checkUniqueDataSourceIds(models))
  }, [models])

  const confirmRowDelete = (model: any) => {
    const serviceBuilder = ctx?.serviceBuilder
    const updatedModels = models.filter((r) => r.id !== model.id)
    serviceBuilder.resolvers = serviceBuilder.resolvers.filter((res: any) => res.model !== model.name)
    serviceBuilder.models = updatedModels
    setModels(updatedModels)
    ctx?.setServiceBuilder(serviceBuilder)
  }

  const dataSourceHandler = (dataSource: any, model: any) => {
    const serviceBuilder = ctx?.serviceBuilder
    const updatedModels = models.map((f) =>
      f.id === model.id ? { ...f, dataSource: { id: dataSource.id, name: dataSource.name, type: 'Dynamo' } } : f
    )
    serviceBuilder.models = updatedModels
    setModels(updatedModels)
    ctx?.setServiceBuilder(serviceBuilder)
  }

  const modelIsObjectTypeHandler = (e: Event | any, model: any) => {
    const serviceBuilder = ctx?.serviceBuilder
    const updatedModels = models.map((f) =>
      f.id === model.id ? { ...f, isObjectType: e?.target?.checked, dataSource: e?.target?.checked ? null : f.dataSource } : f
    )
    serviceBuilder.models = updatedModels
    setModels(updatedModels)
    ctx?.setServiceBuilder(serviceBuilder)
  }
  return (
    <div className={'bg-[#171B21] w-2/3 mt-2 rounded-lg mx-auto px-12 pt-8 pb-8 mb-[100px] flex flex-col  gap-[27px] text-white'}>
      <div className={'flex flex-col gap-[8px]'}>
        <div className={'text-lg font-bold'}>Map Models to Data Sources</div>
        <div className={'text-xs text-[#7E858F]'}>To ensure your implementation runs smoothly, it&apos;s essential to correctly map your Models and Data Sources.
          Choose a Model from the dropdown and then pick the corresponding Data Source. Proper configuration is key to success.
        </div>
        <div className={'text-xs text-[#7E858F]'}>If your Schema has object/custom types, tick Object Type.</div>
      </div>
      <div className={'flex flex-col gap-[8px]'}>
        {isModelAlert && <Alert variant={'error'}>{isModelAlert}</Alert>}
        {isCustomObjectAlert && <Alert variant={'info'}>There must be at least one Model tied to a data source</Alert>}
        <div className={'text-lg font-bold'}>Models</div>
        {models.map((m) => <ModelDataSourceRow key={m.id} models={models} modelIsObjectTypeHandler={modelIsObjectTypeHandler} dataSources={listDynamos} model={m} confirmRowDelete={confirmRowDelete} dataSourceHandler={dataSourceHandler} />)}
      </div>
    </div>)
}
