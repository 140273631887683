import React, { FC, ReactNode } from 'react'
import { classNames } from 'src/utils/css'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number
  placeholder?: string
  showRequired?: boolean
  comingSoon?: boolean
  type?: 'password' | 'text' | 'email' | 'number'
  iconRight?: ReactNode
  iconLeft?: ReactNode
  error?: string | boolean
  ref?: React.ForwardedRef<HTMLInputElement>
  inputClassName?: string
  className?: string
  labelText?: string
  maxLength?: number
}

export const TextInput: FC<InputProps> = React.forwardRef<
HTMLInputElement,
InputProps
>(
  (
    {
      placeholder = '',
      showRequired = false,
      comingSoon = false,
      type = 'text',
      iconRight,
      iconLeft,
      className = '',
      error = '',
      inputClassName = '',
      labelText = '',
      ...props
    },
    ref
  ) => {
    return (
      <div className={className}>
        <div className="mt-[6px] relative flex items-center">
          <label
            className={`relative text-xs ${error ? 'text-red-default' : props.disabled ? 'text-[#7E858F]' : 'text-white'}`}
            >
            {labelText}{showRequired && <span className={'ml-[4px] text-red-default'}>*</span>}
          </label>
          {comingSoon &&
            <span className={`absolute right-0 text-xs italic ${error ? 'text-red-default' : props.disabled ? 'text-[#7E858F]' : 'text-white'}`}>Coming soon...</span>
          }
        </div>
        <div className="mt-[6px] relative flex items-center">
          <div className="absolute inset-y-0 left-0 flex items-center">
            {iconLeft}
          </div>
          {iconRight && <div className="bg-black-darker border-2 border-[#2B2D31] rounded-r-lg  pointer absolute inset-y-0 right-0 flex items-center px-2">
            {iconRight}
          </div>}
          <input
            ref={ref}
            type={type}
            className={classNames(
              ` box-border bg-[#2B2D31] w-full px-[16px] ${props.disabled ? 'text-[#7E858F]' : 'text-white'}  cursor-pointer rounded-lg py-[13px] block font-normal text-xs hover:ring-2 ring-[#826AED]    focus:outline-none   focus:ring-2 ring-[#826AED] ${error && 'ring-2 ring-red-default'} `,
              'placeholder-[#7E858F]',
              inputClassName
            )}
            placeholder={placeholder}
            {...props}
          />
        </div>
        {error && <div className={'mt-[4px] text-[10px] text-red-default'}>{error}</div>}
      </div>
    )
  }
)
