import { FIELD_TYPE, FIELDTYPE_TYPE } from 'src/enums'

export const setIconToDataSource = (listDynamos: any) => {
  return listDynamos.map((dynamo: any) => {
    let type: string = ''
    switch (dynamo?.resourceType) {
      case 'Dynamo':
        type = '/assets/images/dynamoDbTable.svg'
        break
      default:
        break
    }
    return {
      ...dynamo,
      type
    }
  })
}
export const setFieldTypeToField = (type: string, scalarTypesList: any, models: any) => {
  if (scalarTypesList?.map((s: any) => s.type).includes(type as FIELD_TYPE)) {
    return FIELDTYPE_TYPE.SCALAR
  }
  if (models?.map((m: any) => m.name).includes(type)) {
    return FIELDTYPE_TYPE.MODEL
  } else {
    return FIELDTYPE_TYPE.CUSTOM
  }
}
