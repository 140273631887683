import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { CreateLambdaInput, Lambda, UpdateLambdaInput } from '../../API'
import { createLambda, deleteLambda, updateLambda as updateLambdaMutation } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const addLambda = async (input: CreateLambdaInput) => {
  const addLambdaResult = (await API.graphql(
    graphqlOperation(createLambda, { input })
  )) as GraphQLResult<{ createLambda: Lambda }>
  const newLambda = (addLambdaResult?.data?.createLambda)
  return newLambda
}

export const updateLambda = async (input: UpdateLambdaInput) => {
  const updateLambdaResult = (await API.graphql(
    graphqlOperation(updateLambdaMutation, { input: { ...input } })
  )) as GraphQLResult<{ updateLambda: Lambda }>
  const updatedLambda = (updateLambdaResult?.data?.updateLambda)
  return updatedLambda
}

export const removeLambda = async (id: string, serviceId: string) => {
  const removeLambdaResult = (await API.graphql(
    graphqlOperation(deleteLambda, { input: { id, serviceId } })
  )) as GraphQLResult<{ removeLambda: Lambda }>
  const deletedLambda = (removeLambdaResult?.data?.removeLambda)
  return deletedLambda
}

export const updateTeam = async (id: string, name: string) => {
  const updateLambdaResult = (await API.graphql(
    graphqlOperation(updateLambdaMutation, { input: { id, name } })
  )) as GraphQLResult<{ updateTeam: { Team: Lambda } }>
  const updatedLambda = (updateLambdaResult?.data?.updateTeam?.Team)
  return updatedLambda
}
