import { v4 as uuidv4 } from 'uuid'
import { RESOURCE_TYPE } from 'src/enums'

const monolithicBluePrint = () => {
  const ddbA = {
    id: uuidv4(),
    name: 'items',
    resourceType: RESOURCE_TYPE.DYNAMO_DB,
    description: 'stores item info',
    keyType: 'SIMPLE',
    primaryKeyName: 'id',
    primaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: false
  }

  const lambdaA = {
    id: uuidv4(),
    name: 'items',
    resourceType: RESOURCE_TYPE.LAMBDA,
    description: 'handles all actions on items',
    timeout: 30,
    tracing: true,
    runtime: 'NODEJS16',
    memory: 1536,
    vpcPrivate: false,
    connection: { id: ddbA.id, permissions: { create: true, read: true, update: true, delete: true } }
  }

  const authA = {
    id: uuidv4(),
    name: 'mainUserpool',
    resourceType: RESOURCE_TYPE.COGNITO_USER_POOL,
    cognitoType: 'EMAIL',
    description: 'main user pool for the site',
    cognitoTestEmail: 'example@company.com',
    cognitoExisting: false
  }

  const routes = [
    {
      id: uuidv4(),
      name: 'items',
      path: '/items',
      method: 'ANY',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceId: lambdaA.id,
      resourceType: RESOURCE_TYPE.ROUTE,
      authId: authA.id,
      auth: authA,
      resource: lambdaA,
      private: true
    }
  ]

  const resources = [lambdaA, authA, ddbA]

  return { resources, routes }
}

const manyRoutesBluePrint = () => {
  const ddbA = {
    id: uuidv4(),
    name: 'items',
    resourceType: RESOURCE_TYPE.DYNAMO_DB,
    description: 'stores item info',
    keyType: 'SIMPLE',
    primaryKeyName: 'id',
    primaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: false
  }
  const lambdaA = {
    id: uuidv4(),
    name: 'items',
    resourceType: RESOURCE_TYPE.LAMBDA,
    description: 'handles all actions on items',
    timeout: 30,
    tracing: true,
    runtime: 'NODEJS16',
    memory: 1536,
    vpcPrivate: false,
    connection: { id: ddbA.id, permissions: { create: true, read: true, update: true, delete: true } }
  }
  const authA = {
    id: uuidv4(),
    name: 'mainUserpool',
    resourceType: RESOURCE_TYPE.COGNITO_USER_POOL,
    cognitoType: 'EMAIL',
    description: 'main user pool for the site',
    cognitoTestEmail: 'example@company.com',
    cognitoExisting: false
  }

  const routes = [
    {
      id: uuidv4(),
      name: 'createItemById',
      path: '/items',
      method: 'POST',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceId: lambdaA.id,
      resourceType: RESOURCE_TYPE.ROUTE,
      authId: authA.id,
      auth: authA,
      resource: lambdaA,
      private: true
    },
    {
      id: uuidv4(),
      name: 'getItemById',
      path: '/items/{id}',
      method: 'GET',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceId: lambdaA.id,
      authId: authA.id,
      auth: authA,
      resource: lambdaA,
      private: true,
      resourceType: RESOURCE_TYPE.ROUTE
    },
    // {
    //   id: uuidv4(),
    //   name: 'getItems',
    //   path: '/items',
    //   method: 'GET',
    //   type: 'LAMBDA_PROXY',
    //   cors: true,
    //   resourceId: lambdaA.id,
    //   resourceType: 'ROUTE',
    //   authId: authA.id,
    //   auth: authA,
    //   resource: lambdaA,
    //   private: true,
    // },
    {
      id: uuidv4(),
      name: 'updateItemById',
      path: '/items/{id}',
      method: 'PUT',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceType: RESOURCE_TYPE.ROUTE,
      resourceId: lambdaA.id,
      authId: authA.id,
      auth: authA,
      resource: lambdaA,
      private: true
    },
    {
      id: uuidv4(),
      name: 'deleteItemById',
      path: '/items/{id}',
      method: 'DELETE',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceType: RESOURCE_TYPE.ROUTE,
      resourceId: lambdaA.id,
      authId: authA.id,
      auth: authA,
      resource: lambdaA,
      private: true
    }
  ]
  const resources = [lambdaA, authA, ddbA]

  return { resources, routes }
}

const perRouteBluePrint = () => {
  const ddbA = {
    id: uuidv4(),
    name: 'items',
    resourceType: RESOURCE_TYPE.DYNAMO_DB,
    description: 'stores item info',
    keyType: 'SIMPLE',
    primaryKeyName: 'id',
    primaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: false
  }
  const authA = {
    id: uuidv4(),
    name: 'mainUserpool',
    resourceType: RESOURCE_TYPE.COGNITO_USER_POOL,
    cognitoType: 'EMAIL',
    description: 'main user pool for the site',
    cognitoTestEmail: 'example@company.com',
    cognitoExisting: false
  }
  const createItemById = {
    id: uuidv4(),
    name: 'createItemById',
    resourceType: RESOURCE_TYPE.LAMBDA,
    description: 'handles creating new items',
    timeout: 30,
    tracing: true,
    runtime: 'NODEJS16',
    memory: 1024,
    vpcPrivate: false,
    connection: { id: ddbA.id, permissions: { create: true, read: false, update: false, delete: false } }
  }
  const getItemById = {
    id: uuidv4(),
    name: 'getItemById',
    resourceType: RESOURCE_TYPE.LAMBDA,
    description: 'handles getting a single item by id',
    timeout: 30,
    tracing: true,
    runtime: 'NODEJS16',
    memory: 1536,
    vpcPrivate: false,
    connection: { id: ddbA.id, permissions: { create: false, read: true, update: false, delete: false } }
  }
  // let getItems = {
  //   id: uuidv4(),
  //   name: 'getItems',
  //   resourceType: RESOURCE_TYPE.LAMBDA,
  //   description: 'handles getting all items',
  //   timeout: 30,
  //   tracing: true,
  //   webpack: true,
  //   prune: true,
  //   runtime: 'NODEJS16',
  //   memory: 1024,
  //   vpcPrivate: false,
  //   connection: { id: ddbA.id, permissions: { create: false, read: true, update: false, delete: false } },
  // };
  const updateItemById = {
    id: uuidv4(),
    name: 'updateItemById',
    resourceType: RESOURCE_TYPE.LAMBDA,
    description: 'handles updating a single item by id',
    timeout: 30,
    tracing: true,
    runtime: 'NODEJS16',
    memory: 1024,
    vpcPrivate: false,
    connection: { id: ddbA.id, permissions: { create: false, read: false, update: true, delete: false } }
  }
  const deleteItemById = {
    id: uuidv4(),
    name: 'deleteItemById',
    resourceType: RESOURCE_TYPE.LAMBDA,
    description: 'handles deleting a single item by id',
    timeout: 30,
    tracing: true,
    runtime: 'NODEJS16',
    memory: 1536,
    vpcPrivate: false,
    connection: { id: ddbA.id, permissions: { create: false, read: false, update: false, delete: true } }
  }

  const routes = [
    {
      id: uuidv4(),
      name: 'createItemById',
      path: '/items',
      method: 'POST',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceId: createItemById.id,
      authId: authA.id,
      auth: authA,
      resourceType: RESOURCE_TYPE.ROUTE,
      resource: createItemById,
      private: true
    },
    {
      id: uuidv4(),
      name: 'getItemById',
      path: '/items/{id}',
      method: 'GET',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceId: getItemById.id,
      resourceType: RESOURCE_TYPE.ROUTE,
      authId: authA.id,
      auth: authA,
      resource: getItemById,
      private: true
    },
    // {
    //   id: uuidv4(),
    //   name: 'getItems',
    //   path: '/items',
    //   method: 'GET',
    //   type: 'LAMBDA_PROXY',
    //   cors: true,
    //   resourceId: getItems.id,
    //   resourceType: 'ROUTE',
    //   authId: authA.id,
    //   auth: authA,
    //   resource: getItems,
    //   private: true,
    // },
    {
      id: uuidv4(),
      name: 'updateItemById',
      path: '/items/{id}',
      method: 'PUT',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceType: RESOURCE_TYPE.ROUTE,
      resourceId: updateItemById.id,
      authId: authA.id,
      auth: authA,
      resource: updateItemById,
      private: true
    },
    {
      id: uuidv4(),
      name: 'deleteItemById',
      path: '/items/{id}',
      method: 'DELETE',
      type: 'LAMBDA_PROXY',
      cors: true,
      resourceType: RESOURCE_TYPE.ROUTE,
      resourceId: deleteItemById.id,
      authId: authA.id,
      auth: authA,
      resource: deleteItemById,
      private: true
    }
  ]

  const resources = [
    createItemById,
    getItemById,
    // getItems,
    deleteItemById,
    updateItemById,
    authA,
    ddbA
  ]

  return { resources, routes }
}

export {
  perRouteBluePrint,
  monolithicBluePrint,
  manyRoutesBluePrint
}
