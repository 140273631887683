import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { TextInput } from 'src/components/atoms/text-input'
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo-cloudgto.svg'
import * as yup from 'yup'
import { isValidEmail } from 'src/utils/validation'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Button } from 'src/components/atoms/button'
import { resetPassword } from 'src/auth/cognito'
import { Alert } from 'src/components/atoms/alert'

interface FormForgotPassword {
  email: string
}
const schema = yup.object({
  email: yup
    .string()
    .required('This field is required')
    .test(
      'Email',
      'Please enter a valid email address',
      (value) => {
        if (value) return isValidEmail(value)
        return true
      }
    )
})

const ForgotPassword: FC = () => {
  const navigate = useNavigate()

  const [alertMessage, setAlertMessage] = useState('')

  const {
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormForgotPassword>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      email: ''
    })
  }, [reset])
  const backToLoginHandle = () => {
    navigate('/login')
  }
  const onSubmit = async (data: FormForgotPassword) => {
    try {
      const email = data.email
      localStorage.setItem('reset-password-email', email)
      await resetPassword(email)
      console.log('success')
      navigate('/reset-password-code')
    } catch (err) {
      console.log(err)
      setAlertMessage((err as Error).message)
    }
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'fixed', top: '0', left: '0' }} className={'bg-[#020409] z-50'}></div>
      <div className={'absolute inset-0 z-50 text-white w-screen flex flex-col items-center justify-center gap-[50px] h-full'}>
        <LogoIcon/>
        <div className={'bg-[#171B21] rounded-2xl md:w-[478px] sm:w-full'}>
          <div className={'p-8'}>
            <div className={'flex flex-col gap-[22px]'}>
              <div><div className={'text-2xl font-normal'}>Forgot password?</div>
              <div className={'text-[#7E858F] text-xs'}>Find your account</div>
              </div>
              <div className={'text-[#7E858F] text-xs'}>Already have an account ?<span onClick={backToLoginHandle} className={'cursor-pointer pl-4 text-[#826AED]'}>Back to login</span></div>
              <div className={'border-b-[1px] border-[#2B2D31]'}></div>
            </div>
            {alertMessage && <Alert classname={'mt-[22px]'} variant={'error'}>{alertMessage}</Alert>}
            <form className={'flex flex-col mt-[16px] gap-[22px]'}>
                  <Controller
                    name={'email'}
                    rules={{ required: true }}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                      {...register('email')}
                      labelText={'Email:'}
                      error={errors?.email?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}/>)}
                  />
              <div className={'flex items-start'}>
                    <Button title={'Next'} disabled={!isDirty || !isValid} variant={'primary'} onClick={handleSubmit(onSubmit)} type={'submit'}/>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
  )
}

export default ForgotPassword
