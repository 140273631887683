import React, { FC, useContext, useEffect, useState } from 'react'
import DefineProjectRestApi from 'src/components/organisms/service-builder/wizard/define-project/DefineProjectRestApi'
import DefineService from 'src/components/organisms/service-builder/wizard/define-service/DefineService'
import Review from 'src/components/organisms/service-builder/wizard/review/Review'
import { CreateServiceInput, OptionBluePrint, UseCase } from '../../../../API'
import ApplicationContext from '../../../../store/application-context-provider'
import { addService, removeService } from '../../../../data/services/mutations'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import LoadingModal from '../../../UI/LoadingModal/LoadingModal'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as NextIcon } from 'src/assets/icons/arrow-forward-circle.svg'
import { StepperWrapper } from 'src/components/molecules/stepper-wrapper/StepperWrapper'
import DefineBlueprint from 'src/components/organisms/service-builder/wizard/define-blueprint/DefineBlueprint'
import DefineProjectGraphQLApi
  from 'src/components/organisms/service-builder/wizard/define-project/DefineProjectGraphQLApi'
import { DefineSchemaFile } from 'src/components/organisms/service-builder/wizard/define-schema-file/DefineSchemaFile'
import {
  MapResolversToModels
} from 'src/components/organisms/service-builder/wizard/map-resolvers-to-models/MapResolversToModels'
import {
  MapModelsToDataSources
} from 'src/components/organisms/service-builder/wizard/map-models-to-datasources/MapModelsToDataSources'

const Wizard: FC = () => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const ctx = useContext(ApplicationContext)
  const navigate = useNavigate()
  const [status, setStatus] = useState('Building...')
  const [serviceId, setServiceId] = useState(null)

  useEffect(() => {
    if (!serviceId) {
      return
    }

    try {
      (API.graphql(graphqlOperation(`
        subscription MySubscription {
          onUpdateCreateServiceStatus(serviceId: "${serviceId}") {
            serviceId
            status
            done
            error
          }
        }
      `)) as any).subscribe({
        next: async (data: any) => {
          if (data.value.data.onUpdateCreateServiceStatus.error) {
            setStatus(`
              ${data.value.data.onUpdateCreateServiceStatus.status}!
              We are going to delete this service and redirect you to service list in a few seconds.
            `)

            if (!ctx?.isServiceEdit) {
              await removeService(serviceId)
            }

            setTimeout(() => {
              navigate('/')
            }, 5001)

            return
          }

          setStatus(data.value.data.onUpdateCreateServiceStatus.status)

          if (data.value.data.onUpdateCreateServiceStatus.done) {
            localStorage.setItem('previewServiceId', serviceId)
            ctx?.setSelectedServiceId(serviceId)
            setLoading(false)
            navigate(`/service-builder/${serviceId}/preview/congrats`)
          }
        },
        error: (error: any) => {
          console.warn(error)
        }
      })
    } catch (e) {
      console.error('Error setting up subscription:', e)
    }
  }, [serviceId])

  const handleSubmit = (event: Event | any) => {
    event?.preventDefault()
    if (page === 1) {
      handleDefineServiceNextClick(event)
    }
    if (page === 4) {
      if (ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA) {
        setPage(page + 1)
      } else {
        handleBuildClick()
      }
    }
    if (page === 8) {
      if (ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA) {
        handleBuildClick()
      }
    }
  }

  const handleMapModelsToDataSourcesNextClick = () => {
    const serviceBuilder = ctx?.serviceBuilder
    const customObjects = serviceBuilder.models.filter((m: any) => m.isObjectType)
    serviceBuilder.models = serviceBuilder.models.filter((m: any) => !m.isObjectType).map((obj: any) => {
      const { isObjectType, ...rest } = obj
      return rest
    })
    serviceBuilder.customObjects = customObjects.map((obj: any) => {
      const { dataSource, description, isObjectType, ...rest } = obj
      return rest
    })
    ctx?.setServiceBuilder(serviceBuilder)
    setPage(page + 1)
  }
  const handleDefineServiceNextClick = (event: Event | any) => {
    setPage(2)
  }

  const handleBuildClick = async () => {
    const org = localStorage.getItem('selectedOrganization')
    if (!org) {
      navigate('/select-organization')
      return
    }
    const orgId = JSON.parse(org).id

    const service = {
      ...ctx?.serviceBuilder,
      models: ctx?.serviceBuilder?.models.map((model: any) => {
        const { type, ...rest } = model
        return rest
      }),
      resources: ctx?.serviceBuilder?.resources.map((resource: any) => {
        const { type, ...rest } = resource
        return rest
      })
    }
    setLoading(true)
    const input: CreateServiceInput = {
      iacFramework: service.config.iacFramework,
      useCase: service.config.useCase,
      name: service.config.name,
      description: service.config.description,
      cloudProvider: service.config.cloudProvider,
      cloudProviderRegion: service.config.defaults.region,
      stage: service.config.defaults.stage,
      optionBluePrint: service.optionBluePrint,
      webpack: service.config.package.webpack,
      prune: service.config.package.prune,
      appsyncName: service?.config?.appSyncDetails?.appsyncName,
      appsyncDescription: service?.config?.appSyncDetails?.appsyncDescription,
      authType: service?.config?.appSyncDetails?.authType,
      logging: service?.config?.appSyncDetails?.logging,
      caching: service?.config?.appSyncDetails?.caching,
      xRay: true,
      operatingSystem: service.config.operatingSystem,
      chatgpt: service.config.chatgpt,
      orgId
    }
    // console.log('handleBuildClick', input)
    try {
      if (ctx?.isServiceEdit) {
        await removeService(service.id)
      }

      const addServiceData = await addService(input)
      service.id = addServiceData?.id
      setServiceId(service.id)

      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/pattern`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
        },
        body: JSON.stringify({
          id: service.id, service
        })
      })

      const data = await result.json()
      service.executionArn = data.executionArn

      const authCurrentSessionData = await Auth.currentSession()

      await fetch(`${process.env.REACT_APP_API}/pattern/status?executionArn=${encodeURIComponent(service.executionArn)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', Authorization: authCurrentSessionData.getIdToken().getJwtToken()
        }
      })
    } catch (err) {
      console.log(err)
    };
  }

  const prevPage = () => {
    setPage(page - 1)
  }

  const handleCancelClick = () => {
    navigate('/service-builder')
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="h-full">
            <div className="relative h-full flex flex-col">
              {page === 1 && (
                <div className="card-heading">
                  <StepperWrapper currentStep={1} currentPage={'Service'}/>
                </div>
              )}
              {page === 2 && (
                <div className="card-heading">
                  <StepperWrapper currentStep={2} currentPage={'Blueprint'}/>
                </div>
              )}
              {page === 3 && (
                <div className="card-heading">
                  <StepperWrapper currentStep={3} currentPage={'Project'}/>
                </div>
              )}
              {page === 4 && (
                <div className="card-heading">
                  <StepperWrapper currentStep={4} currentPage={ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? 'Schema File' : 'Review'}/>
                </div>
              )}
              {page === 5 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (
                <div className="card-heading">
                  <StepperWrapper currentStep={5} currentPage={'Models'}/>
                </div>
              )}
              {page === 6 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (
                <div className="card-heading">
                  <StepperWrapper currentStep={6} currentPage={'Resolvers'}/>
                </div>
              )}
              {page === 7 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (
                <div className="card-heading">
                  <StepperWrapper currentStep={7} currentPage={'Project'}/>
                </div>
              )}
              {page === 8 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (
                <div className="card-heading">
                  <StepperWrapper currentStep={8} currentPage={'Review'}/>
                </div>
              )}

              {/* PAGES */}
              <div className="overflow-y-auto lg:p-12 md:p-6 flex justify-center items-start" style={{ flex: '1 1 0' }}>
                {page === 1 && <DefineService />}
                {page === 2 && <DefineBlueprint/>}
                {page === 3 && (ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? <DefineProjectRestApi /> : <DefineProjectGraphQLApi isShowModels={ctx?.serviceBuilder?.optionBluePrint !== OptionBluePrint.BYOSCHEMA} />)}
                {page === 4 && (ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? <DefineSchemaFile/> : <Review />)}
                {page === 5 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && <MapModelsToDataSources/>}
                {page === 6 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && <MapResolversToModels/>}
                {page === 7 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && <DefineProjectGraphQLApi isShowModels={true} />}
                {page === 8 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && <Review />}
              </div>

              {/* FOTTER */}
              <div className={'bg-[#020409] flex  w-full py-[16px] border-t-[1px] border-[#171B21] z-20'}>
                <div className="flex w-full  justify-between px-4">
                  {page !== 1 && (<Button onClick={prevPage} title={'Back'} variant={'darker'} type={'button'}/>)}
                    {page === 1 && (<Button title={'Cancel'} type={'button'} variant={'warning'} onClick={handleCancelClick}/>)}
                  {page === 1 && (
                      <div className="text-end">
                        <Button title={'Setup Blueprint'} iconRight={<NextIcon/>} disabled={ctx?.isServiceNextButtonDisabled} variant={'primary'} type={'submit'}/>
                      </div>
                  )}
                  {page === 2 && <Button title={'Configure project'} onClick={() => { setPage(page + 1) }} iconRight={<NextIcon/>} variant={'primary'} type="button"/>}
                  {page === 3 && (<Button disabled={ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? (!ctx?.resourceSearch || !ctx?.routeSearch) : ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? (!ctx?.resourceSearch || ctx?.isApiDetailsNextButtonDisabled) : (!ctx?.resourceSearch || ctx?.isApiDetailsNextButtonDisabled || !ctx?.modelsSearch)} title={ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? 'Import Schema' : 'Review'} onClick={() => { setPage(page + 1) }} iconRight={<NextIcon/>} variant={'primary'} type="button"/>
                  )}
                    {page === 4 && (<Button disabled={ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? Object.keys(ctx?.schemaFileResponse).length === 0 : false} title={ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? 'Next' : 'Build'} variant={'primary'} type={'submit'}/>)}
                  {page === 5 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (<Button title={'Save'} disabled={ctx?.isMapModelsToDataSourcesButtonDisabled} variant={'primary'} onClick={handleMapModelsToDataSourcesNextClick} type={'button'}/>)}
                  {page === 6 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (<Button title={'Next'} disabled={ctx?.isMapResolversToModelsButtonDisabled} variant={'primary'} onClick={() => { setPage(page + 1) }} type={'button'}/>)}
                  {page === 7 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (<Button title={'Save'} disabled={!ctx?.resourceSearch || ctx?.isApiDetailsNextButtonDisabled || !ctx?.modelsSearch} variant={'primary'} onClick={() => { setPage(page + 1) }} type={'button'}/>)}
                  {page === 8 && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA && (<Button title={'Build'} disabled={false} variant={'primary'} type={'submit'}/>)}
                </div>
              </div>
            </div>
          </form>

      <LoadingModal open={loading} modalText={status} />
    </>
  )
}

export default Wizard
