import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import ApplicationContext from 'src/store/application-context-provider'
import { v4 as uuidv4 } from 'uuid'
import { isValidResolvers } from 'src/utils/validation'
import { TextInput } from 'src/components/atoms/text-input'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp-gray.svg'
import { Alert } from 'src/components/atoms/alert'
import { scalarTypesList } from 'src/constants/modelForm'
import { setFieldTypeToField } from 'src/utils/modelForm'
import { CustomObjectFieldsRow } from 'src/components/molecules/custom-object-fields-row/CustomObjectFieldsRow'

export interface RowField {
  id: string
  name: string
  type: string
  fieldType: string
  required: boolean
  array: boolean
  isDisabled: boolean
}

interface FormCustomObject {
  objectName: string
  description: string
}

const CustomObjectForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props
  const ctx = useContext(ApplicationContext)
  const [isFieldAlert, setIsFieldAlert] = useState(false)
  const [fields, setFields] = useState<RowField []>([])

  const schema = yup.object({
    objectName: yup
      .string()
      .required('This field is required')
      .max(25)
  })

  const {
    control,
    handleSubmit,
    register,
    trigger,
    formState: {
      errors,
      isValid
    },
    reset

  } = useForm<FormCustomObject>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      objectName: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      objectName: ctx?.customObjectInput?.name
    })
  }, [reset])

  useEffect(() => {
    const newFields = (ctx?.customObjectInput?.fields?.map((f: any) => ({ ...f, id: f.id ?? uuidv4() })) ?? [{
      id: uuidv4(),
      name: '',
      type: '',
      required: false,
      array: false,
      isDisabled: false
    }])

    setFields(newFields)
  }, [])

  const fieldNameHandler = (e: Event | any, row: RowField) => {
    const fieldNameList = fields?.map((s: any) => s.name)
    if (fieldNameList?.includes(e.target.value)) {
      setIsFieldAlert(true)
      const editData = fields.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setFields(editData)
    } else {
      const editData = fields.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setFields(editData)
      setIsFieldAlert(false)
    }
  }

  const fieldTypeHandler = (type: any, row: RowField) => {
    const editData = fields.map((f) =>
      f.id === row.id ? { ...f, type, fieldType: setFieldTypeToField(type, scalarTypesList, ctx?.serviceBuilder?.models) } : f
    )
    setFields(editData)
  }

  const fieldRequiredHandler = (e: Event | any, row: RowField) => {
    const editData = fields.map((f) =>
      f.id === row.id ? { ...f, required: !row.required } : f
    )
    setFields(editData)
  }

  const fieldArrayHandler = (e: Event | any, row: RowField) => {
    const editData = fields.map((f) =>
      f.id === row.id ? { ...f, array: e.target.checked } : f
    )
    setFields(editData)
  }

  const onSubmit = (data: FormCustomObject) => {
    const input = ctx?.customObjectInput
    input.name = data.objectName
    input.fields = fields
    console.log('input object', input)
    ctx?.setCustomObjectInput(input)
    submit()
  }

  const handleClose = (event: Event | any) => {
    close()
  }

  const confirmFieldDelete = (field: any) => {
    const filtered = fields.filter(f => f.id !== field.id)
    setFields(filtered)
  }

  return (
    <>
      <form>
        <div className={'flex px-8 flex-col gap-[16px] mt-[16px]'}>
          <Controller
            name={'objectName'}
            rules={{ required: true }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('objectName', { maxLength: 25 })}
              required
              labelText={'Object Name'}
              showRequired
              maxLength={25}
              error={errors?.objectName?.message}
              id="resourceName"
              onChange={(e) => {
                trigger('objectName')
                onChange(e)
              }}
              onBlur={onBlur}
              value={value.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
          />
          <Controller
            name={'description'}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('description', { maxLength: 25 })}
              labelText={'Description'}
              maxLength={25}
              error={errors?.description?.message}
              id="resourceName"
              onChange={onChange}
              onBlur={onBlur}
              value={value}/>)}
          />
          <div className={'flex justify-between mt-8 gap-40'}>
            <div className={'text-2xl text-white w-10/12'}>Model Fields</div>
            <Button iconLeft={<AddIcon/>} variant={'darker'} classname={'w-2/12'} compact title={'Add Field'} type={'button'} onClick={() => {
              setFields([...fields, {
                id: uuidv4(),
                name: '',
                type: '',
                fieldType: '',
                required: false,
                array: false,
                isDisabled: false
              }])
            }}/>
          </div>
          {isFieldAlert &&
            <Alert variant={'error'}>This field
              name is already in use, please choose a unique name.</Alert>}
          {fields?.map((row: RowField) => <CustomObjectFieldsRow
            key={row.id}
            row={row}
            fieldNameHandler={fieldNameHandler}
            fieldTypeHandler={fieldTypeHandler}
            fieldRequiredHandler={fieldRequiredHandler}
            fieldArrayHandler={fieldArrayHandler}
            confirmFieldDelete={confirmFieldDelete}
          />)}
        </div>
        <ResourceFormControls
          isDisabledSaveButton={!isValid || isFieldAlert || !isValidResolvers(fields)}
          onSubmit={handleSubmit(onSubmit)} onClose={handleClose}/>
      </form>
    </>
  )
}

export default CustomObjectForm
