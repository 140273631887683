import React, { Fragment, useContext } from 'react'
import RouteForm from 'src/components/organisms/service-builder/forms/routes/RouteForm'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import ApplicationContext from 'src/store/application-context-provider'

const RouteModal: React.FC<{ open: boolean, close: Function, submit: Function }> = props => {
  const { open, close, submit } = props
  const ctx = useContext(ApplicationContext)
  const handleClose = (event: Event | any) => {
    close()
  }
  const onSubmit = () => {
    submit()
  }
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#020409] bg-opacity-80"/>
        </Transition.Child>
        <div className="fixed  inset-0 overflow-y-auto">
          <div className="flex  min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-[47%] transform overflow-hidden rounded-lg  bg-[#171B21] pb-[16px] text-left align-middle  transition-all">
                <div className="text-2xl mx-6 my-6 font-bold text-white flex items-center justify-between gap-6">
                  <span>{ctx?.routeInput?.id ? 'Edit route' : 'Add route'}</span>
                  <CloseIcon className={'pointer'} onClick={handleClose}/>
                </div>
                <RouteForm close={handleClose} submit={onSubmit}/>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default RouteModal
