import { TextInput } from 'src/components/atoms/text-input'
import { Button } from 'src/components/atoms/button'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import React from 'react'
import { RowResolver } from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/ModelForm'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import { RESOLVER_TYPE } from 'src/enums'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'

export const ResolverRow: React.FC<{
  row: RowResolver
  resolverNameHandler: (e: Event | any, row: RowResolver) => void
  resolverTypeHandler: (e: any, row: RowResolver) => void
  resolverPipelineHandler: (e: Event | any, row: RowResolver) => void
  resolverDescriptionHandler: (e: Event | any, row: RowResolver) => void
  confirmResolverDelete: (row: RowResolver) => void

}> = props => {
  const { row, confirmResolverDelete, resolverDescriptionHandler, resolverPipelineHandler, resolverNameHandler, resolverTypeHandler } = props

  return (<div className={'flex items-center pt-[4px] justify-left gap-10 border-t-[1px] border-[#2B2D31]'}>
    <TextInput
      className='w-3/12'
      error={row.name === ''}
      required
      value={row.name}
      disabled={row.isDisabled}
      onChange={(e) => {
        resolverNameHandler(e, row)
      }}
      labelText={'Name:'}
      showRequired
      id="resourceName"
    />
    <SelectOutlinedInput
      error={row.type === ''}
      classname={'w-2/12'}
      handleValueChange={(e) => {
        resolverTypeHandler(e, row)
      }}
      showRequired={true}
      labelText={'Type:'}
      selected={row.type}
      disabled={row.isDisabled}
      options={[
        { name: 'Get', value: RESOLVER_TYPE.GET },
        { name: 'Create', value: RESOLVER_TYPE.CREATE },
        { name: 'Delete', value: RESOLVER_TYPE.DELETE },
        { name: 'Update', value: RESOLVER_TYPE.UPDATE },
        { name: 'Field', value: RESOLVER_TYPE.FIELD, disabled: true },
        { name: 'Query', value: RESOLVER_TYPE.QUERY },
        { name: 'Scan', value: RESOLVER_TYPE.SCAN }
      ]
      }
    />

    <CheckboxInput labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   className={'w-2/12'}
                   disabled={row.isDisabled || row.name.includes('.')}
                   isActiveDisabled={(row.isDisabled || row.name.includes('.')) === row.isPipeline}
                   labelText={'Pipeline'}
                   tooltipText='Checking Pipeline makes the function reusable'
                   checked={row.isPipeline}
                   onChange={(e) => { resolverPipelineHandler(e, row) }}
    />
    <TextInput
      className='w-3/12'
      value={row.description}
      disabled={row.isDisabled}
      onChange={(e) => {
        resolverDescriptionHandler(e, row)
      }}
      labelText={'Description:'}
    />
    <Button classname={'mt-[28px] w-2/12'} iconLeft={<TrashBinIcon/>} compact disabled={row.isDisabled} onClick={ () => { confirmResolverDelete(row) }} variant={'darker'} title={'Delete Row'} type={'button'}/>
  </div>)
}
