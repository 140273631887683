import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import ConfirmDialog from 'src/components/molecules/confirm-dialog/ConfirmDialog'
import ApplicationContext from 'src/store/application-context-provider'
import {
  ServiceCardEnumObject
} from 'src/components/organisms/service-builder/wizard/define-enums/serviceCard-enum-object/ServiceCardEnumObject'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp.svg'
import { v4 as uuidv4 } from 'uuid'
import SubscriptionModal
  from 'src/components/organisms/service-builder/wizard/define-subscriptions/enum-modal/SubscriptionModal'
import { checkEmptyFields, checkSubscriptionEmptyField } from 'src/utils/validation'
import { Alert } from 'src/components/atoms/alert'

const DefineSubscriptions: FC = () => {
  const ctx = useContext(ApplicationContext)

  const [openModal, setOpenModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [onConfirm, setOnConfirm] = useState(() => () => { })
  const [subscription, setSubscription] = useState<any[]>([])

  const isShowSubscriptionError = useMemo(() => {
    return checkSubscriptionEmptyField(subscription ?? [])
  }, [subscription])

  useEffect(() => {
    setSubscription(ctx?.serviceBuilder?.subscriptions ?? [])
  }, [])

  const cancelDelete = () => {
    setIsOpen(false)
  }

  const toggleOpenModal = () => {
    setOpenModal(!openModal)
  }

  const confirmDeleteSubscription = (currentSubscription: any) => {
    setIsOpen(true)
    setOnConfirm(() => async () => {
      const serviceBuilder = ctx?.serviceBuilder
      serviceBuilder.subscriptions = serviceBuilder?.subscriptions?.filter((e: any) => e.id !== currentSubscription.id)
      ctx?.setServiceBuilder(serviceBuilder)
      setSubscription(serviceBuilder.subscriptions)
      setIsOpen(false)
    })
  }

  const handleSubscriptionSubmit = () => {
    const serviceBuilder = ctx?.serviceBuilder
    const input = ctx?.subscriptionInput

    if (!input.id) {
      input.id = uuidv4()
      serviceBuilder.subscriptions = serviceBuilder.subscriptions ?? []
      serviceBuilder.subscriptions.push(input)
    } else {
      serviceBuilder.subscriptions = serviceBuilder?.subscriptions?.map((r: any) => {
        if (r.id === input.id) {
          return input
        }
        return r
      })
    }
    ctx?.setServiceBuilder(serviceBuilder)
    setSubscription([...serviceBuilder.subscriptions].flat())
    console.log('submit Subscription', serviceBuilder)
  }

  const editSubscription = (subscriptionInput: any) => {
    const res = ctx?.serviceBuilder?.subscriptions?.find((r: any) => r.id === subscriptionInput.id)
    ctx?.setSubscriptionInput(res)
    toggleOpenModal()
  }
  return (
    <>

      <div className={'flex justify-center'}>
        {isShowSubscriptionError && <Alert classname={'w-2/3'}
                        variant={'info'}>
          The subscription marked in red have an error, please fix them to proceed
        </Alert>}
      </div>
      <div className={'rounded-2xl border-[1px] bg-[#171B21] border-[#2B2D31]'}>
        <div className={'px-4 flex gap-[16px] items-center py-[4px]'}>
          <div className={'text-lg'}>Subscriptions</div>
          <Button iconLeft={<AddIcon/>} classname={'bg-[#171B21] font-normal'} title={'Add subscription'}
                  onClick={() => {
                    ctx?.setSubscriptionInput({})
                    toggleOpenModal()
                  }} variant={'darker'} type="button"/>
        </div>
        <div
          className={'gap-[28px] min-h-[162px]  grid grid-cols-[repeat(auto-fill,minmax(248px,3fr))]  px-[30px] py-[33px] border-t-[1px] border-[#2B2D31]'}>
          {subscription?.map((m) => <ServiceCardEnumObject
            key={m.id}
            type={'subscription'}
            error={!m.type || !m.name || !checkEmptyFields(m.args)}
            handleRemoveCard={confirmDeleteSubscription}
            handleEditCard={editSubscription}
            card={m}/>)}
        </div>
      </div>
      <ConfirmDialog isOpen={isOpen} onConfirm={onConfirm} onCancel={cancelDelete} />
      <SubscriptionModal onClose={toggleOpenModal} onSubmit={handleSubscriptionSubmit} open={openModal}/>
    </>
  )
}

export default DefineSubscriptions
