import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import { Button } from 'src/components/atoms/button'
import * as yup from 'yup'
import { isValidEmail } from 'src/utils/validation'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextInput } from 'src/components/atoms/text-input'
import Preloader from 'src/components/UI/preloader/Preloader'
import { Auth } from 'aws-amplify'

interface FormAddNewOrgUser {
  email: string
}

const AddNewOrgUser: React.FC<{ onCancel: Function, isOpen: boolean }> = (props) => {
  const { onCancel, isOpen } = props
  const [isLoading, setIsloading] = useState(false)
  const schema = yup.object({
    email: yup
      .string()
      .required('This field is required')
      .test(
        'Email',
        'Please enter a valid email address',
        (value) => {
          if (value) return isValidEmail(value)
          return true
        }
      )
  })

  const {
    handleSubmit,
    control,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormAddNewOrgUser>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })
  const handleClose = () => {
    onCancel()
  }

  const onSubmit = async (data: FormAddNewOrgUser) => {
    try {
      await inviteUser(data.email as string)
      onCancel()
    } catch (err) {
      console.log(err)
    }
  }

  const inviteUser = async (email: string) => {
    setIsloading(true)
    const org = localStorage.getItem('selectedOrganization')
    if (!org) {
      console.log('no organization selected')
      return
    }
    const organizationId = JSON.parse(org).id
    // const elements = event.currentTarget.elements
    try {
      const authData = await Auth.currentSession()
      const res = await fetch(`${process.env.REACT_APP_API}/user`, {
        method: 'POST',
        headers: {
          Authorization: authData.getIdToken().getJwtToken(), 'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          organizationId
        })
      })
      const response = await res.json()

      console.log(response)
      setIsloading(false)
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#020409] bg-opacity-80" />
          </Transition.Child>

          <div className="fixed  inset-0 overflow-y-auto">
            <div className="flex  min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="min-w-[597px] transform rounded-lg  bg-[#171B21]  text-left align-middle  transition-all">
                  <div className="text-2xl mx-6 my-6 font-bold text-white flex items-center justify-between gap-6">
                    <span>Add New Organization User</span>
                    <CloseIcon className={'pointer'} onClick={handleClose}/>
                  </div>
                  <div className={'border-t-[1px] border-b-[1px] py-4 px-6 flex flex-col gap-4  border-[#020409] text-sm text-[#7E858F]'}>
                    <form className={'flex flex-col mt-[16px] gap-[22px]'}>
                      <Controller
                        name={'email'}
                        rules={{ required: true }}
                        control={control}
                        render={({
                          field: {
                            onChange,
                            onBlur,
                            value
                          }
                        }) => (<TextInput
                          {...register('email')}
                          labelText={'Email:'}
                          error={errors?.email?.message}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}/>)}
                      />
                    </form>
                  </div>

                  <div className={'px-6 py-4 flex justify-between items-center'}>
                    <Button onClick={handleClose} classname={'bg-[#020409]'} title={'Cancel'} variant={'darker'} type={'button'}/>
                    <Button title={'Save'} variant={'primary'} disabled={!isDirty || !isValid} onClick={handleSubmit(onSubmit)} type={'submit'}/>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Preloader open={isLoading} modalText={'Loading...'}/>
    </>
  )
}

export default AddNewOrgUser
