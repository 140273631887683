import React, { useContext, useEffect, useState } from 'react'
import ApplicationContext from '../../../store/application-context-provider'
import { useNavigate } from 'react-router-dom'
import { fetchServicesByOrgId, getServiceById, searchServicesByName } from '../../../data/services/queries'
import { Auth } from 'aws-amplify'
import { Service } from '../../../API'
import { v4 as uuidv4 } from 'uuid'
import { removeService } from 'src/data/services/mutations'
import { RESOURCE_TYPE } from 'src/enums'
import ConfirmDialog from 'src/components/molecules/confirm-dialog/ConfirmDialog'
import ServiceBuilderToolbar from 'src/components/organisms/service-builder/ServiceBuilderToolbar'
import { ServiceBuilderCards } from 'src/components/molecules/service-builder-cards'
import { ServiceBuilderTable } from 'src/components/atoms/service-builder-table'
import { Button } from 'src/components/atoms/button'
import Preloader from 'src/components/UI/preloader/Preloader'
import { BlankStates } from 'src/components/organisms/blank-states/BlankStates'
import { EmptyStates } from 'src/components/molecules/empty-states/EmptyStates'

const w = window as any

export default function ServiceBuilder () {
  const [rows, setRows] = useState<Service[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [loadingText, setLoadingText] = useState('Loading...')
  const [onConfirm, setOnConfirm] = useState(() => () => {
  })
  const [isCardsActive, setIsCardsActive] = React.useState(true)
  const [scanByName, setScanByName] = React.useState<string>('')
  const [nextToken, setNextToken] = React.useState<string | null>(null)

  const ctx = useContext(ApplicationContext)
  const navigate = useNavigate()

  useEffect(() => {
    const org = localStorage.getItem('selectedOrganization')
    if (!org) {
      navigate('/select-organization')
      return
    }

    ctx?.setServiceList(rows)
  }, [rows.length])

  const getServices = async (name: string, token?: string | null) => {
    setNextToken(null)
    setIsLoading(true)

    try {
      const view = localStorage.getItem('serviceView')

      const params = {
        teamId: ctx?.selectedTeam && view === 'Team Services' ? ctx?.selectedTeam.id : null,
        nextToken: token ?? null,
        name: name?.trim()
      }

      let queryMethod = fetchServicesByOrgId

      if (name) {
        setScanByName(name)
        queryMethod = searchServicesByName
      }

      const data = await queryMethod(params)
      const services = data.services

      let oldRows = [] as Service[]

      if (token) {
        oldRows = rows
      }

      setRows([...oldRows, ...services])

      if (data.nextToken && typeof data.nextToken === 'string') {
        setNextToken(data.nextToken)
      }
    } catch (err: any) {
      console.log(err)

      if (err.errors[0].errorType === 'User is not member of the org') {
        ctx?.signOut()
        localStorage.clear()
        w.location.href = '/login'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const cancelDelete = () => {
    setIsOpen(false)
  }

  const confirmDelete = async (id: string) => {
    setIsOpen(true)
    setOnConfirm(() => async () => {
      try {
        await removeService(id)
        setRows(rows.filter(row => row.id !== id))
      } catch (err) {
        console.log(err)
      } finally {
        setIsOpen(false)
      }
    })
  }

  const download = async (id: string) => {
    try {
      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/pattern/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authData.getIdToken().getJwtToken()
        }
      })
      const data = await result.json()
      window.open(data.url, '_blank')
    } catch (err) {
      console.log(err)
    }
  }

  const previewCode = (id: string) => {
    localStorage.setItem('previewServiceId', id)
    ctx?.setSelectedServiceId(id)
    navigate(`/service-builder/${id}/preview`)
  }

  const editService = async (id: string) => {
    setLoadingText('Loading Service...')
    setIsLoading(true)
    ctx?.clearContext()
    ctx?.setSelectedServiceId(id)
    ctx?.setIsServiceEdit(true)
    const row = rows.find(row => row.id === id)
    const resources: any[] = []
    const routes: any[] = []
    const triggers: any[] = []
    const resolvers: any[] = []
    const models: any[] = []
    const enums: any[] = []
    const subscriptions: any[] = []
    const customObjects: any[] = []

    if (!row) {
      return
    }
    const service = {
      id: row.id,
      optionBluePrint: row.optionBluePrint,
      config: {
        name: row.name,
        chatgpt: row.chatgpt,
        description: row.description,
        cloudProvider: row.cloudProvider,
        iacFramework: row.iacFramework,
        operatingSystem: row.operatingSystem,
        useCase: row.useCase,
        defaults: {
          stage: row.stage,
          region: row.cloudProviderRegion
        },
        package: {
          webpack: row.webpack,
          prune: row.prune
        },
        createdAt: new Date().toISOString()
      },
      resources,
      routes,
      triggers,
      resolvers,
      models,
      enums,
      subscriptions,
      customObjects
    }

    try {
      const getServiceResponse = await getServiceById({ id: row.id })

      const config = {
        ...service.config,
        appSyncDetails: {
          appsyncName: getServiceResponse.appsyncName,
          appsyncDescription: getServiceResponse.appsyncDescription,
          authType: getServiceResponse.authType,
          logging: getServiceResponse.logging,
          caching: getServiceResponse.caching,
          xRay: getServiceResponse.xRay
        }
      }
      service.config = config
      ctx?.setServiceBuilder(service)
      const newResources = getServiceResponse?.resources?.map((r: any) => ({ ...r, resourceType: r.typename })).map((r: any) => r.resourceType === RESOURCE_TYPE.LAMBDA
        ? {
            ...r,
            connection: {
              id: r?.connection?.id,
              permissions: { create: r?.connection?.permissions?.create, read: r?.connection?.permissions?.read, update: r?.connection?.permissions?.update, delete: r?.connection?.permissions?.delete }
            }
          }
        : r)

      const lambdasWithTrigger = newResources.filter((r: any) => r.resourceType === RESOURCE_TYPE.LAMBDA && r?.trigger)
      service.triggers = lambdasWithTrigger?.map((t: any) => {
        return {
          type: 'LAMBDA_PROXY',
          resource: t,
          resourceId: t.id,
          id: uuidv4()
        }
      })
      service.resources = [...newResources.filter((r: any) => r.resourceType !== RESOURCE_TYPE.ROUTE && r.resourceType !== RESOURCE_TYPE.TRIGGER && r.resourceType !== RESOURCE_TYPE.RESOLVER && r.resourceType !== RESOURCE_TYPE.MODEL)]
      ctx?.setServiceBuilder(service)

      service.resolvers = getServiceResponse?.resources?.map((r: any) => ({ ...r, resourceType: r.typename })).filter((r: any) => r.resourceType === RESOURCE_TYPE.RESOLVER)
      ctx?.setServiceBuilder(service)

      service.models = getServiceResponse?.resources?.map((r: any) => ({ ...r, resourceType: r.typename })).filter((r: any) => r.resourceType === RESOURCE_TYPE.MODEL)?.map((m: any) => ({ ...m, dataSource: JSON.parse(m.dataSource), fields: JSON.parse(m.fields) }))
      ctx?.setServiceBuilder(service)
      service.enums = getServiceResponse?.enums
      service.customObjects = getServiceResponse?.customObjects
      service.subscriptions = getServiceResponse?.subscriptions
      service.routes = [...getServiceResponse?.routes]
      ctx?.setServiceBuilder(service)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    } finally {
      navigate('/service-builder/wizard/edit')
    }
  }

  const handleActive = (value: boolean) => {
    setIsCardsActive(value)
  }

  const handleLoadMoreServices = () => {
    getServices(scanByName, nextToken)
  }

  const blankslate = (scanByName: string) => {
    if (!isLoading) {
      if (scanByName) {
        return <BlankStates/>
      }

      return <EmptyStates/>
    }
  }
  return (
    <div>
      <div style={{ width: '100%', height: '100%', position: 'fixed', top: '0', left: '0', zIndex: 0 }} className={'bg-[#020409]'}></div>
      <div className={'pl-6 pr-10 py-9 relative z-10'}>
         <ServiceBuilderToolbar scanByName={scanByName} active={isCardsActive} scanByNameChange={getServices} handleActive={handleActive}/>
                {rows?.length > 0
                  ? isCardsActive
                    ? <ServiceBuilderCards
                        confirmDelete={confirmDelete}
                        download={download} previewCode={previewCode}
                        editService={editService}
                        services={rows}
                    />
                    : <ServiceBuilderTable
                        confirmDelete={confirmDelete}
                        download={download} previewCode={previewCode}
                        editService={editService}
                        services={rows}
                  />
                  : blankslate(scanByName)
                }
          {nextToken && <div className={'flex justify-center mt-[52px]'}>
            <Button onClick={handleLoadMoreServices} type="button" variant={'darker'} title={'Load more services'}/>
          </div>}
          <ConfirmDialog isOpen={isOpen} onConfirm={onConfirm} onCancel={cancelDelete}/>
          <Preloader open={isLoading} modalText={loadingText || 'Loading...'}></Preloader>
      </div>
    </div>
  )
}
