import { Button, Card, CardContent, FormControl, Grid, Input, InputLabel } from '@mui/material'
import { useContext, useEffect, useState, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { addOrganization, updateOrganization } from '../../../../../data/organizations/mutations'
import ApplicationContext from '../../../../../store/application-context-provider'
import { Organization } from '../../../../../API'

const UpsertOrganization: FC = () => {
  const [formData, setFormData] = useState<{ name: string }>({ name: '' })
  const [actionType, setActionType] = useState<'Add' | 'Update'>('Add')
  const navigate = useNavigate()
  const ctx = useContext(ApplicationContext)

  const gotToSettings = () => {
    navigate('/settings')
  }

  const handleOnChange = (event: Event | any) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  useEffect(() => {
    if ((ctx?.selectedOrganization) != null) {
      setActionType('Update')
      setFormData({ name: ctx?.selectedOrganization?.name ?? '' })
    }
  }, [ctx?.selectedOrganization])

  const upsertOrganization = async (e: Event | any) => {
    const name = e.currentTarget.elements.name.value
    e.preventDefault()
    if (!ctx?.selectedOrganization) {
      try {
        const org = await addOrganization(name)
        ctx?.setSelectedOrganization(org as Organization)
        navigate('/settings')
      } catch (err) { console.log(err) }
    } else {
      try {
        const org = await updateOrganization(ctx.selectedOrganization.id, name)
        ctx.setSelectedOrganization(org as Organization)
        navigate('/settings')
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <>
    <div style={{ paddingTop: '1.2em' }}></div>

    <div style={{ display: 'flex' }}>

      <div style={{ width: '50%' }}>
        <h5> <Button variant="text" onClick={gotToSettings}>Settings</Button> &#62; {actionType} Organization</h5>
      </div>

      <div style={{ textAlign: 'right', width: '50%' }}>

      </div>
    </div>

    <Grid container spacing={1}>
      <Grid item xs={0} md={3}></Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardContent>
            <form onSubmit={upsertOrganization}>
              <FormControl fullWidth={true} variant='outlined'>
                <InputLabel htmlFor="name">Organization name</InputLabel>
                <Input id="name" name="name" aria-describedby="name" onChange={handleOnChange} value={formData.name} />
              </FormControl>
              <div style={{ margin: '1em 0' }}></div>
              <FormControl fullWidth={true}>
                <Button type='submit' color='primary' variant='contained'>
                  <span style={{ textTransform: 'none' }}>Save</span>
                </Button>
              </FormControl>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    </>
  )
}

export default UpsertOrganization
