import React, { useContext, useEffect, useState } from 'react'
import ApplicationContext from '../../../../../store/application-context-provider'
import { Controller, useForm } from 'react-hook-form'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import { Method } from 'src/API'
import { RESOURCE_TYPE } from 'src/enums'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'

interface FormRoute {
  routePath: string
  routeMethod: Method
  routeType: string
  routeResource: string
  routeAuthName: string
  routeCORS: 'False' | 'True'
  routePrivate: 'False' | 'True'
}
const schema = yup.object({
  routeResource: yup
    .string()
    .required('routeResource is required')
    .test(
      'routeResource',
      'routeResource isn\'t valid',
      (value) => {
        if (value === 'NONE') return false
        return true
      }
    ),
  routePath: yup
    .string()
    .required('routePath is required')
    .test(
      'routeResource',
      'routeResource isn\'t valid',
      (value) => {
        if (value?.length === 1) return false
        return true
      }
    )

})
const RouteForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props

  const [lambdas, setLambdas] = useState([])
  const [cognitos, setCognitos] = useState([])

  const ctx = useContext(ApplicationContext)

  const {
    handleSubmit,
    control,
    register
    , formState: {
      isValid
    },
    trigger,
    reset
  } = useForm<FormRoute>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      routePath: '/',
      routeMethod: Method.GET,
      routeType: 'LAMBDA_PROXY',
      routeResource: 'NONE',
      routeAuthName: 'NONE',
      routeCORS: 'True',
      routePrivate: 'False'
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      routePath: ctx?.routeInput?.path,
      routeMethod: ctx?.routeInput?.method,
      routeType: ctx?.routeInput?.type,
      routeResource: ctx?.routeInput?.resource?.id,
      routeAuthName: ctx?.routeInput?.auth?.id,
      routeCORS: ctx?.routeInput?.cors === false ? 'False' : 'True',
      routePrivate: ctx?.routeInput?.private === true ? 'True' : 'False'
    })
  }, [reset])

  useEffect(() => {
    const service = ctx?.serviceBuilder
    setLambdas(service.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.LAMBDA && !r?.trigger))
    const cognitos = service.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.COGNITO_USER_POOL)
    setCognitos(cognitos)
  }, [ctx, ctx?.serviceBuilder])

  const onSubmit = (data: FormRoute) => {
    console.log('data', data)
    const input = ctx?.routeInput
    input.path = data.routePath ?? '/'
    input.method = data.routeMethod ?? Method.GET
    input.type = data.routeType
    // for change name route when lambda name changed
    const resource = ctx?.serviceBuilder.resources.find((r: any) => r.id === data.routeResource)
    input.resource = resource
    input.name = resource?.name
    input.resourceId = data.routeResource

    input.auth = ctx?.serviceBuilder.resources.find((r: any) => r.id === data.routeAuthName)
    input.authId = data.routeAuthName
    input.cors = data.routeCORS === 'True'
    input.private = data.routePrivate === 'True'
    console.log('input route', input)
    ctx?.setRouteInput(input)
    submit()
  }
  return (
    <form>
      <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
        <Controller
          name={'routePath'}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('routePath')}
            showRequired
            labelText={'Path'}
            onChange={onChange}
            onBlur={onBlur}
            value={`/${value?.toLowerCase().replace(/^\//g, '').replace(/\s/g, '')}`}/>)}
        />
        <Controller
          name={'routeMethod'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              selected={value}
              options={[
                { name: 'POST', value: Method.POST },
                { name: 'PUT', value: Method.PUT },
                { name: 'DELETE', value: Method.DELETE },
                { name: 'GET', value: Method.GET },
                { name: 'ANY', value: Method.ANY }
              ]
              }
              labelText={'Method'}
              handleValueChange={onChange}/>
          )}
        />
        <Controller
          name={'routeType'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              selected={value}
              options={[
                { name: 'Lambda Proxy', value: 'LAMBDA_PROXY' }
              ]
              }
              labelText={'Route Type'}
              handleValueChange={onChange}/>
          )}
        />
        <Controller
          name={'routeResource'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              selected={value}
              showRequired
              options={[
                { name: 'NONE', value: 'NONE' },
                ...lambdas?.map((l: any) => ({ name: l.name, value: l.id }))
              ].flat()
              }
              labelText={'Resource'}
              handleValueChange={(e) => {
                trigger('routeResource')
                onChange(e)
              }}/>
          )}
        />
        <Controller
          name="routeAuthName"
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              selected={value}
              options={[
                { name: 'NONE', value: 'NONE' },
                ...cognitos?.map((l: any) => ({ name: l.name, value: l.id }))
              ].flat()
              }
              labelText={'Auth'}
              handleValueChange={onChange}/>
          )}
        />
        <Controller
          name="routeCORS"
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput selected={value}
                                 options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
                                 labelText={'CORS'}
                                 handleValueChange={onChange}/>
          )}
        />
        <Controller
          name="routePrivate"
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput selected={value}
                                 options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
                                 labelText={'API Key'}
                                 handleValueChange={onChange}/>
          )}
        />
      </div>
      <ResourceFormControls isDisabledSaveButton={!isValid} onSubmit={handleSubmit(onSubmit)} onClose={close}/>
        </form>
  )
}

export default RouteForm
