import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import { TextInput } from 'src/components/atoms/text-input'
import ApplicationContext from 'src/store/application-context-provider'
import { Button } from 'src/components/atoms/button'
import { Alert } from 'src/components/atoms/alert'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp-gray.svg'
import { SelectInputOption, SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import { SelectMultipleOutlinedInput } from 'src/components/atoms/select-multiple-outlined'
import { v4 as uuidv4 } from 'uuid'
import { SubscriptionFormRow } from 'src/components/molecules/subscription-form-row/SubscriptionFormRow'
import { getToMutationsFromResolvers, getUniqueObjectsByValue, isValidResolvers } from 'src/utils/validation'
import { OptionBluePrint } from 'src/API'

interface FormSubscription {
  subscriptionName: string
  description: string
  type: string
  required: boolean
  array: boolean
}

const SubscriptionForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props

  const ctx = useContext(ApplicationContext)
  const [argumentFields, setArgumentFields] = useState<any []>([])
  const [derivativeMutations, setDerivativeMutations] = useState<SelectInputOption[]>([])
  const [isFieldAlert, setIsFieldAlert] = useState(false)

  const schema = yup.object({
    subscriptionName: yup
      .string()
      .required('This field is required')
      .max(25)

  })

  const listType = useMemo(() => {
    const modelNames = ctx?.serviceBuilder?.models?.map((m: any) => m.name)
    const customObjectNames = ctx?.serviceBuilder?.customObjects.map((m: any) => m.name)
    return [...modelNames, ...customObjectNames]
  }, [ctx?.serviceBuilder])

  const listMutation = useMemo(() => {
    if (ctx?.serviceBuilder.optionBluePrint === OptionBluePrint.BYOSCHEMA) {
      return ctx?.schemaFileResponse?.mutations?.map((m: any) => ({ name: m, value: m }))
    } else {
      return getToMutationsFromResolvers(ctx?.serviceBuilder?.resolvers).map((m: any) => ({ name: m, value: m }))
    }
  }, [ctx?.schemaFileResponse?.mutations, ctx?.serviceBuilder?.resolvers])

  const {
    getValues,
    trigger,
    control,
    handleSubmit,
    register,
    formState: {
      errors,
      isValid
    },
    reset

  } = useForm<FormSubscription>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      subscriptionName: '',
      description: '',
      type: '',
      required: false,
      array: false
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      subscriptionName: ctx?.subscriptionInput?.name,
      type: ctx?.subscriptionInput?.type,
      required: ctx?.subscriptionInput?.required,
      array: ctx?.subscriptionInput.array
    })
  }, [reset])

  useEffect(() => {
    setArgumentFields(ctx?.subscriptionInput.args?.map((a: any) => ({ ...a, id: a.id ?? uuidv4() })) ?? [{
      id: uuidv4(),
      name: '',
      type: '',
      required: false
    }])
    setDerivativeMutations(ctx?.subscriptionInput?.derivative?.mutations?.map((m: any) => ({ name: m, value: m })))
  }, [])

  const onSubmit = (data: FormSubscription) => {
    const input = ctx?.subscriptionInput
    input.name = data.subscriptionName
    input.type = data.type
    input.required = data.required
    input.array = data.array
    input.derivative = {
      mutations: derivativeMutations?.map((o) => o.name) ?? [],
      name: ctx?.subscriptionInput?.derivative?.name ?? '@aws_subscribe'
    }
    input.args = argumentFields
    console.log('input subscriptionForm', input)
    ctx?.setSubscriptionInput(input)
    submit()
  }

  const handleClose = (event: Event | any) => {
    close()
  }
  const fieldNameHandler = (e: Event | any, row: any) => {
    if (argumentFields?.map((f: any) => f.name).includes(e.target.value)) {
      setIsFieldAlert(true)
      const editData = argumentFields.map((f, index: number) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setArgumentFields(editData)
    } else {
      const editData = argumentFields.map((f, index: number) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setArgumentFields(editData)
      setIsFieldAlert(false)
    }
  }
  const handleMutationsChange = (options: any[]) => {
    setDerivativeMutations(options)
  }

  const argumentRequiredHandler = (e: Event | any, row: any) => {
    const updatedFields = argumentFields.map((f) =>
      f.id === row.id ? { ...f, required: e?.target?.checked } : f
    )
    setArgumentFields(updatedFields)
  }
  const confirmRowDelete = (row: any) => {
    const updatedFields = argumentFields?.filter((f) => f.id !== row.id)
    setArgumentFields(updatedFields)
  }
  const argumentTypeHandler = (e: Event | any, row: any) => {
    const editData = argumentFields.map((f) =>
      f.id === row.id ? { ...f, type: e } : f
    )
    setArgumentFields(editData)
  }
  return (
    <div>
      <div className={'border-b-[1px] border-[#020409]'}></div>
      <div className={'flex flex-col py-1 mx-6 gap-4'}>
        <Controller
          name={'subscriptionName'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('subscriptionName', { maxLength: 25 })}
            required
            className={'w-full'}
            labelText={'Subscription Name'}
            showRequired
            maxLength={25}
            error={errors?.subscriptionName?.message}
            id="resourceName"
            onChange={onChange}
            onBlur={onBlur}
            value={value}/>)}
        />
        <Controller
          name={'description'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('description', { maxLength: 65 })}
            required
            className={'w-full'}
            labelText={'Description'}
            maxLength={25}
            error={errors?.description?.message}
            id="resourceName"
            onChange={onChange}
            onBlur={onBlur}
            value={value}/>)}
        />
<div className={'flex items-center justify-left gap-10'}>
        <Controller
          name={'type'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              showRequired={true}
              error={!getValues().type}
              classname='w-3/12'
              selected={value}
              options={
                listType?.map((s: any) => ({ name: s, value: s })) ?? []
              }
              labelText={'Type'}
              tooltipText='Type is the output of the Subscription.'
              handleValueChange={(e) => {
                trigger('type')
                onChange(e)
              }}
            />
          )}
        />
  <Controller
    name={'required'}
    control={control}
    defaultValue={false}
    render={({ field: { onChange, value } }) => (
      <CheckboxInput
        labelClassName={'mt-[28px]'}
        inputClassName={'mt-[28px]'}
        className={'w-1/12'}
        labelText={'Required'} onChange={(e) => {
          trigger('required')
          onChange(e)
        }} checked={value} />
    )}
  />
  <Controller
    name={'array'}
    control={control}
    defaultValue={false}
    render={({ field: { onChange, value } }) => (
      <CheckboxInput
        labelClassName={'mt-[28px]'}
        inputClassName={'mt-[28px]'}
        className={'w-1/12'}
        labelText={'Array'} onChange={(e) => {
          trigger('array')
          onChange(e)
        }} checked={value} />
    )}
  />
</div>
        <div className={'flex justify-between mt-8 gap-40'}>
          <div className={'text-2xl text-white w-10/12'}>Arguments</div>
          <Button iconLeft={<AddIcon/>} variant={'darker'} title={'Add Field'} type={'button'} classname={'w-2/12'} compact onClick={() => {
            setArgumentFields([...argumentFields, {
              id: uuidv4(),
              name: '',
              type: '',
              required: false,
              array: false
            }])
          }}/>
        </div>
        {isFieldAlert &&
          <Alert variant={'error'}>This field
            name is already in use, please choose a unique name.</Alert>}
        {argumentFields?.map((row: any, index) => <SubscriptionFormRow key={row.id}
                                                                  argumentTypeHandler={argumentTypeHandler}
                                                               row={row}
                                                               confirmRowDelete={confirmRowDelete}
                                                               fieldNameHandler={fieldNameHandler}
         argumentRequiredHandler={argumentRequiredHandler}
          />
        )}

        <div className={'flex flex-col gap-4 mt-8'}>
          <div className={'text-2xl text-white'}>Mutations</div>
          <SelectMultipleOutlinedInput
            options={listMutation}
            selectedOptions={getUniqueObjectsByValue(derivativeMutations)}
            handleValueChange={handleMutationsChange}
            classname={'w-full'}
          />
        </div>
      </div>

      <ResourceFormControls isDisabledSaveButton={!isValid || isFieldAlert || !isValidResolvers(argumentFields) } onSubmit={handleSubmit(onSubmit)} onClose={handleClose}/>
    </div>)
}

export default SubscriptionForm
