import React, { useContext, useEffect } from 'react'
import ApplicationContext from '../../../../../store/application-context-provider'
import ResourceFormControls from './ResourceFormControls'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { Alert } from 'src/components/atoms/alert'

interface FormVpc {
  name: string
  description: string
  vpcExisting: 'True' | 'False'
  vpcExistingId: string
  vpcExistingSubnetA: string
  vpcExistingSubnetB: string
  vpcExistingSubnetC: string
  vpcExistingSecurityGroupId: string
}

const ResourceVpcForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props
  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
      .max(25)
  })
  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: {
      errors,
      isDirty,
      isValid
    },
    reset,
    trigger
  } = useForm<FormVpc>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
      vpcExisting: 'False',
      vpcExistingId: 'vpc-xxxxxxxxx',
      vpcExistingSubnetA: 'subnet-xxxxxxxxx',
      vpcExistingSubnetB: 'subnet-xxxxxxxxx',
      vpcExistingSubnetC: 'subnet-xxxxxxxxx',
      vpcExistingSecurityGroupId: 'sg-xxxxxxxxx'
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: ctx?.resourceInput?.name,
      description: ctx?.resourceInput?.description,
      vpcExisting: ctx?.resourceInput?.vpcExisting === true ? 'True' : 'False',
      vpcExistingId: 'vpc-xxxxxxxxx',
      vpcExistingSubnetA: 'subnet-xxxxxxxxx',
      vpcExistingSubnetB: 'subnet-xxxxxxxxx',
      vpcExistingSubnetC: 'subnet-xxxxxxxxx',
      vpcExistingSecurityGroupId: 'sg-xxxxxxxxx'
    })
  }, [reset])

  const ctx = useContext(ApplicationContext)

  const onSubmit = (data: FormVpc) => {
    const input = ctx?.resourceInput
    input.name = data.name
    input.description = data.description ? data.description : null
    input.vpcExisting = data.vpcExisting === 'True'
    if (data.vpcExisting === 'True') {
      input.vpcExistingId = data.vpcExistingId
      input.vpcExistingSubnetA = data.vpcExistingSubnetA
      input.vpcExistingSubnetB = data.vpcExistingSubnetB
      input.vpcExistingSubnetC = data.vpcExistingSubnetC
      input.vpcExistingSecurityGroupId = data.vpcExistingSecurityGroupId
    } else {
      input.vpcExistingId = ''
      input.vpcExistingSubnetA = ''
      input.vpcExistingSubnetB = ''
      input.vpcExistingSubnetC = ''
      input.vpcExistingSecurityGroupId = ''
    }
    console.log('input vpc', input)
    ctx?.setResourceInput(input)
    submit()
  }

  return (
    <form>
      <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
        <Controller
            name={'name'}
            rules={{ required: true }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
                {...register('name', { maxLength: 25 })}
                required
                labelText={'VPC Name'}
                showRequired
                maxLength={25}
                error={errors?.name?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value?.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
        />
        <Controller
            name={'description'}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
                {...register('description', { maxLength: 60 })}
                labelText={'Description'}
                maxLength={60}
                error={errors?.description?.message}
                id="resourceName"
                onChange={onChange}
                onBlur={onBlur}
                value={value}/>)}
        />
        <Controller
            name={'vpcExisting'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
                <SelectOutlinedInput
                    error={errors?.vpcExisting?.message}
                    selected={value}
                    options={[{ name: 'True', value: 'True' }, { name: 'False (Create a new VPC)', value: 'False' }]}
                    labelText={'Existing'}
                    handleValueChange={(e) => {
                      trigger('vpcExisting')
                      onChange(e)
                    }}/>
            )}
        />
        {getValues().vpcExisting === 'True' && (<>
          <Controller
              name={'vpcExistingId'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('vpcExistingId', { maxLength: 20 })}
                  labelText={'VPC Id'}
                  maxLength={20}
                  disabled={true}
                  defaultValue={'vpc-xxxxxxxxx'}
                  error={errors?.vpcExistingId?.message}
                  id="resourceName"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value?.toLowerCase().replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
          />
          <Controller
              name={'vpcExistingSubnetA'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('vpcExistingSubnetA', { maxLength: 30 })}
                  labelText={'VPC Private Subnet A'}
                  maxLength={30}
                  disabled={true}
                  defaultValue={'subnet-xxxxxxxxx'}
                  error={errors?.vpcExistingSubnetA?.message}
                  id="resourceName"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value?.toLowerCase().replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
          />
          <Controller
              name={'vpcExistingSubnetB'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('vpcExistingSubnetB', { maxLength: 30 })}
                  labelText={'VPC Private Subnet B'}
                  maxLength={30}
                  disabled={true}
                  defaultValue={'subnet-xxxxxxxxx'}
                  error={errors?.vpcExistingSubnetB?.message}
                  id="resourceName"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value?.toLowerCase().replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
          />
          <Controller
              name={'vpcExistingSubnetC'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('vpcExistingSubnetC', { maxLength: 30 })}
                  labelText={'VPC Private Subnet C'}
                  maxLength={30}
                  disabled={true}
                  defaultValue={'subnet-xxxxxxxxx'}
                  error={errors?.vpcExistingSubnetC?.message}
                  id="resourceName"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value?.toLowerCase().replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
          />
          <Controller
              name={'vpcExistingSecurityGroupId'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('vpcExistingSecurityGroupId', { maxLength: 30 })}
                  labelText={'VPC Default Security Group'}
                  maxLength={30}
                  disabled={true}
                  defaultValue={'sg-xxxxxxxxx'}
                  error={errors?.vpcExistingSecurityGroupId?.message}
                  id="resourceName"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value?.toLowerCase().replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
          />
        </>)}
        {getValues().vpcExisting === 'True' && <Alert variant={'darker'} >You’ll have to replace xxx variables in your code later</Alert>}
      </div>
        <ResourceFormControls isDisabledSaveButton={!isDirty || !isValid} onSubmit={handleSubmit(onSubmit)} onClose={close}/>
    </form>)
}

export default ResourceVpcForm
