import React, { useContext, useEffect } from 'react'
import ApplicationContext from '../../../../../store/application-context-provider'
import ResourceFormControls from './ResourceFormControls'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { isValidEmail } from 'src/utils/validation'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { TextInput } from 'src/components/atoms/text-input'
import { UseCase } from 'src/API'

interface FormCognito {
  name: string
  description: string
  cognitoExisting: 'True' | 'False'
  cognitoTestEmail: string
  cognitoType: string
}

const ResourceCognitoForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props

  const ctx = useContext(ApplicationContext)

  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
      .max(25),
    cognitoTestEmail: yup.string()
      .nullable()
      .notRequired()
      .test(
        'Email',
        'Please enter a valid email address',
        (value) => {
          if (value) return isValidEmail(value)
          return true
        }
      )
  })
  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: {
      errors,
      isDirty,
      isValid
    },
    reset,
    trigger
  } = useForm<FormCognito>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      cognitoExisting: 'True',
      cognitoTestEmail: '',
      description: '',
      cognitoType: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: ctx?.resourceInput?.name,
      cognitoExisting: ctx?.resourceInput?.cognitoExisting === true ? 'True' : 'False',
      cognitoTestEmail: ctx?.resourceInput?.cognitoTestEmail,
      description: ctx?.resourceInput?.description,
      cognitoType: ctx?.resourceInput?.cognitoType
    })
  }, [reset])

  const NotExistingCognitoComponent = <>
      <Controller
          name={'description'}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
              {...register('description', { maxLength: 60 })}
              required
              labelText={'Description:'}
              maxLength={60}
              error={errors?.description?.message}
              id="description"
              onChange={onChange}
              onBlur={onBlur}
              value={value}/>)}
      />
       <Controller
          name={'cognitoType'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
              <SelectOutlinedInput
                  error={errors?.cognitoType?.message}
                  selected={value}
                  options={[{ name: 'Email', value: 'EMAIL' }]}
                  labelText={'Type:'} handleValueChange={onChange}
                  tooltipText='This is the attribute used to sign into your User Pool. Only Email is supported for now. Support for Phone Number and User Name is coming soon.'
                  />
          )}
       />
  </>

  const ExistingCognitoComponent = <>
      <TextInput
        disabled={true}
          labelText={ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? 'Update UserPoolArn in the  \'default/cloudgto.yml\'  and \'cognito_am\' in \'scripts/auth.bash\' with your existing Cognito ARN' : 'Update userPoolArn in the defaults/cloudgto.yml and cognito_arn in scripts/auth.bash with your existing Amazon Cognito ARN'}
          id='cognitoExistingArn'
          value={'arn:aws:cognito-idp:us-west-2:0123456789:userpool/us-west-2_xxxxxxx'}/>

  </>

  const onSubmit = (data: FormCognito) => {
    const input = ctx?.resourceInput
    input.name = data.name
    input.description = data.description ? data.description : ''
    input.cognitoType = data.cognitoType ? data.cognitoType : ''
    input.cognitoTestEmail = data.cognitoTestEmail
    input.cognitoExisting = data.cognitoExisting === 'True'
    if (data.cognitoExisting === 'True') {
      input.description = ''
      input.cognitoType = ''
    }
    console.log('input Cognito', input)
    ctx?.setResourceInput(input)
    submit()
  }

  return (
    <form >

        <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
       <Controller
          name={'cognitoExisting'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
              <SelectOutlinedInput
                  error={errors?.cognitoExisting?.message}
                  selected={value}
                                   options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
                                   labelText={'Existing:'}
                                   handleValueChange={(e) => {
                                     trigger('cognitoExisting')
                                     onChange(e)
                                   }}/>
          )}
       />
      <Controller
          name={'name'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
              {...register('name', { maxLength: 25 })}
              showRequired
              labelText={'Name'}
              maxLength={25}
              error={errors?.name?.message}
              id="resourceName"
              onChange={onChange}
              onBlur={onBlur}
              value={value.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
      />

        {getValues().cognitoExisting === 'True' ? ExistingCognitoComponent : NotExistingCognitoComponent}
          <Controller
              name={'cognitoTestEmail'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('cognitoTestEmail', { maxLength: 60 })}
                  required
                  labelText={'Test User Email:'}
                  maxLength={60}
                  error={errors?.cognitoTestEmail?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}/>)}
          />
        </div>
        <ResourceFormControls isDisabledSaveButton={!isDirty || !isValid} onSubmit={handleSubmit(onSubmit)} onClose={close}></ResourceFormControls>
    </form>
  )
}

export default ResourceCognitoForm
