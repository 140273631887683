import React, { FC, ReactNode } from 'react'
import { classNames } from 'src/utils/css'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string
  placeholder?: string
  showRequired?: boolean
  type?: 'password' | 'text' | 'email'
  iconRight?: ReactNode
  iconLeft?: ReactNode
  error?: string
  ref?: React.ForwardedRef<HTMLInputElement>
  inputClassName?: string
  className?: string
}

export const SearchInput: FC<InputProps> = React.forwardRef<
HTMLInputElement,
InputProps
>(
  (
    {
      placeholder = '',
      showRequired = false,
      type = 'text',
      iconRight,
      iconLeft,
      className = '',
      error = '',
      inputClassName = '',
      ...props
    },
    ref
  ) => {
    return (
      <div className={className}>
        <div className="relative flex items-center">
            <div className="absolute inset-y-0 left-0 flex items-center">
              {iconLeft}
            </div>
            <div className="bg-black-darker border-2 border-[#2B2D31] rounded-r-lg box-border pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
              {iconRight}
            </div>
            <input
              ref={ref}
              type={type}
              className={classNames(
                'bg-[#2B2D31] w-full px-4 py-[14px] block font-normal text-white cursor-pointer rounded-lg text-xs  hover:ring-2 ring-[#826AED]    focus:outline-none   focus:ring-2 ring-[#826AED]',
                'placeholder-[#7E858F] font-bold ',
                inputClassName
              )}
              placeholder={placeholder}
              {...props}
            />
          </div>
        {/* {error && <MiniAlert message={error} variant={AlertVariant.Error} />} */}
      </div>
    )
  }
)
