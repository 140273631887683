import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { signUp } from 'src/auth/cognito'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo-cloudgto.svg'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { RadioInput } from 'src/components/atoms/radio-input/RadioInput'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import { Button } from 'src/components/atoms/button'
import Preloader from 'src/components/UI/preloader/Preloader'

interface FormQuestions {
  serverless: string
  aws: string
  personalOrTeam: string
  serverlessFramework: boolean
  cloudformation: boolean
  awsSam: boolean
  terraform: boolean
  architect: boolean
  cdk: boolean
  serverlessStack: boolean
  other: boolean
  contacted: boolean
}

const schema = yup.object({
  serverless: yup
    .string()
    .required('serverless is required'),
  aws: yup
    .string()
    .required('aws is required'),
  personalOrTeam: yup
    .string()
    .required('personalOrTeam is required'),
  contacted: yup
    .boolean()
    .required('contacted is required')
})
const QuestionsForm: React.FC = () => {
  const navigate = useNavigate()
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsloading] = useState(false)
  const {
    control,
    handleSubmit,

    formState: {
      errors,
      isDirty,
      isValid
    },
    reset
  } = useForm<FormQuestions>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      serverless: '',
      aws: '',
      personalOrTeam: '',
      serverlessFramework: false,
      cloudformation: false,
      awsSam: false,
      terraform: false,
      architect: false,
      cdk: false,
      serverlessStack: false,
      other: false,
      contacted: false
    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      serverless: '',
      aws: '',
      personalOrTeam: '',
      serverlessFramework: false,
      cloudformation: false,
      awsSam: false,
      terraform: false,
      architect: false,
      cdk: false,
      serverlessStack: false,
      other: false,
      contacted: false
    })
  }, [reset])

  const onSubmit = async (data: FormQuestions) => {
    const email = localStorage.getItem('email') as string
    const name = localStorage.getItem('name') as string
    const firstName = localStorage.getItem('firstName') as string
    const lastName = localStorage.getItem('lastName') as string

    console.log(firstName, lastName)
    const body = {
      organization: name,
      email,
      sls_experience: data.serverless,
      aws_experience: data.aws,
      evaluating_for: data.personalOrTeam,
      'follow-up': `${data.contacted}`,
      iac: [`${data.serverlessFramework ? 'Serverless Framework' : ''}`, `${data.cloudformation ? 'Cloudformation' : ''}`, `${data.awsSam ? 'AWS SAM' : ''}`, `${data.terraform ? 'Terraform' : ''}`,
        `${data.architect ? 'Architect' : ''}`, `${data.cdk ? 'CDK' : ''}`, `${data.serverlessStack ? 'Serverless Stack (SST)' : ''}`, `${data.other ? 'Other' : ''}`].filter(e => e).join()

    }
    try {
      setIsloading(true)
      const responseSignUp = await signUp({ email, name, firstName, lastName })
      if (Object.prototype.hasOwnProperty.call(responseSignUp, 'User')) {
        setAlertMessage('success')
      } else if (Object.prototype.hasOwnProperty.call(responseSignUp, 'message')) {
        setAlertMessage(responseSignUp.message)
      }

      await fetch(`${process.env.REACT_APP_API_GOOGLE_SHEET}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      setIsloading(false)
    } catch (error) {
      console.log('error', error)
      setAlertMessage('error')
      setIsloading(false)
    }
  }

  return (
    <>
    <div style={{ width: '100%', height: '100%', position: 'fixed', top: '0', left: '0' }} className={'bg-[#020409] z-50'}></div>
    <div
      className={'absolute inset-0 z-50 text-white w-screen flex flex-col items-center justify-center gap-[50px] h-full'}>
      <LogoIcon/>
      <div>
        {alertMessage && !isLoading
          ? (<div className={'bg-[#171B21] p-8 rounded-2xl md:w-[478px] sm:w-full'}>
            <div className={'flex flex-col gap-[22px]'}>
              <div className={'flex flex-col gap-[8px]'}>
                <div className={'text-2xl font-normal'}>{alertMessage === 'success' ? 'Success!' : 'Error'}</div>
                <div className={'text-lg'}>{alertMessage === 'success' ? 'A confirmation mail has been sent to the email address you provided.' : alertMessage}</div>
                {alertMessage === 'success' && <div className={'text-[#7E858F] text-xs'}>Go to your mailbox to confirm.</div>}
              </div>
              <div className={'border-b-[1px] border-[#2B2D31]'}></div>
            </div>
            <div className={'flex items-start pt-[22px]'}>
              <Button title={alertMessage === 'success' ? 'Go to Login' : 'Go to Sign Up'} onClick={() => {
                navigate(alertMessage === 'success' ? '/login' : '/signup')
              }}
                      variant={'primary'} type={'button'}/>
            </div>
          </div>
            )
          : (
            <div className={'bg-[#171B21] p-8 rounded-2xl md:w-[478px] sm:w-full'}>
              <div className={'flex flex-col gap-[22px]'}>
                <div>
                  <div className={'text-2xl font-normal'}>
                    Welcome to CloudGTO
                  </div>
                  <div className={'mt-[2px] text-[#7E858F] text-xs'}>
                    Finish your registration by answering the below
                  </div>
                </div>
                <div className={'border-b-[1px] border-[#2B2D31]'}></div>
              </div>
              <form className={'flex flex-col gap-[22px] mt-1'}>
                <Controller
                  name={'serverless'}
                  control={control}
                  render={({
                    field: {
                      onChange,
                      value
                    }
                  }) => (
                    <SelectOutlinedInput
                      showRequired={true}
                      error={errors?.serverless?.message}
                      selected={value}
                      options={[
                        { name: 'Less than 2 years', value: 'Less than 2 years' },
                        { name: '2-5 years', value: '2-5 years' },
                        { name: '5+ years', value: '5+ years' }
                      ]
                      }
                      labelText={'How long have you been working with serverless ?'}
                      handleValueChange={onChange}/>
                  )}
                />
                <Controller
                  name={'aws'}
                  control={control}
                  render={({
                    field: {
                      onChange,
                      value
                    }
                  }) => (
                    <SelectOutlinedInput
                      showRequired={true}
                      error={errors?.aws?.message}
                      selected={value}
                      options={[
                        { name: 'Less than 2 years', value: 'Less than 2 years' },
                        { name: '2-5 years', value: '2-5 years' },
                        { name: '5+ years', value: '5+ years' }
                      ]
                      }
                      labelText={'How long have you been working with AWS ?'}
                      handleValueChange={onChange}/>
                  )}
                />
                <div>
                  <div className={'text-xs pb-[8px]'}>Who are you evaluating for ?<span
                    className={'ml-[4px] text-red-default'}>*</span></div>
                  <Controller
                    name="personalOrTeam"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className={'flex gap-[16px]'}>
                        <RadioInput
                          labelText={'Personal'}
                          variant={'darker'}
                          id="personal"
                          value="Personal"
                          inputClassName='text-white'
                          onChange={() => {
                            onChange('Personal')
                          }}
                          checked={(value === 'Personal') as any}
                        />
                        <RadioInput
                          variant={'darker'}
                          labelText={'Dev team'}
                          id="devTeam"
                          value="Dev team"
                          inputClassName='text-white'
                          onChange={() => {
                            onChange('Dev team')
                          }}
                          checked={value === 'Dev team'}
                        /></div>
                    )}
                  />
                </div>
                <div className={'flex flex-col gap-[8px]'}>
                  <div className={'text-xs'}>What IaC do you typically use 80% or more of the time ?</div>

                  <Controller
                    name={'serverlessFramework'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'Serverless Framework'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />

                  <Controller
                    name={'cloudformation'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'Cloudformation'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />
                  <Controller
                    name={'awsSam'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'AWS SAM'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />
                  <Controller
                    name={'terraform'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'Terraform'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />
                  <Controller
                    name={'architect'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'Architect'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />
                  <Controller
                    name={'cdk'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'CDK'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />
                  <Controller
                    name={'serverlessStack'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'Serverless Stack (SST)'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />
                  <Controller
                    name={'other'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'Other'} labelClassName='text-white' onChange={onChange} checked={value}/>
                    )}
                  />
                </div>
                <div className={'flex flex-col gap-[8px]'}>
                  <div className={'text-xs'}>Contact me about my CloudGTO experience ?</div>
                  <Controller
                    name={'contacted'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxInput variant={'darker'} labelText={'Yes, I want!'} labelClassName='text-white' onChange={onChange}
                                     checked={value}/>
                    )}
                  />
                </div>
                <div className={'flex items-start'}>
                  <Button title={'Finalize'} disabled={!isDirty || !isValid} onClick={handleSubmit(onSubmit)}
                          variant={'primary'} type={'submit'}/>
                </div>
              </form>
            </div>
            )}
      </div>
    </div>
    <Preloader open={isLoading} modalText={'Loading...'}/>
    </>
  )
}

export default QuestionsForm
