import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { SQS_TOPIC_TYPE, Timeout } from 'src/enums'
import ApplicationContext from 'src/store/application-context-provider'
import { transformInputValueToSeconds } from 'src/utils/resourceSqsForm'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { TextInput } from 'src/components/atoms/text-input'

interface FormSqs {
  topicName: string
  topicType: SQS_TOPIC_TYPE
  description: string
  deadLetterQueue: 'True' | 'False'
  visibilityTimeout: number
  timeoutType: Timeout
  deliveryDelay: number
  deliveryDelayType: Timeout
  receiveMessageWT: number
  messageRetention: number
  messageRetentionTimeoutType: Timeout
  maxMsgSize: number
}

export const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

const ResourceSqsForm: React.FC<{ close: Function, submit: Function }> = props => {
  const {
    close,
    submit
  } = props

  const schema = yup.object({
    topicName: yup
      .string()
      .required('This field is required')
      .max(25)
  })

  const ctx = useContext(ApplicationContext)

  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: {
      errors,
      isDirty,
      isValid
    },
    reset,
    trigger
  } = useForm<FormSqs>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      topicName: '',
      topicType: SQS_TOPIC_TYPE.SIMPLE,
      description: '',
      deadLetterQueue: 'False',
      visibilityTimeout: 300,
      deliveryDelay: 0,
      timeoutType: Timeout.Seconds,
      deliveryDelayType: Timeout.Seconds,
      receiveMessageWT: 20,
      messageRetention: 1209600,
      messageRetentionTimeoutType: Timeout.Seconds,
      maxMsgSize: 256
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      topicName: ctx?.resourceInput?.name,
      topicType: ctx?.resourceInput?.topicType,
      description: ctx?.resourceInput?.description,
      deadLetterQueue: ctx?.resourceInput?.hasDlq === true ? 'True' : 'False',
      visibilityTimeout: ctx?.resourceInput?.visibilityTimeout,
      deliveryDelay: ctx?.resourceInput?.deliveryDelay,
      receiveMessageWT: ctx?.resourceInput?.receiveMsgWaitTimeSecs,
      messageRetention: ctx?.resourceInput?.msgRetentionPeriod,
      maxMsgSize: ctx?.resourceInput?.maxMsgSize
    })
  }, [reset])

  const onSubmit = (data: FormSqs) => {
    const input = ctx?.resourceInput
    input.name = data.topicName
    input.topicType = data.topicType
    if (data.description) {
      input.description = data.description
    }

    input.hasDlq = data.deadLetterQueue === 'True'
    input.visibilityTimeout = data.timeoutType ? transformInputValueToSeconds(data.timeoutType, data.visibilityTimeout) : Number(data.visibilityTimeout) || null
    input.deliveryDelay = data.deliveryDelayType ? transformInputValueToSeconds(data.deliveryDelayType, data.deliveryDelay) : Number(data.deliveryDelay) || 0
    input.msgRetentionPeriod = data.messageRetentionTimeoutType ? transformInputValueToSeconds(data.messageRetentionTimeoutType, data.messageRetention) : Number(data.messageRetention) || null
    input.receiveMsgWaitTimeSecs = Number(data.receiveMessageWT) || null
    input.maxMsgSize = Number(data.maxMsgSize) || null
    console.log('input sqs', input)
    ctx?.setResourceInput(input)
    submit()
  }

  return (<form>
    <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
      <div className={'flex flex-col gap-[6px]'}>
      <Controller
          name={'topicType'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
              <SelectOutlinedInput
                  error={errors?.topicType?.message}
                  selected={value}
                  options={[
                    { name: 'FIFO (first in, first out)', value: SQS_TOPIC_TYPE.FIFO },
                    { name: 'Standard', value: SQS_TOPIC_TYPE.SIMPLE }
                  ]
                  }
                  labelText={'Queue Type'}
                  handleValueChange={(e) => {
                    trigger('topicName')
                    onChange(e)
                  }}/>
          )}
      />
      {getValues().topicType === SQS_TOPIC_TYPE.FIFO && (<div className="text-white text-xs">
        First-in-first-out delivery, message ordering is preserved.
        <ul className="list-disc list-inside pl-2">
          <li>First-in-first-out delivery</li>
          <li>Exactly-once processing</li>
        </ul>
      </div>)}
      {getValues().topicType === SQS_TOPIC_TYPE.FIFO && (<div className="text-white text-xs">
        At-least-once delivery, message ordering isn&apos;t preserved.
        <ul className="list-disc list-inside pl-2">
          <li>At-least once delivery</li>
          <li>Best-effort ordering</li>
        </ul>
      </div>)}
      </div>
        <Controller
          name={'topicName'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
              {...register('topicName', { maxLength: 25 })}
              required
              labelText={'Queue Name'}
              showRequired
              maxLength={25}
              error={errors?.topicName?.message}
              id="resourceName"
              onChange={onChange}
              onBlur={onBlur}
              value={value?.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '')}/>)}
      />

      <Controller
          name={'description'}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
              {...register('description', { maxLength: 60 })}
              labelText={'Description'}
              maxLength={60}
              error={errors?.description?.message}
              id="resourceName"
              onChange={onChange}
              onBlur={onBlur}
              value={value}/>)}
      />

        <Controller
          name={'deadLetterQueue'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
              <SelectOutlinedInput
                  error={errors?.deadLetterQueue?.message}
                  selected={value}
                  options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
                  labelText={'Dead Letter Queue'}
                  handleValueChange={onChange}/>
          )}
      />
        <h3 className="modal-settings-title text-white">Configuration</h3>
        {/* Visibility timeout */}
        <div className={'flex flex-wrap -mx-1 items-center'}>
        <div className={'w-full sm:w-1/2 px-[4px]'}>
      <Controller
          name={'visibilityTimeout'}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
              {...register('visibilityTimeout', { min: 0 })}
              type={'number'}
              labelText={'Visibility timeout'}
              defaultValue={300}
              min={'0'}
              onKeyDown={blockInvalidChar}
              error={errors?.visibilityTimeout?.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}/>)}
      />
          <div className="text-[#7E858F] text-[10px]">Should be between 0 seconds and 12 hours.</div>
        </div>
        <div className={'w-full sm:w-1/2 px-[4px]'}>
        <Controller
            name={'timeoutType'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
                <SelectOutlinedInput
                    error={errors?.timeoutType?.message}
                    selected={value}
                    labelText={''}
                    options={[
                      { name: 'Seconds', value: Timeout.Seconds },
                      { name: 'Minutes', value: Timeout.Minutes },
                      { name: 'Hours', value: Timeout.Hours }
                    ]
                    }
                    handleValueChange={onChange}/>
            )}
        />
        </div>
      </div>
        <div className={'flex flex-wrap -mx-1 items-center'}>
        <div className={'w-full sm:w-1/2 px-[4px]'}>
          <Controller
              name={'deliveryDelay'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('deliveryDelay', { min: 0 })}
                  type={'number'}
                  labelText={'Delivery delay'}
                  defaultValue={0}
                  min={'0'}
                  onKeyDown={blockInvalidChar}
                  error={errors?.deliveryDelay?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}/>)}
          />
          <div className="text-[#7E858F] text-[10px]">Should be between 0 seconds and 15 minutes.</div>
        </div>
        <div className={'w-full sm:w-1/2 px-[4px]'}>
          <Controller
              name={'deliveryDelayType'}
              control={control}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                  <SelectOutlinedInput
                      error={errors?.deliveryDelayType?.message}
                      selected={value}
                      labelText={''}
                      options={[
                        { name: 'Seconds', value: Timeout.Seconds },
                        { name: 'Minutes', value: Timeout.Minutes },
                        { name: 'Hours', value: Timeout.Hours }
                      ]
                      }
                      handleValueChange={onChange}/>
              )}
          />
        </div>
      </div>
        <div className={'flex flex-wrap -mx-1 items-center'}>
            <div className={'w-full sm:w-1/2 px-[4px]'}>
                <Controller
                    name={'receiveMessageWT'}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                        {...register('receiveMessageWT', { min: 0, max: 20 })}
                        type={'number'}
                        labelText={'Receive message wait time (Seconds)'}
                        defaultValue={20}
                        min={'0'}
                        max={'20'}
                        onKeyDown={blockInvalidChar}
                        error={errors?.receiveMessageWT?.message}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}/>)}
                />
                <div className="text-[#7E858F] text-[10px]">Seconds Should be between 0 and 20 seconds.</div>
            </div>
        </div>
        {/* Message retention period */}
        <div className={'flex flex-wrap -mx-1 items-center'}>
            <div className={'w-full sm:w-1/2 px-[4px]'}>
                <Controller
                    name={'messageRetention'}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                        {...register('messageRetention', { min: 0 })}
                        type={'number'}
                        labelText={'Message retention period'}
                        defaultValue={1209600}
                        min={'0'}
                        onKeyDown={blockInvalidChar}
                        error={errors?.messageRetention?.message}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}/>)}
                />
                <div className="text-[#7E858F] text-[10px]">Should be between 1 minute and 14 days.</div>
            </div>
            <div className={'w-full sm:w-1/2 px-[4px]'}>
                <Controller
                    name={'messageRetentionTimeoutType'}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value
                      }
                    }) => (
                        <SelectOutlinedInput
                            error={errors?.messageRetentionTimeoutType?.message}
                            selected={value}
                            labelText={''}
                            options={[
                              { name: 'Seconds', value: Timeout.Seconds },
                              { name: 'Minutes', value: Timeout.Minutes },
                              { name: 'Hours', value: Timeout.Hours },
                              { name: 'Days', value: Timeout.Days }
                            ]
                            }
                            handleValueChange={onChange}/>
                    )}
                />
            </div>
        </div>
        {/* Maximum message size */}
        <div className={'flex flex-wrap -mx-1 items-center'}>
            <div className={'w-full sm:w-1/2 px-[4px]'}>
                <Controller
                    name={'maxMsgSize'}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                        {...register('maxMsgSize', { min: 0, max: 256 })}
                        type={'number'}
                        labelText={'Maximum message size (KB)'}
                        defaultValue={1}
                        min={'1'}
                        max={'256'}
                        onKeyDown={blockInvalidChar}
                        error={errors?.maxMsgSize?.message}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}/>)}
                />
                <div className="text-[#7E858F] text-[10px]">Should be between 1 KB and 256 KB.</div>
            </div>

        </div>
    </div>
    <ResourceFormControls isDisabledSaveButton={!isDirty || !isValid} onSubmit={handleSubmit(onSubmit)} onClose={close}/>
  </form>)
}

export default ResourceSqsForm
