import React, { useContext, useEffect, useState } from 'react'
import { ResolverModelRow } from 'src/components/molecules/resolver-model-row/ResolverModelRow'
import { v4 as uuidv4 } from 'uuid'
import { RowResolver } from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/ModelForm'
import { Alert } from 'src/components/atoms/alert'
import ApplicationContext from 'src/store/application-context-provider'
import { isValidFieldResolvers } from 'src/utils/validation'
import QueryFromResolverModal
  from 'src/components/organisms/service-builder/wizard/map-resolvers-to-models/query-from-modal/QueryFromResolverModal'
import { RESOLVER_TYPE, RESOURCE_TYPE } from 'src/enums'
import { Gsi } from 'src/components/organisms/service-builder/forms/resources/ResourceDynamoForm'

export const MapResolversToModels = () => {
  const ctx = useContext(ApplicationContext)
  const [resolvers, setResolvers] = useState<RowResolver[]>([])
  const [isResolverAlert, setIsResolverAlert] = useState('')
  const [[isQueryFromModalOpen, currentResolver, options], setQueryFromModalOpen] = useState([false, '', []])
  const [isHasIndexAlert, setIsHasIndexAlert] = useState('')

  useEffect(() => {
    setResolvers([...ctx?.schemaFileResponse?.mutations ?? [], ...ctx?.schemaFileResponse?.queries ?? []]?.map((r) => ({
      id: uuidv4(),
      name: r,
      model: '',
      type: '',
      description: '',
      dataSource: '',
      isPipeline: false,
      isDisabled: false,
      modelFieldId: ''
    })))
  }, [])

  useEffect(() => {
    ctx?.setMapResolversToModelsButtonDisabled(!isValidFieldResolvers(resolvers) || !!isResolverAlert || !!isHasIndexAlert)
  }, [resolvers, isResolverAlert, isHasIndexAlert])

  useEffect(() => {
    function checkResolvers (resolvers: RowResolver[]): boolean {
      const models: Record<string, string[]> = {}
      for (const resolver of resolvers) {
        const { model, type } = resolver

        if (model && type) {
          if (!models[model]) {
            models[model] = []
          }

          if (models[model].includes(type)) {
            return true
          }

          models[model].push(type)
        }
      }

      return false
    }
    if (checkResolvers(resolvers)) {
      setIsResolverAlert('You can’t create two resolvers of the same type in the same model')
    } else {
      setIsResolverAlert('')
    }

    const dataSources = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)
    const isShowAlert = resolvers.some((resolver) => {
      if (resolver.type === RESOLVER_TYPE.QUERY) {
        const dataSource = dataSources.find((ds: any) => ds.name === resolver.dataSource)
        return !dataSource?.hasIndex
      } else {
        return false
      }
    })
    setIsHasIndexAlert(isShowAlert ? 'The data source must have an index in order to create a "query" resolver' : '')
  }, [resolvers])

  const confirmRowDelete = (resolver: RowResolver) => {
    const serviceBuilder = ctx?.serviceBuilder
    setResolvers(resolvers.filter((r) => r.id !== resolver.id))
    serviceBuilder.resolvers = resolvers.filter((r) => r.id !== resolver.id)
    ctx?.setServiceBuilder(serviceBuilder)
  }
  const resolverNameHandler = (e: Event | any, row: RowResolver) => {
    const resolverTypeList = resolvers?.map((s: any) => s.type)
    const resolverNameList = resolvers?.map((s: any) => s.name)
    if (resolverNameList?.includes(e.target.value) || (row.type && resolverTypeList?.includes(row.type) && !e.target.value.includes('.'))) {
      const editData = resolvers.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setIsResolverAlert('This resolver name is already in use, please choose a unique name.')
      setResolvers(editData)
    } else {
      const editData = resolvers.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setResolvers(editData)
      setIsResolverAlert('')
    }
  }
  const resolverModelHandler = (model: any, resolver: RowResolver) => {
    const serviceBuilder = ctx?.serviceBuilder
    const updatedResolvers = resolvers.map((f) =>
      f.id === resolver.id ? { ...f, model: model?.name, dataSource: model?.dataSource?.name } : f
    )
    serviceBuilder.resolvers = updatedResolvers
    setResolvers(updatedResolvers)
    ctx?.setServiceBuilder(serviceBuilder)
  }
  const resolverTypeHandler = (e: Event | any, resolver: RowResolver) => {
    const serviceBuilder = ctx?.serviceBuilder
    const currentDataSource = ctx?.serviceBuilder.resources?.find((r: any) => r.id === ctx?.serviceBuilder.models.find((m: any) => m.name === resolver.model)?.dataSource?.id)
    const updatedResolvers = resolvers.map((f) =>
      f.id === resolver.id ? { ...f, type: e } : f
    ).map((f) => {
      if (f.id === resolver.id && e !== RESOLVER_TYPE.QUERY && f.queryFrom) {
        const { queryFrom, ...rest } = f
        return rest
      }
      return f
    })

    if (e === RESOLVER_TYPE.QUERY && currentDataSource?.hasIndex) {
      setQueryFromModalOpen([true, resolver.id, currentDataSource?.GSI?.map((g: Gsi) => g.gsiName)])
    }
    serviceBuilder.resolvers = updatedResolvers
    setResolvers(updatedResolvers)
    ctx?.setServiceBuilder(serviceBuilder)
  }
  const resolverPipelineHandler = (e: Event | any, resolver: RowResolver) => {
    const serviceBuilder = ctx?.serviceBuilder
    const updatedResolvers = resolvers.map((f) =>
      f.id === resolver.id ? { ...f, isPipeline: !resolver.isPipeline } : f
    )
    serviceBuilder.resolvers = updatedResolvers
    setResolvers(updatedResolvers)
    ctx?.setServiceBuilder(serviceBuilder)
  }
  const onQueryFromModalSubmit = (value: string) => {
    const serviceBuilder = ctx?.serviceBuilder
    const editData = resolvers.map((f) =>
      f.id === currentResolver ? { ...f, queryFrom: value.toLowerCase() } : f
    )
    serviceBuilder.resolvers = editData
    setResolvers(editData)
    ctx?.setServiceBuilder(serviceBuilder)
  }

  return (
    <div className={'bg-[#171B21] w-2/3 mt-2 rounded-lg mx-auto px-12 pt-8 pb-8 mb-[100px] flex flex-col  gap-[27px] text-white'}>
      <div className={'flex flex-col gap-[8px]'}>
        <div className={'text-lg font-bold'}>Map Resolvers to Models</div>
        <div className={'text-xs text-[#7E858F]'}>To ensure your implementation runs smoothly, it&apos;s essential to
          correctly map your Resolvers and Models.
          Choose a Resolver from the dropdown and then pick the corresponding Model. Proper configuration is key to
          success.
        </div>
      </div>
      <div className={'flex flex-col gap-[8px]'}>
        {isResolverAlert && <Alert variant={'error'}>{isResolverAlert}</Alert>}
        {isHasIndexAlert && <Alert variant={'error'}>{isHasIndexAlert}</Alert>}
        <div className={'text-lg font-bold'}>Model Fields</div>
        {resolvers?.map((r) => <ResolverModelRow key={r.id} resolver={r} models={ctx?.serviceBuilder?.models} resolverPipelineHandler={resolverPipelineHandler} confirmRowDelete={confirmRowDelete} resolverTypeHandler={resolverTypeHandler} resolverNameHandler={resolverNameHandler} resolverModelHandler={resolverModelHandler}/>)}
      </div>
      <QueryFromResolverModal resolveVariant={'query from'}
                      open={isQueryFromModalOpen}
                      onClose={() => { setQueryFromModalOpen([false, '', []]) }}
                      onSubmit={onQueryFromModalSubmit}
                      options={options}
                      defaultValue={options[0]}/>
    </div>)
}
