import './Tooltip.css'
import { classNames } from 'src/utils/css'

export interface TooltipProps {
  className?: string
  text?: string
}

export const Tooltip: React.FC<TooltipProps> = ({ text = '', className = '' }) => (
  <div className={classNames('has-tooltip ml-1', className)}>
    <div className='tooltip-wrapper'>
      <div className='tooltip'>
        {text}
      </div>
    </div>
    <span className='tooltip-arrow' />
    <i className='tooltip-i'>
      i
    </i>
  </div>
)
