import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'

export const useUser = () => {
  const [user, setUser] = useState<null | any>(null)

  async function updateUser () {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      setUser(currentUser)
    } catch (e) {
      setUser(undefined)
    }
  }

  useEffect(() => {
    updateUser()
  }, [])

  return user
}
