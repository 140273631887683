import React, { useRef } from 'react'
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg'
const AppsyncDeploymentNote: React.FC<{ isActiveMac?: boolean }> = props => {
  const { isActiveMac } = props

  const installAWSCodeRef = useRef<HTMLPreElement>(null)
  const setupCodeRef = useRef<HTMLPreElement>(null)
  const runSetupCodeRef = useRef<HTMLPreElement>(null)
  const signCognitoCodeRef = useRef<HTMLPreElement>(null)
  const queriesCodeRef = useRef<HTMLPreElement>(null)
  const slsInfoCodeRef = useRef<HTMLPreElement>(null)
  const testCodeRef = useRef<HTMLPreElement>(null)
  const printCodeRef = useRef<HTMLPreElement>(null)
  const deployCodeRef = useRef<HTMLPreElement>(null)
  const slsDeployCodeRef = useRef<HTMLPreElement>(null)
  const installCodeRef = useRef<HTMLPreElement>(null)
  function handleCopy (ref: any) {
    if (ref.current) {
      const codeText = ref.current.textContent
      navigator.clipboard.writeText(codeText as string)
    }
  }

  return (
    <ol className={'list-decimal list-inside text-xs pb-[25px]'}>
        <div className={'text-lg font-bold text-[#7E858F] mt-1'}>CloudGTO Application</div>
        <div className={'text-lg text-[#7E858F] mt-0.5'}>Purpose</div>
        <div className={'text-xs text-[#7E858F] mt-0.5'}> Serverless development can be difficult even for experts. You&apos;ve just skipped ahead hundreds of hours by utilizing CloudGTO</div>
        <div className={'text-sm text-[#7E858F] mt-1'}>Getting Started</div>
      <li className={'py-[8px] mt-[25px] text-[#7E858F] text-xs'}>Install AWS CLI and Serverless Framework CLI</li>
        <div className={'px-[8px] py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(installAWSCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={installAWSCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'} >
          {isActiveMac ? 'brew install awscli \nnpm install -g serverless@latest' : 'choco install awscli \nnpm install -g serverless@latest'}
        </code>
        <li className={'py-[25px] text-[#7E858F] text-xs'}>Update the CloudGTO.yml with real values, if using existing resources e.g. Cognito User Pool Arn if you
          selected “Existing Cognito” in the CloudGTO UI</li>
      <li className={'pb-[8px] text-[#7E858F] text-xs'}>Install/Format/Deploy</li>
        <div className={'px-[8px] py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(setupCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={setupCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          npm run setup
        </code>
      <li className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>Signup/Signin your cognito user</li>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(signCognitoCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={signCognitoCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          npm run signCognito
        </code>
        <p className={'mt-1 ml-1 text-[#7E858F]'}><span className={'font-bold '}> example output:</span> example@company.com signed in. Your initial password is f5592b54-23a6-49bE!</p>
        <li className={'py-[14px] mt-1 text-[#7E858F] text-xs'}>Test E2E (end-to-end) - open your AppSync Console on your AWS Account, locate the deployed AppSync Api and select Queries</li>
        <li className={'py-[14px] text-[#7E858F] text-xs'}>Select authorization type from the authorization provider dropdown on your AWS AppSync Console</li>
        <li className={'py-[14px] text-[#7E858F] text-xs'}>Select Login with User Pools and login if you are using Cognito Authentication</li>
      <li className={'pb-[8px] mt-[10px] text-[#7E858F] text-xs'}>Run your queries and mutation in the Explorer . eg</li>
      <div className={'px-[8px] py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(queriesCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={queriesCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          {`
query MyQuery {
  getItemById(input: { id: "<uuid>" }) {
    id
    name
    price
    inStock
  }
}

mutation MyMutation {
  createItemById(
    input: { name: "EA Sports x Nike Air Max 90", price: 1500, inStock: true }
  ) {
    id
    name
    price
    inStock
  }
}
         `}
        </code>
      <li className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>If you are using Api_Keys Authentication and need to get your api keys for your x-api-key header, run the command below to get your appsync details:</li>
        <div className={'px-[8px]  py-[8px] flex justify-end gap-3 text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>

          <div onClick={() => { handleCopy(slsInfoCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={slsInfoCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          npx sls info
        </code>
      <li className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>To evalute your resolver codes:</li>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(testCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={testCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          npm run test
        </code>
      <li className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>You now have a working CRUD APPSYNC API, add your own custom code to the service and redeploy, then repeat steps above</li>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(deployCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={deployCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          sls deploy
        </code>
        <div className={'mt-2 text-sm text-[#7E858F]'}>Other Commands</div>
      <div className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>Setup (Install/Format/Deploy)</div>
      <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
        <div onClick={() => { handleCopy(runSetupCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={runSetupCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          npm run setup
        </code>
      <div className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>Install</div>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(installCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={installCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          npm install
        </code>
      <div className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'} >Deploy Resources and Services (serverless-compose.yml helps deploy ordering)</div>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(slsDeployCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={slsDeployCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          sls deploy --stage dev --region us-west-2
        </code>
      <div className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'} >Print serverless.yml</div>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
         <div onClick={() => { handleCopy(printCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={printCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
          sls [service-name]:print --stage dev --region us-west-2
        </code>
        <div className={'mt-1 text-[#7E858F] text-lg'}>Questions</div>
        <div className={'mt-1 text-[#7E858F]'}>Contact us <a href="mailto:support@cloudgto.com">support@cloudgto.com</a>.</div>
      </ol>
  )
}

export default AppsyncDeploymentNote
