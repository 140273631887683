import { TextInput } from 'src/components/atoms/text-input'
import { Button } from 'src/components/atoms/button'
import React from 'react'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import { Gsi } from 'src/components/organisms/service-builder/forms/resources/ResourceDynamoForm'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { DYNAMO_KEY_TYPE, DYNAMO_PRIMARY_KEY_TYPE, PROJECTION_TYPE } from 'src/enums'

export const SecondaryIndexesRow: React.FC<{
  gsi: Gsi
  indexNameHandler: (e: Event | any, gsi: Gsi) => void
  keyTypeHandler: (e: Event | any, gsi: Gsi) => void
  primaryKeyNameHandler: (e: Event | any, gsi: Gsi) => void
  primaryKeyTypeHandler: (e: Event | any, gsi: Gsi) => void
  secondaryKeyNameHandler: (e: Event | any, gsi: Gsi) => void
  secondaryKeyTypeHandler: (e: Event | any, gsi: Gsi) => void
  attributeProjectionsHandler: (e: Event | any, gsi: Gsi) => void
  confirmGSIDelete: (gsi: Gsi) => void

}> = props => {
  const { gsi, confirmGSIDelete, attributeProjectionsHandler, secondaryKeyTypeHandler, secondaryKeyNameHandler, indexNameHandler, keyTypeHandler, primaryKeyNameHandler, primaryKeyTypeHandler } = props

  return (<div className={'flex mt-1 items-center justify-between gap-2 border-t-[1px] border-[#2B2D31]'}>
    <TextInput
      className={'max-w-[120px]'}
      required
      value={gsi.gsiName?.replace(/\s/g, '')}
      maxLength={12}
      onChange={(e) => {
        indexNameHandler(e, gsi)
      }}
      labelText={'Index Name:'}
      showRequired
      id="resourceName"
    />
    <SelectOutlinedInput
      classname={'min-w-[130px]'}
      selected={gsi.gsiKeyType}
      options={[
        { name: 'Composite', value: DYNAMO_KEY_TYPE.COMPOSITE },
        { name: 'Simple', value: DYNAMO_KEY_TYPE.SIMPLE }
      ]
      }
      labelText={'Key Type:'}
      handleValueChange={(e) => {
        keyTypeHandler(e, gsi)
      }}/>

    <TextInput
      className={'max-w-[120px]'}
      required
      value={gsi.primaryAttribute?.replace(/\s/g, '')}
      maxLength={12}
      onChange={(e) => {
        primaryKeyNameHandler(e, gsi)
      }}
      labelText={'Primary Key Name:'}
      showRequired
      id="resourceName"
    />
    <SelectOutlinedInput
      classname={'min-w-[130px]'}
      selected={gsi.primaryAttributeType}
      options={[
        { name: 'STRING', value: DYNAMO_PRIMARY_KEY_TYPE.STRING }

      ]
      }
      labelText={'Primary Key Type:'}
      showRequired
      handleValueChange={(e) => {
        primaryKeyTypeHandler(e, gsi)
      }}/>
    <TextInput
      className={'max-w-[140px]'}
      required
      disabled={gsi.gsiKeyType === DYNAMO_KEY_TYPE.SIMPLE}
      value={gsi.gsiKeyType === DYNAMO_KEY_TYPE.COMPOSITE ? gsi.secondaryAttribute?.replace(/\s/g, '') : ''}
      maxLength={12}
      onChange={(e) => {
        secondaryKeyNameHandler(e, gsi)
      }}
      labelText={'Secondary Key Name:'}
      showRequired={gsi.gsiKeyType !== DYNAMO_KEY_TYPE.SIMPLE}
      id="resourceName"
    />
    <SelectOutlinedInput
      showRequired={gsi.gsiKeyType !== DYNAMO_KEY_TYPE.SIMPLE}
      classname={'min-w-[130px]'}
      disabled={gsi.gsiKeyType === DYNAMO_KEY_TYPE.SIMPLE}
      selected={gsi.gsiKeyType === DYNAMO_KEY_TYPE.COMPOSITE ? gsi.secondaryAttributeType : ''}
      options={[{ name: 'STRING', value: DYNAMO_PRIMARY_KEY_TYPE.STRING }]}
      labelText={'Secondary Key Type:'}
      handleValueChange={(e) => {
        secondaryKeyTypeHandler(e, gsi)
      }}/>
    <SelectOutlinedInput
      classname={'min-w-[130px]'}
      selected={gsi.projectionType}
      options={[
        { name: 'All', value: PROJECTION_TYPE.ALL },
        { name: 'Only keys', value: PROJECTION_TYPE.KEYS_ONLY }

      ]}
      labelText={'Attribute Projections:'}
      handleValueChange={(e) => {
        attributeProjectionsHandler(e, gsi)
      }}/>
    <Button classname={'mt-[28px]'} iconLeft={<TrashBinIcon/>} onClick={ () => { confirmGSIDelete(gsi) }} variant={'darker'} title={'Delete Row'} type={'button'}/>
  </div>)
}
