import React, { FC } from 'react'

interface ModalProps {
  open: boolean
  modalText: string
}

const LoadingModal: FC<ModalProps> = (props: ModalProps) => {
  return (
    <>
      {
          props.open && <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen ">
                <div className="fixed inset-0 transition-opacity bg-[#020409] bg-opacity-80"></div>
                <div className="bg-[#171B21] rounded-lg overflow-hidden  transform transition-all max-w-[597px] ">
                  <div className="text-2xl mx-6 my-6 font-bold text-white text-center">
                    May the code be with you!
                  </div>
                  <div className={'border-t-[1px] pt-4 pb-10 px-6 flex flex-col items-center gap-5 border-[#020409] text-sm text-[#7E858F]'}>
                    <img src="/assets/images/loader.gif" alt="Loader" className="h-[81px] w-[68px] object-contain" />
                    <div>{props.modalText}</div>
                  </div>
                </div>
            </div>
        </div>
        }

    </>
  )
}
export default LoadingModal
