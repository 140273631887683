import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { CreateRdsInput, Rds, UpdateRdsInput } from '../../API'
import { createRds, deleteRds, updateRds as updateRdsMutation } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const addRds = async (input: CreateRdsInput) => {
  const addRdsResult = (await API.graphql(
    graphqlOperation(createRds, { input })
  )) as GraphQLResult<{ createRds: Rds }>
  const newRds = (addRdsResult?.data?.createRds)
  return newRds
}

export const updateRds = async (input: UpdateRdsInput) => {
  const updateRdsResult = (await API.graphql(
    graphqlOperation(updateRdsMutation, { input: { ...input } })
  )) as GraphQLResult<{ updateRds: Rds }>
  const updatedRds = (updateRdsResult?.data?.updateRds)
  return updatedRds
}

export const removeRds = async (id: string, serviceId: string) => {
  const removeRdsResult = (await API.graphql(
    graphqlOperation(deleteRds, { input: { id, serviceId } })
  )) as GraphQLResult<{ removeRds: Rds }>
  const deletedRds = (removeRdsResult?.data?.removeRds)
  return deletedRds
}

export const updateTeam = async (id: string, name: string) => {
  const updateRdsResult = (await API.graphql(
    graphqlOperation(updateRdsMutation, { input: { id, name } })
  )) as GraphQLResult<{ updateTeam: { Team: Rds } }>
  const updatedRds = (updateRdsResult?.data?.updateTeam?.Team)
  return updatedRds
}
