import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { UpdateUserProfileInput } from '../../API'
import { updateUserProfile } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const updateProfile = async (input: UpdateUserProfileInput) => {
  const updateProfileResult = (await API.graphql(
    graphqlOperation(updateUserProfile, { input: { ...input } })
  )) as GraphQLResult<{ updateUserProfile: any }>
  return updateProfileResult?.data?.updateUserProfile
}
