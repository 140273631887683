import React, { FC, useContext, useEffect, useState } from 'react'
import {
  manyRoutesBluePrint,
  monolithicBluePrint,
  perRouteBluePrint
} from 'src/components/organisms/service-builder/blueprints/lambda'
import { OptionBluePrint, UseCase } from 'src/API'
import { blueprintList } from 'src/constants/defineService'
import ApplicationContext from 'src/store/application-context-provider'
import {
  multipleDataSourceBluePrint,
  singleDataSourceBluePrint,
  singleTableDesignBluePrint
} from 'src/components/organisms/service-builder/blueprints/appSync'
import { BlueprintCard } from 'src/components/molecules/blueprint-card/BlueprintCard'
import { ButtonTab } from 'src/components/atoms/button-tab'
import { ReactComponent as MultipleIcon } from 'src/assets/diagrams/multiple-data-sources-big.svg'
import { ReactComponent as SingleDataSourceIcon } from 'src/assets/diagrams/single-data-sources-big.svg'
import { ReactComponent as MonolithicLambdaIcon } from 'src/assets/diagrams/monolithic-lambda-big.svg'
import { ReactComponent as SingleLambdaManyRoutesIcon } from 'src/assets/diagrams/single-lambda-many-routes-big.svg'
import { ReactComponent as SingleLambdaPerRouteIcon } from 'src/assets/diagrams/single-lambda-per-route-big.svg'
import { ReactComponent as BYOSchemaIcon } from 'src/assets/diagrams/BYOSchema-big.svg'
import { ReactComponent as CustomIcon } from 'src/assets/diagrams/custom-big.svg'

const DefineBlueprint: FC = () => {
  const ctx = useContext(ApplicationContext)

  const [selectedBlueprint, setSelectedBlueprint] = useState('')
  const [chatGPT, setChatGPT] = useState(ctx?.serviceBuilder?.config?.chatgpt ?? false)
  const [useCase, setUseCase] = useState<UseCase>(ctx?.serviceBuilder?.config?.useCase ?? UseCase.APIGW)

  useEffect(() => {
    setUseCase(ctx?.serviceBuilder?.config?.useCase ?? UseCase.APIGW)
  }, [])

  useEffect(() => {
    const service = ctx?.serviceBuilder
    const config = {
      ...service.config,
      useCase
    }
    ctx?.setServiceBuilder({
      ...service,
      config
    })
  }, [useCase])

  useEffect(() => {
    if (useCase === UseCase.APIGW) {
      handleBluePrintChange(ctx?.isServiceEdit ? ctx?.serviceBuilder?.optionBluePrint : OptionBluePrint.LAMBDA_PER_ROUTE)
    } else {
      handleBluePrintChange(ctx?.isServiceEdit ? ctx?.serviceBuilder?.optionBluePrint : OptionBluePrint.SINGLE_DATA_SOURCE)
    }
    setChatGPT(ctx?.serviceBuilder?.config?.chatgpt ?? false)
  }, [ctx?.serviceBuilder?.config?.useCase, useCase])

  useEffect(() => {
    const service = ctx?.serviceBuilder || {}
    let routeList: any[] = []
    let resourceList: any[] = []
    let resolverList: any[] = []
    let modelList: any[] = []
    let enumList: any = []
    let customObjectList: any = []
    let subscriptionList: any = []
    const enableChatGpt = [OptionBluePrint.LAMBDA_PER_ROUTE, OptionBluePrint.LAMBDA_MANY_ROUTES, OptionBluePrint.MONOLITHIC_LAMBDA].includes(selectedBlueprint as OptionBluePrint)
    const config = {
      ...service.config,
      chatgpt: enableChatGpt ? chatGPT : false
    }
    switch (selectedBlueprint) {
      case OptionBluePrint.MONOLITHIC_LAMBDA as OptionBluePrint:
        const {
          resources,
          routes
        } = monolithicBluePrint()
        resourceList = resources
        routeList = routes
        config.appSyncDetails = {}
        service.optionBluePrint = OptionBluePrint.MONOLITHIC_LAMBDA
        break
      case OptionBluePrint.LAMBDA_MANY_ROUTES as OptionBluePrint:
        const {
          resources: res,
          routes: rt
        } = manyRoutesBluePrint()
        config.appSyncDetails = {}
        resourceList = res
        routeList = rt
        service.optionBluePrint = OptionBluePrint.LAMBDA_MANY_ROUTES
        break
      case OptionBluePrint.LAMBDA_PER_ROUTE as OptionBluePrint:
        const {
          resources: res2,
          routes: rt2
        } = perRouteBluePrint()
        config.appSyncDetails = {}
        resourceList = res2
        routeList = rt2
        service.optionBluePrint = OptionBluePrint.LAMBDA_PER_ROUTE
        break
      case OptionBluePrint.NONE as OptionBluePrint:
        enumList = []
        subscriptionList = []
        customObjectList = []
        routeList = []
        resourceList = []
        service.optionBluePrint = OptionBluePrint.NONE
        break
      case OptionBluePrint.SINGLE_DATA_SOURCE:
        const { resources: res4, resolvers: rslv1, models: mod } = singleDataSourceBluePrint()
        enumList = []
        subscriptionList = []
        customObjectList = []
        routeList = []
        resourceList = res4
        resolverList = rslv1
        modelList = mod
        service.optionBluePrint = OptionBluePrint.SINGLE_DATA_SOURCE
        ctx?.setSchemaFileResponse({})
        break
      case OptionBluePrint.MULTI_DATA_SOURCE:
        const { resources: multipleResources, resolvers: multipleResolvers, models: multipleModels } = multipleDataSourceBluePrint()
        enumList = []
        customObjectList = []
        subscriptionList = []
        routeList = []
        resourceList = multipleResources
        resolverList = multipleResolvers
        modelList = multipleModels
        service.optionBluePrint = OptionBluePrint.MULTI_DATA_SOURCE
        ctx?.setSchemaFileResponse({})
        break
      case OptionBluePrint.SINGLE_TABLE_DESIGN:
        const { resources: singleTableResources, resolvers: singleTableResolvers, models: singleTableModels } = singleTableDesignBluePrint()
        enumList = []
        subscriptionList = []
        customObjectList = []
        routeList = []
        resourceList = singleTableResources
        resolverList = singleTableResolvers
        modelList = singleTableModels
        service.triggers = []
        service.optionBluePrint = OptionBluePrint.SINGLE_TABLE_DESIGN
        ctx?.setSchemaFileResponse({})
        break
      case OptionBluePrint.BYOSCHEMA:
        routeList = []
        resourceList = []
        resolverList = []
        modelList = []
        service.triggers = []
        service.optionBluePrint = OptionBluePrint.BYOSCHEMA
        break
      default:
        service.optionBluePrint = OptionBluePrint.NONE
        break
    }
    service.enums = ctx?.isServiceEdit ? service.enums : enumList
    service.subscriptions = ctx?.isServiceEdit ? service.subscriptions : subscriptionList
    service.customObjects = ctx?.isServiceEdit ? service.customObjects : customObjectList
    service.routes = ctx?.isServiceEdit ? service.routes : routeList
    service.resources = ctx?.isServiceEdit ? service?.resources : resourceList
    service.resolvers = ctx?.isServiceEdit ? service.resolvers : resolverList
    service.models = ctx?.isServiceEdit ? service.models : modelList
    ctx?.setRouteSearch(service.routes)
    ctx?.setServiceBuilder({ ...service, config })
    console.log('useEffect service', ctx?.serviceBuilder)
  }, [selectedBlueprint, chatGPT])

  const handleBluePrintChange = (blueprint: OptionBluePrint) => {
    setSelectedBlueprint(blueprint)
  }

  const handleChatGPTChange = (chatGPT: boolean) => {
    setChatGPT(chatGPT)
  }
  return (<form>
    <div className={'bg-[#020409] text-white flex justify-center'}>
      <div className={'flex flex-col gap-4'}>
        <div className={'font-bold text-2xl'}>Quick Start</div>
        <div className="lg:flex lg:flex-row lg:gap-8  md:flex-col md:gap-2  ">
          <div className="flex flex-col gap-4 max-w-[400px]">
            <div>
              <div className="flex">
                <ButtonTab disabled={ctx?.isServiceEdit} variant={'darker'} isActive={useCase === UseCase.APIGW} onClick={() => { setUseCase(UseCase.APIGW) }} className={'w-full'} type={'button'} title={'REST API'}/>
                <ButtonTab disabled={ctx?.isServiceEdit} variant={'darker'} isActive={useCase === UseCase.APPSYNC} onClick={() => { setUseCase(UseCase.APPSYNC) }} className={'w-full'} type={'button'} title={'GraphQL API'}/>
              </div>
            </div>
            {blueprintList.filter((f) => f.value !== OptionBluePrint.NONE && f.useCase === useCase).map((bl) =>
              <BlueprintCard
                disabled={ctx?.isServiceEdit ?? false}
                chatGPT={chatGPT}
                handleChatGPTChange={handleChatGPTChange}
              selectedBlueprint={selectedBlueprint}
              handleBluePrintChange={handleBluePrintChange} key={bl.title}
              blueprint={bl}/>)}
            {/* {ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW && <div className={'font-bold text-lg pt-[4px]'}>Custom</div>} */}
            {ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW && blueprintList.filter((f) => f.value === OptionBluePrint.NONE).map((bl) => <BlueprintCard
              disabled={ctx?.isServiceEdit ?? false}
              handleChatGPTChange={handleChatGPTChange}
              chatGPT={chatGPT}
              selectedBlueprint={selectedBlueprint}
              handleBluePrintChange={handleBluePrintChange} key={bl.title}
              blueprint={bl}/>)}
          </div>
          <div className="mt-14">
            {blueprintList.filter((v) => v.value === selectedBlueprint).map((b) => <div className={'flex flex-col gap-[20px]'} key={b.value}>
            <div className={'font-bold text-lg'}>{b.title}</div>
              <div>
                {b.value === OptionBluePrint.MULTI_DATA_SOURCE && <MultipleIcon/>}
                {b.value === OptionBluePrint.SINGLE_DATA_SOURCE && <SingleDataSourceIcon/>}
                {b.value === OptionBluePrint.SINGLE_TABLE_DESIGN && <SingleDataSourceIcon/>}
                {b.value === OptionBluePrint.MONOLITHIC_LAMBDA && <MonolithicLambdaIcon/>}
                {b.value === OptionBluePrint.LAMBDA_MANY_ROUTES && <SingleLambdaManyRoutesIcon/>}
                {b.value === OptionBluePrint.LAMBDA_PER_ROUTE && <SingleLambdaPerRouteIcon/>}
                {b.value === OptionBluePrint.BYOSCHEMA && <BYOSchemaIcon/>}
                {b.value === OptionBluePrint.NONE && <CustomIcon/>}
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </div>

  </form>)
}

export default DefineBlueprint
