import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import { TextInput } from 'src/components/atoms/text-input'
import ApplicationContext from 'src/store/application-context-provider'
import { Button } from 'src/components/atoms/button'
import { Alert } from 'src/components/atoms/alert'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp-gray.svg'
import { checkEmptyString } from 'src/utils/validation'

interface FormEnum {
  enumName: string
  description: string
}

const EnumForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props

  const ctx = useContext(ApplicationContext)
  const [fields, setFields] = useState<string []>([])
  const [isFieldAlert, setIsFieldAlert] = useState(false)
  const schema = yup.object({
    enumName: yup
      .string()
      .required('This field is required')
      .max(25)

  })

  const {
    control,
    handleSubmit,
    register,
    formState: {
      errors,
      isValid
    },
    reset

  } = useForm<FormEnum>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      enumName: '',
      description: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      enumName: ctx?.enumInput?.name
    })
  }, [reset])

  useEffect(() => {
    setFields(ctx?.serviceBuilder?.enums?.find((c: any) => c.id === ctx?.enumInput?.id)?.fields ?? [])
  }, [])

  const onSubmit = (data: FormEnum) => {
    const input = ctx?.enumInput
    input.name = data.enumName
    input.fields = fields
    ctx?.setEnumInput(input)
    submit()
  }

  const handleClose = (event: Event | any) => {
    close()
  }
  const fieldNameHandler = (e: Event | any, currentIndex: number) => {
    if (fields?.includes(e.target.value)) {
      setIsFieldAlert(true)
      const editData = fields.map((f, index: number) =>
        index === currentIndex ? e.target.value : f
      )
      setFields(editData)
    } else {
      const editData = fields.map((f, index: number) =>
        index === currentIndex ? e.target.value : f
      )
      setFields(editData)
      setIsFieldAlert(false)
    }
  }
  return (
    <div>
      <div className={'border-b-[1px] border-[#020409]'}></div>
      <div className={'flex flex-col py-1 mx-6 gap-4'}>
        <Controller
          name={'enumName'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('enumName', { maxLength: 25 })}
            required
            className={'w-full'}
            labelText={'Name'}
            showRequired
            maxLength={25}
            error={errors?.enumName?.message}
            id="resourceName"
            onChange={onChange}
            onBlur={onBlur}
            value={value}/>)}
        />
        <Controller
          name={'description'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('description', { maxLength: 65 })}
            required
            className={'w-full'}
            labelText={'Description'}
            showRequired
            maxLength={25}
            error={errors?.description?.message}
            id="resourceName"
            onChange={onChange}
            onBlur={onBlur}
            value={value}/>)}
        />
        <div className={'flex justify-between mt-8 gap-40'}>
          <div className={'text-2xl text-white w-8/12'}>Enum Fields</div>
          <Button classname={'w-4/12'} iconLeft={<AddIcon/>} variant={'darker'} title={'Add Field'} type={'button'} compact onClick={() => {
            setFields([...fields, ''])
          }}/>
        </div>
        {isFieldAlert &&
          <Alert variant={'error'}>This field
            name is already in use, please choose a unique name.</Alert>}
        {fields?.map((row: any, index) => <TextInput key={index}
                                              required
                                              error={row === ''}
                                              value={row}
                                              onChange={(e) => {
                                                fieldNameHandler(e, index)
                                              }}
                                              labelText={'Name:'}
                                              showRequired
                                              id="resourceName"
        />)}
      </div>
      <ResourceFormControls isDisabledSaveButton={!isValid || isFieldAlert || fields.length === 0 || checkEmptyString(fields)} onSubmit={handleSubmit(onSubmit)} onClose={handleClose}/>
    </div>)
}

export default EnumForm
