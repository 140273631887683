import { Auth } from 'aws-amplify'
interface IUser {
  username: string
  password: string
}

interface IAuthenticatedUser {
  id: string
  email: string
  idToken: string
  accessToken: string
  isReset?: boolean
}

const login = async (user: IUser) => {
  const data = await Auth.signIn(user)
  const { challengeName } = data
  if (challengeName === 'NEW_PASSWORD_REQUIRED') {
    return data
  }
  const authenticatedUser: IAuthenticatedUser = {
    id: data.attributes?.sub,
    email: data.attributes?.email,
    idToken: data.signInUserSession?.idToken?.jwtToken,
    accessToken: data.signInUserSession?.accessToken?.jwtToken
  }
  return authenticatedUser
}

const signUp = async (user: { email: string, name: string, firstName: string, lastName: string }) => {
  const result = await fetch(`${process.env.REACT_APP_API}/user/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  })
  const data = await result.json()
  return data
}

const logout = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    console.log(error)
  }
}

const resetPassword = async (email: string) => {
  const data = await Auth.forgotPassword(email)
  return data
}

export type { IUser, IAuthenticatedUser }

export { login, logout, signUp, resetPassword }
