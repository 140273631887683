import { Menu, Transition } from '@headlessui/react'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up.svg'
import { ReactComponent as CreateIcon } from 'src/assets/icons/create.svg'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import React, { Fragment } from 'react'
import { classNames } from 'src/utils/css'

export const ServiceCardEnumObject: React.FC<{ error?: boolean, card: any, handleRemoveCard: (card: any) => void, handleEditCard: (card: any) => void, type?: 'enum' | 'object' | 'subscription' }> = props => {
  const { card, handleEditCard, handleRemoveCard, error = false, type = 'enum' } = props

  return (
    <div className="box-border">
      <div className="w-full h-full flex flex-col gap-[16px] p-[16px] text-white rounded-lg bg-[#020409]  border-t-[1px] border-[#826AED]">
        <div className={'flex items-center justify-end '} >
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button
                    className={classNames(open ? 'bg-[#020409] rounded-t ' : 'bg-[#171B21] rounded', 'text-white px-2 py-[4px] border-[1px] border-[#7E858F] inline-flex  gap-[3px] w-full justify-evenly items-center  text-[10px] font-normal focus:outline-none ')}
                  >
                    Actions
                    { open
                      ? <ArrowUpIcon />
                      : <ArrowDownIcon />
                    }
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className={classNames('absolute w-24 right-0 z-10  m-66 origin-top-right focus:outline-none')}
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => { handleEditCard(card) }}
                          className={classNames(
                            active ? 'bg-blue-150' : '',
                            'bg-[#020409] flex justify-between  border-[1px] border-[#7E858F] px-2 py-2 text-[10px]  cursor-pointer'

                          )}
                        >
                          <span>Edit</span>
                          <CreateIcon/>
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          onClick={() => { handleRemoveCard(card) }}
                          tabIndex={1}
                          className={classNames(
                            active ? 'bg-blue-150' : '',
                            'bg-[#020409] flex justify-between rounded-b border-[1px] border-[#7E858F] px-2 py-2 text-[10px]  cursor-pointer'
                          )}
                        >
                          <span>Delete</span>
                          <TrashBinIcon/>
                        </div>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
        <div className={'flex items-center gap-[10px]'}>
          {type !== 'subscription' && <div className={classNames('border-[1px] px-2 py-[2px]  rounded border-[#7E858F]  text-xs')}>{type === 'enum' ? 'Enum' : 'Object'}</div>}
          <div className={classNames('truncate text-sm font-bold ', error ? 'text-[#FF3F3F]' : '')}>{card.name}</div>
        </div>
      </div>
    </div>
  )
}
