import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { CreateVpcInput, UpdateVpcInput, Vpc } from '../../API'
import { createVpc, deleteVpc, updateVpc as updateVpcMutation } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const addVpc = async (input: CreateVpcInput) => {
  const addVpcResult = (await API.graphql(graphqlOperation(createVpc, { input }))) as GraphQLResult<{ createVpc: Vpc }>
  const newVpc = (addVpcResult?.data?.createVpc)
  return newVpc
}

export const updateVpc = async (input: UpdateVpcInput) => {
  const updateVpcResult = (await API.graphql(graphqlOperation(updateVpcMutation, { input: { ...input } }))) as GraphQLResult<{ updateVpc: Vpc }>
  const updatedVpc = (updateVpcResult?.data?.updateVpc)
  return updatedVpc
}

export const removeVpc = async (id: string, serviceId: string) => {
  const removeVpcResult = (await API.graphql(graphqlOperation(deleteVpc, {
    input: {
      id,
      serviceId
    }
  }))) as GraphQLResult<{ removeVpc: Vpc }>
  const deletedVpc = (removeVpcResult?.data?.removeVpc)
  return deletedVpc
}

export const updateTeam = async (id: string, name: string) => {
  const updateVpcResult = (await API.graphql(graphqlOperation(updateVpcMutation, {
    input: {
      id,
      name
    }
  }))) as GraphQLResult<{ updateTeam: { Team: Vpc } }>
  const updatedVpc = (updateVpcResult?.data?.updateTeam?.Team)
  return updatedVpc
}
