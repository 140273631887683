import React from 'react'
import { AboutOrganization } from 'src/components/organisms/organization-settings/about-organization/AboutOrganization'
import YourOrganization from 'src/components/organisms/organization-settings/your-organization/YourOrganization'
// import YourTeams from 'src/components/organisms/organization-settings/your-teams/YourTeams'

const OrganizationSettings: React.FC = (props) => {
  return (
    <div className={'w-full flex flex-col gap-4 pt-11 pb-11 pr-6 pl-6 text-white'}>
      <AboutOrganization/>
      <YourOrganization/>
      {/* <YourTeams/> */}
    </div>
  )
}

export default OrganizationSettings
