/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCognito = /* GraphQL */ `mutation CreateCognito($input: CreateCognitoInput!) {
  createCognito(input: $input) {
    id
    serviceId
    user
    name
    description
    resourceType
    tags
    cognitoType
    cognitoTestEmail
    cognitoExisting
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCognitoMutationVariables,
  APITypes.CreateCognitoMutation
>;
export const createDynamo = /* GraphQL */ `mutation CreateDynamo($input: CreateDynamoInput!) {
  createDynamo(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    billingMode
    readCapacity
    writeCapacity
    keyType
    primaryKeyName
    primaryKeyType
    secondaryKeyName
    secondaryKeyType
    hasIndex
    GSI {
      gsiId
      gsiName
      gsiKeyType
      primaryAttribute
      primaryAttributeType
      secondaryAttribute
      secondaryAttributeType
      projectionType
      __typename
    }
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDynamoMutationVariables,
  APITypes.CreateDynamoMutation
>;
export const createLambda = /* GraphQL */ `mutation CreateLambda($input: CreateLambdaInput!) {
  createLambda(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    runtime
    memory
    timeout
    prune
    tracing
    vpc
    webpack
    connection {
      id
      resourceId
      permissions {
        create
        read
        update
        delete
        scan
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    trigger {
      id
      serviceId
      resourceId
      user
      name
      description
      type
      private
      tags
      updatedAt
      createdAt
      eventTypes {
        create
        delete
        __typename
      }
      typename
      __typename
    }
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLambdaMutationVariables,
  APITypes.CreateLambdaMutation
>;
export const createModel = /* GraphQL */ `mutation CreateModel($input: CreateModelInput!) {
  createModel(input: $input) {
    id
    serviceId
    user
    name
    description
    dataSource
    fields
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateModelMutationVariables,
  APITypes.CreateModelMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    id
    name
    description
    tags
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const createOrganizationUser = /* GraphQL */ `mutation CreateOrganizationUser($input: CreateOrganizationUserInput!) {
  createOrganizationUser(input: $input) {
    id
    email
    organization {
      id
      name
      description
      tags
      updatedAt
      createdAt
      __typename
    }
    userProfile {
      id
      email
      firstName
      lastName
      bio
      avatar
      updatedAt
      createdAt
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationUserMutationVariables,
  APITypes.CreateOrganizationUserMutation
>;
export const createRds = /* GraphQL */ `mutation CreateRds($input: CreateRdsInput!) {
  createRds(input: $input) {
    id
    serviceId
    user
    name
    description
    vpcName
    vpcExisting
    tags
    rdsEngine
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRdsMutationVariables,
  APITypes.CreateRdsMutation
>;
export const createResolver = /* GraphQL */ `mutation CreateResolver($input: CreateResolverInput!) {
  createResolver(input: $input) {
    id
    serviceId
    user
    name
    description
    model
    dataSource
    type
    resolverType
    isPipeline
    resolveWith_PK
    resolveWith_SK
    resolveFrom
    queryFrom
    modelFieldId
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateResolverMutationVariables,
  APITypes.CreateResolverMutation
>;
export const createResourceConnection = /* GraphQL */ `mutation CreateResourceConnection($input: CreateResourceConnectionInput!) {
  createResourceConnection(input: $input) {
    id
    resourceId
    permissions {
      create
      read
      update
      delete
      scan
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateResourceConnectionMutationVariables,
  APITypes.CreateResourceConnectionMutation
>;
export const createRoute = /* GraphQL */ `mutation CreateRoute($input: CreateRouteInput!) {
  createRoute(input: $input) {
    id
    serviceId
    authId
    auth {
      id
      serviceId
      user
      name
      description
      resourceType
      tags
      cognitoType
      cognitoTestEmail
      cognitoExisting
      updatedAt
      createdAt
      typename
      __typename
    }
    resourceId
    resource {
      ... on Cognito {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
      }
      ... on Dynamo {
        id
        serviceId
        user
        name
        description
        tags
        billingMode
        readCapacity
        writeCapacity
        keyType
        primaryKeyName
        primaryKeyType
        secondaryKeyName
        secondaryKeyType
        hasIndex
        GSI {
          gsiId
          gsiName
          gsiKeyType
          primaryAttribute
          primaryAttributeType
          secondaryAttribute
          secondaryAttributeType
          projectionType
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Lambda {
        id
        serviceId
        user
        name
        description
        tags
        runtime
        memory
        timeout
        prune
        tracing
        vpc
        webpack
        connection {
          id
          resourceId
          permissions {
            create
            read
            update
            delete
            scan
            __typename
          }
          updatedAt
          createdAt
          __typename
        }
        trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Rds {
        id
        serviceId
        user
        name
        description
        vpcName
        vpcExisting
        tags
        rdsEngine
        updatedAt
        createdAt
        typename
      }
      ... on S3 {
        id
        serviceId
        user
        name
        description
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Sns {
        id
        serviceId
        user
        name
        description
        tags
        topicType
        updatedAt
        createdAt
        typename
      }
      ... on Sqs {
        id
        serviceId
        user
        name
        description
        tags
        hasDlq
        topicType
        visibilityTimeout
        deliveryDelay
        msgRetentionPeriod
        receiveMsgWaitTimeSecs
        maxMsgSize
        updatedAt
        createdAt
        typename
      }
      ... on Trigger {
        id
        serviceId
        resourceId
        user
        name
        description
        type
        private
        tags
        updatedAt
        createdAt
        eventTypes {
          create
          delete
          __typename
        }
        typename
      }
      ... on Vpc {
        id
        serviceId
        user
        name
        description
        tags
        vpcExisting
        vpcExistingId
        vpcExistingSubnetA
        vpcExistingSubnetB
        vpcExistingSubnetC
        vpcExistingSecurityGroupId
        updatedAt
        createdAt
        typename
      }
      ... on Resolver {
        id
        serviceId
        user
        name
        description
        model
        dataSource
        type
        resolverType
        isPipeline
        resolveWith_PK
        resolveWith_SK
        resolveFrom
        queryFrom
        modelFieldId
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Model {
        id
        serviceId
        user
        name
        description
        dataSource
        fields
        tags
        updatedAt
        createdAt
        typename
      }
    }
    user
    name
    description
    cors
    method
    path
    type
    private
    tags
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRouteMutationVariables,
  APITypes.CreateRouteMutation
>;
export const createS3 = /* GraphQL */ `mutation CreateS3($input: CreateS3Input!) {
  createS3(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateS3MutationVariables,
  APITypes.CreateS3Mutation
>;
export const createService = /* GraphQL */ `mutation CreateService($input: CreateServiceInput!) {
  createService(input: $input) {
    id
    name
    description
    tags
    cloudProvider
    cloudProviderRegion
    stage
    iacFramework
    useCase
    status
    optionBluePrint
    appsyncName
    appsyncDescription
    authType
    logging
    caching
    xRay
    webpack
    prune
    chatgpt
    operatingSystem
    organization {
      id
      name
      description
      tags
      updatedAt
      createdAt
      __typename
    }
    allowedTeams {
      id
      name
      description
      updatedAt
      createdAt
      __typename
    }
    resources {
      ... on Cognito {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
      }
      ... on Dynamo {
        id
        serviceId
        user
        name
        description
        tags
        billingMode
        readCapacity
        writeCapacity
        keyType
        primaryKeyName
        primaryKeyType
        secondaryKeyName
        secondaryKeyType
        hasIndex
        GSI {
          gsiId
          gsiName
          gsiKeyType
          primaryAttribute
          primaryAttributeType
          secondaryAttribute
          secondaryAttributeType
          projectionType
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Lambda {
        id
        serviceId
        user
        name
        description
        tags
        runtime
        memory
        timeout
        prune
        tracing
        vpc
        webpack
        connection {
          id
          resourceId
          permissions {
            create
            read
            update
            delete
            scan
            __typename
          }
          updatedAt
          createdAt
          __typename
        }
        trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Rds {
        id
        serviceId
        user
        name
        description
        vpcName
        vpcExisting
        tags
        rdsEngine
        updatedAt
        createdAt
        typename
      }
      ... on S3 {
        id
        serviceId
        user
        name
        description
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Sns {
        id
        serviceId
        user
        name
        description
        tags
        topicType
        updatedAt
        createdAt
        typename
      }
      ... on Sqs {
        id
        serviceId
        user
        name
        description
        tags
        hasDlq
        topicType
        visibilityTimeout
        deliveryDelay
        msgRetentionPeriod
        receiveMsgWaitTimeSecs
        maxMsgSize
        updatedAt
        createdAt
        typename
      }
      ... on Trigger {
        id
        serviceId
        resourceId
        user
        name
        description
        type
        private
        tags
        updatedAt
        createdAt
        eventTypes {
          create
          delete
          __typename
        }
        typename
      }
      ... on Vpc {
        id
        serviceId
        user
        name
        description
        tags
        vpcExisting
        vpcExistingId
        vpcExistingSubnetA
        vpcExistingSubnetB
        vpcExistingSubnetC
        vpcExistingSecurityGroupId
        updatedAt
        createdAt
        typename
      }
      ... on Resolver {
        id
        serviceId
        user
        name
        description
        model
        dataSource
        type
        resolverType
        isPipeline
        resolveWith_PK
        resolveWith_SK
        resolveFrom
        queryFrom
        modelFieldId
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Model {
        id
        serviceId
        user
        name
        description
        dataSource
        fields
        tags
        updatedAt
        createdAt
        typename
      }
    }
    routes {
      id
      serviceId
      authId
      auth {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
        __typename
      }
      resourceId
      resource {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      user
      name
      description
      cors
      method
      path
      type
      private
      tags
      updatedAt
      createdAt
      __typename
    }
    stars {
      ServiceStars {
        id
        updatedAt
        createdAt
        __typename
      }
      nextToken
      total
      __typename
    }
    isServiceOwner
    enums {
      id
      name
      serviceId
      fields
      description
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    subscriptions {
      id
      name
      serviceId
      description
      args {
        name
        type
        required
        __typename
      }
      type
      array
      required
      derivative {
        name
        mutations
        __typename
      }
      tags
      createdAt
      updatedAt
      typename
      __typename
    }
    customObjects {
      id
      serviceId
      name
      description
      fields {
        name
        fieldType
        type
        required
        array
        __typename
      }
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceMutationVariables,
  APITypes.CreateServiceMutation
>;
export const createServiceStar = /* GraphQL */ `mutation CreateServiceStar($input: CreateServiceStarInput!) {
  createServiceStar(input: $input) {
    id
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceStarMutationVariables,
  APITypes.CreateServiceStarMutation
>;
export const createSns = /* GraphQL */ `mutation CreateSns($input: CreateSnsInput!) {
  createSns(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    topicType
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSnsMutationVariables,
  APITypes.CreateSnsMutation
>;
export const createSqs = /* GraphQL */ `mutation CreateSqs($input: CreateSqsInput!) {
  createSqs(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    hasDlq
    topicType
    visibilityTimeout
    deliveryDelay
    msgRetentionPeriod
    receiveMsgWaitTimeSecs
    maxMsgSize
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSqsMutationVariables,
  APITypes.CreateSqsMutation
>;
export const createTeam = /* GraphQL */ `mutation CreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    id
    name
    description
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMutationVariables,
  APITypes.CreateTeamMutation
>;
export const createTeamService = /* GraphQL */ `mutation CreateTeamService($input: CreateTeamServiceInput!) {
  createTeamService(input: $input) {
    id
    teamId
    serviceId
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamServiceMutationVariables,
  APITypes.CreateTeamServiceMutation
>;
export const createTeamUser = /* GraphQL */ `mutation CreateTeamUser($input: CreateTeamUserInput!) {
  createTeamUser(input: $input) {
    id
    userId
    team {
      id
      name
      description
      updatedAt
      createdAt
      __typename
    }
    email
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamUserMutationVariables,
  APITypes.CreateTeamUserMutation
>;
export const createTrigger = /* GraphQL */ `mutation CreateTrigger($input: CreateTriggerInput!) {
  createTrigger(input: $input) {
    id
    serviceId
    resourceId
    user
    name
    description
    type
    private
    tags
    updatedAt
    createdAt
    eventTypes {
      create
      delete
      __typename
    }
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTriggerMutationVariables,
  APITypes.CreateTriggerMutation
>;
export const createUserProfile = /* GraphQL */ `mutation CreateUserProfile($input: CreateUserProfileInput!) {
  createUserProfile(input: $input) {
    id
    email
    firstName
    lastName
    bio
    avatar
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProfileMutationVariables,
  APITypes.CreateUserProfileMutation
>;
export const createVpc = /* GraphQL */ `mutation CreateVpc($input: CreateVpcInput!) {
  createVpc(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    vpcExisting
    vpcExistingId
    vpcExistingSubnetA
    vpcExistingSubnetB
    vpcExistingSubnetC
    vpcExistingSecurityGroupId
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVpcMutationVariables,
  APITypes.CreateVpcMutation
>;
export const deleteCognito = /* GraphQL */ `mutation DeleteCognito($input: DeleteCognitoInput!) {
  deleteCognito(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteCognitoMutationVariables,
  APITypes.DeleteCognitoMutation
>;
export const deleteDynamo = /* GraphQL */ `mutation DeleteDynamo($input: DeleteDynamoInput!) {
  deleteDynamo(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteDynamoMutationVariables,
  APITypes.DeleteDynamoMutation
>;
export const deleteLambda = /* GraphQL */ `mutation DeleteLambda($input: DeleteLambdaInput!) {
  deleteLambda(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteLambdaMutationVariables,
  APITypes.DeleteLambdaMutation
>;
export const deleteModel = /* GraphQL */ `mutation DeleteModel($input: DeleteModelInput!) {
  deleteModel(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteModelMutationVariables,
  APITypes.DeleteModelMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization($input: DeleteOrganizationInput!) {
  deleteOrganization(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const deleteOrganizationUser = /* GraphQL */ `mutation DeleteOrganizationUser($input: DeleteOrganizationUserInput!) {
  deleteOrganizationUser(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationUserMutationVariables,
  APITypes.DeleteOrganizationUserMutation
>;
export const deleteRds = /* GraphQL */ `mutation DeleteRds($input: DeleteRdsInput!) {
  deleteRds(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteRdsMutationVariables,
  APITypes.DeleteRdsMutation
>;
export const deleteResolver = /* GraphQL */ `mutation DeleteResolver($input: DeleteResolverInput!) {
  deleteResolver(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteResolverMutationVariables,
  APITypes.DeleteResolverMutation
>;
export const deleteResourceConnection = /* GraphQL */ `mutation DeleteResourceConnection($input: DeleteResourceConnectionInput!) {
  deleteResourceConnection(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteResourceConnectionMutationVariables,
  APITypes.DeleteResourceConnectionMutation
>;
export const deleteRoute = /* GraphQL */ `mutation DeleteRoute($input: DeleteRouteInput!) {
  deleteRoute(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteRouteMutationVariables,
  APITypes.DeleteRouteMutation
>;
export const deleteS3 = /* GraphQL */ `mutation DeleteS3($input: DeleteS3Input!) {
  deleteS3(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteS3MutationVariables,
  APITypes.DeleteS3Mutation
>;
export const deleteService = /* GraphQL */ `mutation DeleteService($input: DeleteServiceInput!) {
  deleteService(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteServiceMutationVariables,
  APITypes.DeleteServiceMutation
>;
export const deleteServiceStar = /* GraphQL */ `mutation DeleteServiceStar($input: DeleteServiceStarInput!) {
  deleteServiceStar(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteServiceStarMutationVariables,
  APITypes.DeleteServiceStarMutation
>;
export const deleteSns = /* GraphQL */ `mutation DeleteSns($input: DeleteSnsInput!) {
  deleteSns(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteSnsMutationVariables,
  APITypes.DeleteSnsMutation
>;
export const deleteSqs = /* GraphQL */ `mutation DeleteSqs($input: DeleteSqsInput!) {
  deleteSqs(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteSqsMutationVariables,
  APITypes.DeleteSqsMutation
>;
export const deleteTeam = /* GraphQL */ `mutation DeleteTeam($input: DeleteTeamInput!) {
  deleteTeam(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteTeamMutationVariables,
  APITypes.DeleteTeamMutation
>;
export const deleteTeamService = /* GraphQL */ `mutation DeleteTeamService($input: DeleteTeamServiceInput!) {
  deleteTeamService(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteTeamServiceMutationVariables,
  APITypes.DeleteTeamServiceMutation
>;
export const deleteTeamUser = /* GraphQL */ `mutation DeleteTeamUser($input: DeleteTeamUserInput!) {
  deleteTeamUser(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteTeamUserMutationVariables,
  APITypes.DeleteTeamUserMutation
>;
export const deleteTrigger = /* GraphQL */ `mutation DeleteTrigger($input: DeleteTriggerInput!) {
  deleteTrigger(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteTriggerMutationVariables,
  APITypes.DeleteTriggerMutation
>;
export const deleteUserProfile = /* GraphQL */ `mutation DeleteUserProfile($input: DeleteUserProfileInput!) {
  deleteUserProfile(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteUserProfileMutationVariables,
  APITypes.DeleteUserProfileMutation
>;
export const deleteVpc = /* GraphQL */ `mutation DeleteVpc($input: DeleteVpcInput!) {
  deleteVpc(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteVpcMutationVariables,
  APITypes.DeleteVpcMutation
>;
export const parseSchema = /* GraphQL */ `mutation ParseSchema($input: ParseSchemaInput!) {
  parseSchema(input: $input) {
    status
    result
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ParseSchemaMutationVariables,
  APITypes.ParseSchemaMutation
>;
export const updateCognito = /* GraphQL */ `mutation UpdateCognito($input: UpdateCognitoInput!) {
  updateCognito(input: $input) {
    id
    serviceId
    user
    name
    description
    resourceType
    tags
    cognitoType
    cognitoTestEmail
    cognitoExisting
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCognitoMutationVariables,
  APITypes.UpdateCognitoMutation
>;
export const updateCreateServiceStatus = /* GraphQL */ `mutation UpdateCreateServiceStatus($input: UpdateCreateServiceStatusInput!) {
  updateCreateServiceStatus(input: $input) {
    serviceId
    status
    done
    error
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCreateServiceStatusMutationVariables,
  APITypes.UpdateCreateServiceStatusMutation
>;
export const updateDynamo = /* GraphQL */ `mutation UpdateDynamo($input: UpdateDynamoInput!) {
  updateDynamo(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    billingMode
    readCapacity
    writeCapacity
    keyType
    primaryKeyName
    primaryKeyType
    secondaryKeyName
    secondaryKeyType
    hasIndex
    GSI {
      gsiId
      gsiName
      gsiKeyType
      primaryAttribute
      primaryAttributeType
      secondaryAttribute
      secondaryAttributeType
      projectionType
      __typename
    }
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDynamoMutationVariables,
  APITypes.UpdateDynamoMutation
>;
export const updateLambda = /* GraphQL */ `mutation UpdateLambda($input: UpdateLambdaInput!) {
  updateLambda(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    runtime
    memory
    timeout
    prune
    tracing
    vpc
    webpack
    connection {
      id
      resourceId
      permissions {
        create
        read
        update
        delete
        scan
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    trigger {
      id
      serviceId
      resourceId
      user
      name
      description
      type
      private
      tags
      updatedAt
      createdAt
      eventTypes {
        create
        delete
        __typename
      }
      typename
      __typename
    }
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLambdaMutationVariables,
  APITypes.UpdateLambdaMutation
>;
export const updateModel = /* GraphQL */ `mutation UpdateModel($input: UpdateModelInput!) {
  updateModel(input: $input) {
    id
    serviceId
    user
    name
    description
    dataSource
    fields
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelMutationVariables,
  APITypes.UpdateModelMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    name
    description
    tags
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const updateOrganizationUser = /* GraphQL */ `mutation UpdateOrganizationUser($input: UpdateOrganizationUserInput!) {
  updateOrganizationUser(input: $input) {
    id
    email
    organization {
      id
      name
      description
      tags
      updatedAt
      createdAt
      __typename
    }
    userProfile {
      id
      email
      firstName
      lastName
      bio
      avatar
      updatedAt
      createdAt
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationUserMutationVariables,
  APITypes.UpdateOrganizationUserMutation
>;
export const updateRds = /* GraphQL */ `mutation UpdateRds($input: UpdateRdsInput!) {
  updateRds(input: $input) {
    id
    serviceId
    user
    name
    description
    vpcName
    vpcExisting
    tags
    rdsEngine
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRdsMutationVariables,
  APITypes.UpdateRdsMutation
>;
export const updateResolver = /* GraphQL */ `mutation UpdateResolver($input: UpdateResolverInput!) {
  updateResolver(input: $input) {
    id
    serviceId
    user
    name
    description
    model
    dataSource
    type
    resolverType
    isPipeline
    resolveWith_PK
    resolveWith_SK
    resolveFrom
    queryFrom
    modelFieldId
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateResolverMutationVariables,
  APITypes.UpdateResolverMutation
>;
export const updateResourceConnection = /* GraphQL */ `mutation UpdateResourceConnection($input: UpdateResourceConnectionInput!) {
  updateResourceConnection(input: $input) {
    id
    resourceId
    permissions {
      create
      read
      update
      delete
      scan
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateResourceConnectionMutationVariables,
  APITypes.UpdateResourceConnectionMutation
>;
export const updateRoute = /* GraphQL */ `mutation UpdateRoute($input: UpdateRouteInput!) {
  updateRoute(input: $input) {
    id
    serviceId
    authId
    auth {
      id
      serviceId
      user
      name
      description
      resourceType
      tags
      cognitoType
      cognitoTestEmail
      cognitoExisting
      updatedAt
      createdAt
      typename
      __typename
    }
    resourceId
    resource {
      ... on Cognito {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
      }
      ... on Dynamo {
        id
        serviceId
        user
        name
        description
        tags
        billingMode
        readCapacity
        writeCapacity
        keyType
        primaryKeyName
        primaryKeyType
        secondaryKeyName
        secondaryKeyType
        hasIndex
        GSI {
          gsiId
          gsiName
          gsiKeyType
          primaryAttribute
          primaryAttributeType
          secondaryAttribute
          secondaryAttributeType
          projectionType
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Lambda {
        id
        serviceId
        user
        name
        description
        tags
        runtime
        memory
        timeout
        prune
        tracing
        vpc
        webpack
        connection {
          id
          resourceId
          permissions {
            create
            read
            update
            delete
            scan
            __typename
          }
          updatedAt
          createdAt
          __typename
        }
        trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Rds {
        id
        serviceId
        user
        name
        description
        vpcName
        vpcExisting
        tags
        rdsEngine
        updatedAt
        createdAt
        typename
      }
      ... on S3 {
        id
        serviceId
        user
        name
        description
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Sns {
        id
        serviceId
        user
        name
        description
        tags
        topicType
        updatedAt
        createdAt
        typename
      }
      ... on Sqs {
        id
        serviceId
        user
        name
        description
        tags
        hasDlq
        topicType
        visibilityTimeout
        deliveryDelay
        msgRetentionPeriod
        receiveMsgWaitTimeSecs
        maxMsgSize
        updatedAt
        createdAt
        typename
      }
      ... on Trigger {
        id
        serviceId
        resourceId
        user
        name
        description
        type
        private
        tags
        updatedAt
        createdAt
        eventTypes {
          create
          delete
          __typename
        }
        typename
      }
      ... on Vpc {
        id
        serviceId
        user
        name
        description
        tags
        vpcExisting
        vpcExistingId
        vpcExistingSubnetA
        vpcExistingSubnetB
        vpcExistingSubnetC
        vpcExistingSecurityGroupId
        updatedAt
        createdAt
        typename
      }
      ... on Resolver {
        id
        serviceId
        user
        name
        description
        model
        dataSource
        type
        resolverType
        isPipeline
        resolveWith_PK
        resolveWith_SK
        resolveFrom
        queryFrom
        modelFieldId
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Model {
        id
        serviceId
        user
        name
        description
        dataSource
        fields
        tags
        updatedAt
        createdAt
        typename
      }
    }
    user
    name
    description
    cors
    method
    path
    type
    private
    tags
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRouteMutationVariables,
  APITypes.UpdateRouteMutation
>;
export const updateS3 = /* GraphQL */ `mutation UpdateS3($input: UpdateS3Input!) {
  updateS3(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateS3MutationVariables,
  APITypes.UpdateS3Mutation
>;
export const updateService = /* GraphQL */ `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    name
    description
    tags
    cloudProvider
    cloudProviderRegion
    stage
    iacFramework
    useCase
    status
    optionBluePrint
    appsyncName
    appsyncDescription
    authType
    logging
    caching
    xRay
    webpack
    prune
    chatgpt
    operatingSystem
    organization {
      id
      name
      description
      tags
      updatedAt
      createdAt
      __typename
    }
    allowedTeams {
      id
      name
      description
      updatedAt
      createdAt
      __typename
    }
    resources {
      ... on Cognito {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
      }
      ... on Dynamo {
        id
        serviceId
        user
        name
        description
        tags
        billingMode
        readCapacity
        writeCapacity
        keyType
        primaryKeyName
        primaryKeyType
        secondaryKeyName
        secondaryKeyType
        hasIndex
        GSI {
          gsiId
          gsiName
          gsiKeyType
          primaryAttribute
          primaryAttributeType
          secondaryAttribute
          secondaryAttributeType
          projectionType
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Lambda {
        id
        serviceId
        user
        name
        description
        tags
        runtime
        memory
        timeout
        prune
        tracing
        vpc
        webpack
        connection {
          id
          resourceId
          permissions {
            create
            read
            update
            delete
            scan
            __typename
          }
          updatedAt
          createdAt
          __typename
        }
        trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
          __typename
        }
        updatedAt
        createdAt
        typename
      }
      ... on Rds {
        id
        serviceId
        user
        name
        description
        vpcName
        vpcExisting
        tags
        rdsEngine
        updatedAt
        createdAt
        typename
      }
      ... on S3 {
        id
        serviceId
        user
        name
        description
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Sns {
        id
        serviceId
        user
        name
        description
        tags
        topicType
        updatedAt
        createdAt
        typename
      }
      ... on Sqs {
        id
        serviceId
        user
        name
        description
        tags
        hasDlq
        topicType
        visibilityTimeout
        deliveryDelay
        msgRetentionPeriod
        receiveMsgWaitTimeSecs
        maxMsgSize
        updatedAt
        createdAt
        typename
      }
      ... on Trigger {
        id
        serviceId
        resourceId
        user
        name
        description
        type
        private
        tags
        updatedAt
        createdAt
        eventTypes {
          create
          delete
          __typename
        }
        typename
      }
      ... on Vpc {
        id
        serviceId
        user
        name
        description
        tags
        vpcExisting
        vpcExistingId
        vpcExistingSubnetA
        vpcExistingSubnetB
        vpcExistingSubnetC
        vpcExistingSecurityGroupId
        updatedAt
        createdAt
        typename
      }
      ... on Resolver {
        id
        serviceId
        user
        name
        description
        model
        dataSource
        type
        resolverType
        isPipeline
        resolveWith_PK
        resolveWith_SK
        resolveFrom
        queryFrom
        modelFieldId
        tags
        updatedAt
        createdAt
        typename
      }
      ... on Model {
        id
        serviceId
        user
        name
        description
        dataSource
        fields
        tags
        updatedAt
        createdAt
        typename
      }
    }
    routes {
      id
      serviceId
      authId
      auth {
        id
        serviceId
        user
        name
        description
        resourceType
        tags
        cognitoType
        cognitoTestEmail
        cognitoExisting
        updatedAt
        createdAt
        typename
        __typename
      }
      resourceId
      resource {
        ... on Cognito {
          id
          serviceId
          user
          name
          description
          resourceType
          tags
          cognitoType
          cognitoTestEmail
          cognitoExisting
          updatedAt
          createdAt
          typename
        }
        ... on Dynamo {
          id
          serviceId
          user
          name
          description
          tags
          billingMode
          readCapacity
          writeCapacity
          keyType
          primaryKeyName
          primaryKeyType
          secondaryKeyName
          secondaryKeyType
          hasIndex
          GSI {
            gsiId
            gsiName
            gsiKeyType
            primaryAttribute
            primaryAttributeType
            secondaryAttribute
            secondaryAttributeType
            projectionType
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Lambda {
          id
          serviceId
          user
          name
          description
          tags
          runtime
          memory
          timeout
          prune
          tracing
          vpc
          webpack
          connection {
            id
            resourceId
            permissions {
              create
              read
              update
              delete
              scan
              __typename
            }
            updatedAt
            createdAt
            __typename
          }
          trigger {
            id
            serviceId
            resourceId
            user
            name
            description
            type
            private
            tags
            updatedAt
            createdAt
            eventTypes {
              create
              delete
              __typename
            }
            typename
            __typename
          }
          updatedAt
          createdAt
          typename
        }
        ... on Rds {
          id
          serviceId
          user
          name
          description
          vpcName
          vpcExisting
          tags
          rdsEngine
          updatedAt
          createdAt
          typename
        }
        ... on S3 {
          id
          serviceId
          user
          name
          description
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Sns {
          id
          serviceId
          user
          name
          description
          tags
          topicType
          updatedAt
          createdAt
          typename
        }
        ... on Sqs {
          id
          serviceId
          user
          name
          description
          tags
          hasDlq
          topicType
          visibilityTimeout
          deliveryDelay
          msgRetentionPeriod
          receiveMsgWaitTimeSecs
          maxMsgSize
          updatedAt
          createdAt
          typename
        }
        ... on Trigger {
          id
          serviceId
          resourceId
          user
          name
          description
          type
          private
          tags
          updatedAt
          createdAt
          eventTypes {
            create
            delete
            __typename
          }
          typename
        }
        ... on Vpc {
          id
          serviceId
          user
          name
          description
          tags
          vpcExisting
          vpcExistingId
          vpcExistingSubnetA
          vpcExistingSubnetB
          vpcExistingSubnetC
          vpcExistingSecurityGroupId
          updatedAt
          createdAt
          typename
        }
        ... on Resolver {
          id
          serviceId
          user
          name
          description
          model
          dataSource
          type
          resolverType
          isPipeline
          resolveWith_PK
          resolveWith_SK
          resolveFrom
          queryFrom
          modelFieldId
          tags
          updatedAt
          createdAt
          typename
        }
        ... on Model {
          id
          serviceId
          user
          name
          description
          dataSource
          fields
          tags
          updatedAt
          createdAt
          typename
        }
      }
      user
      name
      description
      cors
      method
      path
      type
      private
      tags
      updatedAt
      createdAt
      __typename
    }
    stars {
      ServiceStars {
        id
        updatedAt
        createdAt
        __typename
      }
      nextToken
      total
      __typename
    }
    isServiceOwner
    enums {
      id
      name
      serviceId
      fields
      description
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    subscriptions {
      id
      name
      serviceId
      description
      args {
        name
        type
        required
        __typename
      }
      type
      array
      required
      derivative {
        name
        mutations
        __typename
      }
      tags
      createdAt
      updatedAt
      typename
      __typename
    }
    customObjects {
      id
      serviceId
      name
      description
      fields {
        name
        fieldType
        type
        required
        array
        __typename
      }
      tags
      updatedAt
      createdAt
      typename
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceMutationVariables,
  APITypes.UpdateServiceMutation
>;
export const updateSns = /* GraphQL */ `mutation UpdateSns($input: UpdateSnsInput!) {
  updateSns(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    topicType
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSnsMutationVariables,
  APITypes.UpdateSnsMutation
>;
export const updateSqs = /* GraphQL */ `mutation UpdateSqs($input: UpdateSqsInput!) {
  updateSqs(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    hasDlq
    topicType
    visibilityTimeout
    deliveryDelay
    msgRetentionPeriod
    receiveMsgWaitTimeSecs
    maxMsgSize
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSqsMutationVariables,
  APITypes.UpdateSqsMutation
>;
export const updateTeam = /* GraphQL */ `mutation UpdateTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    id
    name
    description
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMutationVariables,
  APITypes.UpdateTeamMutation
>;
export const updateTeamService = /* GraphQL */ `mutation UpdateTeamService($input: UpdateTeamServiceInput!) {
  updateTeamService(input: $input) {
    id
    teamId
    serviceId
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamServiceMutationVariables,
  APITypes.UpdateTeamServiceMutation
>;
export const updateTeamUser = /* GraphQL */ `mutation UpdateTeamUser($input: UpdateTeamUserInput!) {
  updateTeamUser(input: $input) {
    id
    userId
    team {
      id
      name
      description
      updatedAt
      createdAt
      __typename
    }
    email
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamUserMutationVariables,
  APITypes.UpdateTeamUserMutation
>;
export const updateTrigger = /* GraphQL */ `mutation UpdateTrigger($input: UpdateTriggerInput!) {
  updateTrigger(input: $input) {
    id
    serviceId
    resourceId
    user
    name
    description
    type
    private
    tags
    updatedAt
    createdAt
    eventTypes {
      create
      delete
      __typename
    }
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTriggerMutationVariables,
  APITypes.UpdateTriggerMutation
>;
export const updateUserProfile = /* GraphQL */ `mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    id
    email
    firstName
    lastName
    bio
    avatar
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfileMutationVariables,
  APITypes.UpdateUserProfileMutation
>;
export const updateVpc = /* GraphQL */ `mutation UpdateVpc($input: UpdateVpcInput!) {
  updateVpc(input: $input) {
    id
    serviceId
    user
    name
    description
    tags
    vpcExisting
    vpcExistingId
    vpcExistingSubnetA
    vpcExistingSubnetB
    vpcExistingSubnetC
    vpcExistingSecurityGroupId
    updatedAt
    createdAt
    typename
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVpcMutationVariables,
  APITypes.UpdateVpcMutation
>;
