import { Alert, AlertTitle, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { fetchOrganizationUsers } from 'src/data/organizations/queries'
import { OrganizationUser } from 'src/API'
import { addTeamUser } from 'src/data/organizations/mutations'

interface FormAddTeamUser {
  user: string
}
const schema = yup.object({
  user: yup
    .string()
    .required('This field is required')
})
const AddTeamUser: FC = () => {
  const { organizationId, teamId } = useParams()
  const [organizationUsers, setOrganizationUsers] = useState<OrganizationUser[]>([])
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isValid
    },
    reset,
    trigger
  } = useForm<FormAddTeamUser>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      user: ''
    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      user: ''
    })
  }, [reset])

  async function getOrganizationUsers (id: string) {
    try {
      const data = await fetchOrganizationUsers(id)
      setOrganizationUsers(data as OrganizationUser[])
    } catch (err) {
      setErrMsg((err as Error).message)
      console.error(err)
    }
  }

  useEffect(() => {
    if (!organizationId) {
      console.log('No organizationId')
      return
    }
    getOrganizationUsers(organizationId)
  }, [organizationId])

  const goToSettings = () => {
    navigate('/settings')
  }

  const goToOrganizations = () => {
    navigate('/settings/organizations/')
  }

  const goToTeams = () => {
    navigate(`/settings/organizations/${organizationId}/teams/`)
  }

  const goToTeamUsers = () => {
    navigate(`/settings/organizations/${organizationId}/teams/${teamId}/users`)
  }

  const onSubmit = async (data: FormAddTeamUser) => {
    const user = organizationUsers.find(u => u.id === data.user)
    setLoading(true)
    if (!teamId || !user?.email) {
      console.log('No teamId or selectedUser')
      setLoading(false)
      return
    }
    try {
      await addTeamUser(teamId, data.user, user?.email ?? '')
      navigate(`/settings/organizations/${organizationId}/teams/${teamId}/users`)
    } catch (err) {
      setLoading(false)
      setErrMsg((err as Error).message)
      console.log(err)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} >
    <div style={{ paddingTop: '2em' }}></div>
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={6}>
        {errMsg && <Alert severity="error" onClose={() => {
          setErrMsg('')
        }}>
          <AlertTitle>Error</AlertTitle>
          {errMsg}
        </Alert>}

          <h5>
            <Button variant="text" onClick={goToSettings}>
              Settings
            </Button>
            &#62;
            <Button variant="text" onClick={goToOrganizations}>
              Organizations
            </Button>
            &#62;
            <Button variant="text" onClick={goToTeams}>
              Teams
            </Button>
            &#62;
            <Button variant="text" onClick={goToTeamUsers}>
            Team Members
            </Button>
            &#62;
            Add Member
          </h5>
          <Card variant="outlined">
            <CardContent>
              <div className="d-flex">
                <div className="w-[50%]">
                  <h4 className="title-mark">Add Members</h4>
                </div>
                <div className="w-[50%] text-end">

                </div>
              </div>
              <hr />
              <br />
              <FormControl fullWidth>
                <InputLabel
                  error={!!errors?.user?.message}
                  id="select-user-label">Select user</InputLabel>
                <Controller
                  name={'user'}
                  control={control}
                  render={({
                    field: {
                      onChange,
                      value
                    }
                  }) => (<Select
                    error={!!errors?.user?.message}
                    labelId="select-user-label"
                    id="user"
                    label="Select user"
                    variant='outlined'
                    onChange={(e) => {
                      trigger('user')
                      onChange(e)
                    }} value={value}>
                    {
                      organizationUsers?.map((user: OrganizationUser) => {
                        return <MenuItem key={user.id} value={user.id}>{user.email}</MenuItem>
                      })
                    }
                  </Select>)}
                />
              </FormControl>
              <br />
              <br />
              <LoadingButton disabled={!isDirty || !isValid} loading={loading} type={'submit'} variant="contained" color="primary" fullWidth>
                Add
              </LoadingButton>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  )
}

export default AddTeamUser
