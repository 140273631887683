import React, { FC, useContext, useEffect, useState } from 'react'
import { CloudProvider, IACFramework, ServiceStatus, UseCase } from 'src/API'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ApplicationContext from 'src/store/application-context-provider'
import { regionList } from 'src/data/regions'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { Alert } from 'src/components/atoms/alert'
import { validateServiceUniqueName } from 'src/data/services/queries'

const w = window as any

interface FormDefineService {
  serviceName: string
  serviceDescription: string
  defaultStage: string
  defaultRegion: string
  packaging: 'False' | 'True'
  pruning: 'False' | 'True'
}

const DefineService: FC = () => {
  const ctx = useContext(ApplicationContext)

  const [editServiceName, setEditServiceName] = useState('')
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [showAlertMsg, setShowAlertMsg] = useState('')

  const schema = yup.object({
    serviceName: yup
      .string()
      .min(5)
      .required('serviceName is required')
  })

  const {
    control,
    register,
    getValues,
    formState: {
      errors
    },
    trigger,
    reset
  } = useForm<FormDefineService>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      serviceName: '',
      serviceDescription: '',
      defaultStage: 'dev',
      defaultRegion: 'us_east_1',
      packaging: 'True',
      pruning: 'True'
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      serviceName: ctx?.serviceBuilder?.config?.name,
      serviceDescription: ctx?.serviceBuilder?.config?.description,
      defaultStage: ctx?.serviceBuilder?.config?.defaults?.stage,
      defaultRegion: ctx?.serviceBuilder?.config?.defaults?.region,
      packaging: ctx?.serviceBuilder?.config?.package?.webpack === false ? 'False' : 'True',
      pruning: ctx?.serviceBuilder?.config?.package?.prune === false ? 'False' : 'True'
    })
  }, [reset])

  useEffect(() => {
    if (ctx?.serviceBuilder?.config?.name) {
      setEditServiceName(ctx?.serviceBuilder?.config?.name)
    }
  }, [])

  useEffect(() => {
    setIsShowAlert(false)
    const serviceName = getValues().serviceName

    if (serviceName?.length > 4) {
      if (w.location.pathname === '/service-builder/wizard/edit' && !editServiceName?.length) {
        return
      }

      if (editServiceName.length > 0 && editServiceName === getValues().serviceName) {
        return
      }

      const delayDebounceFn = w.setTimeout(() => {
        getServicesByName(serviceName)
      }, 500)

      return () => { clearTimeout(delayDebounceFn) }
    }
  }, [getValues().serviceName])

  useEffect(() => {
    if (getValues().serviceName && getValues().serviceName?.length > 4 && !isShowAlert) {
      ctx?.setIsServiceNextButtonDisabled(false)
      const service = ctx?.serviceBuilder
      const config = {
        ...service.config,
        name: getValues().serviceName,
        description: getValues().serviceDescription,
        cloudProvider: CloudProvider.AWS,
        iacFramework: IACFramework.SLS,
        defaults: {
          stage: getValues().defaultStage,
          region: getValues().defaultRegion
        },
        appSyncDetails: ctx?.serviceBuilder?.config?.appSyncDetails ?? {},
        status: ServiceStatus.PENDING,
        createdAt: new Date().toISOString(),
        package: {
          webpack: getValues().packaging === 'True',
          prune: getValues().pruning === 'True'
        },
        useCase: ctx?.serviceBuilder?.config?.useCase ?? UseCase.APIGW
      }
      ctx?.setServiceBuilder({
        ...service,
        config
      })
    } else {
      ctx?.setIsServiceNextButtonDisabled(true)
    }
  }, [getValues().serviceName, getValues().serviceDescription, getValues().defaultStage, getValues().defaultRegion, getValues().packaging, getValues().pruning, isShowAlert])

  const getServicesByName = async (name: string) => {
    const data = await validateServiceUniqueName({
      name: name.trim()
    })

    const usedNameList = data.services ?? []

    if (usedNameList?.length > 0) {
      setShowAlertMsg('This name is already in use, please choose a unique name.')
      setIsShowAlert(true)
    }
  }

  return (<form className="md:w-[500px] sm:w-full">
    <div
      className={'bg-[#020409] flex justify-center w-full'}>
      <div className={'flex flex-col gap-[10px] w-full'}>
        {isShowAlert && <Alert variant={'error'}>{showAlertMsg}</Alert>}
      <Controller
        name={'serviceName'}
        rules={{ required: true }}
        control={control}
        render={({
          field: {
            onChange,
            onBlur,
            value
          }
        }) => (<TextInput
          {...register('serviceName', { minLength: 5, maxLength: 15 })}
          className={'pt-[8px]'}
          required
          labelText={'Service Name:'}
          placeholder="Example: meal-preparation-service"
          maxLength={15}
          minLength={5}
          showRequired
          error={errors?.serviceName?.message}
          onChange={(e) => {
            trigger('serviceName')
            onChange(e)
          }}
          onBlur={onBlur}
          value={`${value?.toLowerCase().replace(/[&/\\#,+()!$~%.'":;|[\]@*^?<>{}_\s]/g, '-')}`}/>
        )}
      />
      <Controller
        name={'serviceDescription'}
        control={control}
        render={({
          field: {
            onChange,
            onBlur,
            value
          }
        }) => (<TextInput
          {...register('serviceDescription')}
          labelText={'Service Description:'}
          placeholder="Example: Manage a menu and orders for a restaurant"
          error={errors?.serviceDescription?.message}
          onChange={(e) => {
            trigger('serviceDescription')
            onChange(e)
          }}
          onBlur={onBlur}
          value={value}/>)}
      />
      <TextInput
        required
        labelText={'Cloud Provider:'}
        comingSoon
        value={'AWS'} disabled/>
      <TextInput
        required
        labelText={'IaC Framework:'}
        comingSoon
        value={'Serverless Framework'} disabled/>
      <Controller
                name={'defaultStage'}
                control={control}
                render={({
                  field: {
                    onChange,
                    onBlur,
                    value
                  }
                }) => (<TextInput
                  defaultValue={'dev'}
                  labelText={'Default Stage:'}
                  {...register('defaultStage')}
                  onChange={(e) => {
                    trigger('defaultStage')
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}/>)}
              />

      <Controller
        name={'defaultRegion'}
        control={control}
        render={({
          field: {
            onChange,
            value
          }
        }) => (
          <SelectOutlinedInput
            error={errors?.defaultRegion?.message}
            selected={value}
            options={Object.keys(regionList).map((key: string) => ({ name: regionList[key as keyof typeof regionList] + ' (' + key.replace(/_/g, '-') + ')', value: key }))}
            labelText={'Default region:'}
            handleValueChange={(e) => {
              trigger('defaultRegion')
              onChange(e)
            }}/>
        )}
      />
        <Controller
          name={'packaging'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              error={errors?.packaging?.message}
              selected={value}
              options={[{ name: 'Webpack', value: 'True' }, { name: 'NONE', value: 'False' }]}
              labelText={'Packaging:'}
              handleValueChange={(e) => {
                trigger('packaging')
                onChange(e)
              }}/>
          )}
        />
        <Controller
          name={'pruning'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              error={errors?.pruning?.message}
              selected={value}
              options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
              labelText={'Pruning:'}
              handleValueChange={(e) => {
                trigger('pruning')
                onChange(e)
              }}/>
          )}
        />
      </div>
    </div>
  </form>)
}

export default DefineService
