import React from 'react'
import AppRoutes from './Routes'
import { Layout } from 'src/components/templates/layout/Layout'

const Home: React.FC<{ window?: Function }> = (props) => {
  return (
    <Layout>
      <AppRoutes></AppRoutes>
    </Layout>
  )
}

export default Home
