import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { Organization, OrganizationUser, Team, TeamUser } from '../../API'
import {
  createOrganization, createTeam, createTeamUser, deleteOrganization, deleteOrganizationUser, deleteTeam, deleteTeamUser, updateOrganization as updateOrg, updateTeam as updTeam
} from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const addOrganization = async (name: string) => {
  const addOrganizationResult = (await API.graphql(
    graphqlOperation(createOrganization, { input: { name } })
  )) as GraphQLResult<{ addOrganization: { Organization: Organization } }>
  const newOrganization = (addOrganizationResult?.data?.addOrganization?.Organization)
  return newOrganization
}

export const removeOrganization = async (id: string) => {
  const removeOrganizationResult = (await API.graphql(
    graphqlOperation(deleteOrganization, { input: { id } })
  )) as GraphQLResult<{ removeOrganization: { Organization: Organization } }>
  const deletedOrganization = (removeOrganizationResult?.data?.removeOrganization?.Organization)
  return deletedOrganization
}

export const updateOrganization = async (id: string, name: string) => {
  const updateOrganizationResult = (await API.graphql(
    graphqlOperation(updateOrg, { input: { id, name } })
  )) as GraphQLResult<{ updateOrganization: Organization }>
  const updatedOrganization = (updateOrganizationResult?.data?.updateOrganization)
  return updatedOrganization
}

export const addTeam = async (organizationId: string, name: string) => {
  const addTeamResult = (await API.graphql(
    graphqlOperation(createTeam, { input: { orgId: organizationId, name } })
  )) as GraphQLResult<{ addTeam: { Team: Team } }>
  const newTeam = (addTeamResult?.data?.addTeam?.Team)
  return newTeam
}

export const removeTeam = async (id: string, orgId: string) => {
  const removeTeamResult = (await API.graphql(
    graphqlOperation(deleteTeam, { input: { id, orgId } })
  )) as GraphQLResult<{ removeTeam: { Team: Team } }>
  const deletedTeam = (removeTeamResult?.data?.removeTeam?.Team)
  return deletedTeam
}

export const updateTeam = async (id: string, name: string, orgId: string) => {
  const updateTeamResult = (await API.graphql(
    graphqlOperation(updTeam, { input: { id, name, orgId } })
  )) as GraphQLResult<{ updateTeam: { Team: Team } }>
  const updatedTeam = (updateTeamResult?.data?.updateTeam?.Team)
  return updatedTeam
}

export const addTeamUser = async (teamId: string, userId: string, email: string) => {
  const addTeamUserResult = (await API.graphql(
    graphqlOperation(createTeamUser, { input: { teamId, userId, email } })
  )) as GraphQLResult<{ addTeamUser: { Team: TeamUser } }>
  const newUser = (addTeamUserResult?.data?.addTeamUser?.Team)
  return newUser
}

export const removeTeamUser = async (teamId: string, userId: string) => {
  const removeTeamUserResult = (await API.graphql(
    graphqlOperation(deleteTeamUser, { input: { teamId, userId } })
  )) as GraphQLResult<{ removeTeamUser: { Team: TeamUser } }>
  const deletedUser = (removeTeamUserResult?.data?.removeTeamUser?.Team)
  return deletedUser
}

export const removeOrganizationUser = async (orgId: string, id: string) => {
  const removeOrganizationUserResult = (await API.graphql(
    graphqlOperation(deleteOrganizationUser, { input: { id, orgId } })
  )) as GraphQLResult<{ removeOrganizationUser: { Organization: OrganizationUser } }>
  const deletedUser = (removeOrganizationUserResult?.data?.removeOrganizationUser?.Organization)
  return deletedUser
}
