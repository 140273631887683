import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Button, Card, CardContent, Grid, List } from '@mui/material'
import { Auth } from 'aws-amplify'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TeamUser as TeamUserType } from '../../../../../../../API'
import { removeTeamUser } from '../../../../../../../data/organizations/mutations'
import { fetchTeamUsers } from '../../../../../../../data/organizations/queries'
import TeamUser from './TeamUser/TeamUser'
import decode from 'jwt-decode'

const TeamUsers: FC = () => {
  const { organizationId, teamId } = useParams()
  const [showEdit, setShowEdit] = useState(false)
  const [teamUsers, setTeamUsers] = useState<TeamUserType[]>([])
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const navigate = useNavigate()

  const goToSettings = () => {
    navigate('/settings')
  }

  const goToOrganizations = () => {
    navigate('/settings/organizations')
  }

  const goToTeams = () => {
    navigate(`/settings/organizations/${organizationId}/teams`)
  }

  const goAddUser = () => {
    navigate(`/settings/organizations/${organizationId}/teams/${teamId}/users/add`)
  }

  async function getTeamUsers (id: string) {
    try {
      const data = await fetchTeamUsers(id)
      setTeamUsers(data as TeamUserType[])
      let org: any = localStorage.getItem('selectedOrganization')
      org = org ? JSON.parse(org) : null
      const authData = await Auth.currentAuthenticatedUser()
      const decoded: { orgId: string } = decode(authData.signInUserSession.idToken.jwtToken)
      setShowEdit(decoded.orgId === org.id)
    } catch (err) {
      setErrMsg((err as Error).message)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!teamId) return
    setLoading(true)
    getTeamUsers(teamId)
  }, [teamId])

  const handleDelete = async (userId: string) => {
    if (!teamId) return
    try {
      await removeTeamUser(teamId, userId)
      setTeamUsers(teamUsers.filter(u => u.id !== userId))
    } catch (err) {
      setErrMsg((err as Error).message)
      console.log(err)
    }
    ;
  }

  return (
    <>
      <div style={{ paddingTop: '2em' }}></div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          { errMsg &&
          <Alert severity="error" onClose={() => { setErrMsg('') }}>
            <AlertTitle>Error</AlertTitle>
            { errMsg }
          </Alert>
          }

          <h5>
            <Button variant="text" onClick={goToSettings}>
              Settings
            </Button>
            &#62;
            <Button variant="text" onClick={goToOrganizations}>
              Organizations
            </Button>
            &#62;
            <Button variant="text" onClick={goToTeams}>
              Teams
            </Button>
            &#62;
            Team Members
          </h5>
          <Card variant="outlined">
            <CardContent>
              <div className="d-flex">
                <div className="w-[50%]">
                  <h4 className="title-mark">Team Members</h4>
                </div>
                <div className="w-[50%] text-end">
                  {
                    showEdit && <Button size="small" variant="contained" onClick={goAddUser}>
                      <Add />
                    </Button>
                  }
                </div>
              </div>
              <hr />
              <nav aria-label="organizations list">
                {loading
                  ? <LoadingButton fullWidth variant='text' loading={loading}></LoadingButton>
                  : <List>
                  {
                    teamUsers?.map(row => (
                      <TeamUser key={row.id} teamUser={row} onDelete={handleDelete} showEdit={showEdit}/>
                    ))
                  }
                </List>
                }
              </nav>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default TeamUsers
