import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { CreateDynamoInput, Dynamo, UpdateDynamoInput } from '../../API'
import { createDynamo, deleteDynamo, updateDynamo as updateDynamoMutation } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const addDynamo = async (input: CreateDynamoInput) => {
  const addDynamoResult = (await API.graphql(
    graphqlOperation(createDynamo, { input })
  )) as GraphQLResult<{ createDynamo: Dynamo }>
  const newDynamo = (addDynamoResult?.data?.createDynamo)
  return newDynamo
}

export const updateDynamo = async (input: UpdateDynamoInput) => {
  const updateDynamoResult = (await API.graphql(
    graphqlOperation(updateDynamoMutation, { input: { ...input } })
  )) as GraphQLResult<{ updateDynamo: Dynamo }>
  const updatedDynamo = (updateDynamoResult?.data?.updateDynamo)
  return updatedDynamo
}

export const removeDynamo = async (id: string, serviceId: string) => {
  const removeDynamoResult = (await API.graphql(
    graphqlOperation(deleteDynamo, { input: { id, serviceId } })
  )) as GraphQLResult<{ removeDynamo: Dynamo }>
  const deletedDynamo = (removeDynamoResult?.data?.removeDynamo)
  return deletedDynamo
}

export const updateTeam = async (id: string, name: string) => {
  const updateDynamoResult = (await API.graphql(
    graphqlOperation(updateDynamoMutation, { input: { id, name } })
  )) as GraphQLResult<{ updateTeam: { Team: Dynamo } }>
  const updatedDynamo = (updateDynamoResult?.data?.updateTeam?.Team)
  return updatedDynamo
}
