import React, { FC, Fragment, useMemo } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { SelectInputOption } from 'src/components/atoms/select-outlined'
import { classNames } from 'src/utils/css'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up.svg'
import { ReactComponent as CheckIcon } from 'src/assets/icons/checkmarkBlack.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close_multi.svg'
import { nameExists, removeObjectsWithDuplicateName } from 'src/utils/validation'

export interface SelectInputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string
  error?: string | undefined | boolean
  classname?: string
  options?: SelectInputOption[]
  selectedOptions: SelectInputOption[]
  handleValueChange: (data: SelectInputOption[]) => void
  classNameOptions?: string
  bgOpenDropdown?: string
  labelText?: string
  children?: React.ReactNode
  showRequired?: boolean
  iconLeft?: React.ReactNode
  maxDisplayedValues?: number
}
export const SelectMultipleOutlinedInput: FC<SelectInputProps> = ({
  classname = '',
  showRequired = false,
  error,
  options,
  classNameOptions = '',
  labelText = '',
  handleValueChange,
  selectedOptions = [],
  placeholder,
  bgOpenDropdown = 'text-[#7E858F]',
  iconLeft,
  maxDisplayedValues = 3,
  ...props
}) => {
  const displayedValues = useMemo(() => {
    return selectedOptions?.slice(0, maxDisplayedValues)
  }, [selectedOptions])

  const handleRemoveOption = (e: Event | any, item: any) => {
    e.stopPropagation()
    const updatedSelectedPeople = selectedOptions.filter(person => person.value !== item.value)
    handleValueChange(updatedSelectedPeople)
  }

  return (
    <div className={classname}>
      <label
        className={`text-xs ${error ? 'text-red-default' : props.disabled ? 'text-[#7E858F]' : 'text-white'} `}
      >
        {labelText}{showRequired && <span className={'ml-[4px] text-red-default'}>*</span>}</label>
    <Listbox disabled={props.disabled} value={selectedOptions} onChange={(options) => { handleValueChange(removeObjectsWithDuplicateName(options)) }} multiple>
      {({ open }) => (
        <div className="relative  mt-[6px]">
      <Listbox.Button className={classNames(
        `bg-[#2B2D31] overflow-hidden flex items-center gap-2  min-h-[42px] ${props.disabled ? 'text-[#7E858F]' : 'text-white'}  cursor-pointer text-xs  relative w-full rounded-lg  py-2 pl-[8px] pr-10 text-left  focus:outline-none `,
        `${error && 'ring-2 ring-red-default'}`
      )} >
        {displayedValues?.map((item, index: number) => <div key={item?.value}
                                                          className={'flex bg-[#020409] text-[#7E858F] rounded-lg px-2 py-2 items-center gap-2'}>
          <div>{item?.name}</div>
          <button onClick={(e) => { handleRemoveOption(e, item) }} className={'cursor-pointer outline-none focus:outline-none'}>
            <CloseIcon className={'w-3 h-3'}/>
          </button>

        </div>)}

        {selectedOptions?.length > maxDisplayedValues && (
          <div className="flex bg-[#020409] rounded-lg px-[9px] py-[7px] items-center gap-[8px]">
            <div className="text-white">+{selectedOptions?.length - maxDisplayedValues}</div>
          </div>
        )}
        <span
          className="
              bg-black-darker border-2 border-[#2B2D31] rounded-r-lg box-border pointer-events-none absolute inset-y-0 right-0 flex items-center px-2"
        >
            { open
              ? <ArrowUpIcon />
              : <ArrowDownIcon />
            }
            </span>

      </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
      <Listbox.Options className={classNames('absolute mt-0.5 py-0 z-40  w-full overflow-auto rounded-lg bg-[#2B2D31] text-white  text-xs focus:outline-none', classNameOptions)} >
        {options?.map((option) => (
          <Listbox.Option
            className={({ active, disabled }) => `relative cursor-pointer select-none py-2 px-4 ${nameExists(option.name, selectedOptions) ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'}    ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} ${disabled ? 'text-[#7E858F]' : 'text-white'} `}
            key={option.value} value={option}>
            {() => (
              <div className={'flex items-center justify-between'}>
                <div>{option.name}</div>
                {nameExists(option.name, selectedOptions)
                  ? (
                    <div><CheckIcon /></div>
                    )
                  : null}
              </div>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
      {error && <div className={'mt-[4px] text-[10px] text-red-default'}>{error}</div>}
    </div>
  )
}
