import React, { useContext, useEffect, useState } from 'react'
import ApplicationContext from 'src/store/application-context-provider'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LAMBDA_MEMORY_TYPE, LAMBDA_TIMEOUT_TYPE, RESOURCE_TYPE, RUNTIME_TYPE } from 'src/enums'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import { UseCase } from 'src/API'

interface FormLambda {
  lambdaName: string
  description: string
  runtime: RUNTIME_TYPE
  memory: LAMBDA_MEMORY_TYPE
  timeout: LAMBDA_TIMEOUT_TYPE
  vpcPrivateLambda: 'False' | 'True'
  triggerLambda: string
  vpc: string
  tracing: 'False' | 'True'
  connection: string
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
  createEvent: boolean
  deleteEvent: boolean
}

const ResourceLambdaForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props

  const [vpcs, setVpcs] = useState([])
  const [dynamos, setDynamos] = useState([])
  const [sqs, setSqs] = useState([])
  const [sns, setSns] = useState([])
  const [s3, setS3] = useState([])
  const [isEventTypes, setIsEventTypes] = useState(false)

  const ctx = useContext(ApplicationContext)

  const schema = yup.object({
    lambdaName: yup
      .string()
      .required('This field is required')
      .max(25),
    description: yup
      .string()

  })

  const {
    control,
    handleSubmit,
    setValue,
    register,
    getValues,
    formState: {
      errors,
      isDirty,
      isValid
    },
    trigger,
    reset
  } = useForm<FormLambda>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      lambdaName: '',
      description: '',
      runtime: RUNTIME_TYPE.NODEJS16,
      memory: LAMBDA_MEMORY_TYPE.MB1536,
      timeout: LAMBDA_TIMEOUT_TYPE.SEC30,
      vpcPrivateLambda: 'False',
      triggerLambda: 'NONE',
      vpc: '',
      tracing: 'False',
      connection: 'NONE',
      read: false,
      create: false,
      update: false,
      delete: false,
      deleteEvent: false,
      createEvent: false
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      lambdaName: ctx?.resourceInput?.name,
      description: ctx?.resourceInput?.description,
      runtime: ctx?.resourceInput?.runtime,
      memory: ctx?.resourceInput?.memory,
      timeout: ctx?.resourceInput?.timeout,
      vpcPrivateLambda: ctx?.resourceInput?.vpcPrivateLambda === true ? 'True' : 'False',
      triggerLambda: ctx?.resourceInput?.trigger?.id ?? 'NONE',
      vpc: ctx?.resourceInput?.vpc,
      tracing: ctx?.resourceInput?.tracing === true ? 'True' : 'False',
      connection: ctx?.resourceInput?.connection?.id ?? 'NONE',
      read: ctx?.resourceInput?.connection?.permissions?.read,
      create: ctx?.resourceInput?.connection?.permissions?.create,
      update: ctx?.resourceInput?.connection?.permissions?.update,
      delete: ctx?.resourceInput?.connection?.permissions?.delete,
      deleteEvent: ctx?.resourceInput?.trigger?.eventTypes?.delete,
      createEvent: ctx?.resourceInput?.trigger?.eventTypes?.create
    })
  }, [reset])

  useEffect(() => {
    const service = ctx?.serviceBuilder
    const currentTriggerId = ctx?.resourceInput?.trigger?.id
    const selectedTriggerIdList = service.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.LAMBDA && r?.trigger && r?.trigger.id !== currentTriggerId).map((l: any) => l?.trigger?.id)
    const allSqs = service.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.SQS)
    const unselectedSqs = allSqs?.filter((s: any) => !selectedTriggerIdList.includes(s.id))
    setSqs(selectedTriggerIdList?.length ? unselectedSqs : allSqs)
    const allSns = service.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.SNS)
    const unselectedSns = allSns?.filter((s: any) => !selectedTriggerIdList.includes(s.id))
    setSns(selectedTriggerIdList ? unselectedSns : allSns)
    const allS3 = service.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.S3)
    const unselectedS3 = allS3?.filter((s: any) => !selectedTriggerIdList.includes(s.id))
    setS3(selectedTriggerIdList ? unselectedS3 : allS3)
  }, [ctx, getValues().triggerLambda])

  useEffect(() => {
    const vpcsInput = ctx?.serviceBuilder?.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.VPC)
    setVpcs(vpcsInput)
  }, [ctx?.serviceBuilder?.resources, getValues().vpcPrivateLambda])

  useEffect(() => {
    const service = ctx?.serviceBuilder
    const dynamos = service.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)
    setDynamos(dynamos)
  }, [ctx?.serviceBuilder])

  useEffect(() => {
    setIsEventTypes(ctx?.serviceBuilder?.resources?.filter((r: any) => r.resourceType === 'S3').map((s: any) => s.id)?.includes(getValues().triggerLambda))
  }, [getValues().triggerLambda])

  const onSubmit = (data: FormLambda) => {
    const input = ctx?.resourceInput
    input.name = data.lambdaName
    input.description = data.description
    input.runtime = data.runtime
    input.memory = data.memory
    input.timeout = data.timeout
    input.vpcPrivateLambda = data.vpcPrivateLambda === 'True'

    input.tracing = data.tracing === 'True'

    if (data.connection === 'NONE') {
      // set all permissions to false
      input.connection = {
        id: 'NONE',
        permissions: {
          create: false,
          update: false,
          delete: false,
          read: false
        }
      }
      setValue('create', false)
      setValue('update', false)
      setValue('delete', false)
      setValue('read', false)
    } else {
      input.connection = {
        id: data.connection,
        permissions: {
          create: data.create,
          update: data.update,
          delete: data.delete,
          read: data.read
        }
      }
    }

    if (data.triggerLambda && data.triggerLambda !== 'NONE') {
      if (isEventTypes) {
        input.trigger = {
          id: data.triggerLambda,
          eventTypes: {
            create: data.createEvent ?? false,
            delete: data.deleteEvent ?? false
          }
        }
      } else {
        input.trigger = {
          id: data.triggerLambda
        }
      }
      delete input.connection
    } else {
      delete input.trigger
      setValue('createEvent', false)
      setValue('deleteEvent', false)
    }

    if (data.vpc === 'NONE') {
      input.vpc = ''
    } else {
      input.vpc = data.vpc || ''
    }

    ctx?.setResourceInput(input)
    console.log('input Lambda', input)
    submit()
  }

  return (
    <form>
      <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
        <Controller
            name={'lambdaName'}
            rules={{ required: true }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
                {...register('lambdaName', { maxLength: 25 })}
                required
                labelText={'Name'}
                showRequired
                maxLength={25}
                error={errors?.lambdaName?.message}
                id="resourceName"
                onChange={onChange}
                onBlur={onBlur}
                value={value.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
        />
        <Controller
            name={'description'}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
                {...register('description', { maxLength: 60 })}
                labelText={'Description'}
                maxLength={60}
                error={errors?.description?.message}
                id="resourceName"
                onChange={onChange}
                onBlur={onBlur}
                value={value}/>)}
        />
        <Controller
            name={'runtime'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
                <SelectOutlinedInput
                    error={errors?.runtime?.message}
                    selected={value}
                    options={[
                      { name: 'Node.js 14.x', value: RUNTIME_TYPE.NODEJS14 },
                      { name: 'Node.js 16.x', value: RUNTIME_TYPE.NODEJS16 },
                      { name: 'Node.js 18.x', value: RUNTIME_TYPE.NODEJS18 }
                    ]
                }
                    labelText={'Runtime'}
                    handleValueChange={onChange}/>
            )}
        />
          <Controller
            name={'memory'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
                <SelectOutlinedInput
                    error={errors?.memory?.message}
                    selected={value}
                    options={[
                      { name: '128 MB', value: LAMBDA_MEMORY_TYPE.MB128 },
                      { name: '256 MB', value: LAMBDA_MEMORY_TYPE.MB256 },
                      { name: '512 MB', value: LAMBDA_MEMORY_TYPE.MB512 },
                      { name: '1024 MB', value: LAMBDA_MEMORY_TYPE.MB1024 },
                      { name: '1536 MB (recommended)', value: LAMBDA_MEMORY_TYPE.MB1536 },
                      { name: '2048 MB', value: LAMBDA_MEMORY_TYPE.MB2048 },
                      { name: '2560 MB', value: LAMBDA_MEMORY_TYPE.MB2560 },
                      { name: '3008 MB', value: LAMBDA_MEMORY_TYPE.MB3008 },
                      { name: '4096 MB', value: LAMBDA_MEMORY_TYPE.MB4096 },
                      { name: '5120 MB', value: LAMBDA_MEMORY_TYPE.MB5120 },
                      { name: '6144 MB', value: LAMBDA_MEMORY_TYPE.MB6144 },
                      { name: '7168 MB', value: LAMBDA_MEMORY_TYPE.MB7168 },
                      { name: '8192 MB', value: LAMBDA_MEMORY_TYPE.MB8192 },
                      { name: '9216 MB', value: LAMBDA_MEMORY_TYPE.MB9216 },
                      { name: '10240 MB', value: LAMBDA_MEMORY_TYPE.MB10240 }
                    ]
                    }
                    labelText={'Memory'}
                    handleValueChange={onChange}/>
            )}
        />
          <Controller
            name={'timeout'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
                <SelectOutlinedInput
                    error={errors?.timeout?.message}
                    selected={value}
                    options={
                      ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW
                        ? [
                            { name: '30 sec (recommended)', value: LAMBDA_TIMEOUT_TYPE.SEC30 }]
                        : [
                            { name: '30 sec (recommended)', value: LAMBDA_TIMEOUT_TYPE.SEC30 },
                            { name: '1 min', value: LAMBDA_TIMEOUT_TYPE.SEC60 },
                            { name: '5 min', value: LAMBDA_TIMEOUT_TYPE.SEC300 },
                            { name: '10 min', value: LAMBDA_TIMEOUT_TYPE.SEC600 },
                            { name: '15 min', value: LAMBDA_TIMEOUT_TYPE.SEC900 }
                          ]
                    }
                    labelText={'Timeout'}
                    handleValueChange={onChange}/>
            )}
        />
          {(vpcs?.length > 0) && <Controller
            name={'vpcPrivateLambda'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
                <SelectOutlinedInput
                    error={errors?.vpcPrivateLambda?.message}
                    selected={value}
                                     options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
                                     labelText={'Private'}
                                     handleValueChange={(e) => {
                                       trigger('vpcPrivateLambda')
                                       onChange(e)
                                     }}/>
            )}
        />}
          {(sqs?.length > 0 || sns?.length > 0 || s3?.length > 0) && <>
          <h3 className="modal-settings-title text-white">{getValues().connection === 'NONE' ? 'Trigger' : 'Remove a resource to be able to select-outlined a trigger'}</h3>

            {(getValues().connection === 'NONE') &&
                <>
                  <Controller
                      name={'triggerLambda'}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value
                        }
                      }) => (
                          <SelectOutlinedInput selected={value}
                                               error={errors?.triggerLambda?.message}
                                               options={[[{ name: 'NONE', value: 'NONE' }],
                                                 ...sqs?.map((s: any) => ({ name: `SQS: ${s.name}`, value: s.id })),
                                                 ...sns?.map((s: any) => ({ name: `SNS: ${s.name}`, value: s.id })),
                                                 ...s3?.map((s: any) => ({ name: `S3: ${s.name}`, value: s.id }))

                                               ].flat()}
                                               labelText={'Resource'}
                                               handleValueChange={(e) => {
                                                 trigger('triggerLambda')
                                                 onChange(e)
                                               }}/>
                      )}
                  />
                </> }
          </>}

          { isEventTypes && (
          <>
            <h3 className="modal-settings-title text-white">Event</h3>
            <div className="pl-1 flex flex-col gap-4">
              <Controller
                  name={'createEvent'}
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                      <CheckboxInput labelText={'Create'} onChange={onChange} checked={value} />
                  )}
              />
              <Controller
                  name={'deleteEvent'}
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                      <CheckboxInput labelText={'Delete'} onChange={onChange} checked={value} />
                  )}
              />
            </div>
          </>
          )}

        {getValues().vpcPrivateLambda === 'True' && (
              <Controller
                  name={'vpc'}
                  control={control}
                  render={({
                    field: {
                      onChange,
                      value
                    }
                  }) => (
                      <SelectOutlinedInput selected={value}
                                           error={errors?.vpc?.message}
                                           options={[[{ name: 'NONE', value: 'NONE' }],
                                             ...vpcs?.map((s: any) => ({ name: `VPC: ${s.name}`, value: s.id }))
                                           ].flat()}
                                           labelText={'VPC'}
                                           handleValueChange={onChange}/>
                  )}
              />

        )}
          <h3 className="modal-settings-title text-white">Extra</h3>
        <Controller
            name={'tracing'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
                <SelectOutlinedInput selected={value}
                                     error={errors?.tracing?.message}
                                     options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
                                     labelText={'Tracing'}
                                     handleValueChange={onChange}/>
            )}
        />
      {/* <FormGroup> */}
          {/*   <FormControl fullWidth={true} variant="standard" margin="normal"> */}
      {/*     <InputLabel id="vpc-label">Webpack</InputLabel> */}
      {/*     <Controller */}
      {/*       name={'webpack'} */}
      {/*       control={control} */}
      {/*       render={({ */}
      {/*         field: { */}
      {/*           onChange, */}
      {/*           value */}
      {/*         } */}
      {/*       }) => (<Select onChange={onChange} value={value}> */}
      {/*         <MenuItem value={true as any}>True</MenuItem> */}
      {/*         <MenuItem value={false as any}>False</MenuItem> */}
      {/*       </Select>)} */}
      {/*     /> */}
      {/*   </FormControl> */}
      {/*   <FormControl fullWidth={true} variant="standard" margin="normal"> */}
      {/*     <InputLabel id="vpc-label">Prune</InputLabel> */}
      {/*     <Controller */}
      {/*       name={'prune'} */}
      {/*       control={control} */}
      {/*       render={({ */}
      {/*         field: { */}
      {/*           onChange, */}
      {/*           value */}
      {/*         } */}
      {/*       }) => (<Select onChange={onChange} value={value}> */}
      {/*         <MenuItem value={true as any}>True</MenuItem> */}
      {/*         <MenuItem value={false as any}>False</MenuItem> */}
      {/*       </Select>)} */}
      {/*     /> */}
      {/*   </FormControl> */}
      {/* </FormGroup> */}
<div className={'pl-[30px] pb-[10px] ring-1 ring-[#826AED] rounded-lg'}>
    <h3 className="modal-settings-title text-white" style={{ marginBottom: '0px' }}>{getValues().triggerLambda === 'NONE' ? 'Connect your Lambda to another AWS Resource' : 'Remove a trigger to be able to select-outlined a resource'}</h3>
      {getValues().triggerLambda === 'NONE' && <div className="text-muted font-small text-white">Generates IAM permissions and some application code</div>}
</div>
        {getValues().triggerLambda === 'NONE' &&
<>
            <Controller
                name={'connection'}
                control={control}
                render={({
                  field: {
                    onChange,
                    value
                  }
                }) => (
                    <SelectOutlinedInput selected={value}
                                         error={errors?.connection?.message}
                                         options={[[{ name: 'NONE', value: 'NONE' }],
                                           ...dynamos?.map((s: any) => ({ name: `DynamoDB: ${s.name}`, value: s.id }))
                                         ].flat()}
                                         labelText={'Resource'}
                                         handleValueChange={(e) => {
                                           trigger('connection')
                                           setValue('create', false)
                                           setValue('update', false)
                                           setValue('delete', false)
                                           setValue('read', false)
                                           onChange(e)
                                         }}/>
                )}
            />

</>}

        { (getValues().connection && getValues().connection !== 'NONE') && (
          <>
            <h3 className="modal-settings-title text-white">Permissions</h3>
            <div className="pl-1 mt-[16px] flex flex-col gap-[16px]">
                <Controller
                    name={'read'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxInput labelText={'Read'} onChange={onChange} checked={value} />
                    )}
                />
                <Controller
                    name={'create'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxInput labelText={'Create'} onChange={onChange} checked={value} />
                    )}
                />
                <Controller
                    name={'update'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxInput labelText={'Update'} onChange={onChange} checked={value} />
                    )}
                />
                <Controller
                    name={'delete'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxInput labelText={'Delete'} onChange={onChange} checked={value} />
                    )}
                />
            </div>
          </>
        )}

      </div>
      <ResourceFormControls isDisabledSaveButton={!isDirty || !isValid} onSubmit={handleSubmit(onSubmit)} onClose={close}/>
    </form>
  )
}

export default ResourceLambdaForm
