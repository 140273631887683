import { Button } from 'src/components/atoms/button'
import React from 'react'

const ResourceFormControls: React.FC<{ onClose: Function, onSubmit: Function, isDisabledSaveButton?: boolean }> = props => {
  const { onClose, onSubmit, isDisabledSaveButton = false } = props

  const handleClose = (event: Event | any) => {
    onClose()
    event?.preventDefault()
  }

  const handleSubmit = (event: Event | any) => {
    onSubmit()
    event?.preventDefault()
    onClose()
    return false
  }

  return (
    <>
      <div className={'mt-[16px] border-t-[1px] border-[#020409]'} ></div>
      <div className={'px-6 mt-[16px] flex justify-between items-center'}>
        <Button onClick={handleClose} classname={'bg-[#020409]'} title={'Cancel'} variant={'darker'} type={'button'}/>
        <Button disabled={isDisabledSaveButton} onClick={handleSubmit} title={'Save'} variant={'primary'} type={'button'}/>
      </div>
    </>
  )
}

export default ResourceFormControls
