export const asyncForEach = async (array: any[], callback: Function) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
export const uniqueItemsById = (items: any[]) => {
  return items.filter((item, index, self) =>
    index === self.findIndex((t) => t.id === item.id)
  )
}
export const uniqueItemsByName = (items: any[]) => {
  return items.filter((item, index, self) =>
    index === self.findIndex((t) => t.name === item.name)
  )
}
