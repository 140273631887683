import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ServiceBuilder from 'src/components/organisms/service-builder/ServiceBuilder'
import Login from 'src/pages/login/Login'
import ServiceBuilderPreview from 'src/components/organisms/service-builder/preview/ServiceBuilderPreview'
import AddUser from 'src/components/organisms/settings/AddUser/AddUser'
import Review from 'src/components/organisms/service-builder/wizard/review/Review'
import ChangePassword from 'src/components/organisms/settings/Profile/ChangePassword/ChangePassword'
import SignUp from 'src/pages/signUp/SignUp'
import UpsertOrganization from 'src/components/organisms/settings/Organization/UpsertOrganization/UpsertOrganization'
import UpsertTeam from 'src/components/organisms/settings/Organization/Teams/Team/UpsertTeam/UpsertTeam'
import Teams from 'src/components/organisms/settings/Organization/Teams/Teams'
import SelectOrganization from 'src/pages/login/SelectOrganization'
import Billing from 'src/components/organisms/settings/Billing/Billing'
import TeamUsers from 'src/components/organisms/settings/Organization/Teams/Team/TeamUsers/TeamUsers'
import AddTeamUser from 'src/components/organisms/settings/Organization/Teams/Team/TeamUsers/TeamUser/AddTeamUser'
import ForgotPassword from 'src/pages/login/ForgotPassword/ForgotPassword'
import Wizard from 'src/components/organisms/service-builder/wizard/Wizard'
import QuestionsForm from 'src/pages/signUp/QuestionsForm'
import Settings from 'src/pages/settings/Settings'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  async function checkAuthenticatedUser () {
    try {
      await Auth.currentSession()
      setIsAuthenticated(true)
    } catch {
      setIsAuthenticated(false)
      navigate('/login')
    }
  }

  useEffect(() => {
    checkAuthenticatedUser()
  }, [navigate])

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace/>
  }

  return children
}

function MissingRoute () {
  return <Navigate to="/" />
}

const AppRoutes: React.FC = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <ServiceBuilder />
              </RequireAuth>
            }
          />
          <Route path="*" element={<MissingRoute/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/questionsForm" element={<QuestionsForm />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/select-organization" element={<SelectOrganization />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route path="/signup" element={<login />} /> */}
          {/* <Route path="/change-password" element={<login />} /> */}
          {/* <Route path="/reset-password" element={<login />} /> */}
          {/* <Route path="/reset-password-code" element={<login />} /> */}
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/reset-password" element={<ChangePassword />} />
          <Route path="/reset-password-code" element={<ChangePassword />} />
          <Route
            path="/settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/billing"
            element={
              <RequireAuth>
                <Billing />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organization"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organizations/:organizationId/upsert"
            element={
              <RequireAuth>
                <UpsertOrganization />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organizations/:organizationId/teams"
            element={
              <RequireAuth>
                <Teams />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organizations/:organizationId/add-team"
            element={
              <RequireAuth>
                <Teams />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organizations/:organizationId/teams/:teamId/upsert"
            element={
              <RequireAuth>
                <UpsertTeam />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organizations/:organizationId/teams/:teamId/users"
            element={
              <RequireAuth>
                <TeamUsers />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organizations/:organizationId/teams/upsert"
            element={
              <RequireAuth>
                <UpsertTeam />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/organizations/:organizationId/teams/:teamId/users/add"
            element={
              <RequireAuth>
                <AddTeamUser />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/add-user"
            element={
              <RequireAuth>
                <AddUser />
              </RequireAuth>
            }
          />
          <Route
            path="/service-builder"
            element={
              <RequireAuth>
                <ServiceBuilder />
              </RequireAuth>
            }
          />
          <Route
            path="/service-builder/:serviceId/preview"
            element={
              <RequireAuth>
                <ServiceBuilderPreview />
              </RequireAuth>
            }
          />
          <Route
            path="/service-builder/:serviceId/preview/congrats"
            element={
              <RequireAuth>
                <ServiceBuilderPreview />
              </RequireAuth>
            }
          />
          <Route
            path="/service-builder/:serviceId/info"
            element={
              <RequireAuth>
                <div className={'flex justify-center items-start p-12 overflow-y-auto'}>
                  <Review />
                </div>
              </RequireAuth>
            }
          />
          <Route
            path="/service-builder/wizard"
            element={
              <RequireAuth>
                <Wizard />
              </RequireAuth>
            }
          />
          <Route
            path="/service-builder/wizard/edit"
            element={
              <RequireAuth>
                <Wizard />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </>
  )
}

export default AppRoutes
