import React, { useContext, useEffect, useState } from 'react'
import ApplicationContext from '../../../../../store/application-context-provider'
import ResourceFormControls from './ResourceFormControls'
import { RDS_ENGINE_TYPE, RESOURCE_TYPE } from 'src/enums'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { Alert } from 'src/components/atoms/alert'

interface FormRds {
  name: string
  rdsEngine: RDS_ENGINE_TYPE
  vpcName: string
  description: string
}

const ResourceRdsForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props

  const [vpcs, setVpcs] = useState<any[]>([])
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)

  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
      .max(25),
    vpcName: yup
      .string()
      .required('This field is required')
      .test(
        'vpcName',
        'Please enter a valid VPC',
        (value) => {
          if (value === 'NONE') return false
          return true
        }
      )
  })

  const ctx = useContext(ApplicationContext)

  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: {
      errors,
      isDirty,
      isValid
    },
    reset,
    trigger
  } = useForm<FormRds>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
      rdsEngine: RDS_ENGINE_TYPE.MySql,
      vpcName: 'NONE'
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: ctx?.resourceInput?.name,
      description: ctx?.resourceInput?.description,
      rdsEngine: ctx?.resourceInput?.rdsEngine,
      vpcName: ctx?.resourceInput?.vpcName
    })
  }, [reset])

  useEffect(() => {
    const service = ctx?.serviceBuilder
    const currentVpcName = ctx?.resourceInput?.vpcName
    const selectedRdsList = service.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.RDS && r.vpcName && r?.vpcName !== currentVpcName).map((l: any) => l?.vpcName)
    const allVPC = service.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.VPC)
    const unselectedVPC = allVPC.filter((s: any) => !selectedRdsList.includes(s.name))
    setVpcs(selectedRdsList.length > 0 ? unselectedVPC : allVPC)
    setIsShowErrorAlert(!(unselectedVPC.length > 0))
  }, [ctx, getValues().vpcName])

  const onSubmit = (data: FormRds) => {
    const input = ctx?.resourceInput
    input.name = data.name
    input.rdsEngine = data.rdsEngine
    input.description = data.description ?? null
    if (data.vpcName === 'NONE') {
      input.vpcName = ''
    } else {
      input.vpcName = data.vpcName ?? ''
    }
    input.vpcExisting = vpcs?.filter((v) => data.vpcName === v.name)[0]?.vpcExisting
    console.log('input Rds', input)
    ctx?.setResourceInput(input)
    submit()
  }

  return (
    <form>
      <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
          <Controller
          name={'name'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
              {...register('name', { maxLength: 25 })}
              showRequired
              labelText={'Table Name'}
              maxLength={25}
              error={errors?.name?.message}
              id="resourceName"
              onChange={onChange}
              onBlur={onBlur}
              value={value?.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
      />
          <Controller
              name={'description'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                  {...register('description', { maxLength: 60 })}
                  labelText={'Description'}
                  maxLength={60}
                  error={errors?.description?.message}
                  id="resourceName"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}/>)}
          />
          <Controller
              name={'rdsEngine'}
              control={control}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                  <SelectOutlinedInput
                      error={errors?.rdsEngine?.message}
                      selected={value}
                      options={[
                        { name: 'MySql', value: RDS_ENGINE_TYPE.MySql },
                        { name: 'PostgreSql', value: RDS_ENGINE_TYPE.PostgreSql }
                      ]
                      }
                      labelText={'Engine'}
                      handleValueChange={onChange}/>
              )}
          />
          <Controller
              name={'vpcName'}
              control={control}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                  <SelectOutlinedInput selected={value}
                                       showRequired
                                       error={errors?.vpcName?.message}
                                       options={[[{ name: 'NONE', value: 'NONE' }],
                                         ...vpcs?.map((s: any) => ({ name: `VPC: ${s.name}`, value: s.name }))
                                       ].flat()}
                                       labelText={'VPC'}
                                       handleValueChange={(e) => {
                                         trigger('vpcName')
                                         onChange(e)
                                       }}/>
              )}
          />
          {isShowErrorAlert && <Alert variant={'error'}>Please create a VPC</Alert>}
      </div>
      <ResourceFormControls isDisabledSaveButton={!isDirty || !isValid} onSubmit={handleSubmit(onSubmit)} onClose={close}/>
    </form>
  )
}

export default ResourceRdsForm
