import React, { useEffect, useRef } from 'react'
// import { ReactComponent as HomeIcon } from 'src/assets/icons/homeIcon.svg'
import { ReactComponent as ServicesIcon } from 'src/assets/icons/servicesIcon.svg'
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings-sharp.svg'
import { ReactComponent as BugIcon } from 'src/assets/icons/bug.svg'
import { ReactComponent as ChatIcon } from 'src/assets/icons/chatbox-ellipses.svg'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

const w = window as any

export const Sidebar: React.FC<{ open: boolean }> = props => {
  const tawkMessengerRef = useRef<any | null>(null)
  // const [hoverHome, setHoverHome] = React.useState(false)
  const [hoverServices, setHoverServices] = React.useState(false)
  const [hoverSettings, setHoverSettings] = React.useState(false)

  const goToSettings = () => {
    w.location.href = '/settings/profile'
    setHoverSettings(true)
  }

  // const goHome = () => {
  //   w.location.href = '/'
  //   setHoverHome(true)
  // }
  const goToServices = () => {
    w.location.href = '/'
    setHoverServices(true)
  }
  useEffect(() => {
    setHoverSettings(w.location.pathname === '/settings/profile' || w.location.pathname === '/settings/organization')
    setHoverServices(w.location.pathname === '/' || w.location.pathname === '/service-builder/preview' || w.location.pathname === '/service-builder/preview/congrats')
  }, [w.location.pathname])

  const handleMinimize = () => {
    if (tawkMessengerRef.current) {
      tawkMessengerRef.current.maximize()
    }
  }
  const handleSubmitFeedback = () => {
    w.open('https://docs.google.com/forms/d/1I7oxPLS-dILrV0dMl7BKxSrQBxGpJsjIu7p1VgHR18s/viewform?edit_requested=true', '_blank')
  }
  return (
      <div className={`fixed z-30 pt-[16px] bg-[#020409] h-full transition-all duration-300 ${'translate-x-0 ease-out'}  w-48   border-[#171B21] border-r-2 hidden lg:block`}>
      <nav className={`text-xs transition-all duration-300 ${' translate-x-0 ease-out'} text-[#7E858F] pt-[52px]  `}>
        <ul>
          {/* <li
            onClick={goHome}
            className={`flex items-center pointer px-6 gap-2.5  hover:text-white  ${hoverHome ? 'bg-[#2B2D31] text-white' : ''}`}>
            <HomeIcon fill={hoverHome ? '#826AED' : '#7E858F'} />
            <span
              className="block py-[18px]"
            >
            Home
          </span>
          </li> */}
          <li
            onClick={goToServices}
            className={`flex items-center px-6  gap-2.5 hover:text-white pointer ${hoverServices ? 'bg-[#2B2D31] text-white' : ''}`}>
            <ServicesIcon fill={hoverServices ? '#826AED' : '#7E858F'}/>
            <span
              className="block py-[18px]"
            >
            Services
          </span>
          </li>
          <li onClick={goToSettings}
              className={`flex items-center px-6 gap-2.5 hover:text-white pointer ${hoverSettings ? 'bg-[#2B2D31] text-white' : ''}   `}>
            <SettingsIcon fill={hoverSettings ? '#826AED' : '#7E858F'}/>
            <span
              className="block py-[18px]"
            >
            Settings
          </span>
          </li>
        </ul>
      </nav>
      <div className={'absolute bottom-0 pb-6 px-6'}>
        <div onClick={handleMinimize} className={'flex items-center gap-[10px] cursor-pointer'}><BugIcon/> <div className={'text-xs text-[#7E858F]'}>Report a Bug</div></div>
        <div onClick={handleSubmitFeedback} className={'mt-[24px] flex items-center gap-[10px] cursor-pointer'}><ChatIcon/> <div className={'text-xs text-[#7E858F]'}>Product Feedback</div></div>
      </div>
        <TawkMessengerReact propertyId={`${process.env.REACT_APP_PROPERTY_ID}`} widgetId={`${process.env.REACT_APP_WIDGET_ID}`} ref={tawkMessengerRef} />
    </div>)
}
