import { ReactComponent as EyeSlashIcon } from 'src/assets/icons/eyeSlash.svg'
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg'
import React, { ReactNode, useState } from 'react'
import { TextInput } from 'src/components/atoms/text-input'

export interface PasswordInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string
  placeholder?: string
  type?: 'password' | 'text' | 'email'
  iconRight?: ReactNode
  className?: string
  dataCypressId?: string
  error?: string
  labelText?: string
  showRequired?: boolean
}

export const PasswordInput = React.forwardRef<HTMLInputElement,
PasswordInputProps>(({
  placeholder = '',
  showRequired = false,
  iconRight,
  className = '',
  error = '',
  dataCypressId,
  labelText = '',
  ...props
}, ref) => {
  const [passwordType, setPasswordType] = useState<'text' | 'password'>(
    'password'
  )
  return (
    <TextInput
      showRequired={showRequired}
      labelText={labelText}
      className={className}
      placeholder={placeholder}
      type={passwordType}
      ref={ref}
      {...props}
      error={error}
      iconRight={
        passwordType === 'password'
          ? (
          <EyeSlashIcon
            className="cursor-pointer"
            onClick={() => { setPasswordType('text') }}
          />
            )
          : (
            <EyeIcon
              data-testid="type-text-button"
              onClick={() => { setPasswordType('password') }}
              className="cursor-pointer"
            />
            )
      }
    />
  )
})
