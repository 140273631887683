import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import ConfirmDialog from 'src/components/molecules/confirm-dialog/ConfirmDialog'
import ModelsModal from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/ModelsModal'
import ApplicationContext from 'src/store/application-context-provider'
import { v4 as uuidv4 } from 'uuid'
import { FIELD_TYPE, FIELDTYPE_TYPE, RESOURCE_TYPE } from 'src/enums'
import { ServiceCard } from 'src/components/atoms/service-card'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp.svg'
import { Button } from 'src/components/atoms/button'
import { Alert } from 'src/components/atoms/alert'
import { OptionBluePrint } from 'src/API'
import {
  ServiceCardEnumObject
} from 'src/components/organisms/service-builder/wizard/define-enums/serviceCard-enum-object/ServiceCardEnumObject'
import CustomObjectModal
  from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/custom-object-modal/CustomObjectModal'
import { checkEmptyFields } from 'src/utils/validation'

interface Row {
  id: string
  name: string
  description: string
  type: string
  dataSource: { type: string, name: string, id: string }
  fields: any[]
}

const DefineModels: FC = () => {
  const ctx = useContext(ApplicationContext)

  const [openModal, setOpenModal] = useState(false)
  const [openObjectModal, setOpenObjectModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [onConfirm, setOnConfirm] = useState(() => () => { })
  const [models, setModels] = useState<Row[]>([])
  const [customObjects, setCustomObjects] = useState<any[]>([])

  const isShowError = useMemo(() => {
    const allFields = ctx?.serviceBuilder?.models.map((item: any) => item.fields).flat()
    return !models.every((m: any) => m.dataSource?.id && checkEmptyFields(ctx?.serviceBuilder?.resolvers?.filter((r: any) => r.model === m.name)) && checkEmptyFields(allFields))
  }, [models, ctx?.serviceBuilder.models, ctx?.serviceBuilder?.resolvers])

  const isShowObjectError = useMemo(() => {
    const allFields = ctx?.serviceBuilder?.customObjects?.map((item: any) => item.fields).flat()
    return !customObjects.every((m: any) => m.name && checkEmptyFields(allFields))
  }, [customObjects, ctx?.serviceBuilder?.customObjects])

  useEffect(() => {
    const serviceBuilder = ctx?.serviceBuilder
    setModels(serviceBuilder.models.map((model: any) => ({ ...model, type: '/assets/images/dynamoDB_Table_light-bg.svg' })))
    setCustomObjects(ctx?.serviceBuilder?.customObjects ?? [])
    ctx?.setModelsSearch(isShowError ? [] : models)
  }, [ctx?.serviceBuilder.resources, ctx?.serviceBuilder.models, ctx?.serviceBuilder?.customObjects])

  useEffect(() => {
    if (ctx?.serviceBuilder?.optionBluePrint !== OptionBluePrint.BYOSCHEMA) {
      const dynamos = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)
      const models = ctx?.serviceBuilder?.models.map((model: any) => {
        const datasource = dynamos.find(({ id }: { id: number }) => id === model?.dataSource?.id)
        const defaultFields = datasource?.secondaryKeyName
          ? [{
              id: uuidv4(),
              name: datasource?.primaryKeyName,
              type: datasource?.primaryKeyType === 'S' ? FIELD_TYPE.ID : datasource.primaryKeyType,
              required: true,
              fieldType: 'Scalar',
              array: false,
              isDisabled: true
            }, {
              id: uuidv4(),
              name: datasource?.secondaryKeyName,
              type: datasource?.secondaryKeyType === 'S' ? FIELD_TYPE.STRING : datasource.secondaryKeyType,
              required: true,
              fieldType: 'Scalar',
              array: false,
              isDisabled: false
            }]
          : [{
              id: uuidv4(),
              name: datasource?.primaryKeyName,
              type: datasource?.primaryKeyType === 'S' ? FIELD_TYPE.ID : datasource?.primaryKeyType,
              fieldType: 'Scalar',
              required: true,
              array: false,
              isDisabled: true
            }]

        const newFields = (model.fields?.map((f: any) => ({ ...f, id: f.id ?? uuidv4() })) ?? [{
          id: uuidv4(),
          name: '',
          type: '',
          required: false,
          array: false,
          isDisabled: false
        }]).slice(defaultFields?.length)

        const modelFields = [...defaultFields, ...newFields]
        return {
          ...model,
          fields: modelFields
        }
      })
      ctx?.setServiceBuilder({
        ...ctx?.serviceBuilder,
        models
      })
    }
  }, [])

  useEffect(() => {
    ctx?.setModelsSearch(isShowError ? [] : models)
  }, [isShowError, models])

  const cancelDelete = () => {
    setIsOpen(false)
  }

  const toggleOpenModal = () => {
    setOpenModal(!openModal)
  }
  const toggleOpenObjectModal = () => {
    setOpenObjectModal(!openObjectModal)
  }
  const confirmDeleteModel = (model: any) => {
    setIsOpen(true)
    setOnConfirm(() => async () => {
      const serviceBuilder = ctx?.serviceBuilder
      serviceBuilder.resolvers = serviceBuilder.resolvers.filter((res: any) => res.model !== model.name)
      serviceBuilder.models = serviceBuilder.models?.filter((res: any) => res.id !== model.id)?.map((m: any) => ({ ...m, fields: m.fields.filter((f: any) => !(f.fieldType === FIELDTYPE_TYPE.MODEL && f.type === model?.name)) }))
      const customTypes = model?.fields?.filter((f: any) => f.fieldType === FIELDTYPE_TYPE.CUSTOM).map((f: any) => f.type)
      const filteredCustomFields = ctx?.customTypesList?.filter((f) => !customTypes.includes(f.name))
      ctx?.setCustomTypesList(filteredCustomFields)
      serviceBuilder.customFields = serviceBuilder?.customFields?.filter((f: any) => !customTypes.includes(f.name)) ?? []
      ctx?.setServiceBuilder(serviceBuilder)
      ctx?.setModelsSearch(isShowError ? [] : serviceBuilder.models)
      setModels(serviceBuilder.models)
      setIsOpen(false)
    })
  }

  const handleModelSubmit = () => {
    const serviceBuilder = ctx?.serviceBuilder
    const input = ctx?.modelInput
    if (!input.id) {
      input.id = uuidv4()
      serviceBuilder.models = serviceBuilder.models ?? []
      serviceBuilder.models.push(input)
    } else {
      serviceBuilder.models = serviceBuilder?.models?.map((r: any) => {
        if (r.id === input.id) {
          return input
        }
        return r
      })
    }
    ctx?.setModelsSearch(isShowError ? [] : serviceBuilder.models)
    ctx?.setServiceBuilder(serviceBuilder)
    setModels([...serviceBuilder.models].flat())
    console.log('submit DefineModels', serviceBuilder)
  }
  const handleObjectSubmit = () => {
    const serviceBuilder = ctx?.serviceBuilder
    const input = ctx?.customObjectInput
    if (!input.id) {
      input.id = uuidv4()
      serviceBuilder.customObjects = serviceBuilder.customObjects ?? []
      serviceBuilder.customObjects.push(input)
    } else {
      serviceBuilder.customObjects = serviceBuilder?.customObjects?.map((r: any) => {
        if (r.id === input.id) {
          return input
        }
        return r
      })
    }
    ctx?.setModelsSearch(isShowError ? [] : serviceBuilder.customObjects)
    ctx?.setServiceBuilder(serviceBuilder)
    setCustomObjects([...serviceBuilder.customObjects].flat())
    console.log('submit DefineObject')
  }

  const editModel = (model: any) => {
    const res = ctx?.serviceBuilder?.models.find((r: any) => r.id === model.id)
    ctx?.setModelInput(res)
    toggleOpenModal()
  }
  const editCustomObject = (objectInput: any) => {
    const res = ctx?.serviceBuilder?.customObjects?.find((r: any) => r.id === objectInput.id)
    ctx?.setCustomObjectInput(res)
    toggleOpenObjectModal()
  }
  const confirmDeleteCustomObject = (customObject: any) => {
    setIsOpen(true)
    setOnConfirm(() => async () => {
      const serviceBuilder = ctx?.serviceBuilder
      serviceBuilder.customObjects = serviceBuilder?.customObjects?.filter((e: any) => e.id !== customObject.id)
      ctx?.setServiceBuilder(serviceBuilder)
      setCustomObjects(serviceBuilder.customObjects)
      setIsOpen(false)
    })
  }
  return (
    <div className={'flex mt-2 flex-col gap-2'}>
      <div className={'text-lg font-bold'}>GraphQL Types</div>
      <div className={'flex justify-center'}>
      {isShowError && <Alert classname={'w-2/3'}
                             variant={'info'}>
        {ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? 'Edit models to confirm resolver types before proceeding' : 'The models marked in red have an error, please fix them to proceed'}
      </Alert>}
        {isShowObjectError && <Alert classname={'w-2/3'}
                               variant={'info'}>
          The custom object marked in red have an error, please fix them to proceed
        </Alert>}
      </div>
      <div className={'rounded-2xl border-[1px] bg-[#171B21] border-[#2B2D31]'}>
      <div className={'px-4 flex gap-[16px] items-center py-[4px]'}>
        <div className={'text-lg'}>Models and Object Types</div>
        <Button iconLeft={<AddIcon/>} classname={'bg-[#171B21] font-normal'} title={'Add model'}
                onClick={() => {
                  ctx?.setModelInput({})
                  toggleOpenModal()
                }} variant={'darker'} type="button"/>
         <Button iconLeft={<AddIcon/>} classname={'bg-[#171B21] font-normal'} title={'Add object type'}
                 onClick={() => {
                   ctx?.setCustomObjectInput({})
                   toggleOpenObjectModal()
                 }} variant={'darker'} type="button"/>
      </div>
      <div
        className={'gap-[28px]  min-h-[162px] grid grid-cols-[repeat(auto-fill,minmax(248px,3fr))]  px-[30px] py-[33px] border-t-[1px] border-[#2B2D31]'}>
        {models?.map((m) => <ServiceCard
        error={!m.dataSource?.id || !checkEmptyFields(ctx?.serviceBuilder?.resolvers?.filter((r: any) => r.model === m.name)) || !checkEmptyFields(m.fields)}
        key={m.id}
                   handleRemoveCard={confirmDeleteModel}
                   handleEditCard={editModel}
                   card={m}/>)}
        {customObjects?.map((m) => <ServiceCardEnumObject
          key={m.id}
          type={'object'}
          error={!m.name || !checkEmptyFields(m.fields)}
          handleRemoveCard={confirmDeleteCustomObject}
          handleEditCard={editCustomObject}
          card={m}/>)}
      </div>
      </div>
      <ConfirmDialog isOpen={isOpen} onConfirm={onConfirm} onCancel={cancelDelete} />
      <ModelsModal onClose={toggleOpenModal} onSubmit={handleModelSubmit} open={openModal}/>
      <CustomObjectModal onClose={toggleOpenObjectModal} onSubmit={handleObjectSubmit} open={openObjectModal}/>

    </div>
  )
}

export default DefineModels
