import React, { useContext } from 'react'
import { Button } from 'src/components/atoms/button'
import ApplicationContext from 'src/store/application-context-provider'
import { useNavigate } from 'react-router-dom'

export const EmptyStates = () => {
  const ctx = useContext(ApplicationContext)
  const navigate = useNavigate()
  const buildService = () => {
    ctx?.clearContext()
    ctx?.setIsServiceEdit(false)
    navigate('/service-builder/wizard')
  }

  return (
    <div className={'flex flex-col'}>
      <div className={'flex items-center justify-center mt-[153px]'}>
        <div className={'flex flex-col rounded-2xl gap-[22px] items-center px-[32px] py-[50px] w-2/3   bg-[#171B21] '}>
          <div className={'text-2xl text-white'}>Are you ready to start building?</div>
          <div className={'text-xs text-[#7E858F]'}>This space is waiting for you to create something amazing.</div>
          <Button type="button" onClick={buildService} variant={'primary'} title={'Start now!'}/>
        </div>
      </div>
    </div>)
}
