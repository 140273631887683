import { v4 as uuidv4 } from 'uuid'
import { DYNAMO_KEY_TYPE, DYNAMO_PRIMARY_KEY_TYPE, PROJECTION_TYPE, RESOURCE_TYPE } from 'src/enums'

const singleDataSourceBluePrint = () => {
  const ddbA = {
    id: uuidv4(),
    name: 'items',
    resourceType: RESOURCE_TYPE.DYNAMO_DB,
    description: 'stores item info',
    keyType: 'SIMPLE',
    primaryKeyName: 'id',
    primaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: false
  }

  const authA = {
    id: uuidv4(),
    name: 'mainUserpool',
    resourceType: RESOURCE_TYPE.COGNITO_USER_POOL,
    cognitoType: 'EMAIL',
    description: 'main user pool for the site',
    cognitoTestEmail: 'example@company.com',
    cognitoExisting: false
  }

  const models = [
    {
      id: uuidv4(),
      name: 'Item',
      description: 'a simple model for items',
      dataSource: {
        id: ddbA.id,
        name: ddbA.name,
        type: 'Dynamo'
      },
      fields: [
        {
          name: 'id',
          type: 'ID',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'name',
          type: 'String',
          required: true,
          array: false
        },
        {
          name: 'price',
          type: 'Int',
          required: true,
          array: false
        },
        {
          name: 'pictures',
          type: 'AWSURL',
          required: true,
          array: true
        },
        {
          name: 'variations',
          type: 'AWSJSON',
          required: false,
          array: true
        },
        {
          name: 'inStock',
          type: 'Boolean',
          required: false,
          array: false
        }
      ]
    }
  ]

  const resolvers = [
    {
      id: uuidv4(),
      name: 'getItemById',
      type: 'get',
      model: models[0].name,
      dataSource: ddbA.name,
      description: 'handles getting a single item by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'listItem',
      type: 'scan',
      model: models[0].name,
      dataSource: ddbA.name,
      description: 'list all items',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'createItemById',
      type: 'create',
      model: models[0].name,
      dataSource: ddbA.name,
      description: 'handles creating a single item by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'deleteItemById',
      type: 'delete',
      model: models[0].name,
      dataSource: ddbA.name,
      description: 'handles deleting a single item by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'updateItemById',
      type: 'update',
      model: models[0].name,
      dataSource: ddbA.name,
      description: 'handles updating a single item by id',
      isPipeline: false
    }

  ]

  const resources = [authA, ddbA]

  return { resources, resolvers, models }
}

const singleTableDesignBluePrint = () => {
  const ddbA = {
    id: uuidv4(),
    name: 'data',
    resourceType: 'Dynamo',
    description: 'stores all info',
    keyType: 'COMPOSITE',
    primaryKeyName: 'PK',
    primaryKeyType: 'S',
    secondaryKeyName: 'SK',
    secondaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: true,
    GSI: [
      {
        gsiId: uuidv4(),
        gsiName: 'author',
        gsiKeyType: 'COMPOSITE',
        primaryAttribute: 'authorId',
        primaryAttributeType: 'S',
        secondaryAttribute: 'authorEmail',
        secondaryAttributeType: 'S',
        projectionType: 'ALL'
      },
      {
        gsiId: uuidv4(),
        gsiName: 'post',
        gsiKeyType: 'COMPOSITE',
        primaryAttribute: 'postId',
        primaryAttributeType: 'S',
        secondaryAttribute: 'createdAt',
        secondaryAttributeType: 'S',
        projectionType: 'ALL'
      }
    ]
  }

  const authA = {
    id: uuidv4(),
    name: 'mainUserpool',
    resourceType: RESOURCE_TYPE.COGNITO_USER_POOL,
    cognitoType: 'EMAIL',
    description: 'main user pool for the app',
    cognitoTestEmail: 'example@company.com',
    cognitoExisting: false
  }

  const models = [
    {
      id: uuidv4(),
      name: 'Author',
      description: 'a simple model for authors',
      dataSource: {
        id: ddbA.id,
        name: ddbA.name,
        type: 'Dynamo'
      },
      fields: [
        {
          name: 'PK',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'SK',
          type: 'String',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'email',
          fieldType: 'Scalar',
          type: 'AWSEmail',
          required: true,
          array: false
        },
        {
          name: 'posts',
          fieldType: 'Model',
          type: 'Post',
          required: true,
          array: true
        },
        {
          name: 'createdAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        },
        {
          name: 'updatedAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        }
      ]
    },
    {
      id: uuidv4(),
      name: 'Post',
      description: 'a simple model for posts',
      dataSource: {
        id: ddbA.id,
        name: ddbA.name,
        type: 'Dynamo'
      },
      fields: [
        {
          name: 'PK',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'SK',
          type: 'String',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'title',
          fieldType: 'Scalar',
          type: 'String',
          required: true,
          array: false
        },
        {
          name: 'content',
          fieldType: 'Scalar',
          type: 'String',
          required: true,
          array: false
        },
        {
          name: 'authorId',
          fieldType: 'Scalar',
          type: 'ID',
          required: true,
          array: false
        },
        {
          name: 'author',
          fieldType: 'Model',
          type: 'Author',
          required: true,
          array: false
        },
        {
          name: 'comments',
          fieldType: 'Model',
          type: 'Comment',
          required: true,
          array: true
        }, {
          name: 'publishDate',
          fieldType: 'Scalar',
          type: 'AWSDate',
          required: true,
          array: false
        },
        {
          name: 'createdAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        }, {
          name: 'updatedAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        }
      ]
    },
    {
      id: uuidv4(),
      name: 'Comment',
      description: 'a simple model for comments',
      dataSource: {
        id: ddbA.id,
        name: ddbA.name,
        type: 'Dynamo'
      },
      fields: [
        {
          name: 'PK',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'SK',
          type: 'String',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'message',
          type: 'String',
          fieldType: 'Scalar',
          required: true,
          array: false
        },
        {
          name: 'createdAt',
          type: 'AWSDateTime',
          fieldType: 'Scalar',
          required: true,
          array: false
        }, {
          name: 'updatedAt',
          type: 'AWSDateTime',
          fieldType: 'Scalar',
          required: true,
          array: false
        },
        {
          name: 'postId',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false
        }
      ]
    }
  ]

  const resolvers = [
    {
      id: uuidv4(),
      name: 'getPost',
      type: 'get',
      model: 'Post',
      dataSource: ddbA.name,
      description: 'get posts by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'getPosts',
      type: 'query',
      model: 'Post',
      dataSource: ddbA.name,
      isPipeline: true,
      description: 'get all posts by an author',
      queryFrom: 'author',
      isDisabled: false
    },
    {
      id: uuidv4(),
      name: 'getAllData',
      type: 'scan',
      model: 'Post',
      dataSource: ddbA.name,
      description: 'get all author posts',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'createPost',
      type: 'create',
      model: 'Post',
      dataSource: ddbA.name,
      description: 'create post by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'updatePost',
      type: 'update',
      model: 'Post',
      dataSource: ddbA.name,
      description: 'update post by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'deletePost',
      type: 'delete',
      model: 'Post',
      dataSource: ddbA.name,
      description: 'delete post by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'Post.author',
      type: 'field',
      model: 'Post',
      dataSource: ddbA.name,
      resolveWith_PK: 'authorId',
      resolveWith_SK: 'authorEmail',
      description: 'get author of a Post',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'Post.comments',
      type: 'field',
      model: 'Post',
      dataSource: ddbA.name,
      resolveWith_SK: 'createdAt',
      resolveWith_PK: 'postId',
      description: 'get all comments for a Post',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'getAuthor',
      type: 'get',
      model: 'Author',
      dataSource: ddbA.name,
      description: 'get Author by name',
      isPipeline: true
    },
    {
      id: uuidv4(),
      name: 'createAuthor',
      type: 'create',
      model: 'Author',
      dataSource: ddbA.name,
      description: 'create Author by name',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'Author.posts',
      type: 'field',
      model: 'Author',
      dataSource: ddbA.name,
      resolveFrom: 'author',
      description: 'get all post by an Author',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'deleteAuthor',
      type: 'delete',
      model: 'Author',
      dataSource: ddbA.name,
      isPipeline: false,
      description: 'delete author by id',
      isDisabled: false
    },
    {
      id: uuidv4(),
      name: 'createComment',
      type: 'create',
      model: 'Comment',
      dataSource: ddbA.name,
      description: 'create a comment',
      isPipeline: false
    }

  ]

  const resources = [authA, ddbA]

  return { resources, resolvers, models }
}
const multipleDataSourceBluePrint = () => {
  const authors = {
    id: uuidv4(),
    name: 'authors',
    resourceType: RESOURCE_TYPE.DYNAMO_DB,
    description: 'stores authors info',
    keyType: 'SIMPLE',
    primaryKeyName: 'id',
    primaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: false
  }
  const posts = {
    id: uuidv4(),
    name: 'posts',
    resourceType: RESOURCE_TYPE.DYNAMO_DB,
    description: 'stores posts info',
    keyType: 'SIMPLE',
    primaryKeyName: 'id',
    primaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: true,
    GSI: [{
      gsiId: uuidv4(),
      gsiName: 'author',
      gsiKeyType: DYNAMO_KEY_TYPE.COMPOSITE,
      primaryAttribute: 'authorId',
      primaryAttributeType: DYNAMO_PRIMARY_KEY_TYPE.STRING,
      secondaryAttribute: 'createdAt',
      secondaryAttributeType: DYNAMO_PRIMARY_KEY_TYPE.STRING,
      projectionType: PROJECTION_TYPE.ALL
    }]
  }
  const comments = {
    id: uuidv4(),
    name: 'comments',
    resourceType: RESOURCE_TYPE.DYNAMO_DB,
    description: 'stores comments info',
    keyType: 'SIMPLE',
    primaryKeyName: 'id',
    primaryKeyType: 'S',
    billingMode: 'PAY_PER_REQUEST',
    hasIndex: true,
    GSI: [{
      gsiId: uuidv4(),
      gsiName: 'post',
      gsiKeyType: DYNAMO_KEY_TYPE.COMPOSITE,
      primaryAttribute: 'postId',
      primaryAttributeType: DYNAMO_PRIMARY_KEY_TYPE.STRING,
      secondaryAttribute: 'createdAt',
      secondaryAttributeType: DYNAMO_PRIMARY_KEY_TYPE.STRING,
      projectionType: PROJECTION_TYPE.ALL
    }]
  }
  const authA = {
    id: uuidv4(),
    name: 'mainUserpool',
    resourceType: RESOURCE_TYPE.COGNITO_USER_POOL,
    cognitoType: 'EMAIL',
    description: 'main user pool for the app',
    cognitoTestEmail: 'example@company.com',
    cognitoExisting: false
  }

  const models = [
    {
      id: uuidv4(),
      name: 'Author',
      description: 'a simple model for authors',
      dataSource: {
        id: authors.id,
        name: authors.name,
        type: 'Dynamo'
      },
      fields: [
        {
          name: 'id',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'email',
          fieldType: 'Scalar',
          type: 'AWSEmail',
          required: true,
          array: false
        },
        {
          name: 'posts',
          fieldType: 'Model',
          type: 'Post',
          required: true,
          array: true
        },
        {
          name: 'createdAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        },
        {
          name: 'updatedAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        }
      ]
    },
    {
      id: uuidv4(),
      name: 'Post',
      description: 'a simple model for posts',
      dataSource: {
        id: posts.id,
        name: posts.name,
        type: 'Dynamo'
      },
      fields: [
        {
          name: 'id',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'title',
          fieldType: 'Scalar',
          type: 'String',
          required: true,
          array: false
        },
        {
          name: 'content',
          fieldType: 'Scalar',
          type: 'String',
          required: true,
          array: false
        },
        {
          name: 'authorId',
          fieldType: 'Scalar',
          type: 'ID',
          required: true,
          array: false
        },
        {
          name: 'author',
          fieldType: 'Model',
          type: 'Author',
          required: true,
          array: false
        },
        {
          name: 'comments',
          fieldType: 'Model',
          type: 'Comment',
          required: true,
          array: true
        }, {
          name: 'publishDate',
          fieldType: 'Scalar',
          type: 'AWSDate',
          required: true,
          array: false
        },
        {
          name: 'createdAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        }, {
          name: 'updatedAt',
          fieldType: 'Scalar',
          type: 'AWSDateTime',
          required: true,
          array: false
        }
      ]
    },
    {
      id: uuidv4(),
      name: 'Comment',
      description: 'a simple model for comments',
      dataSource: {
        id: comments.id,
        name: comments.name,
        type: 'Dynamo'
      },
      fields: [
        {
          name: 'id',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        },
        {
          name: 'message',
          type: 'String',
          fieldType: 'Scalar',
          required: true,
          array: false
        },
        {
          name: 'createdAt',
          type: 'AWSDateTime',
          fieldType: 'Scalar',
          required: true,
          array: false
        }, {
          name: 'updatedAt',
          type: 'AWSDateTime',
          fieldType: 'Scalar',
          required: true,
          array: false
        },
        {
          name: 'postId',
          type: 'ID',
          fieldType: 'Scalar',
          required: true,
          array: false,
          isDisabled: true
        }
      ]
    }
  ]

  const resolvers = [
    {
      id: uuidv4(),
      name: 'getPost',
      type: 'get',
      model: 'Post',
      dataSource: posts.name,
      description: 'get posts by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'getPosts',
      type: 'query',
      model: 'Post',
      dataSource: posts.name,
      isPipeline: true,
      queryFrom: 'author',
      description: 'get all posts by an author',
      isDisabled: false
    },
    {
      id: uuidv4(),
      name: 'getAllPosts',
      type: 'scan',
      model: 'Post',
      dataSource: posts.name,
      description: 'get all author posts',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'createPost',
      type: 'create',
      model: 'Post',
      dataSource: posts.name,
      description: 'create post by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'updatePost',
      type: 'update',
      model: 'Post',
      dataSource: posts.name,
      description: 'update post by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'deletePost',
      type: 'delete',
      model: 'Post',
      dataSource: posts.name,
      description: 'delete post by id',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'Post.author',
      type: 'field',
      model: 'Post',
      dataSource: authors.name,
      resolveWith_PK: 'authorId',
      description: 'get author of a Post',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'Post.comments',
      type: 'field',
      model: 'Post',
      dataSource: comments.name,
      description: 'get all comments for a Post',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'getAuthor',
      type: 'get',
      model: 'Author',
      dataSource: authors.name,
      description: 'get Author by name',
      isPipeline: true,
      resolveWith_PK: 'authorId',
      isDisabled: true
    },
    {
      id: uuidv4(),
      name: 'createAuthor',
      type: 'create',
      model: 'Author',
      dataSource: authors.name,
      description: 'create Author by name',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'Author.posts',
      type: 'field',
      model: 'Author',
      dataSource: posts.name,
      description: 'get all post by an Author',
      isPipeline: false
    },
    {
      id: uuidv4(),
      name: 'deleteAuthor',
      type: 'delete',
      model: 'Author',
      dataSource: authors.name,
      isPipeline: false,
      description: 'delete author by id',
      isDisabled: false
    },
    {
      id: uuidv4(),
      name: 'createComment',
      type: 'create',
      model: 'Comment',
      dataSource: comments.name,
      description: 'create a comment',
      isPipeline: false
    }

  ]

  const resources = [authA, authors, posts, comments]

  return { resources, resolvers, models }
}

export {
  singleTableDesignBluePrint,
  singleDataSourceBluePrint,
  multipleDataSourceBluePrint
}
