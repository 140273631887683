import React, { useEffect, useState } from 'react'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import { OrganizationUser as OrganizationUserType } from 'src/API'
import { Auth } from 'aws-amplify'
import decode from 'jwt-decode'
import { removeOrganizationUser } from 'src/data/organizations/mutations'
import Preloader from 'src/components/UI/preloader/Preloader'

const OrgTable: React.FC<{ orgId: string, orgUsers: OrganizationUserType[] }> = props => {
  const { orgId, orgUsers } = props
  const [showEdit, setShowEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function validateAdmin (orgId: string) {
    try {
      const authData = await Auth.currentAuthenticatedUser()
      const decoded: { orgId: string } = decode(authData.signInUserSession.idToken.jwtToken)
      setShowEdit(decoded.orgId === orgId)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (orgUsers.length) return
    validateAdmin(orgId)
  }, [])

  const handleDelete = async (userId: string) => {
    if (!orgId) return
    try {
      setIsLoading(true)
      await removeOrganizationUser(orgId, userId)
    } catch (err) {
      console.log(err)
    }

    setIsLoading(false)
  }

  return (
    <div>
      <div className="mt-1 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full px-7">
            <div className="shadow overflow-hidden  rounded-lg">
              <table className="min-w-full">
                <thead className="bg-[#2B2D31]">
                <tr>
                  <th scope="col" className="px-6 py-5 text-left text-sm font-bold text-[#7E858F]">
                    Name
                  </th>
                  <th scope="col" className="px-6   py-5 text-left text-sm font-bold text-[#7E858F]">
                    User
                  </th>
                  <th scope="col" className="px-6 py-5 text-right text-sm font-bold text-[#7E858F]">
                    Actions
                  </th>

                </tr>
                </thead>
                <tbody className="">
                {orgUsers.map((user, index) =>
                  <tr className={`${index % 2 === 0 ? 'bg-[#171B21]' : 'bg-[#020409]'}`} key={user.id}>
                    <td className="px-6 py-4 text-left whitespace-nowrap text-xs font-medium text-[#7E858F]">
                      { user.userProfile?.firstName } { user.userProfile?.lastName }
                    </td>
                    <td className="px-6  py-4 text-left whitespace-nowrap text-xs text-[#7E858F]">
                      {user.email}
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap text-xs text-[#7E858F]">
                      {showEdit && <>
                        <div className={'flex gap-4 justify-end'}>
                          <TrashBinIcon onClick={() => {
                            handleDelete(user.id)
                          }} className={'pointer'}/>
                        </div>
                      </>}
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Preloader open={isLoading} modalText={'Loading...'}></Preloader>
    </div>

  )
}

export default OrgTable
