import React, { useContext, useEffect, useState } from 'react'
import ApplicationContext from '../../../../../store/application-context-provider'
import ResourceFormControls from './ResourceFormControls'
import { DYNAMO_BILLING_MODE_TYPE, DYNAMO_KEY_TYPE, DYNAMO_PRIMARY_KEY_TYPE, PROJECTION_TYPE } from 'src/enums'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { checkDataSourceName, checkUniqueGsiNames, isValidGsiList } from 'src/utils/validation'
import { Button } from 'src/components/atoms/button'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp-gray.svg'
import { SecondaryIndexesRow } from 'src/components/molecules/secondary-indexes-row/SecondaryIndexesRow'
import { Alert } from 'src/components/atoms/alert'

export interface Gsi {
  gsiId: string
  gsiName: string
  gsiKeyType: DYNAMO_KEY_TYPE
  primaryAttribute: string
  primaryAttributeType: string
  secondaryAttribute?: string
  secondaryAttributeType?: string
  projectionType: PROJECTION_TYPE
}

interface FormDynamo {
  name: string
  description: string
  billingMode: DYNAMO_BILLING_MODE_TYPE
  readCapacity: string
  writeCapacity: string
  keyType: DYNAMO_KEY_TYPE | ''
  primaryKeyName: string
  primaryKeyType: DYNAMO_PRIMARY_KEY_TYPE | ''
  secondaryKeyName: string
  secondaryKeyType: DYNAMO_PRIMARY_KEY_TYPE | ''
  index: 'False' | 'True'
}

const ResourceDynamoForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props
  const ctx = useContext(ApplicationContext)
  const [gsiList, setGsiList] = useState<Gsi[]>([])
  const [isIndexAlert, setIsIndexAlert] = useState(false)

  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
      .max(25)
      .test(
        'Name',
        'Please enter an identifier that starts with a letter (A-Z, a-z) or an underscore, followed by any combination of letters (A-Z, a-z), digits (0-9), or underscores. For example, valid identifiers can be: \'myIdentifier\', \'valid_ID_123\', \'_anotherIdentifier\'."',
        (value) => {
          if (value) return checkDataSourceName(value)
          return true
        }
      ),
    keyType: yup
      .string()
      .required('This field is required'),
    primaryKeyName: yup
      .string()
      .required('This field is required'),
    primaryKeyType: yup
      .string()
      .required('This field is required'),
    secondaryKeyName: yup
      .string()
      .when('keyType', {
        is: 'COMPOSITE',
        then: yup.string().required('This field is required'),
        otherwise: yup.string().notRequired()
      }),
    secondaryKeyType: yup
      .string()
      .when('keyType', {
        is: 'COMPOSITE',
        then: yup.string().required('This field is required'),
        otherwise: yup.string().notRequired()
      }),
    index: yup
      .string()
      .required('This field is required')
  })
  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: {
      errors,
      isValid
    },
    reset,
    trigger
  } = useForm<FormDynamo>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
      billingMode: DYNAMO_BILLING_MODE_TYPE.PAY_PER_REQUEST,
      readCapacity: '1',
      writeCapacity: '1',
      keyType: '',
      primaryKeyName: '',
      primaryKeyType: '',
      secondaryKeyName: '',
      secondaryKeyType: '',
      index: 'False'
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: ctx?.resourceInput?.name,
      description: ctx?.resourceInput?.description ?? '',
      billingMode: ctx?.resourceInput?.billingMode,
      readCapacity: ctx?.resourceInput?.readCapacity ?? '',
      writeCapacity: ctx?.resourceInput?.writeCapacity ?? '',
      keyType: ctx?.resourceInput?.keyType ?? '',
      primaryKeyName: ctx?.resourceInput?.primaryKeyName ?? '',
      primaryKeyType: ctx?.resourceInput?.primaryKeyType ?? '',
      secondaryKeyName: ctx?.resourceInput?.secondaryKeyName ?? '',
      secondaryKeyType: ctx?.resourceInput?.secondaryKeyType ?? '',
      index: ctx?.resourceInput?.hasIndex === true ? 'True' : 'False'
    })
  }, [reset])

  useEffect(() => {
    setGsiList(ctx?.resourceInput?.GSI)
  }, [])

  useEffect(() => {
    setIsIndexAlert(!checkUniqueGsiNames(gsiList ?? []))
  }, [gsiList])

  const indexNameHandler = (e: Event | any, gsi: Gsi) => {
    const editData = gsiList.map((f) =>
      f.gsiId === gsi.gsiId ? { ...f, gsiName: e.target.value } : f
    )
    setGsiList(editData)
  }
  const primaryKeyNameHandler = (e: Event | any, gsi: Gsi) => {
    const editData = gsiList.map((f) =>
      f.gsiId === gsi.gsiId ? { ...f, primaryAttribute: e.target.value } : f
    )
    setGsiList(editData)
  }
  const secondaryKeyNameHandler = (e: Event | any, gsi: Gsi) => {
    const editData = gsiList.map((f) =>
      f.gsiId === gsi.gsiId ? { ...f, secondaryAttribute: e.target.value } : f
    )
    setGsiList(editData)
  }
  const keyTypeHandler = (type: any, gsi: Gsi) => {
    const editData = gsiList.map((f) =>
      f.gsiId === gsi.gsiId
        ? type === DYNAMO_KEY_TYPE.SIMPLE
          ? {
              gsiId: f.gsiId,
              gsiName: f.gsiName,
              gsiKeyType: DYNAMO_KEY_TYPE.SIMPLE,
              primaryAttribute: f.primaryAttribute,
              primaryAttributeType: f.secondaryAttributeType,
              projectionType: f.projectionType
            }
          : { ...f, gsiKeyType: type }
        : f
    )
    setGsiList(editData as Gsi[])
  }
  const primaryKeyTypeHandler = (type: any, gsi: Gsi) => {
    const editData = gsiList.map((f) =>
      f.gsiId === gsi.gsiId ? { ...f, primaryAttributeType: type } : f
    )
    setGsiList(editData)
  }
  const secondaryKeyTypeHandler = (type: any, gsi: Gsi) => {
    const editData = gsiList.map((f) =>
      f.gsiId === gsi.gsiId ? { ...f, secondaryAttributeType: type } : f
    )
    setGsiList(editData)
  }
  const attributeProjectionsHandler = (type: any, gsi: Gsi) => {
    const editData = gsiList.map((f) =>
      f.gsiId === gsi.gsiId ? { ...f, projectionType: type } : f
    )
    setGsiList(editData)
  }
  const confirmGSIDelete = (gsi: Gsi) => {
    const editData = gsiList.filter((f) => f.gsiId !== gsi.gsiId)
    setGsiList(editData)
  }
  const onSubmit = (data: FormDynamo) => {
    const serviceBuilder = ctx?.serviceBuilder
    serviceBuilder.resolvers = serviceBuilder.resolvers.map((res: any) => res.dataSource === ctx?.resourceInput?.name
      ? ({
          ...res, dataSource: data.name
        })
      : res)

    serviceBuilder.models = serviceBuilder.models.map((m: any) => m.dataSource?.id === ctx?.resourceInput?.id
      ? ({
          ...m, dataSource: { id: m?.dataSource?.id, type: m?.dataSource?.type, name: data.name }
        })
      : m)
    ctx?.setServiceBuilder(serviceBuilder)
    const input = ctx?.resourceInput
    input.name = data.name
    input.description = data.description ?? ''
    input.billingMode = data.billingMode
    input.readCapacity = data.readCapacity ?? '1'
    input.writeCapacity = data.writeCapacity ?? '1'
    if (data.billingMode === DYNAMO_BILLING_MODE_TYPE.PAY_PER_REQUEST) {
      delete input.readCapacity
      delete input.writeCapacity
    }
    input.keyType = data.keyType
    input.primaryKeyName = data.primaryKeyName ?? ''
    input.primaryKeyType = data.primaryKeyType ?? ''
    input.secondaryKeyName = data.secondaryKeyName ?? ''
    input.secondaryKeyType = data.secondaryKeyType ?? ''
    if (data.keyType === DYNAMO_KEY_TYPE.SIMPLE) {
      delete input.secondaryKeyName
      delete input.secondaryKeyType
    }

    input.hasIndex = data.index === 'True'
    if (data.index === 'True') {
      input.GSI = gsiList
    } else {
      delete input.GSI
    }
    console.log('input Dynamo', input)
    ctx?.setResourceInput(input)
    submit()
  }
  return (
    <form>
      <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
        <Controller
          name={'name'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('name', { maxLength: 25 })}
            required
            labelText={'Table Name'}
            maxLength={25}
            showRequired
            error={errors?.name?.message}
            id="resourceName"
            onChange={(e) => {
              trigger('name')
              onChange(e)
            }}
            onBlur={onBlur}
            value={value}/>)}
        />

        <Controller
          name={'description'}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('description', { maxLength: 60 })}
            labelText={'Table Description'}
            maxLength={60}
            error={errors?.description?.message}
            id="resourceName"
            onChange={onChange}
            onBlur={onBlur}
            value={value}/>)}
        />

        <Controller
          name={'billingMode'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              error={errors?.billingMode?.message}
              selected={value}
              options={[
                { name: 'PAY PER REQUEST', value: DYNAMO_BILLING_MODE_TYPE.PAY_PER_REQUEST },
                { name: 'PROVISIONED', value: DYNAMO_BILLING_MODE_TYPE.PROVISIONED }
              ]
              }
              labelText={'Billing Mode'}
              handleValueChange={(e) => {
                trigger('billingMode')
                onChange(e)
              }}/>
          )}
        />
        {getValues().billingMode === DYNAMO_BILLING_MODE_TYPE.PROVISIONED && (
          <>
            <Controller
              name={'readCapacity'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                {...register('readCapacity', { maxLength: 2 })}
                labelText={'READ Capacity'}
                maxLength={2}
                defaultValue={'1'}
                error={errors?.readCapacity?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value?.toLowerCase().replace(/\D/, '')}/>)}
            />
            <Controller
              name={'writeCapacity'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                {...register('writeCapacity', { maxLength: 2 })}
                labelText={'WRITE Capacity'}
                maxLength={2}
                defaultValue={'1'}
                error={errors?.writeCapacity?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value?.toLowerCase().replace(/\D/, '')}/>)}
            />
          </>
        )}
        <Controller
          name={'keyType'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              showRequired
              error={errors?.keyType?.message}
              selected={value}
              options={[
                { name: 'Composite', value: DYNAMO_KEY_TYPE.COMPOSITE },
                { name: 'Simple', value: DYNAMO_KEY_TYPE.SIMPLE }
              ]
              }
              labelText={'Key Type'}
              handleValueChange={(e) => {
                trigger('keyType')
                onChange(e)
              }}/>
          )}
        />
        {(getValues().keyType === DYNAMO_KEY_TYPE.COMPOSITE || getValues().keyType === DYNAMO_KEY_TYPE.SIMPLE) && (
          <>
            <Controller
              name={'primaryKeyName'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                showRequired
                {...register('primaryKeyName', { maxLength: 12 })}
                labelText={'Primary Key Name'}
                maxLength={12}
                error={errors?.primaryKeyName?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value?.replace(/\s/g, '')}/>)}
            />
            <Controller
              name={'primaryKeyType'}
              control={control}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <SelectOutlinedInput
                  error={errors?.primaryKeyType?.message}
                  selected={value}
                  showRequired
                  options={[{ name: 'STRING', value: DYNAMO_PRIMARY_KEY_TYPE.STRING }]}
                  labelText={'Primary Key Type'}
                  handleValueChange={onChange}/>
              )}
            />
          </>
        )}

        {getValues().keyType === DYNAMO_KEY_TYPE.COMPOSITE && (
          <>
            <Controller
              name={'secondaryKeyName'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<TextInput
                showRequired
                {...register('secondaryKeyName')}
                labelText={'Secondary Key Name'}
                error={errors?.secondaryKeyName?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value?.replace(/\s/g, '')}/>)}
            />
            <Controller
              name={'secondaryKeyType'}
              control={control}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <SelectOutlinedInput
                  showRequired
                  error={errors?.secondaryKeyType?.message}
                  selected={value}
                  options={[{ name: 'STRING', value: DYNAMO_PRIMARY_KEY_TYPE.STRING }]}
                  labelText={'Secondary Key Type'}
                  handleValueChange={onChange}/>
              )}
            />
          </>
        )}

        <div className={'flex justify-between mt-8'}>
          <div className={'text-xl text-white'}>Secondary indexes</div>
          <Button iconLeft={<AddIcon/>} disabled={gsiList?.length === 20 || getValues().index === 'False'}
                  variant={'darker'} title={'Add Index'} type={'button'}
                  onClick={() => {
                    setGsiList([...gsiList ?? [], {
                      gsiId: uuidv4(),
                      gsiName: '',
                      gsiKeyType: DYNAMO_KEY_TYPE.SIMPLE,
                      primaryAttribute: '',
                      primaryAttributeType: '',
                      projectionType: PROJECTION_TYPE.ALL
                    }])
                  }}/>
        </div>
        {isIndexAlert && <Alert variant={'error'}>This index name is already in use, please choose a unique name.</Alert>}
        <Controller
          name={'index'}
          control={control}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <SelectOutlinedInput
              error={errors?.index?.message}
              selected={value}
              options={[{ name: 'NONE', value: 'False' },
                { name: 'GLOBAL SECONDARY', value: 'True' }

              ]}
              labelText={'Index:'}
              handleValueChange={(e) => {
                trigger('index')
                if (e === 'True') {
                  setGsiList([...gsiList ?? [], {
                    gsiId: uuidv4(),
                    gsiName: '',
                    gsiKeyType: DYNAMO_KEY_TYPE.SIMPLE,
                    primaryAttribute: '',
                    primaryAttributeType: '',
                    projectionType: PROJECTION_TYPE.ALL
                  }])
                } else {
                  setGsiList([])
                }
                onChange(e)
              }}/>
          )}
        />
        {gsiList?.map((gsi: Gsi) => <SecondaryIndexesRow gsi={gsi} key={gsi.gsiId}
                                                         indexNameHandler={indexNameHandler}
                                                         keyTypeHandler={keyTypeHandler}
                                                         primaryKeyNameHandler={primaryKeyNameHandler}
                                                         primaryKeyTypeHandler={primaryKeyTypeHandler}
                                                         secondaryKeyNameHandler={secondaryKeyNameHandler}
                                                         secondaryKeyTypeHandler={secondaryKeyTypeHandler}
                                                         attributeProjectionsHandler={attributeProjectionsHandler}
                                                         confirmGSIDelete={confirmGSIDelete}
        />)}
      </div>
      <ResourceFormControls isDisabledSaveButton={!isValid || isIndexAlert || !isValidGsiList(gsiList)}
                            onSubmit={handleSubmit(onSubmit)} onClose={close}></ResourceFormControls>
    </form>
  )
}

export default ResourceDynamoForm
