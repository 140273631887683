import { GetServiceInput, ListServicesInput } from '../../API'
import { API, graphqlOperation } from 'aws-amplify'
import { getService, listServices, listServicesByOrg, scanServicesByName, findServicesByName } from '../../graphql/queries'
import { GraphQLResult } from '@aws-amplify/api'

export const fetchServices = async (input: ListServicesInput) => {
  const listServicesResult = (await API.graphql(
    graphqlOperation(listServices, { input })
  )) as GraphQLResult<{ listServices: { Services: any, nextToken: any, scannedCount: any } }>
  return {
    services: listServicesResult?.data?.listServices?.Services,
    nextToken: listServicesResult?.data?.listServices?.nextToken,
    scannedCount: listServicesResult?.data?.listServices?.scannedCount
  }
}

export const fetchServicesByOrgId = async (input: ListServicesInput) => {
  const listServicesByOrgResult = (await API.graphql(
    graphqlOperation(listServicesByOrg, { input })
  )) as GraphQLResult<{ listServicesByOrg: { Services: any, nextToken: any, scannedCount: any } }>
  return {
    services: listServicesByOrgResult?.data?.listServicesByOrg?.Services,
    nextToken: listServicesByOrgResult?.data?.listServicesByOrg?.nextToken,
    scannedCount: listServicesByOrgResult?.data?.listServicesByOrg?.scannedCount
  }
}

export const getServiceById = async (input: GetServiceInput) => {
  const getServiceResult = (await API.graphql(
    graphqlOperation(getService, { input })
  )) as GraphQLResult<{ getService: any }>

  return getServiceResult?.data?.getService
}
export const searchServicesByName = async (input: ListServicesInput) => {
  const scanServicesByNameResult = (await API.graphql(
    graphqlOperation(scanServicesByName, { input })
  )) as GraphQLResult<{ scanServicesByName: { Services: any, nextToken: any, scannedCount: any } }>

  return {
    services: scanServicesByNameResult?.data?.scanServicesByName?.Services,
    nextToken: scanServicesByNameResult?.data?.scanServicesByName?.nextToken,
    scannedCount: scanServicesByNameResult?.data?.scanServicesByName?.scannedCount
  }
}
export const validateServiceUniqueName = async (input: ListServicesInput) => {
  const findServicesByNameResult = (await API.graphql(
    graphqlOperation(findServicesByName, { input })
  )) as GraphQLResult<{ findServicesByName: { Services: any, nextToken: any, scannedCount: any } }>

  return {
    services: findServicesByNameResult?.data?.findServicesByName?.Services,
    nextToken: findServicesByNameResult?.data?.findServicesByName?.nextToken,
    scannedCount: findServicesByNameResult?.data?.findServicesByName?.scannedCount
  }
}
