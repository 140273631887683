import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextInput } from 'src/components/atoms/text-input'
import { Button } from 'src/components/atoms/button'
import ApplicationContext from 'src/store/application-context-provider'
import { updateOrganization } from 'src/data/organizations/mutations'
import { Organization } from 'src/API'
import Preloader from 'src/components/UI/preloader/Preloader'
import { Auth } from 'aws-amplify'
import decode from 'jwt-decode'

interface FormAboutOrg {
  name: string
}
export const AboutOrganization = () => {
  const [isLoading, setIsloading] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const ctx = useContext(ApplicationContext)
  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
  })
  const {
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormAboutOrg>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema)
  })

  const org = JSON.parse(localStorage.getItem('selectedOrganization') as string)

  async function validateOrgOwnership (orgId: string) {
    try {
      const authData = await Auth.currentAuthenticatedUser()
      const decoded: { orgId: string } = decode(authData.signInUserSession.idToken.jwtToken)
      setShowEdit(decoded.orgId === orgId)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    validateOrgOwnership(org.id)
  }, [org])

  useEffect(() => {
    reset({
      name: ctx?.selectedOrganization?.name ?? JSON.parse(localStorage.getItem('selectedOrganization') as string)?.name
    })
  }, [reset])

  const onSubmit = async (data: FormAboutOrg) => {
    try {
      setIsloading(true)
      const org = await updateOrganization(JSON.parse(localStorage.getItem('selectedOrganization') as string)?.id as string, data.name)
      ctx?.setSelectedOrganization(org as Organization)
      localStorage.setItem('selectedOrganization', JSON.stringify(org))
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className={'flex flex-col  px-6 py-[30px] rounded-2xl border-[#171B21] border-[1px]'}>
        <div className="text-lg font-bold text-white pb-[4px]">About Organization</div>
        <Controller
          name={'name'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('name', { maxLength: 25 })}
            labelText={'Organization name:'}
            error={errors?.name?.message}
            className={'w-1/3'}
            maxLength={25}
            showRequired={true}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={!showEdit}/>)}
        />
        <div className={'flex pt-[32px] justify-start'}>
          {showEdit && <>
            <Button title={'Save changes'} disabled={!isDirty || !isValid} variant={'darker'}
                    onClick={handleSubmit(onSubmit)} type={'submit'}/>
          </>}
        </div>
      </div>
      <Preloader open={isLoading} modalText={'Loading...'}/>
    </>
  )
}
