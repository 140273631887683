import React, { useState, useEffect } from 'react'
import { Button } from 'src/components/atoms/button'
import { OrganizationUser as OrganizationUserType } from 'src/API'
import { fetchOrganizationUsers } from 'src/data/organizations/queries'
import AddNewOrgUser from 'src/components/organisms/organization-settings/add-new-org-user/AddNewOrgUser'
import OrgTable from 'src/components/organisms/organization-settings/org-table/OrgTable'
import { Auth } from 'aws-amplify'
import decode from 'jwt-decode'

const YourOrganization: React.FC = (props) => {
  const [isOpenNewOrgUser, setIsOpenNewOrgUser] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [orgUsers, setOrganizationUsers] = useState<OrganizationUserType[]>([])

  const org = JSON.parse(localStorage.getItem('selectedOrganization') as string)

  async function validateOrgOwnership (orgId: string) {
    try {
      const authData = await Auth.currentAuthenticatedUser()
      const decoded: { orgId: string } = decode(authData.signInUserSession.idToken.jwtToken)
      setShowEdit(decoded.orgId === orgId)
      const data = await fetchOrganizationUsers(orgId)
      setOrganizationUsers(data as OrganizationUserType[])
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    validateOrgOwnership(org.id)
  }, [])

  return (<>
    <div className={'rounded-2xl flex items-center justify-between  p-6 border-[#171B21]  border-[1px]'}>
        <div className={'font-bold text-lg'}>Users ({orgUsers.length} / 3 slots)</div>
        {showEdit && <>
          <div className={'flex gap-[8px]'}>
            {orgUsers.length < 3 &&
              <Button variant={'darker'} title={'Add new user'} onClick={() => { setIsOpenNewOrgUser(true) }} type={'button'} />
            }
          </div>
        </>}
      </div>
      <OrgTable orgId={org.id} orgUsers={orgUsers}/>
      <AddNewOrgUser isOpen={isOpenNewOrgUser} onCancel={() => { setIsOpenNewOrgUser(false) }}/>
    </>
  )
}

export default YourOrganization
