import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter'
    ].join(','),
    button: {
      textTransform: undefined
    }
  },

  palette: {
    primary: {
      main: '#7467ef'
    },
    secondary: {
      main: '#ff9e43'
    }
  }
})

export default theme
