import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'src/components/atoms/button'
import { Auth } from 'aws-amplify'
import Preloader from 'src/components/UI/preloader/Preloader'
import { fetchUserProfile } from 'src/data/userProfile/queries'
import { TextInput } from 'src/components/atoms/text-input'
import * as yup from 'yup'
import { isValidEmail } from 'src/utils/validation'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateProfile } from 'src/data/userProfile/mutations'
import ApplicationContext from 'src/store/application-context-provider'

interface FormUserProfile {
  bio: string
  email: string
  firstName: string
  lastName: string
}
export interface UserProfileResponse {
  __typename: 'UserProfile'
  id: string
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  bio?: string | null
  avatar?: string | null
  updatedAt?: string | null
  createdAt?: string | null
}

const PersonalInformation: React.FC = (props) => {
  const [user, setUser] = useState<UserProfileResponse>()
  const [isLoading, setIsloading] = useState(false)
  const ctx = useContext(ApplicationContext)
  const schema = yup.object({
    bio: yup
      .string(),
    firstName: yup
      .string(),

    lastName: yup
      .string(),

    email: yup
      .string()
      .test(
        'Email',
        'Please enter a valid email address',
        (value) => {
          if (value) return isValidEmail(value)
          return true
        }
      )
  })
  const {
    setValue,
    trigger,
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormUserProfile>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      bio: '',
      email: '',
      lastName: '',
      firstName: ''
    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      bio: user?.bio ?? '',
      email: user?.email ?? '',
      lastName: user?.lastName ?? '',
      firstName: user?.firstName ?? ''
    })
  }, [reset])

  const getUserProfile = async () => {
    try {
      setIsloading(true)
      const authUser = await Auth.currentAuthenticatedUser()
      const data = await fetchUserProfile({ id: authUser?.attributes?.sub })
      setUser(data)
      setValue('firstName', data.firstName)
      setValue('lastName', data.lastName)
      setValue('email', data.email)
      setValue('bio', data.bio)
      setIsloading(false)
    } catch (err) {
      setIsloading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    getUserProfile()
  }, [ctx?.isUpdateUserProfile])

  const onSubmit = async (data: FormUserProfile) => {
    try {
      setIsloading(true)
      const result = await updateProfile({
        firstName: (data.firstName).trim(),
        lastName: (data.lastName).trim(),
        avatar: '',
        bio: (data.bio).trim()
      })
      ctx?.setIsUpdateUserProfile(true)
      setUser(result)
      setIsloading(false)
      ctx?.setIsUpdateUserProfile(false)
    } catch (err) {
      ctx?.setIsUpdateUserProfile(false)
      setIsloading(false)
      console.log(err)
    }
  }
  return (<form>
      <div className={'flex items-center gap-9 p-6 rounded-2xl border-[#171B21] border-[1px]'}>
        <img alt={'avatar'} src={`https://ui-avatars.com/api/?name=${user?.email}`} className={'w-[90px] h-[90px] rounded-full '}/>
        <div className={'flex flex-col gap-2'}>
          <div className={'text-sm font-bold'}>{user?.firstName}&nbsp;{user?.lastName}</div>
          <div className={'text-xs text-[#7E858F] flex flex-col'}>
            {/* <span>Team Manager @Air Canada</span> */}
            <span>{user?.email}</span>
          </div>
        </div>
      </div>
      <div className={'rounded-2xl mt-[16px] p-6 border-[#171B21]  border-[1px]'}>
        <div className={'font-bold text-lg'}>Personal information</div>
        <div className={'mt-[16px] grid grid-cols-[repeat(auto-fill,minmax(360px,4fr))] gap-x-6  gap-y-4 '}>
          <Controller
            name={'firstName'}
            rules={{ required: false }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('firstName', { maxLength: 25 })}
              className={'max-w-sm'}
              labelText={'First Name:'}
              error={errors?.firstName?.message}
              maxLength={25}
              onChange={(e) => {
                trigger('firstName')
                onChange(e)
              }}
              onBlur={onBlur}
              value={value}/>)}
          />
          <Controller
            name={'lastName'}
            rules={{ required: false }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('lastName', { maxLength: 25 })}
              className={'max-w-sm'}
              labelText={'Last Name:'}
              error={errors?.lastName?.message}
              maxLength={25}
              onChange={(e) => {
                trigger('lastName')
                onChange(e)
              }}
              onBlur={onBlur}
              value={value}/>)}
          />
          <Controller
            name={'email'}
            rules={{ required: true }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('email')}
              labelText={'User / Email:'}
              disabled={true}
              error={errors?.email?.message}
              className={'max-w-sm'}
              onChange={(e) => {
                trigger('email')
                onChange(e)
              }}
              onBlur={onBlur}
              value={value}/>)}
          />
          <Controller
            name={'bio'}
            rules={{ required: false }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('bio', { maxLength: 25 })}
              className={'max-w-sm'}
              labelText={'Bio:'}
              maxLength={25}
              error={errors?.bio?.message}
              onChange={(e) => {
                trigger('bio')
                onChange(e)
              }}
              onBlur={onBlur}
              value={value}/>)}
          />
        </div>
        <Button classname={'mt-[32px]'} variant={'darker'} title={'Save Changes'} disabled={!isDirty || !isValid} onClick={handleSubmit(onSubmit)} type={'submit'}/>
      </div>
      <Preloader open={isLoading} modalText={'Loading...'}/>
    </form>
  )
}

export default PersonalInformation
