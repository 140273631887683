import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { parseSchema } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'
import { ParseSchemaInput } from 'src/API'

export const addSchema = async (input: ParseSchemaInput) => {
  const addSchemaResult = (await API.graphql(
    graphqlOperation(parseSchema, { input })
  )) as GraphQLResult<{ parseSchema: { status: string, result: string } }>
  const newSchema = (addSchemaResult?.data)
  return newSchema
}
