import React from 'react'
import { AccountDropdown } from 'src/components/molecules/account-dropdown'
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo-cloudgto.svg'
const w = window as any
export const Header: React.FC<{ toggleSidebar: () => void }> = props => {
  const goHome = () => {
    w.location.href = '/'
  }

  return (
      <header className="bg-[#171B21] fixed top-0 left-0 w-full z-40 flex py-4 items-center">
      <div className="ml-6 flex items-center justify-center bg-[#171B21]">
        <div onClick={goHome} className='pointer'>
          <LogoIcon height={24}/>
        </div>
      </div>
      <div className="ml-auto mr-6">
        <AccountDropdown/>
      </div>
    </header>)
}
