import ReactDOM from 'react-dom'
import App from './App'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
