import React, { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg'
import { classNames } from 'src/utils/css'
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up.svg'
import { Tooltip } from '../tooltip'
export interface SelectInputOption {
  name: string
  value: string | number
  disabled?: boolean
}

export interface SelectInputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string
  error?: string | undefined | boolean
  classname?: string
  options?: SelectInputOption[]
  handleValueChange: (data: string | number) => void
  selected?: string | number
  classNameOptions?: string
  bgOpenDropdown?: string
  labelText?: string
  children?: React.ReactNode
  showRequired?: boolean
  iconLeft?: React.ReactNode
  tooltipText?: string
}

export const SelectOutlinedInput: FC<SelectInputProps> = ({
  classname = '',
  showRequired = false,
  error,
  options,
  classNameOptions = '',
  labelText = '',
  handleValueChange,
  selected,
  placeholder,
  bgOpenDropdown = 'text-[#7E858F]',
  iconLeft,
  tooltipText = '',
  ...props
}) => (
  <div className={classname}>
    <label
        className={`block mt-[6px] text-xs ${error ? 'text-red-default' : props.disabled ? 'text-[#7E858F]' : 'text-white'} `}
    >
      {labelText}{showRequired && <span className={'ml-[4px] text-red-default'}>*</span>}
      {tooltipText &&
        <Tooltip text={tooltipText} />
      }
    </label>
    <Listbox disabled={props.disabled} value={selected} onChange={handleValueChange}>
      {({ open }) => (
        <div className="relative  mt-[6px]">
          <Listbox.Button
            className={classNames(
              `bg-[#2B2D31] min-h-[42px] ${props.disabled ? 'text-[#7E858F]' : 'text-white'}  cursor-pointer text-xs  relative w-full rounded-lg  py-3 pl-4 pr-10 text-left  focus:outline-none ${open && 'ring-2'} ring-[#826AED] `,
                `${error && 'ring-2 ring-red-default'}`
            )}
          >
            {iconLeft && <span
            className="
              bg-black-darker border-2 border-[#2B2D31] rounded-l-lg box-border  absolute inset-y-0 left-0 flex items-center px-2"
          >
           {iconLeft}
            </span>}
            {options?.find((o) => o.value === selected)?.name || placeholder}
            <span
              className="
              bg-black-darker border-2 border-[#2B2D31] rounded-r-lg box-border pointer-events-none absolute inset-y-0 right-0 flex items-center px-2"
            >
            { open
              ? <ArrowUpIcon />
              : <ArrowDownIcon />
             }
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={classNames('absolute mt-0.5 py-0 z-40  w-full overflow-auto rounded-lg bg-[#2B2D31] text-white  text-xs focus:outline-none ring-2 ring-[#826AED]', classNameOptions)}
            >
              {options?.map((o) => (
                  <Listbox.Option
                    disabled={o.disabled}
                      key={o.value}
                      className={({ active, disabled }) => `relative cursor-pointer select-none py-2 px-4 ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} ${disabled ? 'text-[#7E858F]' : 'text-white'} `}
                      value={o.value}
                  >
                    {o.name}
                  </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
    {error && <div className={'mt-[4px] text-[10px] text-red-default'}>{error}</div>}
  </div>
)
