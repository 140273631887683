import React, { createContext, useState } from 'react'
import { IAuthenticatedUser } from '../auth/cognito'
import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Organization, Team } from '../API'

interface AppContextInterface {
  serviceBuilder: any
  selectedServiceId: any
  authenticatedUser: IAuthenticatedUser | null | CognitoUser
  resourceInput: any
  routeInput: any
  modelInput: any
  enumInput: any
  subscriptionInput: any
  customTypesInput: any
  customObjectInput: any
  schemaFileResponse: any
  customTypesList: any[]
  serviceList: any
  resolvers: any
  selectedOrganization?: Organization | null
  selectedTeam?: Team | null
  isServiceEdit: boolean
  isRouteEdit: boolean
  resourceSearch: boolean
  modelsSearch: boolean
  isUpdateUserProfile: boolean
  isApiDetailsNextButtonDisabled: boolean
  isServiceNextButtonDisabled: boolean
  isMapResolversToModelsButtonDisabled: boolean
  isMapModelsToDataSourcesButtonDisabled: boolean
  routeSearch: boolean
  isShowDynamoAlert: boolean
  setIsServiceEdit: (value: boolean) => void
  setIsRouteEdit: (value: boolean) => void
  setRouteInput: (routeInput: any) => void
  setSubscriptionInput: (subscriptionInput: any) => void
  setResourceInput: (resourceInput: any) => void
  setSelectedServiceId: (id: any) => void
  setResourceSearch: (resourceType: string | string[]) => void
  setIsApiDetailsNextButtonDisabled: (apiDetails: boolean) => void
  setMapResolversToModelsButtonDisabled: (value: boolean) => void
  setMapModelsToDataSourcesButtonDisabled: (value: boolean) => void
  setIsServiceNextButtonDisabled: (value: boolean) => void
  setRouteSearch: (resourceType: string) => void
  setModelsSearch: (model: any) => void
  setServiceBuilder: (data: {}) => void
  setAuthenticatedUser: (authenticatedUser: IAuthenticatedUser | null | CognitoUser) => void
  signOut: () => void
  getSession: () => Promise<any>
  getCurrentUser: () => Promise<any>
  clearContext: () => void
  setSelectedOrganization: (organization: Organization | null) => void
  setSelectedTeam: (team: Team | null) => void
  setServiceList: (service: any) => void
  setResolvers: (resolver: any) => void
  setModelInput: (model: any) => void
  setEnumInput: (enumInput: any) => void
  setCustomObjectInput: (customInput: any) => void
  setIsShowDynamoAlert: (isShow: boolean) => void
  setCustomTypesInput: (customTypes: any) => void
  setCustomTypesList: (customTypesList: any) => void
  setSchemaFileResponse: (schemaFile: any) => void
  setIsUpdateUserProfile: (value: boolean) => void
}

const ApplicationContext = createContext<AppContextInterface | null>(null)

export const ApplicationContextProvider: React.FC = (props) => {
  const [serviceBuilder, setServiceBuilder] = useState({})
  const [selectedServiceId, setSelectedServiceId] = useState(null)
  const [resourceSearch, setResourceSearch] = useState(false)
  const [isApiDetailsNextButtonDisabled, setIsApiDetailsNextButtonDisabled] = useState(false)
  const [isServiceNextButtonDisabled, setIsServiceNextButtonDisabled] = useState(false)
  const [isMapResolversToModelsButtonDisabled, setMapResolversToModelsButtonDisabled] = useState(false)
  const [isMapModelsToDataSourcesButtonDisabled, setMapModelsToDataSourcesButtonDisabled] = useState(false)
  const [routeSearch, setRouteSearch] = useState(false)
  const [modelsSearch, setModelsSearch] = useState(false)
  const [resourceInput, setResourceInput] = useState({})
  const [subscriptionInput, setSubscriptionInput] = useState({})
  const [modelInput, setModelInput] = useState({})
  const [enumInput, setEnumInput] = useState({})
  const [customObjectInput, setCustomObjectInput] = useState({})
  const [routeInput, setRouteInput] = useState({})
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>()
  const [selectedTeam, setSelectedTeam] = useState<Team | null>()
  const [isServiceEdit, setIsServiceEdit] = useState(false)
  const [isRouteEdit, setIsRouteEdit] = useState(false)
  const [serviceList, setServiceList] = useState([] as any[])
  const [resolvers, setResolvers] = useState([])
  const [customTypesInput, setCustomTypesInput] = useState({})
  const [schemaFileResponse, setSchemaFileResponse] = useState({})
  const [customTypesList, setCustomTypesList] = useState([])
  const [isShowDynamoAlert, setIsShowDynamoAlert] = useState(false)
  const [isUpdateUserProfile, setIsUpdateUserProfile] = useState(false)

  const { children } = props

  const [authenticatedUser, setAuthenticatedUser] = useState<IAuthenticatedUser | null | CognitoUser>(null)
  const setAuthenticatedUserHandler = (authenticatedUser: IAuthenticatedUser | null | CognitoUser) => {
    setAuthenticatedUser(authenticatedUser)
  }

  const signOutHandler = async () => {
    try {
      await Auth.signOut()
      localStorage.clear()
      setAuthenticatedUser(null)
    } catch (err) {
      console.log(err)
    }
  }

  const getSessionHandler = async () => {
    try {
      const data = await Auth.currentSession()
      const idToken = data.getIdToken()
      const accessToken = data.getAccessToken()
      const payload = idToken.decodePayload()
      const user: IAuthenticatedUser = {
        id: payload.sub, email: payload.email, idToken: idToken.getJwtToken(), accessToken: accessToken.getJwtToken()
      }
      ctx?.setAuthenticatedUser(user)
    } catch (err) {
      console.log(err)
      ctx?.setAuthenticatedUser(null)
    }
  }

  const getCurrentUserHandler = async () => await Auth.currentAuthenticatedUser()

  const setServiceBuilderHandler = (data: any) => {
    setServiceBuilder(data)
  }

  const setSelectedServiceIdHandler = (data: any) => {
    setSelectedServiceId(data)
  }

  const setResourceSearchHandler = (resourceType: string | string[]) => {
    const types = Array.isArray(resourceType) ? resourceType : [resourceType]
    setResourceSearch(
      ctx?.serviceBuilder?.resources?.some((x: any) => {
        return types.every((type) =>
          ctx?.serviceBuilder?.resources?.some((resource: any) =>
            resource.resourceType === type && !resource.trigger
          )
        )
      })
    )
  }
  const setIsApiDetailsNextButtonDisabledHandler = (apiDetails: boolean) => {
    setIsApiDetailsNextButtonDisabled(apiDetails)
  }
  const setIsServiceNextButtonDisabledHandler = (value: boolean) => {
    setIsServiceNextButtonDisabled(value)
  }
  const setMapResolversToModelsButtonDisabledHandler = (value: boolean) => {
    setMapResolversToModelsButtonDisabled(value)
  }
  const setMapModelsToDataSourcesButtonDisabledHandler = (value: boolean) => {
    setMapModelsToDataSourcesButtonDisabled(value)
  }

  const setRouteSearchHandler = (routes: any) => {
    setRouteSearch(routes?.length > 0)
  }
  const setModelsSearchHandler = (models: any) => {
    setModelsSearch(models?.length > 0)
  }
  const setIsShowDynamoAlertHandler = (isShow: boolean) => {
    setIsShowDynamoAlert(isShow)
  }
  const setCustomTypesInputHandler = (customTypes: any) => {
    setCustomTypesInput(customTypes)
  }
  const setSchemaFileResponseHandler = (schemaFile: any) => {
    setSchemaFileResponse(schemaFile)
  }
  const setCustomTypesListHandler = (customTypesList: any) => {
    setCustomTypesList(customTypesList)
  }
  const setSubscriptionInputHandler = (subscrInput: any) => {
    setSubscriptionInput(subscrInput)
  }

  const clearContextHandler = () => {
    setServiceBuilder({})
    setSelectedServiceId(null)
  }

  const setResourceInputHandler = (resourceInput: any) => {
    setResourceInput(resourceInput)
  }
  const setRouteInputHandler = (routeInput: any) => {
    setRouteInput(routeInput)
  }

  const setSelectedOrganizationHandler = (selectedOrganization: Organization | null) => {
    setSelectedOrganization(selectedOrganization)
  }

  const setSelectedTeamHandler = (selectedTeam: Team | null) => {
    setSelectedTeam(selectedTeam)
  }

  const setIsServiceEditHandler = (isServiceEdit: boolean) => {
    setIsServiceEdit(isServiceEdit)
  }
  const setIsUpdateUserProfileHandler = (isUpdate: boolean) => {
    setIsUpdateUserProfile(isUpdate)
  }
  const setModelInputHandler = (model: any) => {
    setModelInput(model)
  }
  const setEnumInputHandler = (enumInput: any) => {
    setEnumInput(enumInput)
  }
  const setCustomObjectInputHandler = (customInput: any) => {
    setCustomObjectInput(customInput)
  }

  const setIsRouteEditHandler = (isRouteEdit: boolean) => {
    setIsRouteEdit(isRouteEdit)
  }
  const setServiceListHandler = (service: any) => {
    setServiceList(service)
  }

  const setResolversHandler = (resolver: any) => {
    setResolvers(resolver)
  }

  const ctx: AppContextInterface = {
    subscriptionInput,
    customObjectInput,
    schemaFileResponse,
    customTypesList,
    customTypesInput,
    isShowDynamoAlert,
    modelsSearch,
    modelInput,
    enumInput,
    resolvers,
    isApiDetailsNextButtonDisabled,
    isMapResolversToModelsButtonDisabled,
    isMapModelsToDataSourcesButtonDisabled,
    serviceList,
    resourceInput,
    routeInput,
    selectedServiceId,
    serviceBuilder,
    authenticatedUser,
    selectedOrganization,
    selectedTeam,
    isServiceEdit,
    isRouteEdit,
    resourceSearch,
    routeSearch,
    isServiceNextButtonDisabled,
    isUpdateUserProfile,
    setIsServiceEdit: setIsServiceEditHandler,
    setIsUpdateUserProfile: setIsUpdateUserProfileHandler,
    setIsRouteEdit: setIsRouteEditHandler,
    setRouteInput: setRouteInputHandler,
    setResourceInput: setResourceInputHandler,
    setSelectedServiceId: setSelectedServiceIdHandler,
    setResourceSearch: setResourceSearchHandler,
    setIsApiDetailsNextButtonDisabled: setIsApiDetailsNextButtonDisabledHandler,
    setRouteSearch: setRouteSearchHandler,
    setServiceBuilder: setServiceBuilderHandler,
    setAuthenticatedUser: setAuthenticatedUserHandler,
    signOut: signOutHandler,
    getSession: getSessionHandler,
    getCurrentUser: getCurrentUserHandler,
    clearContext: clearContextHandler,
    setSelectedOrganization: setSelectedOrganizationHandler,
    setSelectedTeam: setSelectedTeamHandler,
    setServiceList: setServiceListHandler,
    setIsServiceNextButtonDisabled: setIsServiceNextButtonDisabledHandler,
    setMapResolversToModelsButtonDisabled: setMapResolversToModelsButtonDisabledHandler,
    setMapModelsToDataSourcesButtonDisabled: setMapModelsToDataSourcesButtonDisabledHandler,
    setResolvers: setResolversHandler,
    setModelInput: setModelInputHandler,
    setEnumInput: setEnumInputHandler,
    setCustomObjectInput: setCustomObjectInputHandler,
    setModelsSearch: setModelsSearchHandler,
    setIsShowDynamoAlert: setIsShowDynamoAlertHandler,
    setCustomTypesInput: setCustomTypesInputHandler,
    setSchemaFileResponse: setSchemaFileResponseHandler,
    setCustomTypesList: setCustomTypesListHandler,
    setSubscriptionInput: setSubscriptionInputHandler
  }

  return (
    <ApplicationContext.Provider value={ctx}>
      {children}
    </ApplicationContext.Provider>
  )
}

export default ApplicationContext
