import { ReactComponent as CodeSlashIcon } from 'src/assets/icons/code-slash.svg'
import { ReactComponent as CloudDownLoadIcon } from 'src/assets/icons/cloud-download.svg'
import { ReactComponent as CreateIcon } from 'src/assets/icons/create.svg'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import React from 'react'
import { Service } from 'src/API'

export const ServiceBuilderTable: React.FC<{ confirmDelete: (id: string) => void, download: (id: string) => void, previewCode: (id: string) => void, editService: (id: string) => void, services: Service[] }> = props => {
  const { confirmDelete, editService, previewCode, download, services } = props
  return (
      <div className="mt-11 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full px-7">
                  <div className="shadow overflow-hidden  rounded-lg">
                      <table className="min-w-full">
                          <thead className="bg-[#2B2D31]">
                          <tr>
                              <th scope="col" className="px-6 py-5 text-left text-sm font-bold text-[#7E858F]">
                                  Name
                              </th>
                              <th scope="col" className="px-6   py-5 text-left text-sm font-bold text-[#7E858F]">
                                  Use Case
                              </th>
                              <th scope="col" className="px-6 py-5  text-sm font-bold text-[#7E858F]">
                                  Last change
                              </th>
                              <th scope="col" className="px-6 py-5 text-right text-sm font-bold text-[#7E858F]">
                                  Actions
                              </th>

                          </tr>
                          </thead>
                          <tbody className="">
                          {services.map((service, index) =>
                              <tr className={`${index % 2 === 0 ? 'bg-[#171B21]' : 'bg-[#020409]'}`} key={service.id}>
                                  <td className="px-6 py-4 cursor-pointer text-left whitespace-nowrap text-xs font-medium text-[#7E858F]" onClick={() => { previewCode(service.id) }}>
                                      {service.name}
                                  </td>
                                  <td className="px-6  py-4 text-left whitespace-nowrap text-xs text-[#7E858F]">
                                      {service.useCase}
                                  </td>
                                  <td className="px-6 py-4 text-center whitespace-nowrap text-xs text-[#7E858F]">
                                      {service.updatedAt && new Date(service.updatedAt).toLocaleString()}
                                  </td>
                                  <td className="px-6 py-4 text-right whitespace-nowrap text-xs text-[#7E858F]">
                                      <div className={'flex gap-4 justify-end'}>
                                          <CodeSlashIcon onClick={() => { previewCode(service.id) }} className={'pointer'} />
                                          <CloudDownLoadIcon onClick={() => { download(service.id) }} className={'pointer'} />
                                          {service.isServiceOwner && <>
                                            <CreateIcon onClick={() => { editService(service.id) }} className={'pointer'} />
                                            <TrashBinIcon onClick={() => { confirmDelete(service.id) }} className={'pointer'}/>
                                          </>}
                                      </div>
                                  </td>

                              </tr>)}

                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </div>
  )
}
export default ServiceBuilderTable
