import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { Cognito, CreateCognitoInput, UpdateCognitoInput } from '../../API'
import { createCognito, deleteCognito, updateCognito as updateCognitoMutation } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const addCognito = async (input: CreateCognitoInput) => {
  const addCognitoResult = (await API.graphql(
    graphqlOperation(createCognito, { input })
  )) as GraphQLResult<{ createCognito: Cognito }>
  const newCognito = (addCognitoResult?.data?.createCognito)
  return newCognito
}

export const updateCognito = async (input: UpdateCognitoInput) => {
  const updateCognitoResult = (await API.graphql(
    graphqlOperation(updateCognitoMutation, { input: { ...input } })
  )) as GraphQLResult<{ updateCognito: Cognito }>
  const updatedCognito = (updateCognitoResult?.data?.updateCognito)
  return updatedCognito
}

export const removeCognito = async (id: string, serviceId: string) => {
  const removeCognitoResult = (await API.graphql(
    graphqlOperation(deleteCognito, { input: { id, serviceId } })
  )) as GraphQLResult<{ removeCognito: Cognito }>
  const deletedCognito = (removeCognitoResult?.data?.removeCognito)
  return deletedCognito
}

export const updateTeam = async (id: string, name: string) => {
  const updateCognitoResult = (await API.graphql(
    graphqlOperation(updateCognitoMutation, { input: { id, name } })
  )) as GraphQLResult<{ updateTeam: { Team: Cognito } }>
  const updatedCognito = (updateCognitoResult?.data?.updateTeam?.Team)
  return updatedCognito
}
