import React, { useContext, useEffect } from 'react'
import { Amplify } from 'aws-amplify'
import { ThemeProvider } from '@mui/material'
import ApplicationContext, { ApplicationContextProvider } from './store/application-context-provider'
import theme from './theme/theme'
import Home from 'src/components/organisms/home/Home'
import config from './aws-exports'
import './App.css'

Amplify.configure({
  ...config
})

const App = () => {
  const ctx = useContext(ApplicationContext)

  useEffect(() => {
    ctx?.getSession()
  }, [ctx])

  return (
    <ThemeProvider theme={theme}>
      <ApplicationContextProvider>
        <Home></Home>
      </ApplicationContextProvider>
    </ThemeProvider>
  )
}

export default App
