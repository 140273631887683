import { ReactComponent as CodeSlashIcon } from 'src/assets/icons/code-slash.svg'
import { ReactComponent as CloudDownLoadIcon } from 'src/assets/icons/cloud-download.svg'
import { ReactComponent as CreateIcon } from 'src/assets/icons/create.svg'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import { ReactComponent as MultipleIcon } from 'src/assets/diagrams/multiple-data-sources-small.svg'
import { ReactComponent as SingleDataSourceIcon } from 'src/assets/diagrams/single-data-sources-small.svg'
import { ReactComponent as MonolithicLambdaIcon } from 'src/assets/diagrams/monolithic-lambda-small.svg'
import { ReactComponent as SingleLambdaManyRoutesIcon } from 'src/assets/diagrams/single-lambda-many-routes-small.svg'
import { ReactComponent as SingleLambdaPerRouteIcon } from 'src/assets/diagrams/single-lambda-per-route-small.svg'
import { ReactComponent as BYOSchemaIcon } from 'src/assets/diagrams/byoSchema-small.svg'
import { ReactComponent as CustomIcon } from 'src/assets/diagrams/custom-small.svg'
import React from 'react'
import { OptionBluePrint } from 'src/API'

export const ServiceBuilderCard: React.FC<{ confirmDelete: (id: string) => void, download: (id: string) => void, previewCode: (id: string) => void, editService: (id: string) => void, service: any }> = props => {
  const { confirmDelete, editService, previewCode, download, service } = props
  return (
    <div className={'flex flex-col items-center justify-center'}>
      <div className={'cursor-pointer'} onClick={() => { previewCode(service.id) }}>
        {service.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE && <MultipleIcon/>}
        {service.optionBluePrint === OptionBluePrint.SINGLE_DATA_SOURCE && <SingleDataSourceIcon/>}
        {service.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN && <SingleDataSourceIcon/>}
        {service.optionBluePrint === OptionBluePrint.MONOLITHIC_LAMBDA && <MonolithicLambdaIcon/>}
        {service.optionBluePrint === OptionBluePrint.LAMBDA_MANY_ROUTES && <SingleLambdaManyRoutesIcon/>}
        {service.optionBluePrint === OptionBluePrint.LAMBDA_PER_ROUTE && <SingleLambdaPerRouteIcon/>}
        {service.optionBluePrint === OptionBluePrint.BYOSCHEMA && <BYOSchemaIcon/>}
        {service.optionBluePrint === OptionBluePrint.NONE && <CustomIcon/>}
      </div>
      <div className={'flex gap-4 py-4'}>
        <CodeSlashIcon onClick={() => { previewCode(service.id) }} className={'cursor-pointer'} />
        <CloudDownLoadIcon onClick={() => { download(service.id) }} className={'cursor-pointer'} />
        {service.isServiceOwner && <>
          <CreateIcon onClick={() => { editService(service.id) }} className={'cursor-pointer'} />
          <TrashBinIcon onClick={() => { confirmDelete(service.id) }} className={'cursor-pointer'}/>
        </>}
      </div>
      <div className={'text-xs text-[#7E858F] gap-3 font-normal flex'}>
        <div className={'border-[#7E858F] border-r-[1px] pr-3'} >{service.updatedAt && new Date(service.updatedAt).toLocaleString()}</div>
        <div>{service.useCase}</div>
      </div>
      <div className={'mt-[12px] text-sm font-bold text-white'}>{service.name}</div>
    </div>
  )
}
export default ServiceBuilderCard
