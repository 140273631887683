import { Timeout } from '../enums'

export const transformInputValueToSeconds = (timeoutType: Timeout, inputValue: number | null) => {
  switch (timeoutType) {
    case Timeout.Seconds:
      return Number(inputValue)
    case Timeout.Minutes:
      return Number(inputValue) * 60
    case Timeout.Hours:
      return Number(inputValue) * 3600
    case Timeout.Days:
      return Number(inputValue) * 86400
  }
}
