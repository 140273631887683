import React, { useEffect, useState } from 'react'
import { Button } from 'src/components/atoms/button'
import { PasswordInput } from 'src/components/atoms/password-input'
import { Auth } from 'aws-amplify'
import Preloader from 'src/components/UI/preloader/Preloader'
import { Alert } from 'src/components/atoms/alert'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
interface FormChangePassword {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}
const ChangePasswordAccount: React.FC = (props) => {
  const [errText, setErrText] = useState('')
  const [isLoading, setIsloading] = useState(false)
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)

  const schema = yup.object({
    oldPassword: yup
      .string()
      .required('This field is required'),
    newPassword: yup
      .string()
      .required('This field is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: yup
      .string()
      .required('This field is required')
      .test(
        'confirmPassword',
        'Passwords do not match',
        (value) => {
          if (value === getValues().newPassword) return true
          return false
        }
      )
  })
  const {
    setValue,
    trigger,
    getValues,
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormChangePassword >({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''

    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
  }, [reset])
  const onSubmit = async (data: FormChangePassword) => {
    if (data.newPassword !== data.confirmPassword) {
      setErrText('Passwords do not match')
      return
    }
    try {
      setIsloading(true)
      const authUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(authUser, data.oldPassword, data.newPassword)
      setIsPasswordChanged(true)
      setIsloading(false)
      setValue('oldPassword', '')
      setValue('newPassword', '')
      setValue('confirmPassword', '')
    } catch (err) {
      console.log(err)
      setErrText((err as Error).message)
      setIsloading(false)
    }
    ;
  }

  return (<>
      <div className={'rounded-2xl p-6 border-[#171B21]  border-[1px]'}>
          <div className={'font-bold text-lg'}>Change Password</div>
          {errText && <Alert isCloseIcon={true} onClose={() => { setErrText('') }} variant={'error'}> {errText}</Alert>}
          {isPasswordChanged && (
           <div className={' mt-1 flex justify-center'}> <Alert classname={'w-1/3'} isCloseIcon={true} onClose={() => { setIsPasswordChanged(false) }} variant={'success'}>
              Password successfully changed
            </Alert></div>
          )}
          <form>
          {!isPasswordChanged && <div className={'mt-[16px] grid grid-cols-[repeat(auto-fill,minmax(360px,4fr))] gap-x-6  gap-y-4 '}>
            <Controller
              name={'oldPassword'}
              rules={{ required: true }}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<PasswordInput
                showRequired
                {...register('oldPassword')}
                disabled={location.pathname === '/reset-password' ? !!localStorage.getItem('temporary_password') : false }
                className={'max-w-sm'} labelText={'Current Password:'}
                error={errors?.oldPassword?.message}
                onChange={(e) => {
                  trigger('oldPassword')
                  onChange(e)
                }}
                onBlur={onBlur}
                value={location.pathname === '/reset-password' ? localStorage.getItem('temporary_password') as string : value }/>)}
            />
            <Controller
              name={'newPassword'}
              rules={{ required: true }}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<PasswordInput
                showRequired
                {...register('newPassword')}
                className={'max-w-sm'} labelText={'New Password:'}
                error={errors?.newPassword?.message}
                onChange={(e) => {
                  trigger('newPassword')
                  onChange(e)
                }}
                onBlur={onBlur}
                value={value}/>)}
            />
            <Controller
              name={'confirmPassword'}
              rules={{ required: true }}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<PasswordInput
                showRequired
                {...register('confirmPassword')}
                className={'max-w-sm'} labelText={'Confirm New Password:'}
                error={errors?.confirmPassword?.message}
                onChange={(e) => {
                  trigger('confirmPassword')
                  onChange(e)
                }}
                onBlur={onBlur}
                value={value}/>)}
            />
          </div>}

          {!isPasswordChanged && <Button disabled={!isDirty || !isValid} classname={'mt-[32px]'} variant={'darker'} title={'Save Changes'} onClick={handleSubmit(onSubmit)} type={'submit'}/>}
          </form>
          </div>
      <Preloader open={isLoading} modalText={'Loading...'}/>
    </>
  )
}

export default ChangePasswordAccount
