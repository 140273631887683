import { graphqlOperation } from '@aws-amplify/api-graphql'
import { API } from 'aws-amplify'
import { CreateServiceInput, Service, UpdateServiceInput } from '../../API'
import { createService, deleteService, updateService as updateServiceMutation } from '../../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'

export const addService = async (input: CreateServiceInput) => {
  const addServiceResult = (await API.graphql(
    graphqlOperation(createService, { input })
  )) as GraphQLResult<{ createService: Service }>
  const newService = (addServiceResult?.data?.createService)
  return newService
}

export const updateService = async (input: UpdateServiceInput) => {
  const updateServiceResult = (await API.graphql(
    graphqlOperation(updateServiceMutation, { input: { ...input } })
  )) as GraphQLResult<{ updateService: Service }>
  const updatedService = (updateServiceResult?.data?.updateService)
  return updatedService
}

export const removeService = async (id: string) => {
  const removeServiceResult = (await API.graphql(
    graphqlOperation(deleteService, { input: { id } })
  )) as GraphQLResult<{ removeService: Service }>
  const deletedService = (removeServiceResult?.data?.removeService)
  return deletedService
}

export const updateTeam = async (id: string, name: string) => {
  const updateServiceResult = (await API.graphql(
    graphqlOperation(updateServiceMutation, { input: { id, name } })
  )) as GraphQLResult<{ updateTeam: { Team: Service } }>
  const updatedService = (updateServiceResult?.data?.updateTeam?.Team)
  return updatedService
}
