import { ReactComponent as CodeSlashIcon } from 'src/assets/icons/code-slash.svg'
import Editor from '@monaco-editor/react'
import React, { useContext, useEffect, useState } from 'react'
import { addSchema } from 'src/data/schema/mutations'
import { Alert } from 'src/components/atoms/alert'
import ApplicationContext from 'src/store/application-context-provider'
import { validateSchema } from 'business-logic'

export const DefineSchemaFile = () => {
  const ctx = useContext(ApplicationContext)
  const [text, setText] = useState('')
  const [isErrorMsg, setIsErrorMsg] = useState('')

  useEffect(() => {
    setText(ctx?.schemaFileResponse?.schema ?? '')
  }, [])

  const handleEditorChange = (value: any) => {
    setText(value)
  }

  async function handleParseSchema (newText: string) {
    const input = {
      schema: btoa(newText)
    }
    try {
      const response = await addSchema(input)
      if (response?.parseSchema.status === 'success') {
        ctx?.setSchemaFileResponse({ ...JSON.parse(response.parseSchema.result), schema: text })

        setIsErrorMsg('')
      }
      if (response?.parseSchema.status === 'schema has invalid configuration') {
        ctx?.setSchemaFileResponse({})
        setIsErrorMsg(response?.parseSchema.status)
      } else if (response?.parseSchema.status === 'failed to parse Schema') {
        ctx?.setSchemaFileResponse({})
        setIsErrorMsg(response?.parseSchema.status)
      }
    } catch (err) {
      console.log(err)
      setIsErrorMsg('Error')
    }
  }

  const validateSchemaEditor = async (schema: string) => {
    try {
      const res = await validateSchema(schema)
      if (res.valid) {
        handleParseSchema(schema)
      } else {
        ctx?.setSchemaFileResponse({})
        setIsErrorMsg('Schema has invalid configuration')
      }
    } catch (err) {
      ctx?.setSchemaFileResponse({})
      console.log(err)
      setIsErrorMsg('Schema has invalid configuration')
    }
  }
  useEffect(() => {
    if (text) {
      validateSchemaEditor(text)
    } else {
      setIsErrorMsg('')
      ctx?.setSchemaFileResponse({})
    }
  }, [text])

  return (
    <div className={'bg-[#171B21] w-2/3 mt-2 rounded-lg mx-auto px-12 py-8 flex flex-col  gap-[16px] text-white'}>
      {isErrorMsg && <Alert isCloseIcon={true} onClose={() => { setIsErrorMsg('') }} variant={'error'}>{isErrorMsg}</Alert>}
      <div className={'flex flex-col gap-[5px]'}>
        <div className={'text-lg font-bold'}>File Content Submission</div>
        <div className={'text-xs text-[#7E858F]'}>Write or paste the content of your file below.</div>
        <Alert variant={'info'}>Please note that support for Unions/Interfaces and Directives is still under development.</Alert>
      </div>
      <div>
        <div className={'bg-[#2B2D31] px-[8px] py-[9px] flex items-center gap-[12px] text-xs text-[#7E858F] rounded-t-lg'}>
          graphql
          <CodeSlashIcon/>
        </div>
        <div className="w-full overflow-auto bg-[#171B21] border-[1px]  border-[#2B2D31]">

          <Editor
            height="60vh"
            theme="vs-dark"
            language="graphql"
            value={text}
            onChange={handleEditorChange}
          />
        </div>
      </div>
    </div>)
}
