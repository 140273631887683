import React, { useContext, useEffect } from 'react'
import ApplicationContext from '../../../../../store/application-context-provider'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import { TextInput } from 'src/components/atoms/text-input'
import { AutocompleteInput } from 'src/components/atoms/autocomplete-input'

interface FormS3 {
  bucketName: string
  description: string
  tags: any[]
}
const ResourceS3Form: React.FC<{ close: Function, submit: Function }> = props => {
  const {
    close,
    submit
  } = props

  const ctx = useContext(ApplicationContext)

  const schema = yup.object({
    bucketName: yup
      .string()
      .required('This field is required')
      .max(25)
  })

  const {
    handleSubmit,
    control,
    register,
    formState: {
      errors,
      isDirty,
      isValid
    },
    reset
  } = useForm<FormS3>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      bucketName: '',
      description: '',
      tags: []
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      bucketName: ctx?.resourceInput?.name,
      description: ctx?.resourceInput?.description,
      tags: ctx?.resourceInput?.tags
    })
  }, [reset])

  const onSubmit = (data: FormS3) => {
    const input = ctx?.resourceInput
    input.name = data.bucketName
    input.tags = data.tags ?? null
    input.description = data.description ?? null
    console.log('input s3', input)
    ctx?.setResourceInput(input)
    submit()
  }

  return (
    <form>
      <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
      <h3 className="modal-settings-title text-white">General configuration</h3>
        <div>
        <Controller
            name={'bucketName'}
            rules={{ required: true }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
                {...register('bucketName', { maxLength: 25 })}
                required
                labelText={'Bucket Name'}
                showRequired
                maxLength={25}
                error={errors?.bucketName?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value?.toLowerCase().replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '')}/>)}
        />
          <div className="text-[#7E858F] text-[10px]">Bucket name must be globally unique and must not contain spaces or uppercase letters.</div>
        </div>
        <Controller
            name={'description'}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
                {...register('description', { maxLength: 60 })}
                labelText={'Description'}
                maxLength={60}
                error={errors?.description?.message}
                id="description"
                onChange={onChange}
                onBlur={onBlur}
                value={value}/>)}
        />
        <div>
          <Controller
              name={'tags'}
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value
                }
              }) => (<AutocompleteInput labelText={'Tags'}
                                                 {...register('tags')}
                                                 onChange={onChange}
                                                 onBlur={onBlur}
                                                 value={value}/>)}
          />
          <div className="text-[#7E858F] text-[10px]">Press enter to add a tag</div>
        </div>
      </div>
      <ResourceFormControls isDisabledSaveButton={!isDirty || !isValid} onSubmit={handleSubmit(onSubmit)} onClose={close}/>
    </form>
  )
}

export default ResourceS3Form
