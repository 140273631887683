import React, { FC, useContext, useEffect, useState } from 'react'
import { ReactComponent as SignOutIcon } from 'src/assets/icons/signOut.svg'
import { Grid } from 'src/components/atoms/grid/Grid'
import { Dropdown, Item } from 'src/components/atoms/dropdown'
import ApplicationContext from 'src/store/application-context-provider'
import { Organization as OrgType } from 'src/API'
import { fetchOrganizations } from 'src/data/organizations/queries'
import { ReactComponent as BusinessGrayIcon } from 'src/assets/icons/business-gray.svg'
import { ReactComponent as BusinessIcon } from 'src/assets/icons/business.svg'
import AddOrganization from 'src/components/organisms/settings/Organization/add-organization/AddOrganization'
import { Auth } from 'aws-amplify'
import { fetchUserProfile } from 'src/data/userProfile/queries'
import { UserProfileResponse } from 'src/components/organisms/account-settings/personal-information/PersonalInformation'

const w = window as any

export const AccountDropdown: FC = () => {
  const ctx = useContext(ApplicationContext)
  const [rows, setRows] = useState<OrgType[]>([])
  const [user, setUser] = useState<UserProfileResponse>()
  const [isAddOrg, setIsAddOrg] = useState<boolean>(false)

  async function getOrganizations () {
    try {
      const data = await fetchOrganizations()
      setRows(data as OrgType[])
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getOrganizations()
  }, [ctx?.selectedOrganization])

  const getUserProfile = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser()
      const data = await fetchUserProfile({ id: authUser?.attributes?.sub })
      setUser(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserProfile()
  }, [ctx?.isUpdateUserProfile, ctx?.authenticatedUser])

  const logout = async () => {
    ctx?.signOut()
    localStorage.clear()
    w.location.href = '/login'
  }
  const goToAccountSettings = () => {
    w.location.href = '/settings/profile'
  }

  const setCurrentOrganization = (org: OrgType) => {
    localStorage.setItem('selectedOrganization', JSON.stringify(org))
    ctx?.setSelectedOrganization(org)
  }
  return (
    <div className="relative"><Dropdown title={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
                                        iconLeft={<img alt={'avatar'} src={`https://ui-avatars.com/api/?name=${user?.email}`} className={'w-[32px] h-[32px] rounded-full '}/>}>
      <Grid>
        <div className="col-span-12">
          <div className="flex flex-col px-6 items-center  border-b-[1px] border-[#2B2D31]">
            <div className="text-white flex gap-3 items-center text-sm py-[16px] truncate">
              <img alt={'avatar'} src={`https://ui-avatars.com/api/?name=${user?.email}`} className={'w-[32px] h-[32px] rounded-full '}/>
              <p className="truncate">
                <p className={'text-sm'}>Signed in as</p>
                <p className="truncate text-xs font-bold ">{user?.email}</p>
              </p>
            </div>
            {rows.length > 1 && <div className={'px-6 pb-4 text-xs  flex items-center gap-[8px] text-[#7E858F] font-normal'}>
              <BusinessIcon fill={'#826AED'}/>{ctx?.selectedOrganization?.name}
            </div>}

          </div>
          <Item
            className="text-[#7E858F] border-b-[1px] border-[#2B2D31]  hover:bg-[#2B2D31] hover:text-white"
            title={'Account settings'}
            onClick={goToAccountSettings}
          />
          {rows?.map((o) => <Item key={o.id}
                                  iconLeft={<BusinessGrayIcon/>}
                                  title={o.name as string}
                                  className="text-[#7E858F] hover:bg-[#2B2D31] hover:text-white"
                                  onClick={() => {
                                    setCurrentOrganization(o)
                                  }}
          />)}
          <div
            className={'flex flex-row  border-t-[1px] border-[#2B2D31] items-center px-6 gap-2 hover:bg-[#2B2D31] hover:text-white'}>
            <SignOutIcon/>
            <Item
              title={'Sign out'}
              className="text-[#7E858F] hover:text-white pl-0"
              onClick={logout}
            />
          </div>
        </div>
      </Grid>

    </Dropdown>
      <AddOrganization isOpen={isAddOrg} onClose={() => { setIsAddOrg(false) }}/>
    </div>
  )
}
