import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ApplicationContext from '../../../../../store/application-context-provider'
import { UseCase } from 'src/API'
import { DEPLOYMENT_NOTE_TYPE, RESOURCE_TYPE } from 'src/enums'
import { ReactComponent as LambdaIcon } from 'src/assets/icons/lambdaBlackIcon.svg'
import { ReactComponent as DynamoIcon } from 'src/assets/icons/dynamoDbBlackIcon.svg'
import { ReactComponent as CognitoIcon } from 'src/assets/icons/cognitoBlackIcon.svg'
import { ReactComponent as RdsIcon } from 'src/assets/icons/rdsBlacIcon.svg'
import { ReactComponent as S3Icon } from 'src/assets/icons/s3BlackIcon.svg'
import { ReactComponent as SqsIcon } from 'src/assets/icons/sqsBlackIcon.svg'
import { ReactComponent as SnsIcon } from 'src/assets/icons/snsBlackIcon.svg'
import { ReactComponent as VpcIcon } from 'src/assets/icons/vpcBlackIcon.svg'
import Preloader from 'src/components/UI/preloader/Preloader'
import ApigwDeploymentNote
  from 'src/components/organisms/service-builder/wizard/review/DeploymentNotes/ApigwDeploymentNote'
import AppsyncDeploymentNote
  from 'src/components/organisms/service-builder/wizard/review/DeploymentNotes/AppsyncDeploymentNote'
import { LinkCard } from 'src/components/atoms/link-card'
import { ButtonTab } from 'src/components/atoms/button-tab'
import { LinkCardModel } from 'src/components/atoms/link-card-model'
import { getServiceById } from 'src/data/services/queries'
import { Button } from 'src/components/atoms/button'

const Review: FC = () => {
  const ctx = useContext(ApplicationContext)
  const params = useParams()
  const navigate = useNavigate()

  const [isActiveNote, setIsActiveNote] = useState<DEPLOYMENT_NOTE_TYPE>(ctx?.serviceBuilder?.config?.operatingSystem ?? DEPLOYMENT_NOTE_TYPE.MAC)
  const [isLoading, setIsloading] = useState(true)

  const getService = async () => {
    let service = ctx?.serviceBuilder

    let config = {
      ...service.config,
      operatingSystem: isActiveNote
    }

    if (Object.keys(service).length === 0 && params.serviceId) {
      service = await getServiceById({ id: params.serviceId })
      setIsActiveNote(service.operatingSystem)

      config = {
        ...config,
        operatingSystem: service.operatingSystem,
        useCase: service.useCase,
        name: service.name,
        description: service.description
      }
    }

    console.log('service: ', service)
    console.log('params: ', params)
    console.log('isActiveNote: ', isActiveNote)

    ctx?.setServiceBuilder({ ...service, config })
    setIsloading(false)
  }
  useEffect(() => {
    getService()
  }, [isActiveNote])

  useEffect(() => {
    console.log(ctx?.serviceBuilder)
  }, [ctx])

  const goToCodePreview = () => {
    const id = ctx?.selectedServiceId ?? localStorage.getItem('previewServiceId')
    navigate(`/service-builder/${id}/preview`)
  }

  return (
    <>
      {!isLoading &&
        <div className={'bg-[#020409] xl:flex xl:flex-row  xl:gap-12 lg:flex lg:flex-col lg:gap-y-4'}>
          <div className={'flex flex-col gap-8'}>
            <div className={'text-white font-bold text-xs flex flex-col gap-4'}>
              <div className={'text-lg'}>Service Definition</div>
              <div className={'text-[#7E858F] text-xs'}>Service Name:&nbsp;<span
                className={'font-normal'}>{ctx?.serviceBuilder?.config?.name}</span></div>
              <div className={'text-[#7E858F] '}>Service Description:&nbsp;<span
                className={'font-normal'}>{ctx?.serviceBuilder?.config?.description}</span></div>
              <div className={'text-[#7E858F]'}>Cloud Provider:&nbsp;<span
                className={'font-normal'}>AWS</span></div>
              <div className={'text-[#7E858F]'}>IaC Framework:&nbsp;<span
                className={'font-normal'}>Serverless Framework</span>
              </div>
              <div className={'text-[#7E858F]'}>Default Stage:&nbsp;<span
                className={'font-normal'}>{ctx?.serviceBuilder?.config?.defaults?.stage || 'dev'}</span>
              </div>
              <div className={'text-[#7E858F]'}>Default Region:&nbsp;<span
                className={'font-normal'}>{ctx?.serviceBuilder?.config?.defaults?.region?.replace(/_/g, '-') || 'us-east-1'}</span>
              </div>
              <div className={'text-[#7E858F]'}>Use Case:&nbsp;<span
                className={'font-normal'}>{ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? 'REST API' : 'GraphQL API'}</span>
              </div>
            </div>
            {ctx?.serviceBuilder?.config?.useCase === UseCase.APPSYNC &&
              <div className={'text-white font-bold text-xs flex flex-col gap-4'}>
                <div className={'text-lg'}>API Details</div>
                <div className={'text-[#7E858F] text-xs'}>API Name:&nbsp;<span
                  className={'font-normal'}>{ctx?.serviceBuilder?.config?.appSyncDetails?.appsyncName}</span></div>
                <div className={'text-[#7E858F] '}>API Description:&nbsp;<span
                  className={'font-normal'}>{ctx?.serviceBuilder?.config?.appSyncDetails?.appsyncDescription}</span>
                </div>
                <div className={'text-[#7E858F]'}>Auth:&nbsp;<span
                  className={'font-normal'}>{ctx?.serviceBuilder?.config?.appSyncDetails?.authType}</span></div>
                <div className={'text-[#7E858F]'}>API Caching:&nbsp;<span
                  className={'font-normal'}>{ctx?.serviceBuilder?.config?.appSyncDetails?.caching ? 'True' : 'False'}</span>
                </div>
                <div className={'text-[#7E858F]'}>Logging:&nbsp;<span
                  className={'font-normal'}>{ctx?.serviceBuilder?.config?.appSyncDetails?.logging}</span>
                </div>
                <div className={'text-[#7E858F]'}>X-RAY:&nbsp;<span
                  className={'font-normal'}>{ctx?.serviceBuilder?.config?.appSyncDetails?.xRay ? 'True' : 'False'}</span>
                </div>
              </div>}
            <div className={'text-white text-xs flex flex-col'}>
              <div className={'text-lg font-bold mb-[16px]'}>Resources</div>
              {ctx?.serviceBuilder?.resources && ctx?.serviceBuilder?.resources.map((r: any) => <div
                  key={r.id}
                  className={'flex px-[8px] py-[8px]  justify-between items-center border-b-[1px] border-[#2B2D31]'}>
                  <div className={'font-bold'}>{r.name}</div>
                  <div className={'flex items-center gap-2'}>
                    <div>{(r.resourceType || r.typename)}</div>
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.LAMBDA &&
                      <LambdaIcon className={'w-[18px] h-[18px]'}/>}
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.DYNAMO_DB &&
                      <DynamoIcon className={'w-[18px] h-[18px]'}/>}
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.COGNITO_USER_POOL &&
                      <CognitoIcon className={'w-[18px] h-[18px]'}/>}
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.RDS && <RdsIcon className={'w-[18px] h-[18px]'}/>}
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.S3 && <S3Icon className={'w-[18px] h-[18px]'}/>}
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.SQS && <SqsIcon className={'w-[18px] h-[18px]'}/>}
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.SNS && <SnsIcon className={'w-[18px] h-[18px]'}/>}
                    {(r.resourceType || r.typename) === RESOURCE_TYPE.VPC && <VpcIcon className={'w-[18px] h-[18px]'}/>}
                  </div>
                </div>
              )}

            </div>
            {ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW &&
              <div className={'text-white text-xs flex flex-col gap-4'}>
                <div className={'text-lg font-bold mb-[16px]'}>Routes</div>
                {ctx?.serviceBuilder?.routes && ctx?.serviceBuilder?.routes.map((r: any) => <LinkCard card={r}
                                                                                                      key={r.id}
                                                                                                      title={r.method}/>)}
              </div>}
            {ctx?.serviceBuilder?.config?.useCase === UseCase.APPSYNC &&
              <div className={'text-white text-xs flex flex-col gap-4'}>
                <div className={'text-lg font-bold mb-[16px]'}>Models</div>
                <div>
                  <div className="w-full max-w-md">
                    <div
                      className={'flex w-full justify-between rounded-lg px-4 py-1 text-left text-sm font-medium text-white '}>
                      <div className={'text-sm'}>Name</div>
                      <div className={'text-sm'}>Data Source</div>
                    </div>
                  </div>
                  {ctx?.serviceBuilder?.routes && ctx?.serviceBuilder?.models.map((r: any) => <LinkCardModel
                    resolvers={ctx?.serviceBuilder?.resolvers?.filter((f: any) => f.model === r.name)}
                    subTitle={r.dataSource?.name}
                    key={r.id}
                    title={r.name}/>)}
                </div>
              </div>}
          </div>
          <div className={'text-white'}>
            <div className={'font-bold text-lg'}>Deployment Notes</div>
            <div
              className={'mt-[16px] box-border bg-[#171B21]  flex w-full border-[1px] rounded-tl-2xl rounded-tr-2xl border-[#2B2D31] overflow-hidden'}>
              <ButtonTab isActive={isActiveNote === DEPLOYMENT_NOTE_TYPE.MAC}
                        className={'md:w-[180px] sm:w-full  py-[18px] bg-[#171B21] rounded-tl-2xl'} onClick={() => {
                          setIsActiveNote(DEPLOYMENT_NOTE_TYPE.MAC)
                        }} type={'button'} title={'Mac'}/>
              <ButtonTab isActive={isActiveNote === DEPLOYMENT_NOTE_TYPE.WINDOWS} onClick={() => {
                setIsActiveNote(DEPLOYMENT_NOTE_TYPE.WINDOWS)
              }} className={'md:w-[180px] sm:w-full  py-[18px] font-bold bg-[#171B21]'} type={'button'} title={'Windows'}/>

              <ButtonTab className={'md:w-[180px] sm:w-full py-[18px] font-bold bg-[#171B21]'} disabled={true}
                        type={'button'} title={'Linux'}/>
            </div>
            <div className={'px-[22px] bg-[#171B21] border-[1px] border-[#2B2D31] rounded-b-2xl '}>
              <div>
                <div className={'pt-[32px] text-xs text-[#7E858F]'}>All steps below can also be found in the
                  README.md file when you download the service.
                </div>
                {isActiveNote === DEPLOYMENT_NOTE_TYPE.MAC && ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW &&
                  <ApigwDeploymentNote isActiveMac={true}/>}
                {isActiveNote === DEPLOYMENT_NOTE_TYPE.WINDOWS && ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW &&
                  <ApigwDeploymentNote isActiveMac={false}/>}
                {ctx?.serviceBuilder?.config?.useCase === UseCase.APPSYNC && isActiveNote === DEPLOYMENT_NOTE_TYPE.MAC &&
                  <AppsyncDeploymentNote isActiveMac={true}/>}
                {ctx?.serviceBuilder?.config?.useCase === UseCase.APPSYNC && isActiveNote === DEPLOYMENT_NOTE_TYPE.WINDOWS &&
                  <AppsyncDeploymentNote isActiveMac={false}/>}
              </div>
            </div>
            {params.serviceId &&
            <div className='flex justify-end mt-2'>
              <Button onClick={goToCodePreview} title={'Code and Architecture Preview'} variant={'darker'} type={'button'}/>
            </div>
            }
          </div>
        </div>
      }
      <Preloader open={isLoading} modalText={'Loading...'}/>
    </>
  )
}

export default Review
