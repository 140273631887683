import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInputChildren } from 'src/components/atoms/select-outlined-children'
import { scalarTypesList } from 'src/constants/modelForm'
import { Listbox } from '@headlessui/react'
import { OptionBluePrint } from 'src/API'
import { Button } from 'src/components/atoms/button'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import React, { useContext } from 'react'
import ApplicationContext from 'src/store/application-context-provider'
import { RowField } from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/ModelForm'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import { ReactComponent as TrashBinRedIcon } from 'src/assets/icons/trash-bin-red.svg'
import { ReactComponent as EditRedIcon } from 'src/assets/icons/create-red.svg'

export const ModelFieldsRow: React.FC<{
  row: RowField
  customTypes: any[]
  fieldNameHandler: (e: Event | any, row: RowField) => void
  fieldTypeHandler: (e: Event | any, row: RowField) => void
  fieldRequiredHandler: (e: Event | any, row: RowField) => void
  fieldArrayHandler: (e: Event | any, row: RowField) => void
  confirmFieldDelete: (row: RowField) => void
  editCustomType: (type: any, e: Event | any) => void
  removeCustomType: (type: any, e: Event | any) => void
  handleOpenModal: Function
}> = props => {
  const { row, customTypes, confirmFieldDelete, handleOpenModal, fieldArrayHandler, fieldRequiredHandler, fieldNameHandler, fieldTypeHandler, editCustomType, removeCustomType } = props

  const ctx = useContext(ApplicationContext)

  return (<div className={'flex  justify-between items-left gap-10 pt-[4px] border-t-[1px] border-[#2B2D31]'}>
    <TextInput
      required
      className='w-4/12'
      error={row.name === ''}
      value={row.name}
      disabled={row.isDisabled}
      onChange={(e) => {
        fieldNameHandler(e, row)
      }}
      labelText={'Name:'}
      showRequired
      id="resourceName"
    />
    <SelectOutlinedInputChildren
      error={row.type === ''}
      classname={'w-4/12'}
      handleValueChange={(e) => {
        fieldTypeHandler(e, row)
      }}
      showRequired={true}
      labelText={'Type:'}
      selected={row.type}
      disabled={row.isDisabled}
    >
      <div className={'text-sm p-[8px] font-bold text-white'}>Scalar Types</div>
      {scalarTypesList?.map((o, index) => (
        <Listbox.Option
          key={index}
          className={({ active }) => `text-white text-xs relative cursor-pointer select-none py-[8px] px-[8px] ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} `}
          value={o.type}
        >
          {o.type}
        </Listbox.Option>
      ))}
      {(ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE || ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN) && <>
        <div className={'border-b-[1px] border-[#020409]'}></div>
        <div className={'text-sm p-[8px] font-bold text-white'}>Model Types</div>
      </>}
      {(ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE || ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN) &&
        ctx?.serviceBuilder?.models?.map((d: any, index: number) => {
          return (
            <Listbox.Option
              key={index}
              className={({ active }) => `text-white text-xs relative cursor-pointer select-none py-[8px] px-[8px] ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} `}
              value={d.name}
            >
              {d.name}
            </Listbox.Option>
          )
        })}
      {(ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE || ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN) && <>
        <div className={'border-b-[1px] border-[#020409]'}></div>
        <div className={'text-sm p-[8px] font-bold text-white'}>Custom Types</div>
      </>}
      {(ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE || ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN) &&
        customTypes?.map((c: any) =>
          (<Listbox.Option
              className={({ active }) => `text-white flex items-center justify-between text-xs relative cursor-pointer select-none py-[8px] px-[8px] ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} `}
              onClick={(e) => {
                e.stopPropagation()
              }} key={c.id} value={c.name}>
              <div>{c.name}</div>
              <div className={'flex items-center gap-[10px]'}>
                <EditRedIcon onClick={(e) => {
                  editCustomType(c, e)
                }}/>
                <TrashBinRedIcon onClick={(e) => {
                  removeCustomType(c, e)
                }}/>
              </div>
            </Listbox.Option>
          ))}
      {(ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE || ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN) && <>
        <div className={'flex justify-center  mt-[8px]'}>
          <Button type={'button'} title={'Add Custom Types'} onClick={() => {
            ctx?.setCustomTypesInput({})
            handleOpenModal()
          }} variant="darker"/>
        </div>

      </>}
    </SelectOutlinedInputChildren>

    <CheckboxInput labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   className={'w-1/12 justify-center'}
                   isActiveDisabled={row.isDisabled === row.required}
                   labelText={'Required'} disabled={row.isDisabled}
                   onChange={(e) => { fieldRequiredHandler(e, row) }}
                   checked={row.required}/>
    <CheckboxInput labelText={'Array'}
                   isActiveDisabled={row.isDisabled === row.array}
                   labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   className={'w-1/12 justify-center'}
                   disabled={row.isDisabled}
                   checked={row.array}
                   onChange={(e) => { fieldArrayHandler(e, row) }}
    />
    <Button classname={'mt-[28px] w-2/12'} iconLeft={<TrashBinIcon/>} compact disabled={row.isDisabled} onClick={ () => { confirmFieldDelete(row) }} variant={'darker'} title={'Delete Row'} type={'button'}/>
  </div>)
}
