import { Sidebar } from 'src/components/organisms/sidebar'
import { Header } from 'src/components/organisms/header'
import React from 'react'

export interface LayoutProps {
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isOpenSideBar, setIsOpenSideBar] = React.useState(true)

  const toggleSidebar = () => {
    setIsOpenSideBar(!isOpenSideBar)
  }
  return (
      <div className={'flex flex-col  h-screen'} >
      <Header toggleSidebar={toggleSidebar} />
      <div className="flex flex-1 ">
        <Sidebar open={isOpenSideBar} />
        <div className="flex-1 sm:pl-[0px] md:pl-0 lg:pl-48 pt-[65px]">
          {children}
        </div>
      </div>
    </div>
  )
}
