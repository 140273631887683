import { Menu } from '@headlessui/react'
import React, { FC } from 'react'
import { classNames } from 'src/utils/css'

export interface Props {
  title: string
  onClick: () => void
  className?: string
  iconLeft?: React.ReactNode
}

export const Item: FC<Props> = ({
  title,
  className = '',
  onClick,
  iconLeft
}) => (
  <Menu.Item>
    {({ active }) => (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={onClick}
        onClick={onClick}
        className={classNames(
          active ? 'bg-blue-150' : '',
          'block px-6 py-4 text-xs  flex items-center gap-[8px] font-normal cursor-pointer',
          className
        )}
      >
        {iconLeft && iconLeft}{title}
      </div>
    )}
  </Menu.Item>
)
