import { TextInput } from 'src/components/atoms/text-input'
import { Button } from 'src/components/atoms/button'
import React from 'react'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import { SelectOutlinedInputChildren } from 'src/components/atoms/select-outlined-children'
import { scalarTypesList } from 'src/constants/modelForm'
import { Listbox } from '@headlessui/react'

export const SubscriptionFormRow: React.FC<{
  fieldNameHandler: (e: Event | any, row: any) => void
  argumentRequiredHandler: (e: Event | any, row: any) => void
  argumentTypeHandler: (e: Event | any, row: any) => void
  confirmRowDelete: (row: any) => void
  row: any
}> = props => {
  const { argumentTypeHandler, fieldNameHandler, argumentRequiredHandler, confirmRowDelete, row } = props

  return (<div className={'flex items-center justify-between gap-10'}><TextInput
    required
    error={row.name === ''}
    value={row.name}
    onChange={(e) => {
      fieldNameHandler(e, row)
    }}
    labelText={'Name:'}
    className={'w-4/12'}
    showRequired
    id="resourceName"
  />
    <SelectOutlinedInputChildren
      error={row.type === ''}
      classname={'w-4/12'}
      handleValueChange={(e) => {
        argumentTypeHandler(e, row)
      }}
      showRequired={true}
      labelText={'Type:'}
      selected={row.type}
      disabled={row.isDisabled}
    >
      <div className={'text-sm p-[8px] font-bold text-white'}>Scalar Types</div>
      {scalarTypesList?.map((o, index) => (
        <Listbox.Option
          key={index}
          className={({ active }) => `text-white text-xs relative cursor-pointer select-none py-[8px] px-[8px] ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} `}
          value={o.type}
        >
          {o.type}
        </Listbox.Option>
      ))}

    </SelectOutlinedInputChildren>
    <CheckboxInput labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   className={'w-1/12'}
                   labelText={'Required'}
                   checked={row.required}
                   onChange={(e) => { argumentRequiredHandler(e, row) }}
    />
    <Button classname={'mt-[28px] w-2/12'} iconLeft={<TrashBinIcon/>} onClick={ () => { confirmRowDelete(row) }} variant={'darker'} title={'Delete Row'} type={'button'} compact />
  </div>)
}
