import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInputChildren } from 'src/components/atoms/select-outlined-children'
import { scalarTypesList } from 'src/constants/modelForm'
import { Listbox } from '@headlessui/react'
import { Button } from 'src/components/atoms/button'
import { CheckboxInput } from 'src/components/atoms/checkbox-input'
import React from 'react'
import { RowField } from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/ModelForm'
import { ReactComponent as TrashBinIcon } from 'src/assets/icons/trash-bin.svg'

export const CustomObjectFieldsRow: React.FC<{
  row: RowField
  fieldNameHandler: (e: Event | any, row: RowField) => void
  fieldTypeHandler: (e: Event | any, row: RowField) => void
  fieldRequiredHandler: (e: Event | any, row: RowField) => void
  fieldArrayHandler: (e: Event | any, row: RowField) => void
  confirmFieldDelete: (row: RowField) => void
}> = props => {
  const { row, confirmFieldDelete, fieldArrayHandler, fieldRequiredHandler, fieldNameHandler, fieldTypeHandler } = props

  return (<div className={'flex gap-10 items-center pt-[4px] justify-between border-t-[1px] border-[#2B2D31]'}>
    <TextInput
      required
      error={row.name === ''}
      value={row.name}
      disabled={row.isDisabled}
      onChange={(e) => {
        fieldNameHandler(e, row)
      }}
      labelText={'Name:'}
      showRequired
      id="resourceName"
      className={'w-4/12'}
    />
    <SelectOutlinedInputChildren
      error={row.type === ''}
      classname={'w-4/12'}
      handleValueChange={(e) => {
        fieldTypeHandler(e, row)
      }}
      showRequired={true}
      labelText={'Type:'}
      selected={row.type}
      disabled={row.isDisabled}
    >
      <div className={'text-sm p-[8px] font-bold text-white'}>Scalar Types</div>
      {scalarTypesList?.map((o, index) => (
        <Listbox.Option
          key={index}
          className={({ active }) => `text-white text-xs relative cursor-pointer select-none py-[8px] px-[8px] ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} `}
          value={o.type}
        >
          {o.type}
        </Listbox.Option>
      ))}

    </SelectOutlinedInputChildren>

    <CheckboxInput labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   className={'w-1/12'}
                   isActiveDisabled={row.isDisabled === row.required}
                   labelText={'Required'} disabled={row.isDisabled}
                   onChange={(e) => { fieldRequiredHandler(e, row) }}
                   checked={row.required}/>
    <CheckboxInput labelText={'Array'}
                   isActiveDisabled={row.isDisabled === row.array}
                   labelClassName={'mt-[28px]'}
                   inputClassName={'mt-[28px]'}
                   className={'w-1/12'}
                   disabled={row.isDisabled}
                   checked={row.array}
                   onChange={(e) => { fieldArrayHandler(e, row) }}
    />
    <Button classname={'mt-[28px] w-2/12'} iconLeft={<TrashBinIcon/>} compact disabled={row.isDisabled} onClick={ () => { confirmFieldDelete(row) }} variant={'darker'} title={'Delete Row'} type={'button'}/>
  </div>)
}
