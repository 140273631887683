import React, { Fragment, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import EnumForm from 'src/components/organisms/service-builder/wizard/define-enums/enum-modal/EnumForm'
import ApplicationContext from 'src/store/application-context-provider'

const EnumModal: React.FC<{ open: boolean, onClose: Function, onSubmit: Function }> = props => {
  const { open, onClose, onSubmit } = props
  const ctx = useContext(ApplicationContext)
  const handleClose = (event: Event | any) => {
    onClose()
  }
  const submit = () => {
    onSubmit()
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#020409] bg-opacity-80"/>
        </Transition.Child>
        <div className="fixed  inset-0 overflow-y-auto">
          <div className="flex  min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[550px]  pb-[20px]  transform  rounded-lg  bg-[#171B21]  text-left align-middle   transition-all">
                <div
                  className="text-2xl mx-6 my-6 font-bold text-white flex items-center justify-between gap-6   ">
                  <span>{Object.keys(ctx?.enumInput)?.length === 0 ? 'Add Enum' : 'Edit Enum'}</span>
                  <CloseIcon className={'pointer'} onClick={handleClose}/>
                </div>
                <div className={'border-b-[1px] border-[#020409]'}></div>
                <EnumForm close={handleClose} submit={submit}/>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default EnumModal
