import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import { Button } from 'src/components/atoms/button'
const ConfirmDialog: React.FC<{ onConfirm: Function, onCancel: Function, isOpen: boolean }> = (props) => {
  const { onConfirm, onCancel, isOpen } = props

  const [open, setOpen] = useState(isOpen ?? false)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleClose = () => {
    onCancel()
    setOpen(false)
  }

  const handleConfirm = () => {
    onConfirm()
    setOpen(false)
    onCancel()
  }

  return (
      <Transition appear show={open} as={Fragment}>
          <Dialog as="div" className="relative z-30" onClose={() => {}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#020409] bg-opacity-80" />
            </Transition.Child>

            <div className="fixed  inset-0 overflow-y-auto">
              <div className="flex  min-h-full items-center justify-center text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="max-w-[597px] transform overflow-hidden rounded-lg  bg-[#171B21]  text-left align-middle  transition-all">
                    <div className="text-2xl mx-6 my-6 font-bold text-white flex items-center gap-6">
                      <span>Are you sure you want to delete this item ?</span>
                      <CloseIcon className={'pointer'} onClick={handleClose}/>
                    </div>
                    <div className={'border-t-[1px] border-b-[1px] py-4 px-6 flex flex-col gap-4  border-[#020409] text-sm text-[#7E858F]'}>
                        <div>This action is irreversible, and all related information will be permanently removed.</div>
                        <div>Please confirm your decision by clicking &apos;Delete&apos; below. Otherwise,
                          click &apos;Cancel&apos; to go back without deleting the item.</div>
                    </div>
                      <div className={'px-6 py-4 flex justify-between items-center'}>
                          <Button onClick={handleClose} classname={'bg-[#020409]'} title={'Cancel'} variant={'darker'} type={'button'}/>
                          <Button onClick={handleConfirm} title={'Confirm Delete'} variant={'warning'} type={'button'}/>
                      </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
  )
}

export default ConfirmDialog
