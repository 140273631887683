import { Disclosure } from '@headlessui/react'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up.svg'
import React from 'react'

export const LinkCardModel: React.FC<{ iconLeft?: React.ReactNode, title?: string, subTitle?: string, resolvers: any[] }> = props => {
  const { title, resolvers = [], subTitle, iconLeft } = props

  return (
    <div className="w-[376px] mt-1 box-border">
      <div className="w-full max-w-md rounded-lg bg-[#171B21]">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-3 text-left text-sm font-medium text-white border-2 border-[#2B2D31] hover:ring-2 ring-[#826AED] focus:outline-none   ">
              <div className={'flex gap-2'}>
                  {iconLeft && iconLeft}
                <div className={'text-sm'}>{title}</div>
              </div>
                <div className={'flex items-center gap-2'}>
                  {subTitle && <div className={'text-sm'}>{subTitle}</div>}
                  { open
                    ? <ArrowUpIcon className={'mt-[4px]'} />
                    : <ArrowDownIcon className={'mt-[4px]'} />
                  }
                </div>

              </Disclosure.Button>
              {resolvers?.map((res) => <Disclosure.Panel key={res.id} className="px-4 py-1 text-sm">
                <div className={'flex text-white justify-between text-xs p-[8px] rounded-lg border-2 border-[#2B2D31]'}>
                  <span>{res.name}</span>
                  <span>{res.model}</span>
                </div>
              </Disclosure.Panel>)}
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}
