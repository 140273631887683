import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { Button } from 'src/components/atoms/button'

const QueryFromModal: React.FC<{ isSecondSelect?: boolean, onCancel: () => void, open: boolean, onClose: () => void, onSubmit: (valueFirst: string, valueSecond?: string) => void, options: string[], defaultValue: string, resolveVariant?: string }> = props => {
  const { isSecondSelect = false, open, onCancel, onClose, onSubmit, options, defaultValue, resolveVariant } = props

  const [valueFirstSelect, setValueFirstSelect] = useState(defaultValue)
  const [valueSecondSelect, setValueSecondSelect] = useState('')

  useEffect(() => {
    setValueFirstSelect(defaultValue)
  }, [])

  const handleSubmit = () => {
    isSecondSelect ? onSubmit(valueFirstSelect, valueSecondSelect) : onSubmit(valueFirstSelect)
    onClose()
  }

  return (
    <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#020409] bg-opacity-80"/>
          </Transition.Child>
          <div className="fixed  inset-0 overflow-y-auto">
            <div className="flex  min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-[45%]  pb-[20px]  transform  rounded-lg  bg-[#171B21]  text-left align-middle   transition-all">
                  <div
                    className="text-2xl mx-6 my-6 font-bold text-white flex items-center justify-between gap-6   ">
                    <span>{`Select the model to ${resolveVariant}`}</span>
                  </div>
                  <div className={'border-b-[1px] border-[#020409]'}></div>
                  <div className={'flex pt-1 mx-6 gap-4'}>
                    <SelectOutlinedInput
                      classname={'w-1/3 '}
                      showRequired={resolveVariant === 'resolve with PK,SK'}
                      selected={valueFirstSelect}
                                         options={
                                           options?.map((option) => ({ name: option, value: option }))
                                         }
                                         labelText={resolveVariant === 'resolve with PK,SK' ? 'PK:' : ''}
                                         handleValueChange={(e) => {
                                           setValueFirstSelect(e as string)
                                         }}/>
                    {isSecondSelect && <SelectOutlinedInput
                      showRequired={true}
                      classname={'w-1/3'}
                      selected={valueSecondSelect}
                                         options={options?.filter((o) => o !== valueFirstSelect).map((option) => ({ name: option, value: option }))}
                                         labelText={isSecondSelect ? 'SK:' : ''}
                                         handleValueChange={(e) => {
                                           setValueSecondSelect(e as string)
                                         }}/>}

                  </div>
                  <div className={'flex mx-6 gap-4 mt-2 justify-end'}>
                    <Button variant={'primary'} type={'button'} disabled={ isSecondSelect ? !valueFirstSelect || !valueSecondSelect : (!valueFirstSelect)} title={'Save'} onClick={handleSubmit}/>
                    <Button variant={'warning'} type={'button'} title={'Cancel'} onClick={() => { onCancel() }}/>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
}

export default QueryFromModal
