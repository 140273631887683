import React, { FC, useContext, useEffect } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ApplicationContext from 'src/store/application-context-provider'
import { AUTH_API_DETAILS_TYPE, LOGGING_API_DETAILS_TYPE } from 'src/enums'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'

interface FormApiDetails {
  name: string
  authType: any
  description: string
  logging: LOGGING_API_DETAILS_TYPE
  caching: 'True' | 'False'
  xRay: 'True' | 'False'
}

const DefineApiDetails: FC = () => {
  const ctx = useContext(ApplicationContext)
  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
      .max(25),
    description: yup
      .string()
      .max(25)
  })
  const {
    control,
    register,
    getValues,
    formState: {
      errors
    },
    trigger,
    reset
  } = useForm<FormApiDetails>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
      authType: AUTH_API_DETAILS_TYPE.COGNITO,
      logging: LOGGING_API_DETAILS_TYPE.NONE,
      caching: 'False',
      xRay: 'True'
    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      name: ctx?.serviceBuilder?.config?.appSyncDetails?.appsyncName,
      description: ctx?.serviceBuilder?.config?.appSyncDetails?.appsyncDescription,
      authType: ctx?.serviceBuilder?.config?.appSyncDetails?.authType,
      logging: ctx?.serviceBuilder?.config?.appSyncDetails?.logging,
      caching: ctx?.serviceBuilder?.config?.appSyncDetails?.caching === true ? 'True' : 'False',
      xRay: ctx?.serviceBuilder?.config?.appSyncDetails?.xRay === false ? 'False' : 'True'
    })
  }, [reset])

  useEffect(() => {
    if (getValues().name) {
      ctx?.setIsApiDetailsNextButtonDisabled(false)
      const service = ctx?.serviceBuilder
      const config = {
        ...service.config,
        appSyncDetails: {
          appsyncName: getValues().name,
          appsyncDescription: getValues().description,
          authType: getValues().authType,
          logging: getValues().logging,
          caching: getValues().caching === 'True',
          xRay: getValues().xRay === 'True'
        }
      }
      console.log('config api details', config)
      ctx?.setServiceBuilder({ ...service, config })
    } else {
      ctx?.setIsApiDetailsNextButtonDisabled(true)
    }
  }, [getValues().name, getValues().xRay, getValues().description, getValues().authType, getValues().logging, getValues().caching])

  return (
    <form>
      <div className={'flex flex-col gap-[16px] text-white'}>
        <div className={'text-lg font-bold'}>AppSync API Details</div>
        <div className={'text-xs text-[#7E858F]'}>Define your AWS AppSync API</div>
      </div>
      <div className={'bg-[#020409] flex flex-col gap-[16px] mt-[10px]'}>
      <div className={' md:flex md:flex-row sm:flex-col  gap-[16px]'}>
        <Controller
          name={'name'}
          rules={{ required: true }}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('name', { maxLength: 25 })}
            required
            className={'md:w-1/3 sm:w-full'}
            labelText={'API Name'}
            maxLength={25}
            showRequired
            error={errors?.name?.message}
            onChange={(e) => {
              trigger('name')
              onChange(e)
            }}
            onBlur={onBlur}
            value={value?.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '')}/>)}
        />
        <Controller
          name={'description'}
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value
            }
          }) => (<TextInput
            {...register('description', { maxLength: 25 })}
            labelText={'API Description:'}
            className={'w-full'}
            maxLength={25}
            error={errors?.description?.message}
            onChange={(e) => {
              trigger('description')
              onChange(e)
            }}
            onBlur={onBlur}
            value={value}/>)}
        />
      </div>
        <div className={'md:flex md:flex-row sm:flex-col gap-[16px]'}>
          <Controller
            name={'authType'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
              <SelectOutlinedInput
                classname={'w-full'}
                selected={value}
                options={[{ name: 'Cognito', value: AUTH_API_DETAILS_TYPE.COGNITO }, { name: 'API Key', value: AUTH_API_DETAILS_TYPE.APIKEY }]}
                labelText={'Auth:'}
                handleValueChange={(e) => {
                  trigger('authType')
                  onChange(e)
                }}/>
            )}
          />
          <Controller
            name={'logging'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
              <SelectOutlinedInput
                classname={'w-full'}
                selected={value}
                options={[{ name: 'NONE', value: LOGGING_API_DETAILS_TYPE.NONE },
                  { name: 'ERROR', value: LOGGING_API_DETAILS_TYPE.ERROR },
                  { name: 'ALL', value: LOGGING_API_DETAILS_TYPE.ALL }
                ]}
                labelText={'Logging:'}
                handleValueChange={(e) => {
                  trigger('logging')
                  onChange(e)
                }}/>
            )}
          />
          <Controller
            name={'caching'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
              <SelectOutlinedInput
                classname={'w-full'}
                selected={value}
                disabled={true}
                defaultValue={'False'}
                options={[{ name: 'False', value: 'False' }]}
                labelText={'API Caching:'}
                tooltipText='Coming soon.'
                handleValueChange={(e) => {
                  onChange(e)
                }}/>
            )}
          />
        </div>
        <div>
          <Controller
            name={'xRay'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
              <SelectOutlinedInput
                classname={'w-1/3'}
                selected={value}
                defaultValue={'True'}
                options={[{ name: 'True', value: 'True' }, { name: 'False', value: 'False' }]}
                labelText={'X-Ray:'}
                handleValueChange={(e) => {
                  trigger('xRay')
                  onChange(e)
                }}/>
            )}
          />
        </div>
      </div>
    </form >
  )
}

export default DefineApiDetails
