import React, { useContext, useEffect, useState } from 'react'
import { Organization as OrgType } from '../../../API'
import { fetchOrganizationsByUsers } from '../../../data/organizations/queries'
import ApplicationContext from '../../../store/application-context-provider'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useUser } from '../../../hooks'
import { ReactComponent as BusinessIcon } from 'src/assets/icons/business-gray.svg'
import Preloader from 'src/components/UI/preloader/Preloader'
import { Alert } from 'src/components/atoms/alert'
import { SelectOutlinedInputChildren } from 'src/components/atoms/select-outlined-children'
import { Listbox } from '@headlessui/react'
import { Button } from 'src/components/atoms/button'
import { TextInput } from 'src/components/atoms/text-input'

const Organizations: React.FC = () => {
  const [rows, setRows] = useState<OrgType[]>([])
  const [isLoading, setIsloading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [organization, setOrganization] = useState<OrgType | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const ctx = useContext(ApplicationContext)
  const user = useUser()

  const goToCheckout = async () => {
    try {
      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/stripe/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
        },
        body: JSON.stringify({})
      })
      const data = await result.json()
      window.location.href = data.url
    } catch (err) {
      setErrMsg((err as Error).message)
      console.log(err)
    }
  }

  const selectItem = async () => {
    try {
      setIsloading(true)
      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/stripe/check-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authData.getIdToken().getJwtToken()
        },
        body: JSON.stringify({})
      })
      const data = await result.json()
      if (data.subscription === 'active') {
        localStorage.setItem('selectedOrganization', JSON.stringify(organization))
        ctx?.setSelectedOrganization(organization)
        if (location.pathname === '/settings/organizations') {
          navigate(`/settings/organizations/${organization?.id}/teams`)
        } else {
          navigate('/')
        }
      } else {
        throw new Error('Subscription not active')
      }
    } catch (err) {
      setIsloading(false)
      console.log(err)
      setErrMsg((err as Error).message)
      if ((err as Error).message !== 'Subscription not active') return
      goToCheckout()
    }
  }

  async function getOrganizations () {
    try {
      setIsloading(true)
      const data = await fetchOrganizationsByUsers()

      if (data?.length === 0) {
        setErrMsg('You are not part of any Organization.')
      }

      setRows(data?.map((orgUser) => orgUser.organization) as OrgType[])
      if (data?.length === 1) {
        handleOrganizationChange(data[0].organization)
      }
      setIsloading(false)
    } catch (err) {
      setErrMsg((err as Error).message)
      console.error(err)
    }
  }

  useEffect(() => {
    getOrganizations()
  }, [user])

  const handleOrganizationChange = (data: any) => {
    setOrganization(data)
  }

  return (
    <>
    {errMsg && <Alert variant={'error'} isCloseIcon={true} onClose={() => {
      setErrMsg('')
    }}>  {errMsg}
      </Alert>}
      {isLoading
        ? <Preloader open={isLoading} modalText={'Loading...'}/>
        : rows.length > 1
          ? <SelectOutlinedInputChildren
          iconLeft={<BusinessIcon/>}
          disabled={rows?.length === 1}
          selected={organization?.name as string}
          labelText={'Organization'}
          handleValueChange={handleOrganizationChange}>
          {rows?.map((o, index) => (
            <Listbox.Option
              key={index}
              className={({ active }) => `text-white flex gap-3 items-center text-xs relative cursor-pointer select-none py-[8px] px-[8px] ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} `}
              value={o}
            >
              {o.name}
            </Listbox.Option>
          ))}
        </SelectOutlinedInputChildren>
          : rows.length
            ? <TextInput
            labelText={'Organization'}
            disabled={rows?.length === 1}
            value={organization?.name as string}/>
            : null
      }
      {rows.length
        ? <div className={'flex justify-start'}>
            <Button disabled={!organization?.name} onClick={async () => { await selectItem() }} title={'Select Organization'} variant={'primary'} type={'button'}/>
          </div>
        : <div>
            <Button title={'Go to Login'} onClick={() => {
              navigate('/login')
            }}
              variant={'primary'} type={'button'}
            />
          </div>
      }
    </>
  )
}

export default Organizations
