import React from 'react'
import ChangePasswordAccount from 'src/components/organisms/account-settings/change-password/ChangePasswordAccount'
import PersonalInformation from 'src/components/organisms/account-settings/personal-information/PersonalInformation'

const AccountSettings: React.FC = (props) => {
  return (
      <div className={'w-full flex flex-col gap-4 pt-11 pr-6 pl-6 text-white'}>
      <PersonalInformation/>
     <ChangePasswordAccount/>
    </div>

  )
}

export default AccountSettings
