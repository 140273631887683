import React, { useContext, useEffect, useState } from 'react'
import ApplicationContext from '../../../../store/application-context-provider'
import Editor from '@monaco-editor/react'
// import { fetchUser } from '../data';
import { Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import useInterval from 'src/hooks/use-interval'
import { useNavigate } from 'react-router-dom'
import { PREVIEW_TYPE, STATUS_TEXT_TYPE } from 'src/enums'
import { Stepper } from 'src/components/atoms/stepper'
import { ReactComponent as DocumentIcon } from 'src/assets/icons/document.svg'
import { ReactComponent as FolderIcon } from 'src/assets/icons/folder.svg'
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up.svg'
import { ReactComponent as DownIcon } from 'src/assets/icons/cloud-download-black.svg'
import { ButtonTab } from 'src/components/atoms/button-tab'
import { Button } from 'src/components/atoms/button'
import Preloader from 'src/components/UI/preloader/Preloader'
import { FireworksWrapper } from 'src/components/atoms/confetti-fireworks/FireworksWrapper'
import { CircularProgress } from 'src/components/atoms/circular-progress/CircularProgress'
import 'src/components/atoms/circular-progress/CircularProgress.css'
import { OptionBluePrint, UseCase } from 'src/API'
// interface FolderOpen{
//   i?: number,
//   open: boolean
// }

const ServiceBuilderPreview: React.FC = () => {
  const ctx = useContext(ApplicationContext)
  const navigate = useNavigate()
  // const [fileSchema, setFileSchema] = useState([])
  // const [service, setService] = useState({} as any)
  const [fileTree, setFileTree] = useState({})
  const [fileName, setFileName] = useState('')
  const [language, setLanguage] = useState('')
  const [fileValue, setFileValue] = useState('')
  const [folderOpen, setFolderOpen] = useState<string[]>([])
  const [digramHTML, setdigramHTML] = useState<any>()
  const [delay, setDelay] = useState<number | null>(3000)
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState<number>(0)
  const [previewType, setPreviewType] = React.useState(PREVIEW_TYPE.CODE_PREVIEW)
  const [stoppedFireworks, setStoppedFireworks] = React.useState(false)

  async function getDiagram () {
    try {
      setIsLoading(true)
      const id = ctx?.selectedServiceId ?? localStorage.getItem('previewServiceId')
      const authData = await Auth.currentSession()
      const responseURL = await fetch(`${process.env.REACT_APP_API}/architecture/${id}/signed-url`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
        }
      })
      const data = await responseURL.json()
      const html = await fetch(data.indexUrl)
      switch (html?.statusText) {
        case STATUS_TEXT_TYPE.NOT_FOUND:
          setDelay(3000)
          if (count >= 35) {
            setDelay(null)
            setIsLoading(false)
          }
          break
        case STATUS_TEXT_TYPE.OK:
          setDelay(null)
          setIsLoading(false)
          break
        default:
          setDelay(null)
          setIsLoading(false)
      }
      const text = await html.text()
      setdigramHTML(text.replace('./data.js', data.dataUrl))
    } catch (err) {
      console.log(err)
    }
  }

  async function getPreview () {
    try {
      const id = ctx?.selectedServiceId ?? localStorage.getItem('previewServiceId')
      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/pattern/${id}/paths`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
        }
      })
      const data = await result.json()
      const schema = data.filePaths.map((filePath: string) => {
        const langArray = filePath.split('.')
        const name = filePath.split(`/tmp/${id}/`).pop()
        return {
          name, language: langArray[langArray.length - 1]
        }
      })
      // setFileSchema(schema)
      const tree = schema.reduce((tree: any, path: any) => {
        let node = tree
        path.name.split('/').forEach((item: any, index: number) => {
          if (!node[item]) {
            node[item] = {}
          }
          node = node[item]
        })
        node.path = path
        node.isFile = true
        return tree
      }, {})

      const setIsFolderProperty = (obj: any) => {
        for (const k in obj) {
          if (typeof obj[k] === 'object' && obj[k] !== null) {
            if (Object.prototype.hasOwnProperty.call(obj, k) && !obj[k].isFile) {
              obj[k].isFolder = true
              obj[k].id = uuidv4()
            }
            setIsFolderProperty(obj[k])
          }
        }
      }
      setIsFolderProperty(tree)
      setFileTree(tree)
      setTimeout(function () {
        setStoppedFireworks(true)
      }, 10000)
    } catch (err) {
      setStoppedFireworks(true)
      console.error(err)
    }
  }
  useInterval(async () => {
    getDiagram()
    setCount(count + 1)
  }, delay)

  useEffect(() => {
    getPreview()
  }, [])

  const handleFileClick = async (f: any) => {
    setPreviewType(PREVIEW_TYPE.CODE_PREVIEW)
    setFileValue('Loading file...')
    const id = ctx?.selectedServiceId ?? localStorage.getItem('previewServiceId')
    const authData = await Auth.currentSession()
    const response = await fetch(`${process.env.REACT_APP_API}/pattern/${id}/signed-url?file=${encodeURIComponent(f.name)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
      }
    })
    const res = JSON.parse(await response.text())
    const result = await fetch(res.url)
    const data = await result.text()
    // if (f.language.toLowerCase() === 'js') f.language = 'javascript';
    // if (f.language.toLowerCase() === 'md') f.language = 'markdown';
    // if (f.language.toLowerCase() === 'yml') f.language = 'yaml';
    // if(f.language.toLowerCase() === 'json') f.language = 'json';
    // if(f.language.toLowerCase() === 'yaml') f.language = 'yaml';
    switch (f.language.toLowerCase()) {
      case 'json':
        f.language = 'json'
        break
      case 'js':
        f.language = 'javascript'
        break
      case 'yaml':
        f.language = 'yaml'
        break
      case 'yml':
        f.language = 'yaml'
        break
      case 'gitignore':
        f.language = 'markdown'
        break
      case 'prettierignore':
        f.language = 'markdown'
        break
      case 'prettierrc':
        f.language = 'yaml'
        break
      case 'md':
        f.language = 'markdown'
        break
      case 'bash':
        f.language = 'shell'
        break
      case 'sh':
        f.language = 'shell'
        break
      case 'py':
        f.language = 'python'
        break
      default:
        f.language = f.language.toLowerCase()
        break
    }
    setFileName(f.name)
    setLanguage(f.language)
    setFileValue(data)
  }

  const generateFoldersHTML = (input: any) => {
    const newFileTree = input.fileTree
    const keys: any[] = []

    Object.keys(newFileTree).forEach((k) => {
      const condition =
          typeof k === 'string' &&
          k !== 'id' &&
          k !== 'isOpen' &&
          k !== 'isFolder' &&
          k !== 'isFile'

      if (typeof k !== 'string' || condition) {
        if (!newFileTree[k].path) {
          k && keys.unshift(k)
        } else {
          k && keys.push(k)
        }
      }
    })

    return (
        <div className="box">
          <ul className="w-full" aria-labelledby="nested-file-list">
            {keys.map((key) => {
              const path = newFileTree[key].path
              const id = newFileTree[key].id

              if (path) {
                // file
                let name = path.name
                if (path.name.includes('/')) {
                  name = path.name.split('/')
                  name = name[name.length - 1]
                }
                return (
                    <li key={`${id}-btn`} onClick={async () => { await handleFileClick(path) }} className="flex items-center flex mt-[8px] gap-[8px] cursor-pointer  border-b-[1px] p-[8px] border-[#171B21] ">
                      <DocumentIcon/>
                      <span className="text-sm font-medium text-white">{name}</span>
                    </li>
                )
              } else {
                // folder
                return (
                    <>
                    <li key={`${id}-lib`} className="bg-[#171B21] mt-[16px]   border-[2px] rounded-lg px-[17px] border-[#2B2D31] py-[9px] hover:ring-2 ring-[#826AED]">
                      <button
                          onClick={() => {
                            if (folderOpen.includes(id)) {
                              setFolderOpen(folderOpen.filter((f) => f !== id))
                            } else {
                              setFolderOpen([...folderOpen, id])
                            }
                          }}
                          className="flex w-full items-center justify-between"
                      >
                        <div className="flex gap-[8px] items-center">
                        <FolderIcon key={`${id}-lii`} />
                        <span className="text-sm font-medium text-white">{key}</span>
                        </div>
                        {folderOpen.includes(id)
                          ? <ArrowUpIcon key={`${id}-lico`} />
                          : <ArrowDownIcon key={`${id}-lico2`} />
                        }</button>

                    </li>
                {folderOpen.includes(id) && (
                    <ul className="pl-4">
                      <li>
                        <div className="pl-1">
                          {generateFoldersHTML({ fileTree: newFileTree[key] })}
                        </div>
                      </li>
                    </ul>
                )}
                    </>
                )
              }
            })}
          </ul>
        </div>
    )
  }
  const download = async () => {
    try {
      const id = ctx?.selectedServiceId ?? localStorage.getItem('previewServiceId')
      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/pattern/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
        }
      })
      const data = await result.json()
      window.open(data.url, '_blank')
    } catch (err) {
      console.log(err)
    }
  }

  const goToInfo = () => {
    const id = ctx?.selectedServiceId ?? localStorage.getItem('previewServiceId')
    navigate(`/service-builder/${id}/info`)
  }

  return (
      <>
        {!isLoading &&
          <>
            {(!stoppedFireworks && location.pathname.indexOf('/congrats') > 0) && <FireworksWrapper isStopped={stoppedFireworks}/>}
            <div className={'bg-[#020409] flex py-[16px] items-center justify-center'}>
              <Stepper currentStep={ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? 6 : ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? 9 : 6} currentPage={'Review'}/>
            </div>
            <div className={'p-12'} >
              <div id="fileHierarchy">
                <div>
                  <div className="md:flex md:justify-between xl:justify-center gap-12 md:flex-row sm:flex-col">
                    <div className="w-full lg:w-4/12 xl:w-3/12 2xl:w-3/12 3xl:w-2/12">
                      {location.pathname.indexOf('/congrats') > 0 &&
                        <div className={'flex flex-col gap-[8px] pb-[16px]'}>
                          <div className={'text-white font-bold text-lg'}>Congratulations!</div>
                          <div className={'text-[#7E858F] text-xs flex flex-col flex-wrap'}>
                            Your serverless architecture has been successfully built!
                            <div className="mt-1">Please review the generated files or download your project by clicking the &quot;<span className={'font-bold'}>Download Files</span>&quot; button below.</div>
                          </div>
                        </div>
                      }
                      <div className={'pb-[50px]'}>
                        {generateFoldersHTML({ fileTree })}
                      </div>
                    </div>
                    <div className="sticky top-14 h-full w-full lg:w-8/12 xl:w-9/12 2xl:w-8/12 3xl:w-7/12">
                      {/* <Item>2</Item> */}
                      { (previewType === PREVIEW_TYPE.CODE_PREVIEW && fileName)
                        ? <h3 className={'text-white'} >{ fileName }</h3>
                        : <h3 className={'text-white'}></h3>
                      }
                      <div className={'mt-[16px] box-border bg-[#171B21]  flex w-full border-[1px] border-[#2B2D31] rounded-t-2xl overflow-hidden'}>
                        <ButtonTab isActive={previewType === PREVIEW_TYPE.CODE_PREVIEW}
                                  className={'w-full  py-[18px] bg-[#171B21]'} onClick={() => {
                                    setPreviewType(PREVIEW_TYPE.CODE_PREVIEW)
                                  }} type={'button'} title={PREVIEW_TYPE.CODE_PREVIEW}/>
                        <ButtonTab isActive={previewType === PREVIEW_TYPE.ARCHITECTURE_PREVIEW} onClick={() => {
                          setPreviewType(PREVIEW_TYPE.ARCHITECTURE_PREVIEW)
                        }} className={'w-full py-[18px] font-bold bg-[#171B21]'} type={'button'} title={PREVIEW_TYPE.ARCHITECTURE_PREVIEW}/>
                      </div>
                      <div className="w-full overflow-auto bg-[#171B21] border-[1px] border-[#2B2D31] rounded-b-2xl">
                        {previewType === PREVIEW_TYPE.CODE_PREVIEW && <Editor
                          height="60vh"
                          theme="vs-dark"
                          options={{ readOnly: true }}
                          language={language}
                          value={fileValue || 'Please select-outlined a file.'}
                          />}
                        {isLoading && previewType === PREVIEW_TYPE.ARCHITECTURE_PREVIEW
                          ? <div className={' w-full    circular-loader'}>
                            <CircularProgress/>
                          </div>
                          : <iframe srcDoc={count >= 35 ? 'Oops something went wrong' : digramHTML} style={{ backgroundColor: 'white', height: '60vh', width: '100%', display: previewType === PREVIEW_TYPE.CODE_PREVIEW ? 'none' : 'block' }} ></iframe> }
                      </div>
                      <div className='md:flex md:flex-row md:justify-end sm:justify-start mt-2'>
                        <Button onClick={goToInfo} title={'Deployment Info'} variant={'darker'} type={'button'}/>
                        <span className={'pl-5'}><Button iconRight={<DownIcon/>} onClick={async () => { await download() }} title={'Download files'} variant={'primary'} type={'button'}/></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        <Preloader open={isLoading} modalText={'Loading...'}/>
      </>
  )
}

export default ServiceBuilderPreview
