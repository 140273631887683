import { OptionBluePrint, UseCase } from 'src/API'
export const blueprintList = [
  {
    title: 'Single Lambda Per Route (recommended)',
    value: OptionBluePrint.LAMBDA_PER_ROUTE,
    useCase: UseCase.APIGW,
    description: 'Single Lambda function for each route and HTTP method',
    longDescription: 'We chose a Serverless REST API pattern for our service, following best practices. Each Lambda has a single responsibility, simplifying security. Lean functions enhance maintainability, speed up deployments, and improve performance. Granular scalability and observability offer insights for optimization.'
  },
  {
    title: 'Monolithic Lambda',
    value: OptionBluePrint.MONOLITHIC_LAMBDA,
    useCase: UseCase.APIGW,
    description: 'Single Lambda function and route with ANY method',
    longDescription: 'The Monolithic Lambda pattern uses a single function for various HTTP methods, including \'ANY.\' This means the function must handle all method requests, making it larger and impacting performance, deployment, and maintenance. Achieving Least Privilege Security becomes challenging, and troubleshooting is more cumbersome. Despite these drawbacks, it\'s a valid option for transitioning from Monolithic APIs to Microservices architecture.'
  },
  {
    title: 'Single Lambda Many Routes',
    value: OptionBluePrint.LAMBDA_MANY_ROUTES,
    useCase: UseCase.APIGW,
    description: 'Single Lambda function for multiple routes and HTTP methods',
    longDescription: 'The Single Lambda Many Routes pattern separates HTTP methods into individual routes, offering flexibility in managing each route at a granular level in API Gateway. This allows specific routes to be secured with Cognito, while keeping others open. A single Lambda Function supports all API routes, providing a well-defined set of supported methods. However, similar to the Monolithic approach, this pattern inherits most of its disadvantages.'
  },
  // {
  //   title: 'Custom Project',
  //   value: OptionBluePrint.NONE,
  //   useCase: UseCase.APIGW
  // },
  {
    title: 'DynamoDB (Single Data Source)',
    value: OptionBluePrint.SINGLE_DATA_SOURCE,
    useCase: UseCase.APPSYNC,
    description: 'Single DynamoDB table',
    longDescription: 'Build a GraphQL API with a single model. Only one model can be used at a time.'
  },
  {
    title: 'DynamoDB (Single Table Design)',
    value: OptionBluePrint.SINGLE_TABLE_DESIGN,
    useCase: UseCase.APPSYNC,
    description: 'Single DynamoDB table for all models',
    longDescription: 'Build a GraphQL API using the Single Table Design pattern. Only one DynamoDB table can be used.'
  },
  {
    title: 'DynamoDB (Multiple Data Sources)',
    value: OptionBluePrint.MULTI_DATA_SOURCE,
    useCase: UseCase.APPSYNC,
    description: 'Multiple DynamoDB tables',
    longDescription: 'Build a GraphQL API with multiple models and data sources.'
  },
  {
    title: 'BYOSchema',
    value: OptionBluePrint.BYOSCHEMA,
    useCase: UseCase.APPSYNC,
    description: 'Import your GraphQL Schema',
    longDescription: 'Bring Your Own Schema file, create AWS Resources like DynamoDB tables, and map out your models, data sources and resolvers accordingly.'
  }
]
