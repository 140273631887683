import { Alert } from 'src/components/atoms/alert'
import React, { useContext, useEffect, useState } from 'react'
import ResourceForm from '../../forms/resources/ResourceForm'
import ApplicationContext from '../../../../../store/application-context-provider'
import { v4 as uuidv4 } from 'uuid'
import ConfirmDialog from 'src/components/molecules/confirm-dialog/ConfirmDialog'
import { removeLambda } from '../../../../../data/lambdas/mutations'
import { removeCognito } from '../../../../../data/cognito/mutations'
import { removeRds } from '../../../../../data/rds/mutations'
import { removeVpc } from '../../../../../data/vpc/mutations'
import { AUTH_API_DETAILS_TYPE, RESOURCE_TYPE } from 'src/enums'
import { OptionBluePrint, UseCase } from 'src/API'
import { removeDynamo } from 'src/data/dynamo/mutations'
import { Button } from 'src/components/atoms/button/Button'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp.svg'
import { ServiceCard } from 'src/components/atoms/service-card'
import { blueprintList } from 'src/constants/defineService'
import DefineApiDetails from 'src/components/organisms/service-builder/wizard/appsync/DefineApiDetails'
import DefineModels from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/DefineModels'
import DefineEnums
  from 'src/components/organisms/service-builder/wizard/define-enums/DefineEnums'
import DefineSubscriptions
  from 'src/components/organisms/service-builder/wizard/define-subscriptions/DefineSubscriptions'
import { Tooltip } from 'src/components/atoms/tooltip'

interface RowResorce {
  id: string
  name: string
  description: string
  type: string
  resourceType: string
}

interface CognitoResource {
  cognitoTestEmail: string
  cognitoExisting: boolean
  cognitoType: string
  description: string
  id: string
  name: string
  resourceType: string
}

interface RowRoute {
  id: number
  path: string
  type: string
  method: string
  name: string
  private: boolean
  auth: CognitoResource
}

const DefineProjectGraphQLApi: React.FC<{
  isShowModels?: boolean
}> = props => {
  const { isShowModels } = props
  const ctx = useContext(ApplicationContext)
  const [openResourceModal, setOpenResourceModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [onConfirm, setOnConfirm] = useState(() => () => {
  })
  const [resources, setResources] = useState<RowResorce[]>([])
  const [routes, setRoutes] = useState<RowRoute[]>(ctx?.serviceBuilder?.routes || [])

  const cancelDelete = () => {
    setIsOpen(false)
  }

  const toggleOpenResourceModal = () => {
    setOpenResourceModal(!openResourceModal)
  }

  const confirmDelete = (resource: any) => {
    setIsOpen(true)
    setOnConfirm(() => async () => {
      const filtered = resources.filter(res => res.id !== resource.id)
      setResources(filtered)
      const serviceBuilder = ctx?.serviceBuilder
      serviceBuilder.resources = filtered
      ctx?.setServiceBuilder(serviceBuilder)
      ctx?.setResourceSearch(ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW
        ? RESOURCE_TYPE.LAMBDA
        : (ctx?.serviceBuilder?.config?.appSyncDetails?.authType === AUTH_API_DETAILS_TYPE.COGNITO && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA)
            ? [RESOURCE_TYPE.DYNAMO_DB, RESOURCE_TYPE.COGNITO_USER_POOL]
            : RESOURCE_TYPE.DYNAMO_DB)
      switch (resource.resourceType) {
        case RESOURCE_TYPE.LAMBDA:
          ctx?.serviceBuilder.id && resource.id &&
          await removeLambda(resource.id, ctx?.serviceBuilder.id)
          serviceBuilder.routes = serviceBuilder.routes.filter((r: any) => r?.resource?.id !== resource.id)
          setRoutes(routes.filter((r: any) => r?.resource?.id !== resource.id))
          serviceBuilder.triggers = serviceBuilder.triggers.filter((t: any) => t.resourceId !== resource.id)
          ctx?.setRouteSearch(serviceBuilder?.routes)
          ctx?.setServiceBuilder(serviceBuilder)
          ctx?.setResourceSearch(RESOURCE_TYPE.LAMBDA)
          break

        case RESOURCE_TYPE.COGNITO_USER_POOL:
          ctx?.serviceBuilder.id && resource.id &&
          await removeCognito(resource.id, ctx?.serviceBuilder.id)
          break

        case RESOURCE_TYPE.DYNAMO_DB:
          ctx?.serviceBuilder.id && resource.id &&
          await removeDynamo(resource.id, ctx?.serviceBuilder.id)
          serviceBuilder.models = serviceBuilder.models.map((f: any) => resource.id === f?.dataSource?.id
            ? ({
                ...f,
                dataSource: null
              })
            : f)
          serviceBuilder.resolvers = serviceBuilder.resolvers.filter((res: any) => res.dataSource !== resource.name)
          ctx?.setServiceBuilder(serviceBuilder)
          break

        case RESOURCE_TYPE.RDS:
          ctx?.serviceBuilder.id && resource.id &&
          await removeRds(resource.id, ctx?.serviceBuilder.id)
          break

        case RESOURCE_TYPE.VPC:
          ctx?.serviceBuilder.id && resource.id &&
          await removeVpc(resource.id, ctx?.serviceBuilder.id)
          break

        default:
          setIsOpen(false)
          break
      }
      setIsOpen(false)
    })
  }

  const editResource = (resource: any) => {
    const res = ctx?.serviceBuilder?.resources.find((r: any) => r.id === resource.id)
    ctx?.setResourceInput(res)
    ctx?.setResourceSearch(ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW
      ? RESOURCE_TYPE.LAMBDA
      : (ctx?.serviceBuilder?.config?.appSyncDetails?.authType === AUTH_API_DETAILS_TYPE.COGNITO && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA)
          ? [RESOURCE_TYPE.DYNAMO_DB, RESOURCE_TYPE.COGNITO_USER_POOL]
          : RESOURCE_TYPE.DYNAMO_DB)
    toggleOpenResourceModal()
  }

  const populateResourceTable = () => {
    const resourceList = ctx?.serviceBuilder?.resources || []
    ctx?.setResourceSearch(ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW
      ? RESOURCE_TYPE.LAMBDA
      : (ctx?.serviceBuilder?.config?.appSyncDetails?.authType === AUTH_API_DETAILS_TYPE.COGNITO && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA)
          ? [RESOURCE_TYPE.DYNAMO_DB, RESOURCE_TYPE.COGNITO_USER_POOL]
          : RESOURCE_TYPE.DYNAMO_DB)
    const res = resourceList.map((resource: any) => {
      let type: string = ''
      switch (resource.resourceType) {
        case RESOURCE_TYPE.LAMBDA:
          type = '/assets/images/lambdaBlackIcon.svg'
          break
        case RESOURCE_TYPE.COGNITO_USER_POOL:
          type = '/assets/images/cognitoBlackIcon.svg'
          break
        case RESOURCE_TYPE.DYNAMO_DB:
          type = '/assets/images/dynamoDbBlackIcon.svg'
          break
        case RESOURCE_TYPE.RDS:
          type = '/assets/images/rdsBlacIcon.svg'
          break
        case RESOURCE_TYPE.VPC:
          type = '/assets/images/vpcBlackIcon.svg'
          break
        case RESOURCE_TYPE.SQS:
          type = '/assets/images/sqsBlackIcon.svg'
          break
        case RESOURCE_TYPE.SNS:
          type = '/assets/images/snsBlackIcon.svg'
          break
        case RESOURCE_TYPE.S3:
          type = '/assets/images/s3BlackIcon.svg'
          break
        default:
          break
      }
      return {
        ...resource,
        type
      }
    })
    setResources(ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? res : res.filter((r: any) => r.resourceType !== RESOURCE_TYPE.LAMBDA))
  }

  useEffect(() => {
    populateResourceTable()
  }, [ctx])

  const handleResourceSubmit = () => {
    const serviceBuilder = ctx?.serviceBuilder
    const input = ctx?.resourceInput
    if (!input.id) {
      input.id = uuidv4()
      serviceBuilder.resources = serviceBuilder.resources ?? []
      serviceBuilder?.resources?.push(input)
    } else {
      serviceBuilder.resources = serviceBuilder?.resources?.map((r: any) => {
        if (r.id === input.id) {
          return input
        }
        return r
      })
    }

    const lambdasWithTrigger = serviceBuilder?.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.LAMBDA && r?.trigger)
    serviceBuilder.triggers = lambdasWithTrigger?.map((t: any) => {
      return {
        type: 'LAMBDA_PROXY',
        resource: t,
        resourceId: t.id,
        id: uuidv4()
      }
    })
    ctx?.setServiceBuilder(serviceBuilder)
    populateResourceTable()
    ctx?.setRouteSearch(serviceBuilder?.routes)
    ctx?.setResourceSearch(ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW
      ? RESOURCE_TYPE.LAMBDA
      : (ctx?.serviceBuilder?.config?.appSyncDetails?.authType === AUTH_API_DETAILS_TYPE.COGNITO && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA)
          ? [RESOURCE_TYPE.DYNAMO_DB, RESOURCE_TYPE.COGNITO_USER_POOL]
          : RESOURCE_TYPE.DYNAMO_DB)
  }

  useEffect(() => {
    const isLambdasWithoutTrigger = ctx?.serviceBuilder?.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.LAMBDA && !r.trigger).length > 0
    setIsShowAlert(!isLambdasWithoutTrigger)
  }, [ctx, openResourceModal, isOpen])

  return (
    <>
      <div className={'bg-[#020409] text-white flex flex-col gap-[32px] w-full xl:w-4/5 2xl:w-4/6'}>
        {(
          (isShowAlert && ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW) ||
          (ctx?.isShowDynamoAlert)
        ) &&
        <div className={'flex flex-col gap-4 items-center pt-2'}>
          {isShowAlert && ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW &&
            <Alert classname={'w-2/3'} variant={'error'}>Please create at least one lambda without a trigger.
              For a REST API Use Case, you need at least one Lambda Resource to be triggered by the API Gateway event.
            </Alert>}
          {ctx?.isShowDynamoAlert && <Alert variant={'error'} classname={'w-2/3'} isCloseIcon={true} onClose={() => {
            ctx?.setIsShowDynamoAlert(false)
          }}>You can’t add more than one data source using the DynamoDB (Single Data Source) blueprint. If you want to create multiple
            data sources - please use the DynamoDB (Multiple Data Sources) blueprint.
          </Alert>}
        </div>
        }
        <div className={'text-2xl font-bold'}>{blueprintList.find((b) => b.value === ctx?.serviceBuilder?.optionBluePrint)?.title}</div>
        <DefineApiDetails/>
        {!ctx?.resourceSearch && ctx?.serviceBuilder?.config?.useCase === UseCase.APPSYNC &&
          <div className={'flex justify-center'}>
            <Alert classname={'w-2/3'} variant={'info'}>{`Click on Add resource to add your DynamoDB data sources ${ctx?.serviceBuilder?.config?.appSyncDetails?.authType === AUTH_API_DETAILS_TYPE.COGNITO && ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? 'and  Cognito resource' : ''}`}</Alert>
          </div>}
        <div className={'flex mt-2 flex-col gap-[16px]'}>
          <div className={'text-lg font-bold flex items-center'}>
            Resource Library
            <Tooltip text='Add and configure your AWS Resources such as DynamoDB Tables, Cognito User Pools, S3 Buckets etc.' />
          </div>
        <div className={'rounded-2xl border-[1px] bg-[#171B21] border-[#2B2D31]'}>
          <div className={'px-4 flex gap-[16px] items-center py-[4px]'}>
            <div className={'text-lg'}>Resources</div>
            <Button iconLeft={<AddIcon/>} classname={'bg-[#171B21] font-normal'} title={'Add resource'}
                    onClick={() => {
                      ctx?.setResourceInput({})
                      toggleOpenResourceModal()
                    }} variant={'darker'} type="button"/>
          </div>
          <div className={'gap-[28px] min-h-[162px] grid grid-cols-[repeat(auto-fill,minmax(248px,3fr))]  px-[30px] py-[33px] border-t-[1px] border-[#2B2D31] overflow-y-auto'}>
            {resources?.sort((a, b) => a.resourceType > b.resourceType ? 1 : -1)?.map((row: RowResorce) =>
              <ServiceCard
                handleEditCard={editResource}
                handleRemoveCard={confirmDelete}
                card={row}
                key={row.id}
              />)}
          </div>
          </div>
        </div>
        {isShowModels && <DefineModels/>}
        {isShowModels && <DefineEnums/>}
        {isShowModels && <DefineSubscriptions/>}
      </div>
      <ConfirmDialog isOpen={isOpen} onConfirm={onConfirm} onCancel={cancelDelete}/>
      <ResourceForm onClose={toggleOpenResourceModal} onSubmit={handleResourceSubmit}
                    open={openResourceModal}></ResourceForm>
    </>
  )
}

export default DefineProjectGraphQLApi
