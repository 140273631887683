import { Organization, OrganizationUser, Team, TeamUser } from '../../API'
import { API, graphqlOperation } from 'aws-amplify'
import { getTeam, listOrganizations, listOrganizationUsers, listTeams, listTeamUsers, listOrganizationsByUser } from '../../graphql/queries'
import { GraphQLResult } from '@aws-amplify/api'

export const fetchOrganizations = async (limit?: number) => {
  const listOrganizationsResult = (await API.graphql(
    graphqlOperation(listOrganizations, { input: { limit: limit ?? 10, nextToken: null } })
  )) as GraphQLResult<{ listOrganizations: { Organizations: Organization[] } }>
  const organizations = (listOrganizationsResult?.data?.listOrganizations?.Organizations)
  return organizations
}

export const fetchTeams = async (organizationId: string, limit?: number) => {
  const listTeamsResult = (await API.graphql(
    graphqlOperation(listTeams, { input: { orgId: organizationId, limit: limit ?? 10, nextToken: null } })
  )) as GraphQLResult<{ listTeams: { Teams: Team[] } }>
  const teams = (listTeamsResult?.data?.listTeams?.Teams)
  return teams
}

export const fetchTeam = async (teamId: string) => {
  const getTeamResult = (await API.graphql(
    graphqlOperation(getTeam, { input: { Id: teamId } })
  )) as GraphQLResult<{ getTeam: { Team: Team } }>
  const team = (getTeamResult?.data?.getTeam?.Team)
  return team
}

export const fetchOrganizationUsers = async (organizationId: string, limit?: number) => {
  const listOrganizationUsersResult = (await API.graphql(
    graphqlOperation(listOrganizationUsers, { input: { orgId: organizationId, limit: limit ?? 10, nextToken: null } })
  )) as GraphQLResult<{ listOrganizationUsers: { OrganizationUsers: OrganizationUser[] } }>
  const organizationUsers = (listOrganizationUsersResult?.data?.listOrganizationUsers?.OrganizationUsers)
  return organizationUsers
}

export const fetchOrganizationsByUsers = async () => {
  const listOrganizationsByUserResult = (await API.graphql(
    graphqlOperation(listOrganizationsByUser)
  )) as GraphQLResult<{ listOrganizationsByUser: { OrganizationUsers: OrganizationUser[] } }>
  const organizationUsers = (listOrganizationsByUserResult?.data?.listOrganizationsByUser?.OrganizationUsers)
  return organizationUsers
}

export const fetchTeamUsers = async (teamId: string, limit?: number) => {
  const listTeamUsersResult = (await API.graphql(
    graphqlOperation(listTeamUsers, { input: { teamId, limit: limit ?? 10, nextToken: null } })
  )) as GraphQLResult<{ listTeamUsers: { TeamUsers: TeamUser[] } }>
  const teamUsers = (listTeamUsersResult?.data?.listTeamUsers?.TeamUsers)
  return teamUsers
}
