import React, { useContext } from 'react'
import './stepper.css'
import { ReactComponent as CheckmarkIcon } from 'src/assets/icons/checkmarkGreen.svg'
import { OptionBluePrint } from 'src/API'
import ApplicationContext from 'src/store/application-context-provider'

export const Stepper: React.FC<{ currentPage: string, currentStep: number }> = props => {
  const { currentPage = '', currentStep = 1 } = props
  const ctx = useContext(ApplicationContext)
  const steps = ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.BYOSCHEMA ? ['1', '2', '3', '4', '5', '6', '7', '8'] : ['1', '2', '3', '4', '5']

  return (
    <div className={'w-[296px]'}>
      <div className="flex justify-center ">
        {steps?.map((step, i) => (
          <div
            key={i}
            className={`step-item ${currentStep === i + 1 && 'active'} ${
              (i + 1 < currentStep) && 'complete'
            } `}
          >
            <div className="step">
              {i + 1 < currentStep ? <CheckmarkIcon/> : currentStep === i + 1 ? currentPage : i + 1}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
