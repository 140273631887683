import React, { CSSProperties } from 'react'
import Lottie from 'lottie-react-web'
import animationData from 'src/components/atoms/confetti-fireworks/animation.json'

export const FireworksWrapper: React.FC<{
  isStopped?: boolean
}> = props => {
  const { isStopped } = props
  const style: CSSProperties = {
    top: '5px',
    left: '160px',
    width: '500px',
    height: '280px',
    position: 'fixed',
    zIndex: 2000
  }

  return (
    <Lottie
      isStopped={isStopped}
        style={style}
        options={{
          animationData
        }}
      />
  )
}
