import React, { useRef } from 'react'
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg'

const ApigwDeploymentNote: React.FC<{ isActiveMac?: boolean }> = props => {
  const { isActiveMac } = props

  const setupCodeRef = useRef<HTMLPreElement>(null)
  const installAwsCodeRef = useRef<HTMLPreElement>(null)
  const testCodeRef = useRef<HTMLPreElement>(null)
  const getAPIKeyCodeRef = useRef<HTMLPreElement>(null)
  const getJWTCodeRef = useRef<HTMLPreElement>(null)
  const deployCodeRef = useRef<HTMLPreElement>(null)

  function handleCopy (ref: any) {
    if (ref.current) {
      const codeText = ref.current.textContent
      navigator.clipboard.writeText(codeText as string)
    }
  }

  return (
    <ol className={'list-decimal list-inside text-xs pb-[25px]'}>
      <li className={'py-[8px] mt-[25px] text-[#7E858F] text-xs'}>Install AWS CLI and Serverless Framework</li>
        <div className={'px-[8px] py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(installAwsCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div>
          </div>
        </div>
                <code ref={installAwsCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'} >
                    {isActiveMac ? 'brew install awscli && npm install -g serverless@latest' : 'choco install awscli && npm install -g serverless@latest'}
                </code>
                <li className={'pt-[25px] pb-[22px] text-[#7E858F] text-xs'}>Update the cloudgto.yml file with real values, if using existing resources</li>
      <li className={'pb-[8px]  text-[#7E858F] text-xs'}>Install and Deploy</li>
        <div className={'px-[8px] py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(setupCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
                <code ref={setupCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
                  npm run setup
                </code>
      <li className={'py-[8px] mt-[20px] text-[#7E858F] text-xs'}>Test Locally</li>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(testCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={testCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
            sls [service-name]:invoke local -f [function-name] -p [path-to-test-event].json
        </code>
        <li className={'py-[25px] text-[#7E858F] text-xs'}>Test E2E (end-to-end) - open Postman / Insomnia and paste APIGW URL</li>
      <li className={'pb-[8px]  text-[#7E858F] text-xs'}>Add x-api-key header by running the following command and test:</li>
        <div className={'px-[8px] py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(getAPIKeyCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={getAPIKeyCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
            npm run getAPIKey
        </code>
      <li className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>Add Authorization header with JWT ID Token from Cognito User Pool</li>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(getJWTCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={getJWTCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
            npm run getJWT
        </code>
      <li className={'pb-[8px] mt-[25px] text-[#7E858F] text-xs'}>Add your own custom code to the service and redeploy</li>
        <div className={'px-[8px]  py-[8px] flex justify-end text-xs bg-[#2B2D31] rounded-t-lg text-[#7E858F]'}>
          <div onClick={() => { handleCopy(deployCodeRef) }} className={'flex cursor-pointer gap-[6px]'}><CopyIcon/><div>Copy</div></div></div>
        <code ref={deployCodeRef} className={'bg-[#020409] text-xs rounded-b-lg text-[#7E858F] block px-[24px] py-[24px] whitespace-pre-wrap break-words overflow-wrap-normal'}>
            sls deploy
        </code>
    </ol>
  )
}

export default ApigwDeploymentNote
