import React, { Fragment, useContext, useEffect, useState } from 'react'
import ResourceLambdaForm from './ResourceLambdaForm'
import ResourceCognitoForm from './ResourceCognitoForm'
import ResourceDynamoForm from './ResourceDynamoForm'
import ResourceVpcForm from './ResourceVpcForm'
import ResourceRdsForm from './ResourceRdsForm'
import ApplicationContext from '../../../../../store/application-context-provider'
import ResourceSnsForm from './ResourceSnsForm'
import ResourceSqsForm from './ResourceSqsForm'
import ResourceS3Form from './ResourceS3Form'
import { RESOURCE_TYPE } from 'src/enums'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import { SearchInput } from 'src/components/atoms/search-input'
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg'
import { ReactComponent as LambdaBlackIcon } from 'src/assets/icons/lambdaBlackIcon.svg'
import { ReactComponent as CognitoBlackIcon } from 'src/assets/icons/cognitoBlackIcon.svg'
import { ReactComponent as DynamoBlackIcon } from 'src/assets/icons/dynamoDbBlackIcon.svg'
import { ReactComponent as RDSBlackIcon } from 'src/assets/icons/rdsBlacIcon.svg'
import { ReactComponent as SQSBlackIcon } from 'src/assets/icons/sqsBlackIcon.svg'
import { ReactComponent as SNSBlackIcon } from 'src/assets/icons/snsBlackIcon.svg'
import { ReactComponent as S3BlackIcon } from 'src/assets/icons/s3BlackIcon.svg'
import { ReactComponent as VpcBlackIcon } from 'src/assets/icons/vpcBlackIcon.svg'
import { OptionBluePrint, UseCase } from 'src/API'
import { SelectOutlinedInputChildren } from 'src/components/atoms/select-outlined-children'

const resourcesMap = {
  LAMBDA: {
    imageLink: '/assets/images/lambdaBlackIcon.svg',
    icon: <LambdaBlackIcon/>,
    title: 'Lambda',
    resourceType: RESOURCE_TYPE.LAMBDA
  },
  COGNITO_USER_POOL: {
    icon: <CognitoBlackIcon className={'h-[50px]'} />,
    imageLink: '/assets/images/cognitoBlackIcon.svg',
    title: 'Cognito User Pool',
    resourceType: RESOURCE_TYPE.COGNITO_USER_POOL
  },
  DYNAMO_DB: {
    icon: <DynamoBlackIcon/>,
    imageLink: '/assets/images/dynamoDbBlackIcon.svg',
    title: 'DynamoDB',
    resourceType: RESOURCE_TYPE.DYNAMO_DB
  },
  RDS: {
    icon: <RDSBlackIcon/>,
    imageLink: '/assets/images/rdsBlacIcon.svg',
    title: 'RDS',
    resourceType: RESOURCE_TYPE.RDS
  },
  SQS: {
    icon: <SQSBlackIcon className={'h-[50px]'}/>,
    imageLink: '/assets/images/sqsBlackIcon.svg',
    title: 'SQS',
    resourceType: RESOURCE_TYPE.SQS
  },
  SNS: {
    icon: <SNSBlackIcon/>,
    imageLink: '/assets/images/snsBlackIcon.svg',
    title: 'SNS',
    resourceType: RESOURCE_TYPE.SNS
  },
  S3: {
    icon: <S3BlackIcon/>,
    imageLink: '/assets/images/s3BlackIcon.svg',
    title: 'S3',
    resourceType: RESOURCE_TYPE.S3
  },
  VPC: {
    icon: <VpcBlackIcon/>,
    imageLink: '/assets/images/vpcBlackIcon.svg',
    title: 'VPC',
    resourceType: RESOURCE_TYPE.VPC
  }
}

const ResourceForm: React.FC<{ open: boolean, onClose: Function, onSubmit: Function }> = props => {
  const {
    open,
    onClose,
    onSubmit
  } = props

  const [resources, setResources] = useState(Object.values(resourcesMap))
  const [resourceName, setResourceName] = useState('')
  const [resourceType, setResourceType] = React.useState('')

  const changeResourceNameHandler = (event: Event | any) => {
    setResourceName(event.target.value)
  }

  useEffect(() => {
    if (resourceName) {
      setResources(Object.values(resourcesMap).filter((f) => f.title.toLowerCase().includes(resourceName.trim().toLowerCase())))
    } else {
      setResources(Object.values(resourcesMap))
    }
  }, [resourceName])

  const handleClose = () => {
    onClose()
    setResources(Object.values(resourcesMap))
    setResourceName('')
  }

  const handleSubmit = (event: Event | any) => {
    onSubmit()
    event?.preventDefault()
    onClose()
    return false
  }

  const ctx = useContext(ApplicationContext)

  useEffect(() => {
    setResourceType(ctx?.resourceInput?.resourceType || '')
  }, [ctx?.resourceInput])

  const handleResourceSelectedChange = (data: any) => {
    const resource = data
    ctx?.setResourceInput({ resourceType: resource })
    setResourceType(resource)
  }

  return (
        <>
          <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={() => {}}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#020409] bg-opacity-80"/>
                    </Transition.Child>
                    <div className="fixed  inset-0 overflow-y-auto">
                        <div className="flex  min-h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className={'min-w-[500px] m-4 transform rounded-lg  bg-[#171B21]  text-left align-middle  transition-all'}>
                                    <div
                                        className="text-2xl mx-6 my-6 font-bold text-white flex items-center justify-between gap-6">
                                        <span>{ctx?.resourceInput?.id ? 'Edit Resource' : 'Add Resource'}</span>
                                        <CloseIcon className={'pointer'} onClick={handleClose}/>
                                    </div>
                                    <div className={'border-t-[1px] py-4  flex flex-col gap-4  border-[#020409] text-sm text-[#7E858F]'}>

                                      {!resourceType && <SearchInput className={'px-6'} placeholder={'Search:'} value={resourceName}
                                                                       onChange={changeResourceNameHandler}
                                                                       iconRight={<SearchIcon/>}/>}
                                        <form onSubmit={handleSubmit} className="flex flex-col">
                                          <div className="max-md:w-full md:min-w-max px-6">
                                          {!!resourceType &&
                                            <SelectOutlinedInputChildren
                                              handleValueChange={handleResourceSelectedChange}
                                              labelText={'Resource:'}
                                              selected={resourceType}
                                              disabled={ctx?.resourceInput?.id}
                                            >
                                              {(ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? Object.values(resourcesMap) : Object.values(resourcesMap).filter((r) => r.resourceType !== RESOURCE_TYPE.LAMBDA))?.map((o, index) => (
                                                <Listbox.Option
                                                  key={index}
                                                  className={({ active }) => `text-white flex gap-3 items-center text-xs relative cursor-pointer select-none py-[8px] px-[8px] ${active ? 'bg-[#7E858F]' : 'bg-[#2B2D31]'} `}
                                                  value={o.resourceType}
                                                >
                                                  {o.title}<img src={o.imageLink} width="18" />
                                                </Listbox.Option>
                                              ))}
                                            </SelectOutlinedInputChildren>
                                          }
                                            {!resourceType && <div className={'my-3 gap-4 grid grid-cols-1 md:grid-cols-3'}>
                                                { (ctx?.serviceBuilder?.config?.useCase === UseCase.APIGW ? resources : resources.filter((r: any) => r.resourceType !== RESOURCE_TYPE.LAMBDA))?.map((r) => < div key={r.imageLink}
                                                                            onClick={() => {
                                                                              if (ctx?.serviceBuilder.optionBluePrint === OptionBluePrint.SINGLE_DATA_SOURCE && r.resourceType === RESOURCE_TYPE.DYNAMO_DB && ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).length !== 0) {
                                                                                ctx?.setIsShowDynamoAlert(true)
                                                                                handleClose()
                                                                              } else if (ctx?.serviceBuilder.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN && r.resourceType === RESOURCE_TYPE.DYNAMO_DB && ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)?.length > 0) {
                                                                                ctx?.setIsShowDynamoAlert(true)
                                                                                handleClose()
                                                                              } else {
                                                                                setResourceType(r.resourceType)
                                                                                const input = ctx?.resourceInput
                                                                                input.resourceType = r.resourceType
                                                                                ctx?.setResourceInput(input)
                                                                              }
                                                                            }}
                                                                            className={'bg-[#020409] h-36 md:w-36 w-full cursor-pointer gap-[8px] rounded-lg flex flex-col items-center justify-center'}>
                                                    <div className={'px-[43px]'}>
                                                      {r.icon}
                                                    </div>
                                                    <div className={'text-xs text-white'}>{r.title}</div>
                                                </div>)}
                                            </div>}
                                      </div>
                                      {resourceType === RESOURCE_TYPE.LAMBDA &&
                                                <ResourceLambdaForm close={handleClose} submit={handleSubmit}/>}
                                            {resourceType === RESOURCE_TYPE.COGNITO_USER_POOL &&
                                                <ResourceCognitoForm close={handleClose} submit={handleSubmit}/>}
                                            {resourceType === RESOURCE_TYPE.DYNAMO_DB &&
                                                <ResourceDynamoForm close={handleClose} submit={handleSubmit}/>}
                                            {resourceType === RESOURCE_TYPE.RDS &&
                                                <ResourceRdsForm close={handleClose} submit={handleSubmit}/>}
                                            {resourceType === RESOURCE_TYPE.SNS &&
                                                <ResourceSnsForm close={handleClose} submit={handleSubmit}/>}
                                            {resourceType === RESOURCE_TYPE.SQS &&
                                                <ResourceSqsForm close={handleClose} submit={handleSubmit}/>}
                                            {resourceType === RESOURCE_TYPE.S3 &&
                                                <ResourceS3Form close={handleClose} submit={handleSubmit}/>}
                                            {resourceType === RESOURCE_TYPE.VPC &&
                                                <ResourceVpcForm close={handleClose} submit={handleSubmit}/>}
                                            <br/>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>)
}

export default ResourceForm
