import { DYNAMO_KEY_TYPE } from 'src/enums'
import { Gsi } from 'src/components/organisms/service-builder/forms/resources/ResourceDynamoForm'
import { SelectInputOption } from 'src/components/atoms/select-outlined'

export const isValidEmail = (email: string) => {
  const emailRegEx = /^(?:[\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/
  return emailRegEx.test(email)
}
export const isValidResolvers = (arr: any[]) => {
  if (arr.length === 0) {
    return false
  }
  return arr.every((el: any) => el.name && el.type)
}
export const isValidFieldResolvers = (arr: any[]) => {
  if (arr.length === 0) {
    return false
  }
  return arr.every((el: any) => el.name && el.model && el.type)
}
export const isValidGsiList = (gsiArray: Gsi[]) => {
  for (let i = 0; i < gsiArray?.length; i++) {
    const gsi = gsiArray[i]
    const {
      gsiId,
      gsiName,
      gsiKeyType,
      primaryAttribute,
      primaryAttributeType,
      secondaryAttribute,
      secondaryAttributeType,
      projectionType
    } = gsi

    if (
      !gsiId ||
        !gsiName ||
        !gsiKeyType ||
        !primaryAttribute ||
        !primaryAttributeType ||
        !projectionType ||
        (gsiKeyType === DYNAMO_KEY_TYPE.COMPOSITE && (!secondaryAttribute || !secondaryAttributeType))
    ) {
      return false
    }
  }

  return true
}
export const checkUniqueGsiNames = (array: Gsi[]): boolean => {
  const names = new Set<string>()

  for (const obj of array) {
    const { gsiName } = obj

    if (gsiName.trim() !== '' && names.has(gsiName)) {
      return false
    }
    names.add(gsiName)
  }

  return true
}
export const checkDataSourceName = (input: string): boolean => {
  const regex: RegExp = /^[_A-Za-z][_0-9A-Za-z]*$/
  return regex.test(input)
}
export const checkEmptyString = (arr: string[]) => {
  return arr?.some(string => string === '')
}
export const checkEmptyFields = (arr: any[]) => {
  return arr?.every(obj => obj.type && obj.name)
}
export const getUniqueObjectsByValue = (objects: any[]) => {
  return objects?.reduce((uniqueObjects, obj) => {
    const index = uniqueObjects.findIndex((item: any) => item.name === obj.name)
    if (index === -1) {
      uniqueObjects.push(obj)
    }
    return uniqueObjects
  }, [])
}
export const checkSubscriptionEmptyField = (data: any[]) => {
  return data?.some((item: any) => {
    if (!item.name || !item.type) {
      return true
    }

    if (!item.args) {
      return false
    }
    return item?.args?.some((arg: any) => !arg.name || !arg.type)
  })
}
export const nameExists = (name: string, dataArray: any[]) => {
  return dataArray?.some(item => item.name === name)
}
export const removeObjectsWithDuplicateName = (objects: SelectInputOption[]): SelectInputOption[] => {
  const nameCountMap: Record<string, number> = {}

  objects.forEach((obj) => {
    nameCountMap[obj.name] = (nameCountMap[obj.name] || 0) + 1
  })

  return objects.filter((obj) => nameCountMap[obj.name] !== 2)
}
export const getToMutationsFromResolvers = (resolvers: any[]): string[] => {
  const allowedTypes = ['delete', 'update', 'create']
  const filteredResolvers = resolvers.filter((resolver) => allowedTypes.includes(resolver.type))
  return filteredResolvers.map((resolver) => resolver.name)
}
