import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import { isValidEmail } from 'src/utils/validation'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IAuthenticatedUser, login } from 'src/auth/cognito'
import { CognitoUser } from 'amazon-cognito-identity-js'
import ApplicationContext from 'src/store/application-context-provider'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo-cloudgto.svg'
import { TextInput } from 'src/components/atoms/text-input'
import { PasswordInput } from 'src/components/atoms/password-input'
import { Button } from 'src/components/atoms/button'
import { Alert } from 'src/components/atoms/alert'
import Preloader from 'src/components/UI/preloader/Preloader'

interface FormSignIn {
  password: string
  email: string
}
const schema = yup.object({
  password: yup
    .string()
    .required('This field is required'),
  email: yup
    .string()
    .required('This field is required')
    .test(
      'Email',
      'Please enter a valid email address',
      (value) => {
        if (value) return isValidEmail(value)
        return true
      }
    )
})
const Login: React.FC = () => {
  const navigate = useNavigate()
  const ctx = useContext(ApplicationContext)
  const [isLoading, setIsloading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const {
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isValid
    }
  } = useForm<FormSignIn>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      email: ''
    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    const password = window.location.search.split('password=')[1] || ''
    const email = (Object.fromEntries(new URLSearchParams(window.location.search)).username) || ''

    reset({
      password: decodeURIComponent(password),
      email
    })

    if (password.length && email.length) {
      handleSubmit(onSubmit)()
    }
  }, [reset])

  const createAccountHandle = () => {
    navigate('/signup')
  }
  const forgotPasswordHandle = () => {
    navigate('/forgot-password')
  }
  const onSubmit = async (data: FormSignIn) => {
    try {
      setIsloading(true)
      const response: IAuthenticatedUser | CognitoUser = await login({
        username: data.email,
        password: data.password
      })
      ctx?.setAuthenticatedUser(response)
      if (Object.prototype.hasOwnProperty.call(response, 'id')) {
        navigate('/select-organization')
      } else {
        navigate('/reset-password')
        localStorage.setItem('temporary_password', data.password)
      }
      setIsloading(false)
    } catch (err) {
      console.log(err)
      setErrMsg((err as Error).message)
      setIsloading(false)
    }
  }
  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'fixed', top: '0', left: '0' }} className={'bg-[#020409] z-50'}></div>
      <div className={'inset-0 z-50 text-white w-screen h-full flex absolute flex-col items-center justify-center gap-[50px]'}>
        <LogoIcon/>
        <div className={'bg-[#171B21] rounded-2xl md:w-[478px] sm:w-full '}>
          <div className={'p-8'}>
            <div className={'flex flex-col gap-[22px]'}>
            <div className={'text-2xl font-normal'}>Sign In</div>
            <div className={'text-[#7E858F] text-xs'}>New user?<span onClick={createAccountHandle} className={'cursor-pointer pl-4 text-[#826AED]'}>Create account</span></div>
            <div className={'border-b-[1px] border-[#2B2D31]'}></div>
            </div>
            {errMsg
              ? <Alert isCloseIcon={true} classname={'mt-[22px]'} onClose={() => { setErrMsg('') }} variant={'error'}>{errMsg}</Alert>
              : (
                <form className={'flex flex-col mt-[16px] gap-[22px]'}>
                  <Controller
                    name={'email'}
                    rules={{ required: true }}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                      {...register('email')}
                      labelText={'Email:'}
                      error={errors?.email?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}/>)}
                  />
                  <Controller
                    name={'password'}
                    rules={{ required: true }}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<PasswordInput
                      {...register('password')}
                      labelText={'Password:'}
                      error={errors?.password?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}/>)}
                  />
                  <div className={'flex justify-between items-center'}>
                    <Button title={'Sign In'} disabled={!isValid} variant={'primary'} onClick={handleSubmit(onSubmit)} type={'submit'}/>
                    <div onClick={forgotPasswordHandle} className={'cursor-pointer text-xs text-[#826AED]'}>Forgot password ?</div>
                  </div>
                </form>
                )}
          </div>
        </div>
      </div>
      <Preloader open={isLoading} modalText={'Loading...'}/>
    </>
  )
}

export default Login
