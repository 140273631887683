import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { TextInput } from 'src/components/atoms/text-input'
import { addOrganization } from 'src/data/organizations/mutations'
import { Alert } from 'src/components/atoms/alert'

interface FormAddOrg {
  name: string
}

const AddOrganization: React.FC<{ onClose: Function, isOpen: boolean }> = (props) => {
  const { onClose, isOpen } = props
  const [errMsg, setErrMsg] = useState('')
  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
  })
  const {
    setValue,
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormAddOrg>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: ''
    })
  }, [reset])

  const onSubmit = async (data: FormAddOrg) => {
    console.log('data', data)
    try {
      const org = await addOrganization(data.name)
      console.log('org', org)
      handleClose()
    } catch (err) {
      console.log(err)
      setErrMsg('Error')
    }
  }

  const handleClose = () => {
    onClose()
    setValue('name', '')
  }
  return (<Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-30" onClose={() => {}}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-[#020409] bg-opacity-80"/>
      </Transition.Child>

      <div className="fixed  inset-0 overflow-y-auto">
        <div className="flex  min-h-full items-center justify-center text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className="min-w-[597px] transform overflow-hidden rounded-lg  bg-[#171B21]  text-left align-middle  transition-all">
              <div className="text-2xl mx-6 my-6 font-bold text-white flex items-center justify-between gap-6">
                <span>Add Organization ?</span>
                <CloseIcon className={'pointer'} onClick={handleClose}/>
              </div>
              {errMsg && <Alert variant={'error'} isCloseIcon={true} onClose={() => {
                setErrMsg('')
              }}>  {errMsg}
              </Alert>}
              <form>
                <div
                  className={'border-t-[1px] border-b-[1px] py-4 px-6 flex flex-col gap-4  border-[#020409] text-sm text-[#7E858F]'}>
                  <Controller
                    name={'name'}
                    rules={{ required: true }}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                      {...register('name', { maxLength: 25 })}
                      labelText={'Organization name:'}
                      error={errors?.name?.message}
                      maxLength={25}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}/>)}
                  />

                </div>
                <div className={'px-6 py-4 flex justify-between items-center'}>
                  <Button onClick={handleClose} classname={'bg-[#020409]'} title={'Cancel'} variant={'darker'}
                          type={'button'}/>
                  <Button title={'Save'} disabled={!isDirty || !isValid} variant={'primary'}
                          onClick={handleSubmit(onSubmit)} type={'submit'}/>
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>)
}
export default AddOrganization
