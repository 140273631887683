import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ApplicationContext from '../../../store/application-context-provider'
import { Button } from 'src/components/atoms/button'
// import { SelectInput, SelectInputOption } from 'src/components/atoms/select/SelectInput'
import { ReactComponent as AppSharpIcon } from 'src/assets/icons/apps-sharp.svg'
import { ReactComponent as ReorderFour } from 'src/assets/icons/reorder-four.svg'
import { SearchInput } from 'src/components/atoms/search-input'
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg'

const w = window as any

export const ServiceBuilderToolbar: React.FC<{ active: boolean, scanByName: string, scanByNameChange: (name: string) => void, handleActive: (value: boolean) => void }> = props => {
  const { active, handleActive, scanByName, scanByNameChange } = props
  const ctx = useContext(ApplicationContext)
  // const [currentTeam, setCurrentTeam] = useState('')
  // const [serviceView, setServiceView] = useState('My Services')
  // const [rows, setRows] = useState<Team[]>([])
  // const [isActive, setIsActive] = React.useState(true)
  // const [record, setRecord] = React.useState<SelectInputOption | null>(null)
  const [searchTerm, setSearchTerm] = useState(scanByName)

  const navigate = useNavigate()
  const buildService = () => {
    ctx?.clearContext()
    ctx?.setIsServiceEdit(false)
    navigate('/service-builder/wizard')
  }

  // async function getTeams (id: string) {
  //   try {
  //     const data = await fetchTeams(id)
  //     setRows(data as Team[])
  //     const team = localStorage.getItem('selectedTeam')
  //     if (team) {
  //       setCurrentTeam(team)
  //     }
  //     ;
  //     if (!team && (data && data.length > 0)) {
  //       setCurrentTeam(data[0].id)
  //     }
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  useEffect(() => {
    // const view = localStorage.getItem('serviceView')
    // if (view) {
    //   setServiceView(view)
    // }
    const org = localStorage.getItem('selectedOrganization')
    if (!org) {
      navigate('/select-organization')
      return
    }
    const organizationId = JSON.parse(org).id
    if (!organizationId) {
      console.log('No organizationId')
    }
    // getTeams(organizationId)
  }, [])

  useEffect(() => {
    const delayDebounceFn = w.setTimeout(() => {
      scanByNameChange(searchTerm)
    }, 1000)

    return () => { clearTimeout(delayDebounceFn) }
  }, [searchTerm])

  // const changeTeam = (event: SelectChangeEvent) => {
  //   setCurrentTeam(event.target.value)
  //   localStorage.setItem('selectedTeam', event.target.value)
  //   const team = rows.find(t => t.id === event.target.value)
  //   ctx?.setSelectedTeam((team != null) as unknown as Team || null)
  // }
  //
  // const changeServiceView = (event: SelectChangeEvent) => {
  //   setServiceView(event.target.value)
  //   localStorage.setItem('serviceView', event.target.value)
  //   ctx?.setSelectedView(event.target.value)
  // }
  // const handleRecordsChange = (record: SelectInputOption) => {
  //   setRecord(record)
  //   handleLimitChange(Number(record.value))
  // }
  const handleSearchByName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className={'lg:flex lg:flex-row lg:items-center  lg:justify-between md:flex md:flex-col md:gap-6 md:items-center sm:flex sm:flex-col  sm:items-center '}>
   <div className={'flex items-center gap-10'}>
     <span className={'text-3xl font-bold text-white'}>Services</span>
     <Button type="button" onClick={buildService} variant={'primary'} title={'Create New Service'}/>
   </div>
      <div className={'flex gap-6  md:mt-0 mt-2'}>
        <div className={'flex items-center text-white  gap-[13px] border-[#7E858F] border-r-[1px]  '}>
          <ReorderFour fill={active ? '#7E858F' : '#826AED'} onClick={() => { handleActive(!active) }} className={'pointer'}/>
         <AppSharpIcon fill={active ? '#826AED' : '#7E858F' } onClick={() => { handleActive(!active) }} className={'pointer mr-7'}/>
        </div>
        {/* <SelectInput
            title={'show:'}
           placeholder={'10 records'}
          defaultValue={'10'}
          selected={record as SelectInputOption }
            options={[
              { name: '5 records', value: '5' },
              { name: '10 records', value: '10' }
            ]
            }
            handleValueChange={(e) => {
              handleRecordsChange(e)
            }}/> */}
        <SearchInput placeholder={'Search:'} value={searchTerm} onChange={handleSearchByName} iconRight={<SearchIcon/>} />
      </div>
    </div>
  )
}
export default ServiceBuilderToolbar
