import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Button, Card, FormControl, Grid, Input, InputLabel } from '@mui/material'
import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AddUser: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const navigate = useNavigate()

  const inviteUser = async (event: Event | any) => {
    event.preventDefault()
    setLoading(true)
    const org = localStorage.getItem('selectedOrganization')
    if (!org) {
      setErrMsg('No organization selected')
      console.log('no organization selected')
      return
    }
    const organizationId = JSON.parse(org).id
    const elements = event.currentTarget.elements
    try {
      const authData = await Auth.currentSession()
      const res = await fetch(`${process.env.REACT_APP_API}/user`, {
        method: 'POST',
        headers: {
          Authorization: authData.getIdToken().getJwtToken(), 'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: elements.email.value, organizationId
        })
      })
      const response = await res.json()
      setLoading(false)
      if (response.message === 'Organization has reached its maximum user limit') {
        setErrMsg(response.message)
      } else {
        navigate('/settings/billing')
      }
    } catch (err) {
      setLoading(false)
      setErrMsg((err as Error).message)
      console.log(err)
    }
  }

  const gotToSettings = () => {
    navigate('/settings')
  }

  return (
    <>
      <div style={{ paddingTop: '1.2em' }}></div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          {errMsg && (
            <Alert
              severity="error"
              onClose={() => {
                setErrMsg('')
              }}
            >
              <AlertTitle>Error</AlertTitle>
              {errMsg}
            </Alert>
          )}

          <h5>
            <Button variant="text" onClick={gotToSettings}>
              Settings
            </Button>
            &#62; Invite User
          </h5>

          <Card variant="outlined">
            <div style={{ padding: '1em' }}>
              <form onSubmit={inviteUser}>
                <div style={{ margin: '1em 0' }}></div>
                <FormControl fullWidth={true} variant="outlined">
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input id="email" name="email" aria-describedby="email" />
                </FormControl>
                <div style={{ margin: '1em 0' }}></div>
                <FormControl fullWidth={true}>
                  <LoadingButton loading={loading} type="submit" color="primary" variant="contained">
                    <span style={{ textTransform: 'none' }}>Send Invite</span>
                  </LoadingButton>
                </FormControl>
              </form>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default AddUser
