import { FIELD_TYPE } from 'src/enums'

export const scalarTypesList = [
  { type: FIELD_TYPE.ID },
  {
    type: FIELD_TYPE.STRING
  },
  {
    type: FIELD_TYPE.INT
  },
  {
    type: FIELD_TYPE.FLOAT
  },
  {
    type: FIELD_TYPE.BOOLEAN
  },
  {
    type: FIELD_TYPE.AWS_DATE
  },
  {
    type: FIELD_TYPE.AWS_TIME
  },
  {
    type: FIELD_TYPE.AWS_DATE_TIME
  },
  {
    type: FIELD_TYPE.AWS_TIME_STAMP
  }, {
    type: FIELD_TYPE.AWS_EMAIL
  },
  {
    type: FIELD_TYPE.AWS_JSON
  },
  {
    type: FIELD_TYPE.AWS_Phone
  },
  {
    type: FIELD_TYPE.AWS_URL
  },
  {
    type: FIELD_TYPE.AWS_IP_ADDRESS
  }
]

export const schemaFileResponse = {
  queries: [
    'getAuthor',
    'getAuthors',
    'getPosts',
    'getAllPosts',
    'getPost'
  ],
  mutations: [
    'createAuthor',
    'createPost',
    'createComment',
    'updatePost',
    'deletePost'
  ],
  possibleModels: [
    {
      id: 'bea4eb94-1aae-4a24-97a8-99e26c43e3fc',
      name: 'Post',
      description: '',
      dataSource: null,
      fields: [
        {
          name: 'id',
          fieldType: '',
          type: 'ID',
          array: false,
          required: true
        },
        {
          name: 'title',
          fieldType: '',
          type: 'String',
          array: false,
          required: true
        },
        {
          name: 'content',
          fieldType: '',
          type: 'String',
          array: false,
          required: true
        },
        {
          name: 'authorName',
          fieldType: '',
          type: 'String',
          array: false,
          required: true
        },
        {
          name: 'author',
          fieldType: '',
          type: 'Author',
          array: false,
          required: true
        },
        {
          name: 'comments',
          fieldType: '',
          type: 'Comment',
          array: true,
          required: true
        },
        {
          name: 'publishDate',
          fieldType: '',
          type: 'AWSDate',
          array: false,
          required: true
        },
        {
          name: 'createdAt',
          fieldType: '',
          type: 'AWSDateTime',
          array: false,
          required: true
        },
        {
          name: 'updatedAt',
          fieldType: '',
          type: 'AWSDateTime',
          array: false,
          required: true
        }
      ]
    },
    {
      id: 'f55e2501-ac7e-4c19-86d7-c8efccbc0eca',
      name: 'Author',
      description: '',
      dataSource: null,
      fields: [
        {
          name: 'name',
          fieldType: '',
          type: 'ID',
          array: false,
          required: true
        },
        {
          name: 'email',
          fieldType: '',
          type: 'AWSEmail',
          array: false,
          required: true
        },
        {
          name: 'posts',
          fieldType: '',
          type: 'Post',
          array: true,
          required: true
        },
        {
          name: 'createdAt',
          fieldType: '',
          type: 'AWSDateTime',
          array: false,
          required: true
        },
        {
          name: 'updatedAt',
          fieldType: '',
          type: 'AWSDateTime',
          array: false,
          required: true
        }
      ]
    },
    {
      id: 'bef77ff1-127e-487a-aa66-4388a1930bb3',
      name: 'Comment',
      description: '',
      dataSource: null,
      fields: [
        {
          name: 'id',
          fieldType: '',
          type: 'ID',
          array: false,
          required: true
        },
        {
          name: 'message',
          fieldType: '',
          type: 'String',
          array: false,
          required: true
        },
        {
          name: 'createdAt',
          fieldType: '',
          type: 'AWSDateTime',
          array: false,
          required: true
        },
        {
          name: 'updatedAt',
          fieldType: '',
          type: 'AWSDateTime',
          array: false,
          required: true
        }
      ]
    }
  ]
}
