import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import { ReactComponent as AtCircleSharp } from 'src/assets/icons/at-circle-sharp.svg'
import { ReactComponent as Business } from 'src/assets/icons/business.svg'
import AccountSettings from 'src/components/organisms/account-settings/AccountSettings'
import OrganizationSettings from 'src/components/organisms/organization-settings/OrganizationSettings'
const w = window as any
const Settings: React.FC = (props) => {
  const [activeItem, setActiveItem] = React.useState(true)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/settings/profile') {
      setActiveItem(true)
    } else if (location.pathname === '/settings/organization') {
      setActiveItem(false)
    }
  }, [location.pathname])

  return (
    <div className={'flex h-full'} >
        <div className={'max-w-max border-[#171B21]  border-r-2 '}>
          <div className={'text-3xl pt-[39px] pl-[22px] font-bold  text-white'}>Settings</div>
          <div className={'pt-6 px-5'}>
          <div
            onClick={() => {
              w.location.href = '/settings/profile'
              setActiveItem(true)
            }}
            className={`${activeItem ? 'bg-[#171B21]' : ''} whitespace-nowrap py-3 text-xs rounded-lg pl-[30px] pr-[98px]  text-[#7E858F] flex items-center pointer gap-2.5`}>
            <AtCircleSharp fill={activeItem ? '#826AED' : '#7E858F'}/>
            <span className="block ">Account Settings</span></div>
          <div
            onClick={() => {
              w.location.href = '/settings/organization'
              setActiveItem(false)
            }}
            className={`${!activeItem ? 'bg-[#171B21]' : ''} whitespace-nowrap rounded-lg  pl-[30px] pr-[98px] text-xs py-3 text-[#7E858F] flex items-center pointer   gap-2.5`}>
            <Business fill={!activeItem ? '#826AED' : '#7E858F'}/>
            <span className="block">Organization</span></div>
          </div>
        </div>
      {activeItem ? <AccountSettings/> : <OrganizationSettings/>}
      </div>

  )
}

export default Settings
