import { Alert, AlertTitle, Button, Card, CardContent, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import { OrganizationUser } from '../../../../API'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { Delete, Person } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { removeOrganizationUser } from '../../../../data/organizations/mutations'

interface ISubscription {
  orgUsers: OrganizationUser[]
  quantity: number
  price: number
}

const Billing: React.FC = () => {
  const [subscription, setSubscription] = useState<ISubscription>()
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const navigate = useNavigate()

  async function getSubscription () {
    try {
      setLoading(true)
      const org = localStorage.getItem('selectedOrganization')
      if (!org) return
      const orgId = JSON.parse(org).id

      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/stripe/subscription/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
        },
        body: JSON.stringify({
          organizationId: orgId
        })
      })
      const data = await result.json()
      setSubscription(data)
      setLoading(false)
    } catch (err) {
      setErrMsg((err as Error).message)
      console.error(err)
    }
  }

  useEffect(() => {
    getSubscription()
  }, [])

  const goToAddUser = () => {
    navigate('/settings/add-user')
  }

  const manageSubscription = async () => {
    try {
      setLoading(true)
      const authData = await Auth.currentSession()
      const result = await fetch(`${process.env.REACT_APP_API}/stripe/session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', Authorization: authData.getIdToken().getJwtToken()
        },
        body: JSON.stringify({})
      })
      const data = await result.json()
      window.location.href = data.url
    } catch (err) {
      setLoading(false)
      setErrMsg((err as Error).message)
      console.log(err)
    }
    ;
  }

  const goToSettings = () => {
    navigate('/settings')
  }

  const handleDeleteUser = async (id: string) => {
    try {
      const org = localStorage.getItem('selectedOrganization')
      if (!org) return
      const orgId = JSON.parse(org).id
      await removeOrganizationUser(orgId, id)
      console.log('user deleted')
    } catch (err) {
      setErrMsg((err as Error).message)
      console.log(err)
    }
    ;
  }

  return (
    <>
      <div style={{ paddingTop: '2em' }}></div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          {errMsg && (
            <Alert
              severity="error"
              onClose={() => {
                setErrMsg('')
              }}
            >
              <AlertTitle>Error</AlertTitle>
              {errMsg}
            </Alert>
          )}
          <h5>
            <Button variant="text" onClick={goToSettings}>
              Settings
            </Button>
            &#62;
            Users and Billing
          </h5>

          <Card variant="outlined">
            <CardContent>
              <div className="d-flex">
                <div className="w-[50%]">
                  <h4 className="title-mark">Users and Billing</h4>
                </div>
                <div className="w-[50%] text-end">
                  <h4>
                    <Button variant='contained' size="small" color='primary' onClick={goToAddUser}>Invite user</Button>
                  </h4>
                </div>
              </div>
              <hr />
              <h5>Users in the Organization:</h5>
              { loading
                ? <LoadingButton loading={loading} fullWidth variant="text" loadingPosition="center"></LoadingButton>
                : <List>
                {
                  ((subscription?.orgUsers) != null) && subscription.orgUsers.map((row, index) => (
                    <ListItem disablePadding key={row.id}>
                      <ListItemButton>
                        <ListItemIcon>
                          <Person />
                        </ListItemIcon>
                        <ListItemText primary={row.email} />
                      </ListItemButton>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" color="error" onClick={() => { handleDeleteUser(row.id) }}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                }
              </List>
              }

              <hr />

              <h5>Subscription information:</h5>

              { loading
                ? <LoadingButton loading={loading} fullWidth variant="text" loadingPosition="center"></LoadingButton>
                : <TableContainer>
                <Table aria-label="summary table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Used / Purchased</TableCell>
                      <TableCell align="right">Cost per User</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                       {subscription?.orgUsers?.length} / {subscription?.quantity}
                      </TableCell>
                      <TableCell align="right">$ {subscription?.price}</TableCell>
                      <TableCell align="right">$ {(subscription?.quantity ?? 0) * (subscription?.price ?? 0)}/mo</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              }
              <br/>
              <div className="text-end">
                <LoadingButton loading={loading} fullWidth variant='contained' color='info' onClick={manageSubscription}>Manage subscription</LoadingButton>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Billing
