import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Button, Card, CardContent, Grid, List } from '@mui/material'
import { FC, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Team as TeamType } from '../../../../../API'
import { fetchTeams } from '../../../../../data/organizations/queries'
import decode from 'jwt-decode'
import { removeTeam } from '../../../../../data/organizations/mutations'
import { useUser } from '../../../../../hooks'
import ApplicationContext from 'src/store/application-context-provider'
import Team from './Team/Team'

const Teams: FC = () => {
  const ctx = useContext(ApplicationContext)
  const [rows, setRows] = useState<TeamType[]>([])
  const [showEdit, setShowEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const { organizationId } = useParams()
  const navigate = useNavigate()
  const user = useUser()

  async function getTeams (id: string) {
    try {
      const data = await fetchTeams(id)
      setRows(data as TeamType[])
      let org: any = localStorage.getItem('selectedOrganization')
      org = org ? JSON.parse(org) : null
      if (user) {
        const decoded: { orgId: string } = decode(user?.signInUserSession.idToken.jwtToken)
        setShowEdit(decoded.orgId === org.id)
      }
    } catch (err) {
      setErrMsg((err as Error).message)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (!organizationId) {
      console.log('No organizationId')
      setLoading(false)
      return
    }
    getTeams(organizationId)
  }, [organizationId, user])

  const gotToSettings = () => {
    navigate('/settings')
  }

  const gotToOrganizations = () => {
    navigate('/settings/organizations')
  }

  const goAddTeam = () => {
    ctx?.setSelectedTeam(null)
    navigate(`/settings/organizations/${organizationId}/teams/upsert`)
  }

  const deleteTeam = async (team: TeamType, orgId: string) => {
    try {
      setLoading(true)
      await removeTeam(team.id, orgId)
      const teams = rows.filter(row => row.id !== team.id)
      setRows(teams)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (<>
    <div style={{ paddingTop: '2em' }}></div>
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={6}>
        {errMsg && <Alert severity="error" onClose={() => {
          setErrMsg('')
        }}>
          <AlertTitle>Error</AlertTitle>
          {errMsg}
        </Alert>}

          <h5>
            <Button variant="text" onClick={gotToSettings}>Settings</Button> &#62; <Button variant="text" onClick={gotToOrganizations}>Organizations</Button> &#62; Teams
          </h5>
          <Card variant="outlined">
            <CardContent>
              <div className="d-flex">
                <div className="w-[50%]">
                  <h4 className="title-mark">Teams</h4>
                </div>
                <div className="w-[50%] text-end">
                  {
                    showEdit && <Button size="small" variant="contained" onClick={goAddTeam}> <Add /> </Button>
                  }
                </div>
              </div>
              <hr />
              {loading
                ? <LoadingButton loading={loading} variant='text' fullWidth />
                : <nav aria-label="organizations list">
                <List>
                {
                  rows.map((row) => (
                    <Team key={row.id} team={row} showEdit={showEdit} onRemoveTeam={deleteTeam}/>
                  ))
                }
                </List>
              </nav>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Teams
