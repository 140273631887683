import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ResourceFormControls from 'src/components/organisms/service-builder/forms/resources/ResourceFormControls'
import ApplicationContext from 'src/store/application-context-provider'
import { DYNAMO_KEY_TYPE, FIELD_TYPE, FIELDTYPE_TYPE, RESOLVER_TYPE, RESOURCE_TYPE } from 'src/enums'
import { v4 as uuidv4 } from 'uuid'
import { isValidResolvers } from 'src/utils/validation'
import { uniqueItemsById, uniqueItemsByName } from 'src/utils/serviceBuilder'
import CustomTypeModal from 'src/components/organisms/service-builder/wizard/appsync/DefineModels/CustomTypeModal'
import { setFieldTypeToField } from 'src/utils/modelForm'
import { scalarTypesList } from 'src/constants/modelForm'
import { OptionBluePrint } from 'src/API'
import QueryFromModal from './QueryFromModal'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as AddIcon } from 'src/assets/icons/add-circle-sharp-gray.svg'
import { ModelFieldsRow } from 'src/components/molecules/model-fields-row/ModelFieldsRow'
import { ResolverRow } from 'src/components/molecules/resolver-row/ResolverRow'
import { Alert } from 'src/components/atoms/alert'
import { Gsi } from 'src/components/organisms/service-builder/forms/resources/ResourceDynamoForm'

export interface RowField {
  id: string
  name: string
  type: string
  fieldType: string
  required: boolean
  array: boolean
  isDisabled: boolean
}

export interface RowResolver {
  id: string
  name: string
  type: string
  isPipeline: boolean
  description: string
  model?: string
  isDisabled: boolean
  // The datasource of the model, or of the resolved model
  dataSource?: string
  modelFieldId?: string
  queryFrom?: string
}

interface FormModel {
  modelName: string
  dataSource: any
  description: string
}

const ModelForm: React.FC<{ close: Function, submit: Function }> = props => {
  const { close, submit } = props

  const ctx = useContext(ApplicationContext)
  const [isModelAlert, setIsModelAlert] = useState(false)
  const [isFieldAlert, setIsFieldAlert] = useState(false)
  const modelNameList = useMemo(() => ctx?.serviceBuilder?.models?.map((s: any) => s.name), [ctx?.serviceBuilder?.models])

  const listDynamos = useMemo(() => {
    const selectedDataSourceId = ctx?.serviceBuilder.models.map((m: any) => m.dataSource?.id)
    const unselectedListDynamo = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).filter((s: any) => !selectedDataSourceId.includes(s.id))
    if (ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN) {
      return ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)
    }
    if (ctx?.modelInput?.dataSource?.id) {
      const editingDataSource = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).filter((s: any) => s.id === ctx?.modelInput?.dataSource?.id)
      return [...editingDataSource, ...unselectedListDynamo].flat()
    } else {
      return unselectedListDynamo
    }
  }, [ctx?.serviceBuilder])

  const [customTypes, setCustomTypes] = useState<any[]>([])
  const [fields, setFields] = useState<RowField []>([])
  const [resolvers, setResolvers] = useState<RowResolver[]>([])
  const [isResolverAlert, setIsResolverAlert] = useState('')
  const [isHasIndexAlert, setIsHasIndexAlert] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [[isQueryFromModalOpen, currentResolver], setQueryFromModalOpen] = useState([false, ''])
  const [[isResolveWithPKSKModalOpen, currResolverWithPKSK, currentFieldId], setResolveWithPKSKModalOpen] = useState([false, '', ''])
  const [[isResolveFromModalOpen, currResolverFrom, currFieldId], setResolveFromModalOpen] = useState([false, '', ''])

  const schema = yup.object({
    modelName: yup
      .string()
      .required('This field is required')
      .max(25),
    dataSource: yup
      .string()
      .required('This field is required')
  })

  const {
    control,
    handleSubmit,
    register,
    trigger,
    getValues,
    formState: {
      errors,
      isValid
    },
    reset

  } = useForm<FormModel>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      modelName: '',
      dataSource: '',
      description: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      modelName: ctx?.modelInput?.name,
      dataSource: ctx?.modelInput?.dataSource?.id,
      description: ctx?.modelInput?.description
    })
  }, [reset])

  useEffect(() => {
    const newFields = (ctx?.modelInput?.fields?.map((f: any) => ({ ...f, id: f.id ?? uuidv4() })) ?? [{
      id: uuidv4(),
      name: '',
      type: '',
      required: false,
      array: false,
      isDisabled: false
    }])

    setFields(newFields)
    const resolverListModel = ctx?.serviceBuilder?.resolvers?.filter((r: any) => r.model === ctx?.modelInput?.name).map((f: any) => ({
      ...f,
      isDisabled: (f.isDisabled || f.resolveWith_PK) ??
        false
    }))
    setResolvers(resolverListModel?.length > 0
      ? resolverListModel
      : [{
          id: uuidv4(),
          name: '',
          type: '',
          description: '',
          isPipeline: false,
          isDisabled: false,
          model: ''
        }])
  }, [listDynamos])

  const isActiveResolveFrom = useMemo(() => {
    const currentDataSource = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).find((f: any) => f.id === getValues().dataSource)
    if (ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN || (ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE && currentDataSource?.GSI?.length > 1)) {
      return true
    } else {
      return false
    }
  }, [getValues().dataSource])

  useEffect(() => {
    if (modelNameList?.includes(getValues().modelName) && ctx?.modelInput?.name !== getValues().modelName) {
      setIsModelAlert(true)
    } else {
      setIsModelAlert(false)
    }
  }, [getValues().modelName, ctx?.serviceBuilder?.models])

  useEffect(() => {
    // Check for unique names and types and hasIndex
    const namesMap: Record<string, boolean> = {}
    let isUniqueNames = true
    let isUniqueTypes = true
    let hasEmptyFields = false

    for (const obj of resolvers) {
      if (!obj.type) {
        hasEmptyFields = true
      }
    }

    for (const obj of resolvers) {
      if (namesMap[obj.name] && !obj.name.includes('.')) {
        isUniqueNames = false
        break
      }
      namesMap[obj.name] = true
    }

    const typesSet = new Set<string>()
    for (const obj of resolvers) {
      if (obj.type && !typesSet.has(obj.type)) {
        typesSet.add(obj.type)
      } else if (obj.type && !obj.name.includes('.')) {
        isUniqueTypes = false
        break
      }
    }
    const currentDataSource = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).find((f: any) => f.id === getValues().dataSource)
    const hasQueryType = resolvers.some(obj => obj.type === RESOLVER_TYPE.QUERY)
    setIsHasIndexAlert(hasQueryType && !currentDataSource.hasIndex ? 'The data source must have an index in order to create a "query" resolver' : '')

    if (isUniqueTypes && isUniqueNames) {
      setIsResolverAlert('')
    }
    if (!isUniqueTypes) {
      setIsResolverAlert('You can’t create two resolvers of the same type.')
    }
    if (!isUniqueNames) {
      setIsResolverAlert('This resolver name is already in use, please choose a unique name.')
    }
    if (hasEmptyFields) {
      setIsResolverAlert('Select Resolver type.')
    }
  }, [resolvers])

  const fieldNameHandler = (e: Event | any, row: RowField) => {
    const fieldNameList = fields?.map((s: any) => s.name)
    if (fieldNameList?.includes(e.target.value)) {
      setIsFieldAlert(true)
      const editData = fields.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setFields(editData)
    } else {
      const editData = fields.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setFields(editData)
      setIsFieldAlert(false)

      const currentField = editData.find(f => f.id === row.id)
      if (currentField?.fieldType === FIELDTYPE_TYPE.MODEL) {
        const changedResolvers = resolvers.map((r) => r.modelFieldId === row.id
          ? ({
              ...r,
              name: `${getValues().modelName}.${e.target.value}`
            })
          : r)
        setResolvers(changedResolvers)
      }
    }
  }

  const fieldTypeHandler = (type: any, row: RowField) => {
    const currentDataSource = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).find((f: any) => f.id === getValues().dataSource)
    const editData = fields.map((f) =>
      f.id === row.id ? { ...f, type, fieldType: setFieldTypeToField(type, scalarTypesList, ctx?.serviceBuilder?.models) } : f
    )
    const currentField = editData.find(f => f.id === row.id)
    if (modelNameList.includes(type)) {
      const fieldResolver = {
        id: uuidv4(),
        name: `${getValues().modelName}.${currentField?.name}`,
        model: `${getValues().modelName}`,
        type: RESOLVER_TYPE.FIELD,
        description: `get all ${type} by an ${getValues().modelName}`,
        dataSource: currentDataSource?.name,
        isPipeline: false,
        isDisabled: true,
        modelFieldId: row.id
      }
      setResolvers(uniqueItemsByName([...resolvers, fieldResolver]))

      if (ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN || ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE) {
        setResolveWithPKSKModalOpen([true, fieldResolver.id, currentField?.id as string])
      }
    }
    setFields(editData)
  }

  const fieldRequiredHandler = (e: Event | any, row: RowField) => {
    const editData = fields.map((f) =>
      f.id === row.id ? { ...f, required: !row.required } : f
    )
    setFields(editData)
  }

  const fieldArrayHandler = (e: Event | any, row: RowField) => {
    if (isActiveResolveFrom && row.fieldType === FIELDTYPE_TYPE.MODEL && e.target.checked) {
      const currentResolverId = resolvers?.find((r) => r.modelFieldId === row.id)?.id
      setResolveFromModalOpen([true, currentResolverId as string, row.id])
    }

    const editData = fields.map((f) =>
      f.id === row.id ? { ...f, array: e.target.checked } : f
    )
    setFields(editData)
  }

  const resolverTypeHandler = (type: any, row: RowResolver) => {
    const currentDataSource = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).find((f: any) => f.id === getValues().dataSource)
    // const resolverTypeList = resolvers?.map((s: any) => s.type)
    // The datasource needs to have an index to create a resolver with type "query"
    const editData = resolvers.map((f) =>
      f.id === row.id ? { ...f, dataSource: currentDataSource?.name, type } : f
    )

    if (type === RESOLVER_TYPE.QUERY && currentDataSource?.hasIndex) {
      setQueryFromModalOpen([true, row.id])
    }

    setResolvers(editData)
  }

  const onQueryFromModalSubmit = (value: string) => {
    const editData = resolvers.map((f) =>
      f.id === currentResolver ? { ...f, queryFrom: value.toLowerCase() } : f
    )
    setResolvers(editData)
  }

  const onResolveWithPKSKModalSubmit = (valuePK: string, valueSK: string | undefined) => {
    if (valuePK && valueSK) {
      const editData = resolvers.map((f) =>
        f.id === currResolverWithPKSK ? { ...f, resolveWith_PK: valuePK, resolveWith_SK: valueSK } : f
      )
      setResolvers(editData)
    } else if (valuePK && !valueSK) {
      const editData = resolvers.map((f) =>
        f.id === currResolverWithPKSK ? { ...f, resolveWith_PK: valuePK } : f
      )
      setResolvers(editData)
    }
  }
  const onResolveFromModalSubmit = (value: string) => {
    const editData = resolvers.map((f) =>
      f.id === currResolverFrom
        ? {
            id: f.id,
            name: f.name,
            model: f.model,
            type: f.type,
            description: f.description,
            dataSource: f.dataSource,
            isPipeline: f.isPipeline,
            isDisabled: f.isDisabled,
            modelFieldId: f.modelFieldId,
            resolveFrom: value
          }
        : f
    )
    setResolvers(editData)
  }
  const resolverNameHandler = (e: Event | any, row: RowResolver) => {
    const resolverTypeList = resolvers?.map((s: any) => s.type)
    const resolverNameList = resolvers?.map((s: any) => s.name)
    if (resolverNameList?.includes(e.target.value) || (row.type && resolverTypeList?.includes(row.type) && !e.target.value.includes('.'))) {
      const editData = resolvers.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setResolvers(editData)
    } else {
      const editData = resolvers.map((f) =>
        f.id === row.id ? { ...f, name: e.target.value } : f
      )
      setResolvers(editData)
    }
  }
  const resolverPipelineHandler = (e: Event | any, row: RowResolver) => {
    const editData = resolvers.map((f) =>
      f.id === row.id ? { ...f, isPipeline: !row.isPipeline } : f
    )
    setResolvers(editData)
  }
  const resolverDescriptionHandler = (e: Event | any, row: RowResolver) => {
    const editData = resolvers.map((f) =>
      f.id === row.id ? { ...f, description: e.target.value } : f
    )
    setResolvers(editData)
  }

  const dataSourceHandler = (dataSourceId: any) => {
    if (ctx?.serviceBuilder.optionBluePrint !== OptionBluePrint.BYOSCHEMA) {
      const defaultFields = listDynamos?.filter((l: any) => l.id === dataSourceId)?.map((m: any) =>
        m.secondaryKeyName
          ? [{
              id: uuidv4(),
              name: m.primaryKeyName,
              type: m.primaryKeyType === 'S' ? FIELD_TYPE.ID : m.primaryKeyType,
              required: false,
              array: false,
              isDisabled: true
            }, {
              id: uuidv4(),
              name: m.secondaryKeyName,
              type: m.secondaryKeyType === 'S' ? FIELD_TYPE.STRING : m.secondaryKeyType,
              required: false,
              array: false,
              isDisabled: false
            }]
          : {
              id: uuidv4(),
              name: m.primaryKeyName,
              type: m.primaryKeyType === 'S' ? FIELD_TYPE.ID : m.primaryKeyType,
              required: false,
              array: false,
              isDisabled: true
            }
      ).flat()
      console.log(defaultFields, listDynamos)
      setFields(defaultFields)
    }

    const currentDataSource = ctx?.serviceBuilder.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).find((f: any) => f.id === getValues().dataSource)
    setResolvers(resolvers?.map((res) => ({ ...res, dataSource: currentDataSource.name })))
  }
  const onSubmit = (data: FormModel) => {
    const serviceBuilder = ctx?.serviceBuilder
    if (ctx?.modelInput?.name !== data.modelName) {
      serviceBuilder.models = serviceBuilder.models.map((m: any) => ({ ...m, fields: m.fields.map((f: any) => f.fieldType === FIELDTYPE_TYPE.MODEL && f.type === ctx?.modelInput?.name ? ({ ...f, type: data.modelName }) : f) })
      )
    }
    const currentDataSource = ctx?.serviceBuilder.resources.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB).find((f: any) => f.id === data?.dataSource)
    const input = ctx?.modelInput
    input.name = data.modelName
    input.type = '/assets/images/dynamoDB_Table_light-bg.svg'
    input.description = data.description ?? null
    input.dataSource = {
      id: data.dataSource,
      name: currentDataSource.name,
      type: 'Dynamo'
    }
    input.fields = fields

    const customFieldTypeList = fields?.filter((f) => f.fieldType === FIELDTYPE_TYPE.CUSTOM).map((f) => f.type)
    serviceBuilder.customFields = uniqueItemsById([customTypes?.filter((f) => customFieldTypeList.includes(f.name)), ...ctx?.serviceBuilder.customFields ?? []].flat())
    serviceBuilder.resolvers = uniqueItemsById([resolvers.map((r) => {
      return {
        ...r,
        model: getValues().modelName,
        dataSource: r.dataSource ?? currentDataSource.name
      }
    }), ...ctx?.serviceBuilder.resolvers].flat())

    ctx?.setServiceBuilder(serviceBuilder)
    console.log('input model', input)
    ctx?.setModelInput(input)
    submit()
  }

  const handleClose = (event: Event | any) => {
    close()
  }

  const confirmFieldDelete = (field: any) => {
    const fieldsCustomList = fields.filter((f: any) => f.fieldType === FIELDTYPE_TYPE.CUSTOM && f.type === field.type)
    const serviceBuilder = ctx?.serviceBuilder
    if (fieldsCustomList.length === 1) {
      serviceBuilder.customFields = serviceBuilder.customFields?.filter((c: any) => c.name !== field.type)
      setCustomTypes(customTypes.filter((c: any) => c.name !== field.type))
      ctx?.setCustomTypesList(customTypes.filter((c: any) => c.name !== field.type))
      ctx?.setServiceBuilder(serviceBuilder)
    }
    if (field.fieldType === FIELDTYPE_TYPE.MODEL) {
      const filteredResolvers = resolvers.filter((r) => r.name !== `${getValues().modelName}.${field.name}`)
      serviceBuilder.resolvers = ctx?.serviceBuilder.resolvers.filter((r: any) => r.name !== `${getValues().modelName}.${field.name}`)
      ctx?.setServiceBuilder(serviceBuilder)
      setResolvers(filteredResolvers)
    }
    const filtered = fields.filter(f => f.id !== field.id)
    setFields(filtered)
  }

  const confirmResolverDelete = (resolver: any) => {
    const serviceBuilder = ctx?.serviceBuilder
    const filtered = resolvers.filter(f => f.id !== resolver.id)
    serviceBuilder.resolvers = ctx?.serviceBuilder.resolvers.filter((f: any) => f.id !== resolver.id)
    ctx?.setServiceBuilder(serviceBuilder)
    setResolvers(filtered)
  }

  const removeCustomType = (type: any, e: Event | any) => {
    e.stopPropagation()
    const updatedFields = fields.map((f) => f.type === type.name
      ? {
          ...f, type: ''
        }
      : f)
    setFields(updatedFields)
    const service = ctx?.serviceBuilder
    service.customFields = service.customFields?.filter((f: any) => f.id !== type.id)
    const filtered = customTypes?.filter((c) => c.id !== type.id)
    setCustomTypes(filtered)
    ctx?.setCustomTypesList(filtered)
    ctx?.setServiceBuilder(service)
  }

  const editCustomType = (type: any, e: Event | any) => {
    e.stopPropagation()
    ctx?.setCustomTypesInput(type)
    setIsOpenModal(true)
  }
  const handleOpenModal = () => {
    setIsOpenModal(true)
  }
  useEffect(() => {
    const customFieldNames = ctx?.modelInput?.fields?.filter((f: any) => f.fieldType === FIELDTYPE_TYPE.CUSTOM).map((f: any) => f.type)
    setCustomTypes(ctx?.serviceBuilder?.customFields?.filter((f: any) => customFieldNames?.includes(f.name)) ?? [])
  }, [])

  const handleCustomFieldSubmit = (customType: any) => {
    if (!customType.id) {
      customType.id = uuidv4()
      setCustomTypes([...customTypes, customType].flat())
      ctx?.setCustomTypesList([...customTypes, customType].flat())
    } else {
      const updatesFields = fields.map((f) => f.type === ctx?.customTypesInput?.name
        ? {
            ...f, type: customType.name
          }
        : f)
      setFields(updatesFields)
      const updatedTypes = customTypes?.map((r: any) => {
        if (r.id === customType.id) {
          return customType
        }
        return r
      })
      setCustomTypes(updatedTypes)
      ctx?.setCustomTypesList(updatedTypes)
    }
  }
  const onCancelResolveWithPKSKModalHandle = () => {
    setFields(fields.map((f) =>
      f.id === currentFieldId ? { ...f, type: '', fieldType: '' } : f
    ))
    setResolvers(resolvers.filter((r) => r.id !== currResolverWithPKSK))
    setResolveWithPKSKModalOpen([false, '', ''])
  }
  const onCancelResolveFromModalHandle = () => {
    setFields(fields.map((f) =>
      f.id === currFieldId ? { ...f, type: '', fieldType: '', array: false } : f
    ))
    setResolvers(resolvers.filter((r) => r.id !== currResolverFrom))

    setResolveFromModalOpen([false, '', ''])
  }

  const onCancelQueryFrom = () => {
    setResolvers(resolvers.map((f) =>
      f.id === currentResolver ? { ...f, type: '' } : f
    ))
    setQueryFromModalOpen([false, ''])
  }
  return (
    <>
      <form>
        <div className={'flex px-8 flex-col gap-[16px] mt-[16px]'}>
        {isModelAlert &&
          <Alert variant={'error'}>This model
            name is already in use, please choose a unique name.</Alert>}
        {ctx?.modelInput?.name && !getValues().dataSource &&
          <Alert variant={'error'}>A model must
            have a data source</Alert>}
        {listDynamos.length === 0 && (ctx?.serviceBuilder?.optionBluePrint !== OptionBluePrint.SINGLE_TABLE_DESIGN) &&
          <Alert variant={'error'}>You can’t add
            the same data source to more than one model. Please create new data sources.</Alert>}

          <Controller
            name={'modelName'}
            rules={{ required: true }}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('modelName', { maxLength: 25 })}
              required
              labelText={'Model Name'}
              showRequired
              maxLength={25}
              error={errors?.modelName?.message}
              id="resourceName"
              onChange={(e) => {
                trigger('modelName')
                onChange(e)
              }}
              onBlur={onBlur}
              value={value.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '-')}/>)}
          />
          <Controller
            name={'description'}
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value
              }
            }) => (<TextInput
              {...register('description', { maxLength: 25 })}
              labelText={'Description'}
              maxLength={25}
              error={errors?.description?.message}
              id="resourceName"
              onChange={onChange}
              onBlur={onBlur}
              value={value}/>)}
          />
          <Controller
            name={'dataSource'}
            control={control}
            render={({
              field: {
                onChange,
                value
              }
            }) => (
              <SelectOutlinedInput
                showRequired={true}
                error={ctx?.modelInput?.name && !getValues().dataSource}
                selected={value}
                options={
                  listDynamos?.map((s: any) => ({ name: s.name, value: s.id })) ?? []
                }
                labelText={'Data Source:'}
                handleValueChange={(e) => {
                  trigger('dataSource')
                  onChange(e)
                  dataSourceHandler(e)
                }}/>
            )}
          />
          <div className={'flex justify-between mt-8 gap-40'}>
            <div className={'text-2xl text-white w-10/12'}>Model Fields</div>
            <Button iconLeft={<AddIcon/>} variant={'darker'} classname={'w-2/12'} title={'Add Field'} type={'button'} compact onClick={() => {
              setFields([...fields, {
                id: uuidv4(),
                name: '',
                type: '',
                fieldType: '',
                required: false,
                array: false,
                isDisabled: false
              }])
            }}/>
          </div>
          {isFieldAlert &&
            <Alert variant={'error'}>This field
              name is already in use, please choose a unique name.</Alert>}
          {fields?.map((row: RowField) => <ModelFieldsRow
            key={row.id}
            row={row}
            customTypes={customTypes}
            fieldNameHandler={fieldNameHandler}
            fieldTypeHandler={fieldTypeHandler}
            fieldRequiredHandler={fieldRequiredHandler}
            fieldArrayHandler={fieldArrayHandler}
            confirmFieldDelete={confirmFieldDelete}
            editCustomType={editCustomType}
            removeCustomType={removeCustomType}
            handleOpenModal={handleOpenModal}
          />)}
          <div className={'flex justify-between mt-8 gap-40'}>
            <div className={'text-2xl text-white w-10/12'}>Resolvers</div>
            <Button iconLeft={<AddIcon/>} variant={'darker'} classname={'w-2/12'} title={'Add Resolver'} type={'button'} compact
                    onClick={() => {
                      setResolvers([...resolvers, {
                        id: uuidv4(),
                        name: '',
                        type: '',
                        description: '',
                        isPipeline: false,
                        isDisabled: false
                      }])
                    }}/>
          </div>

          {isResolverAlert && <Alert variant={'error'}>{isResolverAlert}</Alert>}
          {isHasIndexAlert && <Alert variant={'error'}>{isHasIndexAlert}</Alert>}

          {resolvers?.map((row: RowResolver) => <ResolverRow
          key={row.id}
          row={row}
          resolverNameHandler={resolverNameHandler}
          resolverTypeHandler={resolverTypeHandler}
          resolverPipelineHandler={resolverPipelineHandler}
          resolverDescriptionHandler={resolverDescriptionHandler}
          confirmResolverDelete={confirmResolverDelete}
          />)}
        </div>
        <ResourceFormControls
          isDisabledSaveButton={!isValid || isFieldAlert || isModelAlert || !!isHasIndexAlert || !!isResolverAlert || !isValidResolvers(resolvers) || !isValidResolvers(fields)}
          onSubmit={handleSubmit(onSubmit)} onClose={handleClose}/>
      </form>
      <CustomTypeModal onClose={() => {
        setIsOpenModal(false)
      }} onSubmit={handleCustomFieldSubmit} open={isOpenModal}/>
      <QueryFromModal resolveVariant={'query from'}
                      open={isQueryFromModalOpen}
                      onClose={() => { setQueryFromModalOpen([false, '']) }}
                      onSubmit={onQueryFromModalSubmit}
                      onCancel={onCancelQueryFrom}
                      options={ctx?.serviceBuilder?.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)?.find((f: any) => f.id === getValues().dataSource)?.GSI?.map((g: Gsi) => g.gsiName)}
                      defaultValue={ctx?.serviceBuilder?.resources?.filter((r: any) => r.resourceType === RESOURCE_TYPE.DYNAMO_DB)?.find((f: any) => f.id === getValues().dataSource)?.GSI?.map((g: Gsi) => g.gsiName)[0]}/>
      {(ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.SINGLE_TABLE_DESIGN || ctx?.serviceBuilder?.optionBluePrint === OptionBluePrint.MULTI_DATA_SOURCE) &&
        <QueryFromModal resolveVariant={'resolve with PK,SK'}
                        isSecondSelect={listDynamos[0]?.keyType === DYNAMO_KEY_TYPE.COMPOSITE}
                        open={isResolveWithPKSKModalOpen} onCancel={onCancelResolveWithPKSKModalHandle} onSubmit={onResolveWithPKSKModalSubmit}
                        onClose={() => { setResolveWithPKSKModalOpen([false, '', '']) }}
                        options={fields?.filter((f: any) => !(f.fieldType === FIELDTYPE_TYPE.MODEL || f.fieldType === FIELDTYPE_TYPE.CUSTOM))?.map((f) => f.name)}
                        defaultValue={fields?.filter((f: any) => !(f.fieldType === FIELDTYPE_TYPE.MODEL || f.fieldType === FIELDTYPE_TYPE.CUSTOM))?.map((f) => f.name)[0]}/>}
      {isActiveResolveFrom &&
        <QueryFromModal resolveVariant={'resolve from'}
                        onCancel={onCancelResolveFromModalHandle}
                        open={isResolveFromModalOpen} onClose={() => {
                          setResolveFromModalOpen([false, '', ''])
                        }} onSubmit={onResolveFromModalSubmit}
                        options={[...listDynamos[0]?.GSI?.map((g: Gsi) => g.gsiName), listDynamos[0]?.name].flat() ?? []}
                        defaultValue={listDynamos[0]?.name}/>}
    </>
  )
}

export default ModelForm
