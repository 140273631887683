import React, { FC, ReactNode } from 'react'
import { ReactComponent as RadioIcon } from 'src/assets/icons/radio-input.svg'
import { ReactComponent as DisabledRadioIcon } from 'src/assets/icons/radio-input-disabled.svg'
import { classNames } from 'src/utils/css'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: any
  placeholder?: string
  showRequired?: boolean
  iconRight?: ReactNode
  iconLeft?: ReactNode
  error?: string
  ref?: React.ForwardedRef<HTMLInputElement>
  inputClassName?: string
  className?: string
  labelText?: string
  maxLength?: number
  isActiveDisabled?: boolean
  variant?: 'primary' | 'darker'
}

export const RadioInput: FC<InputProps> = React.forwardRef<
HTMLInputElement,
InputProps
>(
  (
    {
      placeholder = '',
      showRequired = false,
      type = 'text',
      iconRight,
      iconLeft,
      className = '',
      error = '',
      variant = 'primary',
      inputClassName = '',
      labelText = '',
      isActiveDisabled = false,
      ...props
    },
    ref
  ) => {
    const classnameByVariant = {
      primary: `${props.disabled ? 'text-[#7E858F]' : 'text-white'}`,
      darker: 'text-[#7E858F]'
    }
    return (
      <label className="flex items-center">
        <input disabled={props.disabled} ref={ref} type="checkbox" {...props} className="hidden" />
        <div className={`w-[16px] h-[16px] rounded-lg cursor-pointer  ${props.checked ? 'bg-[#826AED]' : 'bg-[#7E858F] transition-all ease-in-out duration-300 hover:border-[3px]  hover:border-[#2B2D31] hover:ring-[1px] hover:ring-[#826AED]  hover:ring-offset-0'}`}>
          {props.disabled ? isActiveDisabled && <DisabledRadioIcon/> : props.checked && <RadioIcon />}
        </div>
        <div className={classNames(`${classnameByVariant[variant]}`, 'ml-[4px] font-normal text-xs', inputClassName)}>{labelText}</div>
      </label>
    )
  }
)
