import { Alert, AlertTitle, Button, Card, CardContent, FormControl, FormHelperText, Grid, Input, InputLabel } from '@mui/material'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { LoadingButton } from '@mui/lab'
import { addTeam, updateTeam } from 'src/data/organizations/mutations'
import { Team } from 'src/API'
import ApplicationContext from 'src/store/application-context-provider'

interface FormUpsertTeam {
  name: string
}

const UpsertTeam: FC = () => {
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const navigate = useNavigate()
  const ctx = useContext(ApplicationContext)

  const { organizationId } = useParams()
  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
  })
  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isValid
    },
    reset,
    trigger
  } = useForm<FormUpsertTeam>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: ctx?.selectedTeam?.name ?? ''
    })
  }, [reset])

  const onSubmit = async (data: FormUpsertTeam) => {
    setLoading(true)
    if (!organizationId) {
      console.log('No organizationId')
      return
    }
    if (!ctx?.selectedTeam) {
      try {
        const team = await addTeam(organizationId, data.name)
        ctx?.setSelectedTeam(team as Team)
        navigate(`/settings/organizations/${organizationId}/teams`)
      } catch (err) {
        setLoading(false)
        setErrMsg((err as Error).message)
        console.log(err)
      }
    } else {
      try {
        const team = await updateTeam(ctx?.selectedTeam.id, data.name, organizationId)
        ctx.setSelectedTeam(team as Team)
        navigate(`/settings/organizations/${organizationId}/teams`)
      } catch (err) {
        setLoading(false)
        setErrMsg((err as Error).message)
        console.log(err)
      }
    }
  }

  const gotToSettings = () => {
    navigate('/settings')
  }

  const gotToOrganizations = () => {
    navigate('/settings/organizations')
  }

  const gotToTeams = () => {
    navigate(`/settings/organizations/${organizationId}/teams`)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Grid sx={{ paddingTop: '1.2em' }} container spacing={1}>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
          {errMsg && (
            <Alert
              severity="error"
              onClose={() => {
                setErrMsg('')
              }}
            >
              <AlertTitle>Error</AlertTitle>
              {errMsg}
            </Alert>
          )}

          <div>
            <h5>
              <Button variant="text" onClick={gotToSettings}>
                Settings
              </Button>
              &#62;{' '}
              <Button variant="text" onClick={gotToOrganizations}>
                Organizations
              </Button>
              &#62;{' '}
              <Button variant="text" onClick={gotToTeams}>
                Teams
              </Button>
              &#62; {ctx?.selectedTeam !== null ? 'Update' : 'Add'}
            </h5>
          </div>
          <Card variant="outlined">
            <CardContent>
              <h4>Add Team to Org</h4>
              <FormControl fullWidth={true} variant="outlined">
                  <InputLabel
                    error={!!errors?.name?.message}
                    htmlFor="name">Team name</InputLabel>
                  <Controller
                    name={'name'}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value
                      }
                    }) => (<Input
                      error={!!errors?.name?.message}
                      id="name"
                      onChange={(e) => {
                        trigger('name')
                        onChange(e)
                      }} value={value}/>)}
                  />
                  {!!errors?.name?.message && <FormHelperText error={!!errors?.name?.message} >{errors?.name?.message}</FormHelperText>}
              </FormControl>
              <FormControl sx={{ margin: '1em 0' }} fullWidth={true}>
                  <LoadingButton disabled={!isDirty || !isValid} loading={loading} type="submit" color="primary" variant="contained">
                    <span style={{ textTransform: 'none' }}>Save</span>
                  </LoadingButton>
                </FormControl>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  )
}

export default UpsertTeam
