import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ApplicationContext from 'src/store/application-context-provider'
import { Auth } from 'aws-amplify'
import { Button } from 'src/components/atoms/button'
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo-cloudgto.svg'
import { PasswordInput } from 'src/components/atoms/password-input'
import { Alert } from 'src/components/atoms/alert'
import Preloader from 'src/components/UI/preloader/Preloader'

interface FormChangePassword {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const ChangePassword: React.FC = () => {
  const ctx = useContext(ApplicationContext)
  const [user] = useState(ctx?.authenticatedUser)
  const [errText, setErrText] = useState('')
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const schema = yup.object({
    oldPassword: yup
      .string()
      .required('This field is required'),
    newPassword: yup
      .string()
      .required('This field is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: yup
      .string()
      .required('This field is required')
      .test(
        'confirmPassword',
        'Passwords do not match',
        (value) => {
          if (value === getValues().newPassword) return true
          return false
        }
      )
  })

  const {
    trigger,
    getValues,
    handleSubmit,
    control,
    reset,
    register
    ,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormChangePassword >({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''

    },
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    reset({
      oldPassword: location.pathname === '/reset-password' ? localStorage.getItem('temporary_password') as string : '',
      newPassword: '',
      confirmPassword: ''

    })
  }, [reset])

  const passwordChanged = (navigateLocation: string) => {
    setIsPasswordChanged(true)
    setTimeout(() => {
      navigate(navigateLocation)
    }, 3000)
  }
  useEffect(() => {
    console.log('location.pathname', location.pathname)
  }, [location])
  const onSubmit = async (data: FormChangePassword) => {
    if (data.newPassword !== data.confirmPassword) {
      setErrText('Passwords do not match')
      return
    }
    setErrText('')
    const password = data.newPassword
    const oldPassword = location.pathname === '/change-password' ? data.oldPassword : ''
    const verificationCode = location.pathname === '/reset-password-code' ? data.oldPassword : ''
    if (location.pathname === '/change-password') {
      try {
        setIsloading(true)
        const authUser = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(authUser, oldPassword, password)
        passwordChanged('/')
        setIsloading(false)
      } catch (err) {
        console.log(err)
        setErrText((err as Error).message)
        setIsloading(false)
      }
      ;
    }
    if (location.pathname === '/reset-password') {
      try {
        setIsloading(true)
        await Auth.completeNewPassword(user, password)
        passwordChanged('/select-organization')
        setIsloading(false)
      } catch (err) {
        if (errText.includes('Invalid session for the user, session is expired.')) {
          window.location.reload()
        }

        setErrText((err as Error).message)
        setIsloading(false)
      }
      ;
    }

    // Auth.forgotPasswordSubmit(username, code, new_password)
    // .then(data => console.log(data))
    // .catch(err => console.log(err));
    if (location.pathname === '/reset-password-code') {
      try {
        setIsloading(true)
        await Auth.forgotPasswordSubmit('almir@serverlessguru.com', verificationCode, password)
        passwordChanged('/login')
        setIsloading(false)
      } catch (err) {
        setErrText((err as Error).message)
        setIsloading(false)
      }
      ;
    }
  }
  return (
    <>
    <div style={{ width: '100%', height: '100%', position: 'fixed', top: '0', left: '0' }} className={'bg-[#020409] z-50'}></div>
    <div className={'fixed inset-0 z-50 text-white w-screen flex flex-col items-center justify-center gap-[50px] h-screen'}>
        <LogoIcon/>
        <div className={'bg-[#171B21] rounded-2xl md:w-[478px] sm:w-full'}>
          <div className={'p-8'}>
            <div className={'flex flex-col gap-[22px]'}>
              <div><div className={'text-2xl font-normal'}>Set a new password</div>
                {!isPasswordChanged && <div className={'text-[#7E858F] text-xs'}>Your new password must be 8 characters or longer. To have a strong password, include numbers, letters and punctuation.</div>} </div>
              {errText && <Alert isCloseIcon={true} onClose={() => { setErrText('') }} variant={'error'}> {errText}</Alert>}
              {isPasswordChanged && (
                <Alert variant={'success'}>
                  Password successfully changed
                </Alert>
              )}
              <div className={'border-b-[1px] border-[#2B2D31]'}></div>
            </div>

            <form className={'flex flex-col mt-[16px] gap-[22px]'}>
              {!isPasswordChanged && <>
                <Controller
                name={'oldPassword'}
                rules={{ required: true }}
                control={control}
                render={({
                  field: {
                    onChange,
                    onBlur,
                    value
                  }
                }) => (<PasswordInput
                  {...register('oldPassword')}
                  disabled={location.pathname === '/reset-password' ? !!localStorage.getItem('temporary_password') : false }
                  labelText={location.pathname === '/reset-password' ? 'Temporary password:' : 'Verification code (Sent in your email):'}
                  error={errors?.oldPassword?.message}
                  onChange={(e) => {
                    trigger('oldPassword')
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={location.pathname === '/reset-password' ? localStorage.getItem('temporary_password') as string : value }/>)}
              />
                <Controller
                  name={'newPassword'}
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: {
                      onChange,
                      onBlur,
                      value
                    }
                  }) => (<PasswordInput
                    {...register('newPassword')}
                    labelText={'New password:'}
                    error={errors?.newPassword?.message}
                    onChange={(e) => {
                      trigger('newPassword')
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}/>)}
                />
                <Controller
                  name={'confirmPassword'}
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: {
                      onChange,
                      onBlur,
                      value
                    }
                  }) => (<PasswordInput
                    {...register('confirmPassword')}
                    labelText={'Confirm new password:'}
                    error={errors?.confirmPassword?.message}
                    onChange={(e) => {
                      trigger('confirmPassword')
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}/>)}
                />
              </>
              }
              <div className={'flex items-start'}>
                {isPasswordChanged
                  ? null
                  : <Button title={'Confirm'} disabled={!isDirty || !isValid} variant={'primary'} onClick={handleSubmit(onSubmit)} type={'submit'}/>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
  <Preloader open={isLoading} modalText={'Loading...'}/>
</>
  )
}

export default ChangePassword
