import React, { FC, ReactNode } from 'react'
import { Combobox } from '@headlessui/react'
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: any[]
  placeholder?: string
  showRequired?: boolean
  type?: 'password' | 'text' | 'email' | 'number'
  iconRight?: ReactNode
  iconLeft?: ReactNode
  error?: string
  ref?: React.ForwardedRef<HTMLInputElement>
  inputClassName?: string
  className?: string
  labelText?: string
  maxLength?: number
}

export const AutocompleteInput: FC<InputProps> = React.forwardRef<
HTMLInputElement,
InputProps
>(
  (
    {
      placeholder = '',
      showRequired = false,
      type = 'text',
      iconRight,
      iconLeft,
      className = '',
      error = '',
      inputClassName = '',
      labelText = '',
      ...props
    },
    ref
  ) => {
    return (
        <div className={className}>
                <label className={`text-xs ${error ? 'text-red-default' : 'text-white'}`}>
                    {labelText}{showRequired && <span className={'ml-[4px] text-red-default'}>*</span>}</label>
            <Combobox >
                <Combobox.Input className={` box-border bg-[#2B2D31] w-full px-[16px] text-white cursor-pointer rounded-lg py-[13px] block font-normal text-xs hover:ring-2 ring-[#826AED]    focus:outline-none   focus:ring-2 ring-[#826AED] ${error && 'ring-2 ring-red-default'} `} />
            </Combobox>
                {error && <div className={'mt-[4px] text-[10px] text-red-default'}>{error}</div>}
                </div >

    )
  }
)
AutocompleteInput.displayName = 'AutocompleteInput'
