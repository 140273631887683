import React, { useContext, useEffect } from 'react'
import ResourceFormControls from './ResourceFormControls'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { SQS_TOPIC_TYPE } from 'src/enums'
import ApplicationContext from 'src/store/application-context-provider'
import { TextInput } from 'src/components/atoms/text-input'
import { SelectOutlinedInput } from 'src/components/atoms/select-outlined'
import { AutocompleteInput } from 'src/components/atoms/autocomplete-input'

interface FormSns {
  topicName: string
  topicType: SQS_TOPIC_TYPE
  description: string
  tags: any[]
}

const ResourceSnsForm: React.FC<{
  close: Function
  submit: Function
}> = props => {
  const {
    close,
    submit
  } = props

  const ctx = useContext(ApplicationContext)

  const schema = yup.object({
    topicName: yup
      .string()
      .required('This field is required')
      .max(25)
  })
  const {
    handleSubmit,
    control,
    reset,
    register,
    trigger,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm<FormSns>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      topicName: '',
      topicType: SQS_TOPIC_TYPE.SIMPLE,
      description: '',
      tags: []
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      topicName: ctx?.resourceInput?.name,
      topicType: ctx?.resourceInput?.topicType,
      description: ctx?.resourceInput?.description,
      tags: ctx?.resourceInput?.tags
    })
  }, [reset])

  const onSubmit = (data: FormSns) => {
    const input = ctx?.resourceInput
    input.name = data.topicName
    input.topicType = data.topicType
    input.tags = data.tags ?? null
    input.description = data.description ?? null
    ctx?.setResourceInput(input)
    console.log('input sns', input)
    submit()
  }

  return (
        <form>
            <div className={'flex px-6 flex-col gap-[16px] mt-[16px]'}>
                <Controller
                    name={'topicType'}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value
                      }
                    }) => (
                        <SelectOutlinedInput
                            error={errors?.topicType?.message}
                            selected={value}
                            options={[
                              { name: 'Standard', value: SQS_TOPIC_TYPE.SIMPLE }
                              // { name: 'FIFO (first in, first out)', value: SQS_TOPIC_TYPE.FIFO },
                            ]
                            }
                            labelText={'Topic Type'}
                            handleValueChange={(e) => {
                              trigger('topicType')
                              onChange(e)
                            }}/>
                    )}
                />
                <Controller
                    name={'topicName'}
                    rules={{ required: true }}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                        {...register('topicName', { maxLength: 25 })}
                        required
                        labelText={'Topic Name'}
                        showRequired
                        maxLength={25}
                        error={errors?.topicName?.message}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value?.replace(/[&/\\#,+()$~%.'":;|[\]@*^?<>{}\s]/g, '')}/>)}
                />

                <Controller
                    name={'description'}
                    control={control}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value
                      }
                    }) => (<TextInput
                        {...register('description', { maxLength: 60 })}
                        labelText={'Description'}
                        maxLength={60}
                        error={errors?.description?.message}
                        id="resourceName"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}/>)}
                />
                <div>
                    <Controller
                        name={'tags'}
                        control={control}
                        render={({
                          field: {
                            onChange,
                            onBlur,
                            value
                          }
                        }) => (<AutocompleteInput labelText={'Tags'}
                                                           {...register('tags')}
                                                           onChange={onChange}
                                                           onBlur={onBlur}
                                                           value={value}/>)}
                    />
                    <div className="text-[#7E858F] text-[10px]">Press enter to add a tag</div>
                </div>

            </div>
            <ResourceFormControls isDisabledSaveButton={!isDirty || !isValid} onSubmit={handleSubmit(onSubmit)}
                                  onClose={close}/>
        </form>
  )
}

export default ResourceSnsForm
