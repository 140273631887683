export enum Timeout {
  Seconds = 'seconds',
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
}

export enum SQS_TOPIC_TYPE {
  SIMPLE = 'SIMPLE',
  FIFO = 'FIFO',
}

export enum RUNTIME_TYPE {
  NODEJS12 = 'NODEJS12',
  NODEJS14 = 'NODEJS14',
  NODEJS16 = 'NODEJS16',
  NODEJS18 = 'NODEJS18',
}
export enum LAMBDA_MEMORY_TYPE {
  MB128 = 128,
  MB256 = 256,
  MB512 = 512,
  MB1024 = 1024,
  MB1536 = 1536,
  MB2048 = 2048,
  MB2560 = 2560,
  MB3008 = 3008,
  MB4096 = 4096,
  MB5120 = 5120,
  MB6144 = 6144,
  MB7168 = 7168,
  MB8192 = 8192,
  MB9216 = 9216,
  MB10240 = 10240,
}
export enum LAMBDA_TIMEOUT_TYPE {
  SEC5 = 5,
  SEC10 = 10,
  SEC15 = 15,
  SEC20 = 20,
  SEC30 = 30,
  SEC60 = 60,
  SEC300 = 300,
  SEC600 = 600,
  SEC900 = 900
}
export enum DYNAMO_BILLING_MODE_TYPE {
  PAY_PER_REQUEST = 'PAY_PER_REQUEST',
  PROVISIONED = 'PROVISIONED',
}
export enum DYNAMO_KEY_TYPE {
  COMPOSITE = 'COMPOSITE',
  SIMPLE = 'SIMPLE'
}
export enum DYNAMO_PRIMARY_KEY_TYPE {
  STRING = 'S',
}
export enum RDS_ENGINE_TYPE {
  MySql = 'MYSQL',
  PostgreSql = 'PSQL'
}

export enum RESOURCE_TYPE {
  LAMBDA = 'Lambda',
  COGNITO_USER_POOL = 'Cognito',
  DYNAMO_DB = 'Dynamo',
  RDS = 'Rds',
  SNS = 'Sns',
  SQS = 'Sqs',
  S3 = 'S3',
  VPC = 'Vpc',
  ROUTE = 'Route',
  TRIGGER = 'Trigger',
  MODEL = 'Model',
  RESOLVER = 'Resolver',
}
export enum DEPLOYMENT_NOTE_TYPE {
  MAC = 'MAC',
  WINDOWS = 'WINDOWS'
}

export enum STATUS_TEXT_TYPE {
  NOT_FOUND = 'Not Found',
  OK = 'OK'
}
export enum PREVIEW_TYPE {
  CODE_PREVIEW = 'Code Preview',
  ARCHITECTURE_PREVIEW = 'Architecture Preview'
}
export enum AUTH_API_DETAILS_TYPE {
  COGNITO = 'Cognito',
  APIKEY = 'Api_Keys'
}
export enum LOGGING_API_DETAILS_TYPE {
  NONE = 'NONE',
  ERROR = 'ERROR',
  ALL = 'ALL'
}
export enum RESOLVER_TYPE {
  GET = 'get',
  LIST = 'list',
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
  FIELD = 'field',
  SCAN = 'scan',
  QUERY = 'query'
}
export enum FIELD_TYPE {
  ID = 'ID',
  STRING = 'String',
  BOOLEAN = 'Boolean',
  AWS_DATE = 'AWSDate',
  AWS_TIME = 'AWSTime',
  AWS_DATE_TIME = 'AWSDateTime',
  AWS_TIME_STAMP = 'AWSTimestamp',
  AWS_EMAIL = 'AWSEmail',
  AWS_Phone = 'AWSPhone',
  AWS_URL = 'AWSURL',
  AWS_IP_ADDRESS = 'AWSIPAddress',
  INT = 'Int',
  FLOAT = 'Float',
  AWS_JSON = 'AWSJSON',
}
export enum FIELDTYPE_TYPE {
  SCALAR = 'Scalar',
  MODEL = 'Model',
  CUSTOM = 'Custom'
}
export enum PROJECTION_TYPE {
  ALL = 'ALL',
  KEYS_ONLY = 'KEYS_ONLY',
  INCLUDE = 'INCLUDE',
}
